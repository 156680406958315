import React, { createContext, useMemo, useContext } from 'react';
import { PixelRatio, useWindowDimensions } from 'react-native';
import isDesktopMode, { isMobileMode, isTabletMode } from 'common/utils/desktopMode';
import PropTypes from 'prop-types';

export const baseSizeType = {
  mobile: { // Use iPhone6 as base size which is 375 x 667
    width: 375,
    height: 667,
  },
  tablet: {
    width: 1024,
    height: 768,
  },
  desktop: { // not really used (except for createContext params) but here to be homogenous
    width: 1480,
    height: 1024,
  },
};

const getTheme = (width, height, scale = 1, isNormalized = false, baseSize = baseSizeType.desktop) => {
  const baseWidth = baseSize.width;
  const baseHeight = baseSize.height;
  const widthBaseScale = !isNormalized ? 1 : width / baseWidth;
  const heightBaseScale = !isNormalized ? 1 : height / baseHeight;

  const normalize = (pixel, based) => {
    const newSize = based === 'height' ? pixel * heightBaseScale : pixel * widthBaseScale;

    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  };
  const normalizeFont = (size) => Math.round(normalize(size) / PixelRatio.getFontScale());

  return {
    fonts: {
      default: 'Avenir',
      bold: 'AvenirBold',
      russianDefault: 'Roboto',
      russianBold: 'RobotoBold',
    },
    colors: {
      primary: '#74A6F1',
      primaryBright: '#60E8FF',
      secondary: '#FFD853',
      secondaryLight: '#FBEEB5',
      secondaryDark: '#FF9715',
      beach: '#FBEEB5',
      danger: '#e74C3C',
      dangerBright: '#FF1D47',
      dangerLight: '#FF9279',
      dangerLightest: '#ffcdbb',
      success: '#78e08f',
      orange: '#ffab2d',
      darkOrange: '#e29014',
      white: '#fff',
      whiteAlpha: 'rgba(255,255,255,0.3)',
      black: '#000',
      light: '#f4f4f4',
      dark: '#1d1c26',
      darkBlue: '#25478B',
      darkerBlue: '#3A3947',
      darkGrey: '#2f2f2f',
      info: '#60a3bc',
      link: '#4a69bd',
      warning: '#E74C3C',
      greyLightest: '#f9f9f9',
      greyLighter: '#dddddd',
      greyDarker: '#bdc3c7',
      greyAlpha: 'rgba(0,0,0,0.5)',
      greyMedium: '#ccc',
      greyDarkest: '#76757C',
      transparent: '#FFFFFF00',
      lightBrown: '#FAEEB5',
      lightYellow: '#FFC907',
      darkerYellow: '#B5C327',
      darkerGreen: '#20BF55',
      lighterGreen: '#01BAEF',
      purple: '#7585f0',
      lightPurple: '#b8d9ff',
    },
    sizings: {
      tiny: normalize(4),
      small: normalize(8),
      smallMedium: normalize(12),
      medium: normalize(16),
      mediumLarge: normalize(24),
      large: normalize(32),
      larger: normalize(48),
      waiterMenuSidebar: normalize(60),
      oneThirdScreen: width * 0.35,
      twoThirdsScreen: width * 0.65,
      halfScreen: width * 0.5,
    },
    radius: {
      small: normalize(4),
      medium: normalize(10),
      large: normalize(20),
      rounded: normalize(1000),
    },
    fontSizes: {
      tiny: normalizeFont(10),
      small: normalizeFont(12),
      default: normalizeFont(14),
      medium: normalizeFont(16),
      large: normalizeFont(18),
      larger: normalizeFont(20),
      largest: normalizeFont(24),
      huge: normalizeFont(36),
    },
    orderStateColor: {
      at_arrival: '#25478B', // status deleted
      created: '#1D1C26',
      in_preparation: '#FFD853',
      ready: '#78e08f',
      done: '#74A6F1',
      canceled: '#B8B5C0',
      cornflowerblue: '#7ca4e0',
      goldenrod: '#e49e34',
      cornflowerbluebackground: '#cce0ff',
      goldenrodbackground: '#fbeeb5',
    },
    normalize,
    normalizeFont,
    screen: {
      width,
      height,
      scale,
    },
  };
};

export const ThemeContext = createContext({
  theme: getTheme(baseSizeType.desktop.width, baseSizeType.desktop.height),
});

export const ThemeProvider = ({ children }) => {
  const { width, height, scale } = useWindowDimensions();

  const context = useMemo(() => {
    const isNormalized = !isDesktopMode({ width, height });

    let usedBaseSizeName = 'desktop';

    if (isMobileMode({ width, height })) {
      usedBaseSizeName = 'mobile';
    } else if (isTabletMode({ width, height })) {
      usedBaseSizeName = 'tablet';
    }
    return {
      theme: getTheme(width, height, scale, isNormalized, baseSizeType[usedBaseSizeName]),
    };
  }, [height, scale, width]);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useTheme = () => useContext(ThemeContext);

export default useTheme;
