import React, {
  useState, useCallback, useContext, createContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import fetchJSON from 'common/utils/fetchJSON';
import useAuth from 'auth/contexts/auth';

const urlMessages = 'chat-messages';
const urlUsers = 'chat-users';
const ChatContext = createContext();
// On converti le JSON Message du serveur pour l'adapter au GiftedChat
const formatMessage = (msg) => (
  {
    _id: msg.id,
    text: msg.text,
    createdAt: msg.created_at,
    user: { ...msg.sender, _id: msg.sender.id },
  }
);

export const ChatProvider = ({
  children,
}) => {
  const { booking, setBooking } = useAuth();
  // On sauvegarde les messages dans des tableaux par "utilisateur" (chat room général = 0)
  // { userId1: [...], userId2: [....] }
  const [messages, setMessages] = useState({});
  const [chatWith, setChatWith] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isUserFetching, setIsUserFetching] = useState(false);
  const [isMessageFetching, setIsMessageFetching] = useState(false);
  const [chatActive, setChatActive] = useState(false);

  const chatUser = useMemo(() => booking?.chat_user, [booking]);

  const fetchMessages = useCallback(async () => {
    if (!chatWith) {
      return;
    }

    let params;

    if (chatWith?.id !== 0) {
      // TODO il faut le faire côté back ca, car il faut soit les messages
      // avec sender =X et receiver = Y ou sender=Y et receiver =X
      // Et surtout pour la sécurité on doit pas pouvoir faire les filtres que l'on veut
      params = {
        me: chatUser?.id,
        user: chatWith?.id,
        booking: booking?.id,
      };
    } else {
      params = {
        me: chatUser?.id,
        booking: booking?.id,
      };
    }

    setIsMessageFetching(true);
    try {
      const esc = encodeURIComponent;
      const queryParams = Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((value) => `${esc(key)}=${esc(value)}`).join('&');
        }
        return `${esc(key)}=${esc(params[key])}`;
      }).join('&');
      const res = await fetchJSON({
        url: `${urlMessages}?${queryParams}`,
        method: 'GET',
      });

      // Prevent formatMessage to crash if no sender, TODO voir comment on gere si sender est supprimé
      const messages = res.filter((r) => r.sender !== null);

      // On formatte tous les messages pour Gifted Chat (pas le meme format JSON)
      const formattedMessages = messages.map((m) => formatMessage(m));

      // On sauvegarde les messages dans des tableaux par "utilisateur" (chat room général = 0)
      setMessages((messages) => (
        {
          ...messages,
          [chatWith.id]: formattedMessages,
        }
      ));

      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsMessageFetching(false);
    }
  }, [chatUser, booking, chatWith]);

  const sendMessage = useCallback(async (message, to) => {
    const msg = {
      text: message.text,
      receiver: to !== 0 ? to.id : null,
      sender: chatUser?.id,
      site: booking?.site,
    };

    setIsMessageFetching(true);
    try {
      const res = await fetchJSON({ url: urlMessages, method: 'POST', payload: msg });
      const formattedMessage = formatMessage(res);

      // On sauvegarde les messages dans des tableaux par "utilisateur" (chat room général = 0)
      setMessages((messages) => ({
        ...messages,
        [to.id]: messages[to.id] ? [formattedMessage, ...messages[to.id]] : [formattedMessage],
      }));
    } catch (e) {
      setError(e);
    } finally {
      setIsMessageFetching(false);
    }
  }, [chatUser, booking]);

  const fetchUsers = useCallback(async () => {
    const params = {
      bookingId: booking.id,
    };

    setIsUserFetching(true);
    try {
      const esc = encodeURIComponent;
      const queryParams = Object.keys(params).map((key) => `${esc(key)}=${esc(params[key])}`).join('&');
      const res = await fetchJSON({
        url: `${urlUsers}?${queryParams}`,
        method: 'GET',
      });

      if (res.error) {
        setUsers([]);
      } else {
        setUsers(res);
      }
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsUserFetching(false);
    }
  }, [booking]);

  const saveChatUser = useCallback(async (user) => {
    setIsUserFetching(true);

    const payload = {
      ...user,
      site: booking.site.id,
      booking: booking.id,
    };

    try {
      let res;

      if (payload.id) {
        res = await fetchJSON({ url: `${urlUsers}/${payload.id}`, method: 'PUT', payload });
      } else {
        res = await fetchJSON({ url: urlUsers, method: 'POST', payload });
      }

      const updatedBooking = { ...booking, chat_user: res };

      setBooking(updatedBooking);

      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsUserFetching(false);
    }
  }, [booking, setBooking]);

  const blockUser = useCallback(async () => {
    setIsUserFetching(true);

    try {
      const res = await fetchJSON({
        url: `${urlUsers}/${chatWith.id}/block`,
        method: 'POST',
        payload: { chatUser: booking?.chat_user.id },
      });

      return (res);
    } catch (e) {
      setError(e);
    } finally {
      setIsUserFetching(false);
    }
  }, [booking, chatWith]);

  const reportUser = useCallback(async () => {
    setIsUserFetching(true);

    try {
      const res = await fetchJSON({
        url: `${urlUsers}/${chatWith.id}/report`,
        method: 'POST',
        payload: { chatUser: booking?.chat_user.id, site: booking.site.id },
      });

      return (res);
    } catch (e) {
      setError(e);
    } finally {
      setIsUserFetching(false);
    }
  }, [booking, chatWith]);

  return (
    <ChatContext.Provider
      value={{
        fetchUsers,
        saveChatUser,
        fetchMessages,
        sendMessage,
        setChatWith,
        chatActive,
        setChatActive,
        blockUser,
        reportUser,
        chatUser,
        messages,
        users,
        error,
        isUserFetching,
        isMessageFetching,
        chatWith,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useChat = () => useContext(ChatContext);

export default useChat;
