import { FontAwesome } from '@expo/vector-icons';
import HorizontalItemCard from 'common/components/HorizontalItemCard/HorizontalItemCard';
import NavigationButton from 'common/components/NavigationButton/NavigationButton';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';

const styles = (theme, active) => StyleSheet.create({
  header: {
    marginBottom: theme.sizings.small,
  },

  itemName: {
    marginBottom: theme.sizings.small,
  },

  content: {
    height: theme.normalize(200),
  },

  metas: {
    flexDirection: 'row',
    marginBottom: theme.sizings.small,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  distance: {
    flexDirection: 'row',
  },

  description: {
    marginBottom: theme.sizings.small,
  },

  button: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
  },

  distanceText: {
    marginLeft: theme.sizings.small,
  },

  state: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  stateText: {
    marginRight: theme.sizings.small,
  },

  stateIcon: {
    width: theme.fontSizes.tiny,
    height: theme.fontSizes.tiny,
    borderRadius: theme.radius.rounded,
    backgroundColor: active ? theme.colors.success : theme.colors.danger,
  },
});

const Around = ({ categoryItems, navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const handlePress = (item) => {
    navigation.navigate('AroundDetail', { location: item });
  };

  const isOpen = (item) => dayjs() > dayjs(item.publication_start) && dayjs() < dayjs(item.publication_end);

  return (
    <Page scroll padding style={{ marginBottom: 0 }}>
      {categoryItems.map((category) => (
        category.items.length > 0 && (
          <View key={category.id}>
            <View style={styles(theme).header}>
              <TranslatedText isBold size="larger" color="primary" value={category.name} />
            </View>
            {category.items.map((item) => (
              <React.Fragment key={item.id}>
                <View style={styles(theme).itemName}>
                  <TranslatedText isBold size="large" color="dark" value={item.name} />
                </View>
                <HorizontalItemCard
                  onPress={() => handlePress(item)}
                  image={(item.images.length > 0 ? item.images[0] : {})}
                >
                  <View style={styles(theme).content}>
                    <View style={styles(theme).metas}>
                      <View style={styles(theme).distance}>
                        <FontAwesome
                          name="location-arrow"
                          size={theme.fontSizes.default}
                          color={theme.colors.secondaryLight}
                        />
                        <Text
                          color="greyDarker"
                          style={styles(theme).distanceText}
                        >
                          {t('around.howFar', { distance: item.distance })}
                        </Text>
                      </View>
                      <View style={styles(theme, isOpen(item)).state}>
                        <Text
                          color="greyDarker"
                          style={styles(theme, isOpen(item)).stateText}
                        >
                          {isOpen(item) ? 'Open' : 'Closed'}
                        </Text>
                        <View style={styles(theme, isOpen(item)).stateIcon} />
                      </View>
                    </View>
                    <View style={styles(theme).description}>
                      <TranslatedText
                        tagsStyles={{ p: { color: theme.colors.greyDarker } }}
                        value={item.description}
                        truncateLength={200}
                      />
                    </View>
                    <View style={styles(theme).button}>
                      <NavigationButton
                        title={t('common.seeDetails')}
                        link="AroundDetail"
                        icon="eye"
                        variant="gradient"
                        iconColor={theme.colors.light}
                        iconSize={theme.fontSizes.default}
                        params={{ location: item }}
                      />
                    </View>
                  </View>
                </HorizontalItemCard>
              </React.Fragment>
            ))}
          </View>
        )
      ))}
    </Page>
  );
};

Around.propTypes = {
  navigation: PropTypes.object.isRequired,
  categoryItems: PropTypes.array,
};

Around.defaultProps = {
  categoryItems: [],
};

export default Around;
