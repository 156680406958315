import { Dimensions, StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  categoryView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    marginBottom: theme.sizings.medium,
    borderRadius: theme.radius.small,
  },

  title: {
    marginTop: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  productsCountView: {
    width: theme.normalize(50),
    height: theme.normalize(30),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
  },

  productCount: {
    marginRight: theme.sizings.tiny,
  },

  reductionBadge: {
    backgroundColor: theme.colors.danger,
    borderRadius: theme.radius.large,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
  },

  bottomImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: theme.normalize(300),
  },
});
