import React, {
  createContext, useContext, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import useAlert from 'common/contexts/alert';
import { useTranslation } from 'react-i18next';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

export const SeatSelectorContext = createContext();

export const SeatSelectorProvider = ({ children }) => {
  const {
    handleClickonSquare,
  } = useBeachVisualizer(); // clara 26/01/22, pour que le BoardSquare sache qu'il est coché

  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const [itemDescription, setItemDescription] = useState({});
  const [items, setItems] = useState([]);
  const [selectedFullSeats, setSelectedFullSeats] = useState([]); // full seats
  const [targetSeat, setTargetSeat] = useState(null);

  /* const addTargetToSelectedSeats = useCallback((oldMax = 0) => {
    const newItems = [...items];
    const item = { price: targetSeat.price, ...newItems.find(({ id }) => targetSeat.id === id) };

    if (selectedSeats.length < 3
      && (oldMax === 0
        || (oldMax !== 0 && selectedSeats.reduce((a, b) => a + (b.price || 0), 0) + targetSeat.price <= oldMax))
    ) {
      item.selected = true;
      setSelectedSeats([...selectedSeats, item]);
      setItems(newItems);
    } else if ((oldMax !== 0 && selectedSeats.reduce((a, b) => a + (b.price || 0), 0) + targetSeat.price > oldMax)) {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.seatsToExpensive') });
    } else {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.seatLimit') });
    }

    setTargetSeat(null);
  }, [items, setItems, selectedSeats, t, setAlert, targetSeat]); */

  const handleClientClickSeat = useCallback((sitem) => {
    /*
    const newItems = [...items];
    const item = { price: sitem.price, ...newItems.find(({ id }) => sitem.id === id) };

    if (item.selected) {
      item.selected = !item.selected;
      setSelectedSeats(selectedSeats.filter(((seat) => seat.id !== sitem.id)));
      setItems(newItems);
    } else {
      setTargetSeat(item);
    } */

    // clara 26/01/22
    setSelectedFullSeats((oldSelection) => {
      const isSelected = oldSelection.find(((seat) => seat.id === sitem.id));

      if (isSelected) {
        return oldSelection.filter(((seat) => seat.id !== sitem.id));
      }
      return ([...oldSelection, sitem]);
    });
    handleClickonSquare(sitem.id);
  }, [setSelectedFullSeats, handleClickonSquare]);

  return (
    <SeatSelectorContext.Provider
      value={{
        setItemDescription,
        itemDescription,
        items,
        setItems,
        selectedFullSeats,
        setSelectedFullSeats,
        // addTargetToSelectedSeats,
        setTargetSeat,
        handleClientClickSeat,
        targetSeat,
      }}
    >
      {children}
    </SeatSelectorContext.Provider>
  );
};

SeatSelectorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(SeatSelectorContext);
