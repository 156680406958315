import { FontAwesome5 } from '@expo/vector-icons';
import useTranslation from 'common/contexts/translations';
import { isMobileMode } from 'common/utils/desktopMode';
import fetchJSON from 'common/utils/fetchJSON';
import moment from 'moment';
import useSiteProvider from 'providers/SiteProvider';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  StyleSheet, Text, View, useWindowDimensions,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import JText from 'common/components/Text/Text';
import useTheme from 'providers/ThemeProvider';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

const BookingStats = () => {
  const { t } = useTranslation();
  const { site } = useSiteProvider();
  const { bookingItems } = useBeachVisualizer();
  const [stats, setStats] = useState();
  const [shownStat, setShownStat] = useState(0);

  const { theme } = useTheme();

  const styles = makeStyles(theme);

  const calcStats = useCallback((bookingResults, fromDate, toDate) => {
    const bookings = bookingResults.filter(
      (b) => b.created_at <= moment(toDate).utc().endOf('day').format()
          && b.created_at >= moment(fromDate).utc().startOf('day').format(),
    );

    // Get total number of seats (reservable)
    const activeMapsId = site.maps?.filter((mapitem) => Boolean(mapitem.active)).map((mapitem) => mapitem.id);
    const nbSiteSeats = site.seats?.filter((s) => s.outOfOrder !== true
    && activeMapsId.find((mapid) => mapid === s.map))?.length;
    const nbSeatsForPeriod = nbSiteSeats * (moment(toDate).diff(fromDate, 'days') + 1);

    // Get nb of seats booked / seats available.
    const seatsUsed = bookings.reduce((acc, b) => (acc + b.seats?.length), 0);

    // Get average order cost
    const products = [];

    let productsKey;

    bookings.forEach((booking) => {
      booking.orders.forEach((order) => {
        order.order_items.forEach((product) => {
          const currentProduct = products.findIndex((p) => product?.reference === p.product?.reference);

          if (currentProduct === -1) {
            // eslint-disable-next-line no-param-reassign
            product.count = 0;
            productsKey = products.push(product) - 1;
          } else {
            productsKey = currentProduct;
          }

          products[productsKey].count += product.quantity;
        });
      });
    });

    const nbNoShow = bookings.reduce(
      (total, b) => total + b.booking_items.filter(
        (bi) => bi.status === 'noShow',
      ).length,
      0,
    );
    const nbCanceled = bookings.reduce(
      (total, b) => total + b.booking_items.filter(
        (bi) => bi.status === 'cancelled',
      ).length,
      0,
    );

    return {
      nbBookings: bookings.length,
      nbNoShow,
      nbCanceled,
      seatsUsed,
      seatsNb: nbSeatsForPeriod,
    };
  }, [site]);

  useEffect(() => {
    const getStats = async () => {
      const res = await fetchJSON({
        url: `bookings?created_at_gte=${moment().utc().startOf('month').format()}`,
        method: 'GET',
      });

      setStats([
        {
          ...calcStats(
            res,
            moment().utc().startOf('day'),
            moment().utc().endOf('day'),
          ),
          name: t('stats.day'),
        },
        {
          ...calcStats(
            res,
            moment().utc().startOf('isoweek'),
            moment().utc().endOf('isoweek'),
          ),
          name: t('stats.week'),
        },
        {
          ...calcStats(
            res,
            moment().utc().startOf('month'),
            moment().utc().endOf('month'),
          ),
          name: t('stats.month'),
        },
      ]);
    };

    getStats();
  }, [calcStats, t, bookingItems]);

  return (
    <View style={styles.statsInfos}>
      {stats && stats.map((stat, index) => (
        <TouchableOpacity key={`stat-${index}`} style={styles.statsItem} onPress={() => setShownStat(index)}>
          <JText style={styles.statsTitle} color="greyLighter" isBold isUppercase size="tiny">{stat.name}</JText>
          <JText style={styles.statsValue} color="greyLighter">
            {index === 0
              ? `${stat?.seatsUsed || 0}/${stat?.seatsNb || 0}`
              : `${Math.round((100 * stat?.seatsUsed || 0) / (stat?.seatsNb || 1))} %`}
          </JText>
          { shownStat === index
          && (
          <>
            <View style={styles.statItem}>
              <Text
                style={{
                  fontSize: theme.fontSizes.medium,
                  color: theme.colors.greyLighter,
                }}
              >

                <FontAwesome5
                  name="ghost"
                  color={theme.colors.greyLighter}
                  size={theme.sizings.medium}
                  style={styles.icon}
                />

                {`${stat?.nbNoShow || 0}`}

              </Text>
            </View>

            <View style={styles.statItem}>
              <Text
                style={{
                  fontSize: theme.fontSizes.medium,
                  color: theme.colors.greyLighter,
                }}
              >
                <FontAwesome5
                  name="ban"
                  color={theme.colors.greyLighter}
                  size={theme.sizings.medium}
                  style={styles.icon}
                />

                {`${stat?.nbCanceled || 0}`}
              </Text>
            </View>
          </>
          )}
        </TouchableOpacity>
      ))}
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  statsInfos: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.sizings.waiterMenuSidebar,
  },
  statsItem: {
    flexDirection: 'column',
    backgroundColor: 'rgb(29, 28, 38)',
    width: theme.sizings.waiterMenuSidebar,
    cursor: 'pointer',
  },
  statsTitle: {
    borderColor: theme.colors.whiteAlpha,
    borderTopWidth: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.sizings.small,
  },
  statsValue: {
    paddingVertical: theme.sizings.small,
    width: '100%',
    textAlign: 'center',
    // lineHeight: '1em',
  },
  statItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.small * 0.7,
    paddingVertical: theme.sizings.tiny,
  },
  icon: {
    marginRight: theme.sizings.tiny,
    position: 'relative',
    top: -2,
  },
});

export default BookingStats;
