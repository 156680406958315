import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  image: {
    width: theme.normalize(130),
    height: theme.normalize(120),
    alignSelf: 'center',
    marginBottom: theme.sizings.large,
  },

  price: {
    alignItems: 'center',
  },

  creditCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.sizings.medium,
    padding: theme.sizings.small,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.medium,
  },

  creditCardView: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  creditCardInfo: {
    marginLeft: theme.sizings.small,
    justifyContent: 'center',
  },

  activeBookingButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  activeBookingButton: {
    paddingHorizontal: theme.normalize(10),
    paddingVertical: theme.sizings.tiny,
  },

});
