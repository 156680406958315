import React from 'react';
import {
  Platform, TouchableOpacity, View, useWindowDimensions,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import useTranslation from 'common/contexts/translations';

import useAuth from 'auth/contexts/auth';
import useBasket from 'basket/contexts/basket';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';
import Confirm from '../Confirm/Confirm';

const LogoutButton = ({ waiterLogout }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { setItems: setBasket } = useBasket();
  const { user, signout } = useAuth();
  const { setCurrentSite } = useSite();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const logout = () => {
    signout();
    setBasket([]);
    !Platform.OS === 'web' && ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    if (user && [
      'waiter',
      'barman',
      'reception',
      'manager',
    ].includes(user.role?.type)) {
      setCurrentSite(null);
    }

    navigation.reset({
      index: 0,
      routes: [{ name: 'home' }],
    });
  };

  const isMobile = isMobileMode(useWindowDimensions());

  return waiterLogout ? (
    <Confirm confirmText={t('auth.confirmLogout')}>
      <TouchableOpacity
        onPress={() => {
          logout();
        }}
      >
        <View style={{
          width: theme.sizings.waiterMenuSidebar,
          height: theme.sizings.waiterMenuSidebar,
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.7,
        }}
        >
          <FontAwesome
            name="sign-out"
            size={theme.fontSizes.larger}
            color={isMobile ? theme.colors.darkGrey : theme.colors.light}
          />
        </View>
      </TouchableOpacity>
    </Confirm>
  ) : (
    <Confirm confirmText={t('auth.confirmLogout')}>
      <TouchableOpacity style={commonStyles.headerIcon} onPress={() => logout()}>
        <FontAwesome
          color={isMobile ? theme.colors.darkGrey : theme.colors.light}
          size={theme.fontSizes.larger}
          name="sign-out"
        />
      </TouchableOpacity>
    </Confirm>
  );
};

LogoutButton.propTypes = {
  waiterLogout: PropTypes.bool,
};

LogoutButton.defaultProps = {
  waiterLogout: false,
};

export default LogoutButton;
