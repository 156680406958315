import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  StyleSheet, TouchableOpacity, View, useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useAuth from 'auth/contexts/auth';
import Confirm from 'common/components/Confirm/Confirm';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Select from 'common/components/Select/Select';

import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import fetchJSON from 'common/utils/fetchJSON';
import PaymentState from 'orders/components/PaymentState/PaymentState';
import useOrders from 'orders/contexts/orders';
import OrderItem from 'waiter/components/BookingPayments/OrderItem';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import StayDuration from 'waiter/components/booking-detail-sections/StayDuration';
import { isMobileMode } from 'common/utils/desktopMode';

const OrderListItem = ({
  order_items: orderItems,
  id,
  created_at: createdAt,
  updated_at: updatedAt,
  price,
  state,
  seat,
  products,
  comments,
  payment,
  barman,
  barmanList,
}) => {
  const [isShown, setIsShown] = useState(true);
  const { t } = useTranslation();
  const navigation = useNavigation();

  const {
    updateOrderState, states, remove, refreshOrders,
  } = useOrders();

  const {
    bookingItemsByStayDuration,
  } = useBeachVisualizer();

  const { setAlert } = useAlert();
  const { user } = useAuth();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = makeStyles(theme, isMobileMode({ width, height }));
  const commonStyles = makeCommonStyles(theme);

  const deleteOrder = useCallback(async (id) => {
    await remove(id);

    refreshOrders();
  }, [remove, refreshOrders]);

  const update = useCallback(async (newOrderState) => {
    try {
      await updateOrderState(id, { state: newOrderState });

      refreshOrders();
      setAlert({ color: 'success', title: t('common.success'), message: t('orders.successUpdate') });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('orders.errorUpdate') });
    }
  }, [id, setAlert, t, updateOrderState, refreshOrders]);

  const assignUser = useCallback(async (barmanId) => {
    await fetchJSON({
      url: `orders/${id}/assign-barman`,
      method: 'PUT',
      payload: {
        barman: barmanId, state: barmanId ? 'in_preparation' : 'created',
      },
    });

    refreshOrders();
  }, [id, refreshOrders]);

  const pickerOptions = useMemo(() => states && Object.keys(states).map((key) => ({
    label: states[key],
    value: key,
  })), [states]);

  const colorStatus = useMemo(() => {
    if (barman?.color) {
      return barman.color;
    }
    if (state === 'created') {
      return theme.colors.greyMedium;
    }

    if (state === 'done' || state === 'ready') {
      return theme.orderStateColor.cornflowerbluebackground;
    }

    return theme.orderStateColor.goldenrodbackground;
  }, [state, barman, theme]);

  const ownerBookingItem = bookingItemsByStayDuration?.find((bi) => (bi.seats.find((s) => s.id === seat.id)));
  const customer = ownerBookingItem?.booking.customer;

  const printCustomerName = () => (
    <>
      <View style={styles.iconText}>
        <FontAwesome5
          name={customer?.customer_category?.name === 'VIP' ? 'crown' : 'user'}
          color={customer?.customer_category?.name === 'VIP'
            ? theme.colors.secondaryDark : theme.colors.primary}
          size={theme.sizings.medium}
          style={styles.icon}
        />
        <View style={{
          flex: 1, paddingLeft: theme.sizings.tiny, flexDirection: 'row', alignItems: 'center',
        }}
        >
          <Text
            isBold
            size="medium"
            color="dark"
            style={{ flex: 1 }}
          >
            {customer?.name ? `${customer?.name} ${customer?.firstName || ''}`
              : `${ownerBookingItem?.booking.clientName} ${ownerBookingItem?.booking.clientFirstName || ''}`}
          </Text>
        </View>
      </View>
    </>
  );

  const printCustomerInfos = () => (
    <>
      <StayDuration stayDuration={ownerBookingItem?.stayDuration} />

      <View style={styles.iconText}>
        <FontAwesome5
          name="chair"
          color={theme.colors.primary}
          size={theme.sizings.medium}
          style={styles.icon}
        />
        <Text isBold size="large" color="black">
          {`#${seat?.name}`}
        </Text>
      </View>

      {(customer?.phone || ownerBookingItem?.booking.clientPhone) && (
      <View style={styles.iconText}>
        <FontAwesome5
          name="mobile"
          color={theme.colors.primary}
          size={theme.sizings.medium}
          style={styles.icon}
        />
        <Text>
          {customer?.phone || ownerBookingItem?.booking.clientPhone}
        </Text>
      </View>
      )}
    </>
  );

  const printActionEdit = () => (
    <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
      <Confirm confirmText={t('orders.deleteConfirm')}>
        <TouchableOpacity style={commonStyles.headerIcon} onPress={() => deleteOrder(id)}>
          <FontAwesome
            color={theme.colors.danger}
            size={theme.fontSizes.large}
            name="trash"
          />
        </TouchableOpacity>
      </Confirm>

      <TouchableOpacity
        style={[commonStyles.headerIcon]}
        onPress={() => navigation.navigate('OrderSeatSelect', { orderId: id })}
      >
        <FontAwesome
          color={theme.colors.greyDarkest}
          size={theme.fontSizes.large}
          name="pencil"
        />
      </TouchableOpacity>

      <TouchableOpacity
        style={commonStyles.headerIcon}
        onPress={() => setIsShown((prev) => !prev)}
      >
        <FontAwesome5
          color={theme.colors.primary}
          size={theme.fontSizes.large}
          name={isShown ? 'angle-up' : 'angle-down'}
        />
      </TouchableOpacity>

    </View>
  );

  const printDate = () => (
    <View style={styles.iconText}>
      <FontAwesome
        size={theme.fontSizes.large}
        name="clock-o"
        color={theme.colors.greyDarkest}
        style={styles.icon}
      />
      <Text style={{ fontStyle: 'italic' }}>
        <RelativeDate value={updatedAt || createdAt} />
      </Text>
    </View>
  );

  return (
    <View style={[styles.orderItem, {
      borderColor: colorStatus,
      borderWidth: theme.normalize(2),
    }]}
    >

      <View style={[styles.head, {
        backgroundColor: colorStatus,
      }]}
      >
        <View style={styles.headId}>
          <Text isBold size="large" color="white">
            {`n°${id} `}
          </Text>
        </View>
        <View style={styles.col}>
          {printCustomerName()}
        </View>
        {!isMobileMode({ width, height }) && (
          <>
            <View style={styles.col}>
              {printDate()}
            </View>
            <View style={styles.col}>
              {printActionEdit()}
            </View>
          </>
        )}
      </View>

      {isMobileMode({ width, height }) && (
      <View style={[styles.head, {
        backgroundColor: colorStatus,
      }]}
      >
        <View style={styles.col}>
          {printDate()}
        </View>
        <View style={styles.col}>
          {printActionEdit()}
        </View>
      </View>
      )}

      {isShown && (
        <View style={{ marginTop: theme.sizings.small }}>
          {orderItems.map((orderItem) => {
            const product = products && products.find((p) => p.id === orderItem.product);
            const variant = product?.option && product.variant_prices.find((v) => (
              v.option_variant.id === orderItem.option_variant));

            return <OrderItem key={orderItem.id} item={orderItem} variant={variant} />;
          })}
        </View>
      )}

      <View style={styles.main}>
        <View style={styles.col}>
          <Select
            options={barmanList}
            onChange={assignUser}
            value={barman?.id}
          />
        </View>

        <View style={styles.col}>
          <Select
            options={pickerOptions}
            onChange={update}
            value={state}
          />
        </View>

        { user?.role?.type === 'waiter' && (
          <View style={styles.col}>
            <PaymentState payment={payment} />
          </View>
          )}

        <View style={styles.col}>
          <Text isBold size="large" color="black" style={{ marginTop: theme.sizings.tiny }}>
            {`${formatPrice(price, site?.currency)}`}
          </Text>
        </View>

      </View>

      <View style={styles.footer}>
        <View style={styles.col}>
          {printCustomerInfos()}
        </View>
        <View style={styles.col}>
          {!!comments && (
          <Text>{comments}</Text>
          )}
        </View>
      </View>

    </View>
  );
};

OrderListItem.propTypes = {
  order_items: PropTypes.array,
  products: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  barman: PropTypes.object,
  seat: PropTypes.object,
  payment: PropTypes.object,
  comments: PropTypes.string,
  barmanList: PropTypes.array.isRequired,
};

OrderListItem.defaultProps = {
  order_items: [],
  seat: {},
  payment: {},
  comments: '',
  barman: {},
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  orderItem: {
    marginBottom: theme.sizings.medium,
    borderRadius: theme.radius.medium,
    height: 'auto',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
  },

  paymentStatusContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
  head: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    height: theme.normalize(50),
  },
  headId: {
    backgroundColor: theme.colors.greyAlpha,
    paddingHorizontal: theme.sizings.small,
    height: '100%',
    justifyContent: 'center',
  },
  col: {
    paddingHorizontal: isMobile ? 0 : theme.sizings.small,
    paddingVertical: isMobile ? theme.sizings.small : 0,
    flex: 1,
  },
  main: {
    flexDirection: isMobile ? 'column' : 'row',
    paddingVertical: theme.sizings.small,
    paddingHorizontal: isMobile ? theme.sizings.small : 0,
    width: '100%',
  },
  footer: {
    flexDirection: 'row',
    paddingVertical: theme.sizings.small,
    paddingHorizontal: isMobile ? theme.sizings.small : 0,
    width: '100%',

  },
  orderStatus: {
    flexDirection: 'row',
  },
});

export default OrderListItem;
