import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SiteListItem from 'sites/components/SiteListItem/SiteListItem';
import useList from 'sites/contexts/lists';
import useTheme from 'providers/ThemeProvider';

const SitesListFiltered = ({ navigation }) => {
  const {
    fetchItems: fetchLists,
    item: list,
    items: lists,
    setFilters,
    isFetching,
  } = useList();
  const { theme } = useTheme();

  useEffect(() => {
    setFilters({
      id: list.id,
    });
  }, [setFilters, list]);

  // fetchSites change à chaque fois que setFilters est appelé
  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <Page safeArea scroll padding style={{ marginBottom: 0, paddingBottom: 0 }}>
      {!lists && lists?.length < 1 && (
        <LoadingSpinner
          visible={isFetching}
          theme={theme}
        />
      )}
      {lists[0]?.sites?.map((site) => (
        <SiteListItem
          navigation={navigation}
          key={site.id}
          siteCurrency={site.currency}
          isFilteredList
          {...site}
        />
      ))}
    </Page>
  );
};

SitesListFiltered.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default SitesListFiltered;
