/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import useTheme from 'providers/ThemeProvider';

import useBookings from 'bookings/contexts/bookings';
import useTranslation from 'common/contexts/translations';
import ItemCard from 'common/components/ItemCard/ItemCard';
import Text from 'common/components/Text/Text';
import NavigationButton from 'common/components/NavigationButton/NavigationButton';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import Price from 'common/components/Price/Price';
import makeCommonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';
import styles from './SiteListItemStyle';

const SiteListItem = ({
  navigation,
  name,
  address,
  description,
  id,
  active,
  images,
  distance,
  is_open,
  min_price: minPrice,
  targetLink,
  siteCurrency,
  isFilteredList,
}) => {
  const { t } = useTranslation();
  const { setItem: setBooking } = useBookings();
  const {
    setItem: setSite, fetchSiteData, setCurrentSite, fetchFullSite,
  } = useSite();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const handlePress = async () => {
    async function fetchAndSetData() {
      const site = await fetchSiteData(id);

      setBooking((prevBooking) => ({
        ...prevBooking,
        site: id,
      }));
      setSite(site);
      navigation.navigate(targetLink, { siteId: id });
    }
    fetchAndSetData();
  };

  const handlePressFiltered = () => {
    async function fetchAndSetData() {
      const site = await fetchFullSite(id);
      const siteData = await fetchSiteData(id);

      setCurrentSite(site);
      setSite(siteData);

      navigation.navigate('HomePage');
    }
    fetchAndSetData();
  };

  return (
    <View style={styles(active, theme).siteItem}>
      <View style={styles(active, theme).header}>
        <Text style={styles(active, theme).title} isBold size="large">{name}</Text>
        <Text size="large">
          {address}
        </Text>
      </View>
      <ItemCard onPress={isFilteredList ? handlePressFiltered : handlePress} image={images[0]}>
        <View style={styles(active, theme).content}>
          <View style={styles(active, theme).metas}>
            <View style={styles(active, theme).description}>
              <TranslatedText
                tagsStyles={{ p: { color: theme.colors.greyDarker } }}
                value={description}
                truncateLength={50}
              />
            </View>
            { distance && <Text color="greyDarker">{t('around.howFar', { distance: Math.floor(distance) })}</Text>}
            <View style={styles(active, theme).state}>
              <Text
                color="greyDarker"
                style={styles(active && is_open, theme).stateText}
              >
                {(active && is_open) ? t('common.open') : t('common.closed')}

              </Text>
              <View style={styles(active && is_open, theme).stateIcon} />
            </View>
          </View>

        </View>
        {minPrice > 0 && (
          <View style={commonStyles.priceWrapper}>
            <Text
              style={commonStyles.priceLabel}
              centered
              isUppercase
              color="darkBlue"
              size="tiny"
            >
              {t('beach.priceFrom')}

            </Text>
            <Price
              price={minPrice}
              size="huge"
              siteCurrency={siteCurrency}
            />
          </View>
        ) }
        <View style={commonStyles.cardButtonWrapper}>
          <NavigationButton
            title={t('common.seeDetails')}
            link={isFilteredList ? 'HomePage' : targetLink}
            icon="eye"
            params={isFilteredList ? undefined : { siteId: id }}
            alsoOnPress={isFilteredList ? handlePressFiltered : handlePress}
            navigate={false}
            variant="gradient"
          />
        </View>
      </ItemCard>
    </View>
  );
};

SiteListItem.propTypes = {
  name: PropTypes.string.isRequired,
  navigation: PropTypes.object.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  address: PropTypes.string,
  active: PropTypes.bool.isRequired,
  is_open: PropTypes.bool.isRequired,
  images: PropTypes.array,
  min_price: PropTypes.number,
  distance: PropTypes.number,
  id: PropTypes.number.isRequired,
  targetLink: PropTypes.string,
  siteCurrency: PropTypes.string,
  isFilteredList: PropTypes.bool,
};

SiteListItem.defaultProps = {
  description: '',
  address: '',
  min_price: null,
  images: [],
  distance: null,
  targetLink: 'SitePresentation',
  siteCurrency: 'EUR',
  isFilteredList: false,
};

export default SiteListItem;
