import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as ScreenOrientation from 'expo-screen-orientation';
import useTranslation from 'common/contexts/translations';

import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useAuth from 'auth/contexts/auth';
import { Platform } from 'react-native';
import useSite from 'sites/contexts/sites';
import useList from 'sites/contexts/lists';
import useSiteProvider from 'providers/SiteProvider';
import useTheme from 'providers/ThemeProvider';
import useBooking from './bookings/contexts/bookings';

const HomeRedirect = ({ navigation }) => {
  const { t } = useTranslation();
  const { refreshUser } = useAuth();
  const [fetchUserDone, setfetchUserDone] = useState(false);
  const { setItem: setSite, fetchSiteData, setCurrentSite } = useSite();
  const { setItem: setList, fetchListData, setCurrentList } = useList();
  const { fetchSite } = useSiteProvider();
  const { theme } = useTheme();
  const { checkCode } = useBooking();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await refreshUser();

      // Lock Screen orientation for User
      !Platform.OS === 'web' && ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock[(user && [
          'waiter',
          'barman',
          'reception',
          'manager'].includes(user.role?.type))
          ? 'DEFAULT'
          : 'PORTRAIT_UP'],
      );

      // User is staff
      if (user && [
        'waiter',
        'barman',
        'reception',
        'manager',
      ].includes(user.role?.type)) {
        fetchSite(user.site.id);
        navigation.reset({
          index: 0,
          routes: [{
            name: 'WaiterHome',
          }],
        });
        return;
      }

      /* ====================== SITE & LIST ID ==================== */

      let listId;

      let siteId;

      let codeId;

      // TODO pas que pour le web
      // si site ID dans l'url on charge les infos avant d'arriver sur homepage
      if (Platform.OS === 'web') {
        const fetchAndSetDataList = async (listId) => {
          const list = await fetchListData(listId);

          setList(list);
          setCurrentList(list);
          navigation.reset({
            index: 0,
            routes: [{
              name: 'SitesListFiltered',
            }],
          });
        };

        const fetchAndSetDataSite = async (siteId) => {
          const site = await fetchSiteData(siteId);

          setSite(site);
          setCurrentSite(site);
          navigation.reset({
            index: 0,
            routes: [{
              name: 'HomePage',
            }],
          });
        };

        const params = new URLSearchParams(window.location.search);

        listId = params.get('list');
        siteId = params.get('site');
        codeId = params.get('code');

        if (listId) {
          fetchAndSetDataList(listId);
          setCurrentSite(null);
        } else if (siteId) {
          fetchAndSetDataSite(siteId);
          setCurrentList(null);
        } else if (codeId) {
          const booking = await checkCode(codeId);

          const routes = [{
            name: 'HomePage',
          }];

          if (booking) {
            routes.push({
              name: 'SiteDetail',
              params: { siteId: booking.site.id, bookingId: booking.id },
            });
          }
          navigation.reset({
            index: routes.length - 1,
            routes,
          });
        } else {
          setCurrentList(null);
          setCurrentSite(null);
          navigation.reset({
            index: 0,
            routes: [{
              name: 'HomePage',
            }],
          });
        }
      } else {
        setCurrentSite(null);
        setCurrentList(null);
        navigation.reset({
          index: 0,
          routes: [{
            name: 'HomePage',
          }],
        });
      }
    };

    if (fetchUserDone === false) {
      setfetchUserDone(true);
      fetchUser();
    }
  }, [
    checkCode,
    refreshUser,
    fetchUserDone,
    navigation,
    fetchSiteData,
    fetchSite,
    setCurrentSite,
    setSite,
    setList,
    fetchListData,
    setCurrentList,
  ]);

  return (
    <LoadingSpinner
      visible
      textContent={t('common.loading')}
      theme={theme}
    />
  );
};

HomeRedirect.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default HomeRedirect;
