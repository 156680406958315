import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  ActivityIndicator, StyleSheet, View, Dimensions,
  useWindowDimensions,
} from 'react-native';
import { useAuth } from 'auth/contexts/auth';

import useTheme from 'providers/ThemeProvider';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import useSiteProvider from 'providers/SiteProvider';
import useOrders from 'orders/contexts/orders';
import { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
// import generatePdf from 'common/utils/generatePdf';
import dayjs from 'dayjs';
import useTrans from 'common/hooks/use-trans';
import useBooking from 'bookings/contexts/bookings';
import useAlert from 'common/contexts/alert';
import Board from './components/Board/Board';
import SeatDetails from './components/SeatDetails/SeatDetails';
import MapsSwitch from './components/MapsSwitch/MapsSwitch';
import FocusedSeatBooking from './components/FocusedSeatBooking/FocusedSeatBooking';
import BookButton from './components/BookButton/BookButton';
import useBeachVisualizer from './contexts/beachVisualizer';
import BookingList from '../BookingsList';

const BeachVisualizer = () => {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const {
    date,
    searchBookingItem,
    mapId,
    setMapId,
    bookingModal,
    setBookingModal,
    selectedSeats,
    setSelectedSeats,
    getSeatData,
    handleClickonSquare,
    switchSeat,
    setSwitchSeat,
    seatFocused,
    setSeatFocused,
    bookingItemFocused,
    setBookingItemFocused,
    refreshData: refreshBeachData,
    updateBookingItem,
    bookingItemList,
    showMapBloc,
  } = useBeachVisualizer();

  const {
    update: updateOrder,
  } = useOrders();

  const { setBookingConflicts, checkSameSeatBookings } = useBooking();
  const { setAlert } = useAlert();

  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const [isUpdatingSwitch, setIsUpdatingSwitch] = useState(false);

  const [isPrinting, setIsPrinting] = useState(false);
  const mapRef = React.useRef();

  const styles = makeStyles(theme, isMobileMode({ width, height }), showMapBloc);

  const { site, isFetching } = useSiteProvider();
  const map = useMemo(() => (
    mapId !== -1 ? site?.maps?.find(({ id }) => id === mapId) : null
  ), [mapId, site]);

  useEffect(() => {
    const sameSeatsBookings = checkSameSeatBookings(bookingItemList, dayjs(date).format('YYYY-MM-DD'));

    setBookingConflicts(sameSeatsBookings);
  }, [bookingItemList, checkSameSeatBookings, date, setBookingConflicts]);

  const handleCloseModal = useCallback(() => {
    setBookingModal(null);
  }, [setBookingModal]);

  const handleClickSeat = useCallback(async (seat, bookingItem, hasConflict = false) => {
    const disabledOutOfService = seat.outOfService && !currentUser?.showOutOfService;

    if (switchSeat && seatFocused && bookingItemFocused) {
      if (disabledOutOfService) {
        setAlert({ color: 'info', title: t('common.info'), message: t('auth.isOutOfService') });
        setSwitchSeat(false);
        return null;
      }
      setIsUpdatingSwitch(true);
      setSelectedSeats([]);

      // replace first selected seat
      const newSeatsId = bookingItemFocused.seats
        .filter((s) => s.id !== seatFocused.id).map((seat) => seat.id);

      const newBookingItem = {
        ...bookingItemFocused,
        seats: [
          ...newSeatsId,
          seat.id,
        ],
        override: true,
      };

      const { orders: ordersFirstSeat } = getSeatData(seatFocused.id);

      ordersFirstSeat.map(async (o) => {
        await updateOrder(
          o.id, {
            ...o,
            seat: seat.id,
          },
        );
        return null;
      });

      await updateBookingItem(newBookingItem);

      // replace 2nd selected seat if has own booking
      if (bookingItem) {
        const targetSeats = bookingItem.seats?.filter((s) => s.id !== seat.id);

        const newBookingItem = {
          ...bookingItem,
          seats: [
            ...targetSeats,
            seatFocused,
          ],
          override: true,
        };

        const { orders: ordersTargetSeat } = getSeatData(seat.id);

        ordersTargetSeat.map(async (o) => {
          await updateOrder(
            o.id, {
              ...o,
              seat: seatFocused.id,
            },
          );
          return null;
        });

        await updateBookingItem(newBookingItem);
      }

      setSwitchSeat(false);
      setSeatFocused(null);
      setBookingItemFocused(null);
      await refreshBeachData();
      setIsUpdatingSwitch(false);
      return null;
    }

    const isSameSeat = seatFocused === seat;

    if (bookingItem && !isSameSeat) {
      setBookingItemFocused(bookingItem);
      setSeatFocused(seat);
      setSelectedSeats([]);
    } else {
      setSeatFocused(null);
      setBookingItemFocused(null);

      if (!isSameSeat || !bookingItem) {
        if (disabledOutOfService) {
          setAlert({ color: 'info', title: t('common.info'), message: t('auth.isOutOfService') });
        } else {
          handleClickonSquare(seat.id, null, seat.outOfService);
        }
      }
    }
  }, [handleClickonSquare, bookingItemFocused,
    setBookingItemFocused, setSelectedSeats, switchSeat, setSwitchSeat,
    seatFocused, setSeatFocused, refreshBeachData, getSeatData, updateBookingItem, updateOrder,
    setIsUpdatingSwitch, setAlert, currentUser, t]);

  const printMap = async () => {
    const mapName = translateText(map.name);
    const filename = `${mapName.replace(' ', '_')}-${dayjs(date).format('YYYY-MM-DD')}`;

    /* generatePdf(mapRef.current, {
      mapName: `${mapName}`,
      filters: {
        date: dayjs(date).format('DD/MM/YYYY'),
        stayDuration: currentStayDuration,
        search: searchBookingItem,
      },
      columnsTitle: [
        'Client',
        'VIP',
        'No Show',
        'Code',
        'Status',
        'Durée',
        'Places',
        'Sièges',
      ],
      bookingItems: bookingItemList.map((bi) => {
        const { customer, clientFirstName, clientName } = bi.booking;

        return [
          customer?.name
            ? `${customer.civility
              ? `${t(`customer.civility${customer.civility}`)} `
              : ''}${customer.name} ${customer.firstName || ''}`
            : `${clientName} ${clientFirstName || ''}`,
          customer?.customer_category?.name === 'VIP' ? 'x' : '' || '',
          `${customer?.noShow || 0}`,
          bi.booking.code,
          t(`bookings.bookingItemStatus_${bi.status}`),
          t(`bookings.${bi.stayDuration}`),
          bi.seats?.map((s) => s.name)?.join(", ") ?? '',
          bi.bookingContent?.map((s) => `${s.amount}
          ${translateText(s.furniture.name)} (${s.price_area.name})`)?.join(", ") ?? '',
        ];
      }),
      colsWidth: [
        20,
        5,
        7,
        8,
        10,
        15,
        10,
        25,
      ],
      filename,
    }).then(() =>
      setIsPrinting(false)
    ); */
  };
  // headerRight

  const isMobile = isMobileMode(useWindowDimensions());

  return (
    <WaiterLayout noPadding noScroll={showMapBloc || !isMobile}>
      <View style={styles.splitPage}>
        {/* left part */}
        {((isMobile && !showMapBloc) || !isMobile) && (
        <View style={styles.blocList}>
          <BookingList />
        </View>
        )}

        {/* right part */}
        {((isMobile && showMapBloc) || !isMobile) && (
        <View style={styles.blocMap}>
          { (isFetching) && (
            <View style={styles.loadingBox}>
              <ActivityIndicator size="large" color={theme.colors.white} />
            </View>
          )}
          {(map || isUpdatingSwitch) ? (
            <View style={styles.beachContainer}>
              <MapsSwitch
                maps={currentUser?.site?.maps}
                mapId={mapId}
                setMapId={setMapId}
                printMap={printMap}
                isPrinting={isPrinting}
                setIsPrinting={setIsPrinting}
              />
              {isUpdatingSwitch && (
                <View style={styles.loadingBox}>
                  <ActivityIndicator size="large" color={theme.colors.white} />
                </View>
              )}

              <Board
                ref={mapRef}
                map={map}
                handleClickSeat={handleClickSeat}
                date={date}
                selectedSeats={selectedSeats}
                searchBookingItem={searchBookingItem}
                getSeatData={getSeatData}
                switchAreasColorType
              />
              {bookingItemFocused ? (
                <FocusedSeatBooking />
              ) : !isMobile && (
                <BookButton selectedSeats={selectedSeats} date={date} />
              )}

            </View>
          ) : (
            !isFetching && (
              <View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                <EmptyPage text={t('beach.noMap')} />
              </View>
            )
          )}

          <SeatDetails seatBookingId={bookingModal} closeModal={handleCloseModal} />
        </View>
        )}
      </View>
    </WaiterLayout>
  );
};

const makeStyles = (theme, isMobile, showMapBloc) => StyleSheet.create({
  splitPage: {
    flex: 1,
    height: '100%',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
  },
  blocList: {
    width: isMobile ? '100%' : Dimensions.get('window').width * 0.35,
    maxWidth: isMobile ? '100%' : Dimensions.get('window').width * 0.35,
    maxHeight: isMobile ? undefined : Dimensions.get('window').height - 1,
    marginLeft: !showMapBloc || isMobile ? 0 : -Dimensions.get('window').width * 0.35,
    visibility: !showMapBloc || isMobile ? 'visible' : 'hidden',
    opacity: !showMapBloc || isMobile ? 1 : 0,
    transition: '0.2s',
  },
  blocMap: {
    flex: 1,
    backgroundColor: theme.colors.beach,
    // height: '100%',
    // maxHeight: Dimensions.get('window').height - 1,
    // minHeight: isMobile ? theme.normalize(800) : undefined,
  },
  beachContainer: {
    flex: 1,
    width: '100%',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: theme.normalize(10),
  },
  loadingBox: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.greyAlpha,
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
  },
});

BeachVisualizer.propTypes = {
};

export default BeachVisualizer;
