import { Dimensions, StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({

  description: {
    marginBottom: theme.sizings.small,
    width: '100%',
    fontSize: theme.fontSizes.medium,
    lineHeight: theme.normalize(22),
  },

  priceWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    top: theme.normalize(20),
  },

  topSpacer: {
    /* 118 = La taille en PX de la partie que l'on veut afficher du site détails */
    height: Dimensions.get('window').height - theme.normalize(180),
    width: Dimensions.get('window').width,
  },

  detailsContainer2: {
    marginHorizontal: theme.radius.medium,
    marginBottom: theme.radius.medium,
    flex: 1,
    borderRadius: theme.radius.medium,
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    overflow: 'visible',
    fontSize: theme.fontSizes.medium,
  },
  detailsContainer: {
    paddingTop: theme.radius.large,
  },

  address: {

  },

  addressButton: {
    marginTop: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  timeColumn: {
    padding: 0,
    paddingRight: theme.sizings.larger,
    flex: 0.75,

  },

  addressColumn: {
    padding: 0,
    flex: 1.25,

  },

  details: {
    flexDirection: 'row',
  },

  textIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.sizings.small,
  },

  toggleIcon: {
    marginLeft: theme.sizings.small,
  },
});
