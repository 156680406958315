import useCategories from 'categories/contexts/categories';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import React, { useEffect } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import ProductItem from 'waiter/components/ProductItem/ProductItem';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import { useTranslation } from 'react-i18next';

const makeStyles = (theme) => StyleSheet.create({
  parent: {
    marginTop: theme.sizings.medium,
  },

  child: {
    marginTop: theme.sizings.small,
  },
});

const Catalog = () => {
  const {
    fetchItems: fetchCategories, items: categories,
  } = useCategories();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const hasProducts = categories?.find((cat) => Boolean(cat.products.length));

  return (
    <WaiterLayout boxed>
      { hasProducts ? categories?.map((category) => (
        <View key={category.id}>
          <View style={styles[category.parent ? 'child' : 'parent']}>
            <TranslatedText
              isBold
              color={category.parent ? 'dark' : 'primary'}
              size={category.parent ? 'large' : 'larger'}
              value={category.name}
            />
          </View>
          <View>
            { category.products.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
          </View>
        </View>
      )) : (
        <EmptyPage text={t('stock.empty')} />
      )}
    </WaiterLayout>
  );
};

export default Catalog;
