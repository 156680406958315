import React from 'react';
import {
  View, StyleSheet, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const makeStyles = (theme) => StyleSheet.create({

  fullFormView: {
    flex: 1,
    marginTop: theme.sizings.larger,
  },

  header: {
    paddingHorizontal: theme.sizings.large,
  },

  formView: {
    alignItems: 'center',
  },

  inputs: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.large,
    paddingVertical: theme.sizings.large,
  },

  buttons: {
    width: '80%',
    height: theme.normalize(120),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const ForgotPassword = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { callback } = route.params;
  const { setAlert } = useAlert();
  const { forgotPassword, isFetching } = useAuth();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const onSubmit = async (payload) => {
    try {
      await forgotPassword(payload);
      setAlert({ color: 'success', title: t('common.success'), message: t('auth.newPasswordSent') });
      navigation.navigate('SignInPage', { callback });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('auth.forgotPasswordWrongEmail') });
    }
  };

  return (
    <View style={styles.fullFormView}>
      <LoadingSpinner
        visible={isFetching}
        theme={theme}
      />
      <View style={styles.header}>
        <Text size="large" centered>{t('auth.forgotPasswordMessage')}</Text>
      </View>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: '' }}
        render={({ handleSubmit, submitting }) => (
          <>
            <ScrollView>
              <View style={styles.formView}>
                <View style={styles.inputs}>
                  <FormInput
                    type="email"
                    name="email"
                    placeholder={t('auth.email')}
                    icon="envelope"
                  />
                </View>
              </View>
              <View style={commonStyles.scrollViewWithButton} />
            </ScrollView>
            <View style={commonStyles.buttonWrapper}>
              <View style={commonStyles.buttonContainer}>
                <Button
                  variant="gradient"
                  icon="user"
                  text={t('common.send')}
                  onPress={handleSubmit}
                  disabled={submitting}
                  type="submit"
                />
              </View>
            </View>
          </>
        )}
      />
    </View>
  );
};

ForgotPassword.path = 'signin';

ForgotPassword.navigationOptions = {
  title: 'ForgotPassword',
};

ForgotPassword.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

ForgotPassword.defaultProps = {
};

export default ForgotPassword;
