import { Dimensions, StyleSheet, Platform } from 'react-native';

export default (theme) => StyleSheet.create({
  contentWrapper: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },
  headerIcon: {
    minWidth: theme.normalize(36),
    minHeight: theme.normalize(36),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.sizings.small,
    borderWidth: 1,
    borderColor: 'rgba(255, 255 ,255, .3)',
    borderRadius: theme.radius.rounded,
    backgroundColor: 'transparent',
  },
  fullViewDetailsContainer2: {
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

  },
  fullViewDetailsContainer: {
    paddingTop: theme.radius.large,
    width: Dimensions.get('window').width - theme.sizings.medium,
    position: 'absolute',
    bottom: theme.sizings.small,
    overflow: 'visible',
    borderRadius: theme.radius.medium,
  },

  preAuthorizedMessage: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullViewDetailsContent: {
    paddingTop: theme.normalize(30),
    minHeight: theme.normalize(110),
  },

  fullViewDetailsContentDiscount: {
    paddingTop: theme.normalize(30),
    width: '80%',
    minHeight: theme.normalize(150),
  },

  fullViewContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  fullViewButtonContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 10,
    transform: [
      { translateX: -Dimensions.get('window').width / 2 },
    ],
    left: '50%',
    width: Dimensions.get('window').width,
    justifyContent: 'center',
    alignItems: 'center',
  },

  fullViewDetailsPriceWrapper: {
    zIndex: 10,
    backgroundColor: theme.colors.dark,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    position: 'absolute',
    top: theme.normalize(30),
    right: theme.normalize(-8),
    minWidth: theme.normalize(80),
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.sizings.small,
  },
  fullViewDetailsIcon: {
    minWidth: theme.normalize(35),
    minHeight: theme.normalize(35),
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.sizings.small,
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.dark,
  },
  textWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  singleText: {
    paddingLeft: theme.sizings.small,
    paddingRight: theme.sizings.small,
  },
  priceWrapper: {
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    position: 'absolute',
    top: theme.normalize(-235),
    right: theme.normalize(-8),
    height: theme.normalize(80),
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.smallMedium,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  cardButtonWrapper: {
    position: 'absolute',
    top: theme.normalize(-55),
    left: 0,
    height: theme.normalize(100),
    width: Dimensions.get('window').width - theme.sizings.large,
    flexDirection: 'row',
    justifyContent: 'center',
  },

  shadowBottom: {
    shadowColor: theme.sizings.greyLightest,
    shadowOffset: { height: 1, width: 0 },
    shadowRadius: 2,
    shadowOpacity: 0.3,
  },

  scrollViewWithButton: {
    // paddingBottom: theme.normalize(180),
    paddingBottom: theme.sizings.medium,
  },

  buttonWrapper: {
    paddingVertical: theme.sizings.smallMedium,
    marginBottom: theme.sizings.medium,
    /*
    flex: 1,
    height: Platform.OS === 'web' ? theme.normalize(120) : theme.normalize(100),
    position: 'absolute',
    bottom: 0,
    marginTop: 0,
    left: 0,
    right: 0,
    paddingTop: theme.sizings.smallMedium,
    backgroundColor: theme.colors.dark,
    borderTopWidth: theme.sizings.mediumLarge,
    borderTopColor: theme.colors.white,
    borderStyle: 'solid',
  */

  },

  buttonWrapperIn: {
    height: 70,
    backgroundColor: theme.colors.dark,
    /* position: 'absolute',
    bottom: 0,
    marginTop: 0,
    left: 0,
    right: 0, */
  },

  buttonContainer: {
    // position: 'relative',
    width: '100%',
    // alignItems: 'center',
    // top: -theme.sizings.large,
  },

  buttonContainerIn: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    top: theme.normalize(10),
  },

  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalInner: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalMask: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.sizings.small,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },

  modalBox: {
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.light,
    opacity: 1,
    paddingVertical: theme.sizings.large,
    paddingHorizontal: theme.sizings.medium,
    maxWidth: theme.normalize(400),
    minWidth: theme.normalize(350),
    maxHeight: '100%',
    alignSelf: 'center',
  },

  modalFooter: {
    marginTop: theme.sizings.large,
    flexDirection: 'row',
    justifyContent: 'center',
  },

  modalText: {
    textAlign: 'center',
    fontSize: theme.sizings.medium,
  },

  modalButton: {
    marginHorizontal: theme.sizings.tiny,
  },
});
