import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import {
  View, StyleSheet, TouchableHighlight, ScrollView, Dimensions, ActivityIndicator, useWindowDimensions,
} from 'react-native';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import WaiterLayout, { WaiterSubheader } from 'waiter/components/WaiterLayout/WaiterLayout';
import useCustomers from 'customers/contexts/customers';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Button from 'common/components/Button/Button';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import useCustomerCategories from 'customers/contexts/customerCategories';
import Select from 'common/components/Select/Select';
import useSiteProvider from 'providers/SiteProvider';
import { isMobileMode } from 'common/utils/desktopMode';
import Pagination from 'common/components/Pagination/Pagination';

const customerPageSize = 100;
  
const CustomerList = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { theme } = useTheme()
  const { width, height } = useWindowDimensions();
  const isMobile = isMobileMode(useWindowDimensions())
  const styles = makeStyles(theme, isMobileMode({ width, height }));

  const [selectedCustomersId, setSelectedCustomersId] = useState([]);

  /* globalSearch */
  const [globalSearch, setGlobalSearch] = useState('');
  const [catSearch, setCatSearch] = useState('');
  /* pagination */
  const [page, setPage] = useState(0);

  const { 
    fetchItems: fetchCustomers,
    items: customers,
    remove,
    isFetching: isCustomersFetching,
    pageCount,
    setSort,
  } = useCustomers();
  const { site } = useSiteProvider();
  const {
    items: categories,
    fetchItems: fetchCategories,
    isFetching: isCategoriesFetching,
  } = useCustomerCategories();

  useEffect(() => {
    if(site){
      fetchCategories();
    }
  }, [fetchCategories, site]);

  useEffect(() => {
    setSort('name');
    fetchCustomersList();
  }, [catSearch, fetchCustomersList, globalSearch, page]);

  const fetchCustomersList = useCallback(() => {
    const getCustomers = () => {
      const filters = globalSearch
        ? {
          '[_or][0][name_contains]': globalSearch,
          '[_or][1][firstName_contains]': globalSearch,
          '[_or][2][email_contains]': globalSearch,
          '[_or][3][phone_contains]': globalSearch,
        }
        : {};
      if(catSearch && catSearch !== 'standard' && catSearch !== ''){
        filters['[customer_category_eq]'] = catSearch;
      } else if(catSearch === 'standard'){
        filters['[customer_category_null]'] = 'true';
      }
      fetchCustomers({
        filters,
        pageIndex: page,
        pageSize: customerPageSize,
      });
    }
    getCustomers();
  }, [catSearch, fetchCustomers, globalSearch, page])

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, [setPage])

  const deleteCustomer = useCallback(async (customerId) => {
    await remove(customerId);
    fetchCustomersList();
  }, [fetchCustomersList, remove]);

  const handleDeleteCustomers = useCallback(async () => {
    await Promise.all(
      selectedCustomersId.map(async (selectedId) => {
        await remove(selectedId);
      })
    );
    fetchCustomersList();
  }, [fetchCustomersList, deleteCustomer, selectedCustomersId]);

  const handleNewCustomer = useCallback(() => {
    navigation.navigate('CustomerEdit', {
      setCustomer: () => fetchCustomersList()
    });
  }, [navigation, fetchCustomersList]);

  const handleEditCustomer = useCallback((customerId) => {
    navigation.navigate('CustomerEdit', {
      id: customerId,
      setCustomer: () => fetchCustomersList()
    });
  }, [navigation, fetchCustomersList]);

  const toggleSelectItem = useCallback((customer, isSelected) => {
    if (isSelected) {
      setSelectedCustomersId((oldValues) => oldValues.filter((selectedId) => selectedId !== customer.id));
    } else {
      setSelectedCustomersId((oldValues) => [...oldValues, customer.id]);
    }
  }, [setSelectedCustomersId]);

  const categoriesOptions = useMemo(() => {
    if(site){
      return([
            {
              value: '',
              label: t('customer.allCategories')
            },
            {
              value: 'standard',
              label: t('customer.noCategory')
            },
            ...categories?.filter((cat) => cat.site.id === site?.id)
            .map((cat) => ({
              value: cat.id,
              label: cat.name
            }))
          ])
    }else{
      return []
    }
  }, [site, categories])
  
  return (
    <WaiterLayout noPadding noScroll={!isMobile}>
      <>
   
        <WaiterSubheader centered>
          <View style={{
             flexDirection: 'row',
             flexWrap: 'wrap',
             justifyContent: 'flex-end',
             alignItems: 'center',
             height: '100%',
             width: '100%'
          }}>
            {Boolean(selectedCustomersId.length) && (
            <Button
              onPress={() => setSelectedCustomersId([])}
              text={isMobile ? undefined : t('customer.unselectAll')}
              variant="white"
              iconColor="primary"
              icon="undo"
              small
              style={styles.buttonHeader}
            />
            )}
            {Boolean(selectedCustomersId.length) && (
            <ConfirmButton
              onPress={handleDeleteCustomers}
              text={isMobile ? undefined : t('customer.deleteCustomers', { nb: selectedCustomersId.length })}
              confirmText={t('customer.deleteCustomersConfirm', { nb: selectedCustomersId.length })}
              variant="white"
              iconColor="dangerBright"
              icon="trash"
              small
              style={styles.buttonHeader}
            />
            )}
            <Button
              onPress={handleNewCustomer}
              icon="plus"
              iconFamily="fontawsome5"
              iconColor="primary"
              variant="white"
              text={isMobile ? undefined : t('customer.addCustomer')}
              small
              style={styles.buttonHeader}
            />
          </View>
        </WaiterSubheader>


        {!isCategoriesFetching && !isCategoriesFetching && (
        <View style={styles.searchBar}>
          <DebouncedInput
            value={globalSearch}
            onChange={setGlobalSearch}
            placeholder={t('common.globalSearch')}
            containerStyle={{ padding: 0, marginRight: theme.sizings.medium, width: '50%' }}
          />

          <View style={{flex: 1}}>
          <Select
              options={categoriesOptions}
              value={catSearch}
              onChange={(option) => {
                setCatSearch(option);
              }}
              style={{width: '100%'}}
            />
            </View>
        </View>
        )}

        <ScrollView style={{ 
          padding: isMobileMode({ width, height }) ? theme.sizings.smallMedium : theme.sizings.mediumLarge, 
          maxHeight: Dimensions.get('window').height - theme.normalize(150) 
        }}>
          {isCustomersFetching ? (
            <View style={{width: '100%', heigh: theme.normalize(300), alignItems: 'center'}}>
              <ActivityIndicator />
            </View>
          ):(
          Boolean(customers.length) ? customers?.sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
          })?.map((customer) => {
            const isSelected = selectedCustomersId.find((selectedId) => selectedId === customer.id);

            return (
              <View style={{
                ...styles.customerRow,
                backgroundColor: isSelected ? theme.colors.dangerLightest : theme.colors.greyLightest,
              }}
              key={`customer-${customer.id}`}
              >

                <TouchableOpacity
                  onPress={() => { toggleSelectItem(customer, isSelected); }}
                  containerStyle={{ flex: 6 }}
                  style={styles.customerLeft}
                >
                  <>
                    <View
                      style={[
                        styles.buttonSelect,
                        {
                           backgroundColor: isSelected ? theme.colors.danger : theme.colors.white,
                           borderWidth: isSelected ? 0 : theme.normalize(2),
                           borderColor: isSelected ? undefined : theme.colors.greyLighter
                        },
                      ]}
                    >
                      {isSelected ? (
                        <FontAwesome name="check" color={theme.colors.white} size={theme.sizings.medium} />
                      ) : <></>}
                    </View>

                    <View style={[styles.col, styles.colName]}>
                      <Text
                        style={styles.cellText}
                        isBold
                        color={customer?.noShow > 0 ? 'danger'
                          : 'black'}
                      >
                        {`${customer.civility ? `${t(`customer.civility${customer.civility}`)} `
                         : ''} ${customer.name} ${customer.firstName || ''} `}
                      </Text>

                      <View style={styles.iconText}>
                        <FontAwesome5
                          name="envelope"
                          color={theme.colors.primary}
                          size={theme.sizings.medium}
                          style={styles.icon}
                        />
                        <Text style={styles.cellText}>{customer?.email || ''}</Text>
                      </View>

                    </View>

                    <View style={styles.col}>
                      <View style={styles.iconText}>
                        <FontAwesome5
                          name="mobile"
                          color={theme.colors.primary}
                          size={theme.sizings.medium}
                          style={styles.icon}
                        />
                        <Text style={styles.cellText}>{customer?.phone || ''}</Text>
                      </View>
                    </View>

                    <View style={styles.col}>
                      <View style={styles.iconText}>
                        <FontAwesome5
                          name="ghost"
                          color={customer?.noShow ? theme.colors.danger : theme.colors.primary}
                          size={theme.sizings.medium}
                          style={styles.icon}
                        />
                        <Text style={styles.cellText}>{customer?.noShow || '0'}</Text>
                      </View>
                    </View>

                    <View style={styles.col}>
                      <Text style={styles.cellText}>
                        <View style={styles.iconText}>
                          <FontAwesome5
                            name={customer?.customer_category?.name === 'VIP' ? 'crown' : 'tag'}
                            color={customer?.customer_category?.name === 'VIP'
                              ? theme.colors.secondaryDark
                              : theme.colors.primary}
                            size={theme.sizings.medium}
                            style={styles.icon}
                          />
                          <Text>
                            {customer?.customer_category?.name || t('customer.noCategory')}
                          </Text>
                        </View>
                      </Text>
                    </View>
                  </>
                </TouchableOpacity>

                <View style={styles.customerRight}>
                  <View style={{ flexDirection: 'row' }}>
                    
                    <ConfirmButton
                        onPress={() => deleteCustomer(customer.id)}
                        confirmText={t('customer.deleteCustomerConfirm', { name: `${customer.name} ${customer.firstName || ''}` })}
                        variant="white"
                        iconColor="dangerBright"
                        icon="trash"
                        small
                        style={styles.button}
                      />

                    <TouchableHighlight style={styles.button} onPress={() => handleEditCustomer(customer.id)}>
                      <FontAwesome name="edit" color={theme.colors.primary} size={theme.sizings.medium} />
                    </TouchableHighlight>
                  </View>

                </View>

              </View>
            );
          }):(
            <EmptyPage text={t('customer.empty')} />
          )
          )}

        </ScrollView>
        <Pagination page={page} nbPages={pageCount} onChange={handlePageChange} showArrows/>
      </> 
    </WaiterLayout>
  );
};

const makeStyles = (theme, isMobile) => StyleSheet.create({

  buttonBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: theme.sizings.medium,
  },

  customer: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  button: {
    width: theme.sizings.large,
    height: theme.sizings.large,
    borderWidth: 1,
    borderColor: theme.colors.greyAlpha,
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.sizings.medium,
  },
  buttonSelect: {
    width: theme.sizings.large,
    height: theme.sizings.large,
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },
  customerRow: {
    marginVertical: theme.sizings.tiny,
    borderRadius: theme.sizings.small,
    overflow: 'hidden',
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
  },
  customerLeft: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  customerRight: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    borderLeftColor: 'rgba(0,0,0,0.15)',
    borderLeftWidth: isMobile ? 0 : theme.normalize(2),
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  col: {
    paddingHorizontal: theme.sizings.medium,
    width: isMobile ? undefined : '20%',
    minWidth: isMobile ? '40%' : undefined
  },
  colName: {
    width: isMobile ? '80%' : '40%'
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
  searchBar: {
    backgroundColor: theme.colors.greyLighter,
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.small,
    flexDirection: "row"
  },
  buttonHeader: {
     marginHorizontal: theme.sizings.tiny,
     marginVertical: isMobile ? theme.sizings.tiny : undefined,
     backgroundColor: theme.colors.white,
  }

});

export default CustomerList;
