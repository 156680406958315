import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import useOffer from 'specialOffers/hooks/use-offer';
import Price from 'common/components/Price/Price';

import styles from 'styles/commonStyles';

const {
  lineThrough,
} = styles;

const ReductionPrice = ({
  price,
  reduction,

  newPriceSize,
  newPriceColor,
  newPriceStyle,
  newViewStyle,

  containerStyle,

  reductionViewStyle,
  reductionPriceColor,
  reductionPriceSize,
  reductionPriceStyle,

  direction,

  showOriginalPrice,

}) => {
  const { applyReduction } = useOffer();

  let defaultContainer = { flexDirection: 'column', alignItems: 'center' };

  let defaultReduction = {};

  if (direction === 'row') {
    defaultContainer = {
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'space-between',
    };
    defaultReduction = { paddingRight: 5 };
  }

  return (
    <View style={[defaultContainer, containerStyle]}>
      {(reduction && showOriginalPrice) && (
        <Price
          price={price}
          color={reductionPriceColor}
          size={reductionPriceSize}
          style={reductionPriceStyle}
          viewStyle={[defaultReduction, reductionViewStyle]}
          oldPrice
        />
      )}
      <Price
        price={applyReduction(price, reduction)}
        color={newPriceColor}
        size={newPriceSize}
        style={newPriceStyle}
        viewStyle={newViewStyle}
      />
    </View>
  );
};

ReductionPrice.propTypes = {
  price: PropTypes.number,
  reduction: PropTypes.object,

  reductionPriceColor: PropTypes.string,
  reductionViewStyle: PropTypes.any,
  reductionPriceSize: PropTypes.string,
  reductionPriceStyle: PropTypes.any,

  newPriceColor: PropTypes.string,
  newPriceSize: PropTypes.string,
  newViewStyle: PropTypes.any,
  newPriceStyle: PropTypes.any,

  containerStyle: PropTypes.any,
  direction: PropTypes.string,

  showOriginalPrice: PropTypes.bool,
};

ReductionPrice.defaultProps = {
  price: 0,
  reduction: null,
  showOriginalPrice: true,

  reductionPriceColor: 'greyAlpha',
  reductionPriceSize: 'larger',
  reductionViewStyle: null,
  reductionPriceStyle: [lineThrough],

  newPriceColor: 'dark',
  newPriceSize: 'largest',
  newViewStyle: null,
  newPriceStyle: null,

  containerStyle: null,

  direction: 'column',
};

export default ReductionPrice;
