import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  booking: {
    marginTop: theme.sizings.small,
    marginBottom: theme.sizings.medium,
    paddingHorizontal: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    flexDirection: 'column',
    alignItems: 'center',
  },
  bookingMeta: {
  },
  bookingTitle: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.greyLighter,
    paddingVertical: theme.sizings.medium,
  },
  bookingTop: {
    width: '100%',
    paddingVertical: theme.sizings.medium,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  bookingContent: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.greyLighter,
    paddingVertical: theme.sizings.medium,
    width: '100%',
    justifyContent: 'center',
  },
  bookingStatus: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bookingStatusIcon: {
    marginRight: theme.sizings.small,
  },
  bookingCode: {
    paddingRight: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  bookingDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  bookingButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  bookingButton: {
    paddingHorizontal: 9,
    paddingVertical: theme.sizings.tiny,
  },
});
