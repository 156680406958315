/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import ExpectedSeatsResume from 'waiter/components/booking-detail-sections/ExpectedSeatsResume';
import StayDuration from 'waiter/components/booking-detail-sections/StayDuration';
import SeatsResume from 'waiter/components/booking-detail-sections/SeatsResume';
import Room from 'waiter/components/booking-detail-sections/Room';
import PropTypes, { object } from 'prop-types';
import OrderInProgress from 'waiter/components/booking-detail-sections/OrderInProgress';
import QRCode from 'react-native-qrcode-svg';
import baseUrl from 'common/utils/getBaseUrl';
import { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import formatPrice from 'common/utils/formatPrice';
import PaymentSwitcher from 'waiter/components/booking-detail-sections/PaymentSwitcher';
import useBookings from 'bookings/contexts/bookings';
import DateStatusItem from './DatesStatusItem';

const DatesStatus = ({
  bookingId, currentBookingItem, print,
}) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const { t } = useTranslation();

  const {
    item: completeBooking,
    fetchItem: fetchBooking,
    payBookingItem,
    payFullBooking,
  } = useBookings();
  const styles = makeStyles(theme, isMobile);

  const [editBookingItems, setEditBookingItems] = useState([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [biPaymentVisible, setBiPaymentVisible] = useState(false);
  const [bPaymentVisible, setBPaymentVisible] = useState(false);

  useEffect(() => {
    if (completeBooking) {
      setEditBookingItems(completeBooking.booking_items?.map((bi) => false));
    } else {
      setEditBookingItems([]);
    }
  }, [completeBooking, setEditBookingItems]);

  const fetchCompleteBooking = useCallback(async () => {
    try {
      await fetchBooking(bookingId);
    } catch (e) {
      console.log(e);
    }
  }, [bookingId, fetchBooking]);

  const handlePayFullBooking = useCallback(async (id, offlineMethod) => {
    setBPaymentVisible(false);
    setIsPaymentLoading(true);
    await payFullBooking(id, offlineMethod);
    await fetchCompleteBooking();
    setIsPaymentLoading(false);
  }, [setBPaymentVisible, setIsPaymentLoading, payFullBooking, fetchCompleteBooking]);

  const handlePayBookingItem = useCallback(async (id, offlineMethod) => {
    setBiPaymentVisible(false);
    setIsPaymentLoading(true);
    await payBookingItem(id, offlineMethod);
    await fetchCompleteBooking();
    setIsPaymentLoading(false);
  }, [setBiPaymentVisible, setIsPaymentLoading, payBookingItem, fetchCompleteBooking]);

  return (
    <>
      {/* bloc situation */}
      {currentBookingItem && completeBooking && (
      <View style={{ ...styles.bookingItemSituation, ...styles.topSeparatorUnder }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text isBold size="large">
            {t('common.today')}
          </Text>
          {print && (
          <Button
            icon="print"
            iconFamily="MaterialIcons"
            iconColor="purple"
            iconSize="medium"
            size="medium"
            style={[styles.iconOnlyButton, {
              backgroundColor: theme.colors.white,
              borderWidth: 1,
              borderColor: '#dddddd',
              borderStyle: 'solid',
              borderRadius: theme.radius.small,
              height: theme.sizings.mediumLarge,
              marginRight: theme.sizings.tiny,
            }]}
            onPress={() => print(currentBookingItem)}
            noPadding
          />
          )}
        </View>

        <DateStatusItem
          bookingItem={currentBookingItem}
          biIndex="today"
          editCurrentBookingItem
          currentBookingItem={currentBookingItem}
          editBookingItems={editBookingItems}
          setEditBookingItems={setEditBookingItems}
          fetchCompleteBooking={fetchCompleteBooking}
          isPaymentLoading={isPaymentLoading}
          biPaymentVisible={biPaymentVisible}
          setBiPaymentVisible={setBiPaymentVisible}
          handlePayBookingItem={handlePayBookingItem}
        />

      </View>
      )}

      {completeBooking.booking_items.length && (
      <View style={{ ...styles.bookingFullPeriod, ...styles.topSeparatorUnder }}>
        <View style={isMobile ? {} : { flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text isBold size="large">{t('bookings.fullPeriod')}</Text>

          {/* todo : bouton payer tout le solde */}
          <View style={isMobile ? {} : { width: theme.normalize(300), alignItems: 'flex-end', flexDirection: 'row' }}>
            <Text isBold size="medium" style={{ alignSelf: 'center', paddingHorizontal: theme.sizings.medium }}>
              {t('bookings.totalAmount')}
              {formatPrice(completeBooking.booking_items.reduce((acc, item) => (
                acc + item.price
                          - (item.discount ?? 0)
                          - (item.payments?.reduce((acc, item) => acc + item.amount ?? 0), 0)
              ), 0))}
            </Text>
            <PaymentSwitcher
              elementType="booking"
              elementID={completeBooking?.id}
              isPaid={completeBooking?.isFullyPaid}
              isPaymentLoading={isPaymentLoading}
              visible={bPaymentVisible}
              setVisible={setBPaymentVisible}
              payAction={handlePayFullBooking}
            />
          </View>
        </View>
        <View style={styles.dates}>
          {completeBooking.booking_items
            .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
            .map((bi, biIndex) => (
              <DateStatusItem
                bookingItem={bi}
                biIndex={biIndex}
                editCurrentBookingItem={editBookingItems[biIndex]}
                currentBookingItem={currentBookingItem}
                editBookingItems={editBookingItems}
                setEditBookingItems={setEditBookingItems}
                fetchCompleteBooking={fetchCompleteBooking}
                isPaymentLoading={isPaymentLoading}
                biPaymentVisible={biPaymentVisible}
                setBiPaymentVisible={setBiPaymentVisible}
                handlePayBookingItem={handlePayBookingItem}
              />
            ))}
        </View>
      </View>
      )}
    </>
  );
};

export default DatesStatus;

DatesStatus.propTypes = {
  bookingId: PropTypes.number.isRequired,
  currentBookingItem: PropTypes.object.isRequired,
  print: PropTypes.bool,
};

DatesStatus.defaultProps = {
  print: false,
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingItemInfo: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: isMobile ? 'column' : 'row',
  },
  bookingItemSituation: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  bookingFullPeriod: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  bookingActions: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',

  },

  detailOrders: {
    padding: theme.sizings.medium,
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginVertical: theme.sizings.medium,
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: theme.sizings.small,
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrdersTitle: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: theme.sizings.medium,
    flexDirection: isMobile ? 'column' : 'row',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addOrderLabel: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
  switchLabelIcon: {
    marginRight: theme.sizings.small,
    opacity: 0.6,
  },
  switchBooleanBox: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    borderRadius: theme.radius.rounded,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonBar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? theme.sizings.medium : undefined,
  },
  buttonOrder: {
    marginLeft: theme.sizings.medium,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: theme.sizings.tiny,
  },
  buttonEditItem: {
    height: theme.normalize(38),
    width: theme.normalize(38),
  },
  bookingItemRow: {
    marginTop: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    borderTopColor: theme.colors.greyLighter,
    borderTopWidth: 1,
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'space-between',
  },
  dirRow: {
    flexDirection: 'row',
  },
  dirColumn: {
    flexDirection: 'column',
  },
});
