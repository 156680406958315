import { useNavigation } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import useBasket from 'basket/contexts/basket';
import useCategory from 'categories/contexts/categories';
import useCategoryService from 'categoryServices/contexts/categoryServices';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import useTranslation from 'common/contexts/translations';
import OrderCategory from 'orders/components/OrderCategory/OrderCategory';
import OrderCategoryService from 'orders/components/OrderCategoryService/OrderCategoryService';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import {
  Dimensions, ScrollView, StyleSheet, View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import { isMobileMode } from 'common/utils/desktopMode';

const OrderDetail = ({ route }) => {
  const { setItems: setBasket, items: basket } = useBasket();
  const { orderId } = route.params;
  const headerHeight = useHeaderHeight();
  const {
    seat, booking,
  } = route.params;
  const navigation = useNavigation();
  const {
    items: categories,
    isFetching: isCategorysFetching,
    fetchItems,
  } = useCategory();
  const {
    items: categoryServices,
    isFetching: isCategoryServicesFetching,
    fetchItems: fetchItemsServices,
  } = useCategoryService();
  const {
    fetchItem: fetchOrder,
    item: order,
    isFetching: isOrdersFetching,
    waiterCatalog,
    setCategorySelected,
  } = useOrder();

  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const isMobile = isMobileMode(useWindowDimensions());
  const commonStyles = makeCommonStyles(theme);

  const selectorStyle = {
    flexDirection: 'column',
    width: isMobile ? theme.normalize(80) : '10%',
    maxWidth: isMobile ? theme.normalize(80) : '10%',
    paddingTop: theme.sizings.large,
    paddingBottom: theme.sizings.larger,
    backgroundColor: theme.colors.darkerBlue,
    maxHeight: isMobile ? height - headerHeight - 70 : height - 70,

  };

  const scrollViewStyle = {
    width: 'auto',
    flex: 1,
    paddingVertical: theme.sizings.large,
    maxHeight: isMobile ? height - headerHeight - 70 : height - 70,
  };

  const productViewStyle = {

  };

  useEffect(() => {
    fetchItems();
    fetchItemsServices();
  }, [fetchItems, fetchItemsServices, fetchOrder, orderId, setBasket]);

  const handlePress = useCallback(() => {
    navigation.navigate('OrderValidate', {
      order, bookingId: booking, seatId: seat,
    });
  }, [navigation, order, booking, seat]);

  const showCategories = categories.filter(
    (c) => !c.parent || (!c.parent && c?.products.length),
  );

  const showServiceCategories = categoryServices.filter(
    (c) => !c.parent || (!c.parent && c?.services.length),
  );

  return (
    <WaiterLayout noScroll noPadding={!isMobile} noPaddingHorizontal hideMenu>
      {(isCategorysFetching || isOrdersFetching || isCategoryServicesFetching) && (
      <LoadingSpinner
        visible={isCategorysFetching || isOrdersFetching || isCategoryServicesFetching}
        theme={theme}
      />
      )}
      <View style={styles.container}>
        <ScrollView style={selectorStyle}>
          {showCategories.map((category) => (
            <OrderCategory key={category.id} {...category} categories={showCategories} />))}
          {showServiceCategories.map((categoryService) => (
            <OrderCategoryService key={categoryService.id} {...categoryService} />))}
        </ScrollView>
        <ScrollView style={scrollViewStyle} contentContainerStyle={productViewStyle}>
          {waiterCatalog}
        </ScrollView>
      </View>
      <View style={commonStyles.buttonWrapperIn}>
        <View style={commonStyles.buttonContainerIn}>
          <Button
            variant="gradient"
            icon="arrow-right"
            text={t('orders.goValidation')}
            disabled={basket.length === 0 || !booking}
            onPress={handlePress}
          />
        </View>
      </View>
    </WaiterLayout>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },

  container: {
    flexDirection: 'row',
    // flex: 1,
  },
});

OrderDetail.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderDetail;
