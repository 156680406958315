const getFurniturePrice = ({
  area, furnitureId, stayDuration, hotel, season,
}) => {
  const price = area?.prices.find((p) => p.furniture === furnitureId && p.season === season?.id);

  // console.log({ price });

  if (stayDuration === 'fullday') {
    return hotel ? price?.hotelPrice || 0 : price?.price || 0;
  }
  return hotel ? price?.hotelPriceHalfday || 0 : price?.priceHalfday || 0;
};

export default getFurniturePrice;
