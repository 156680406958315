import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  View, ScrollView, StyleSheet, TouchableHighlight, useWindowDimensions
} from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import Button from 'common/components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import useCustomers from 'customers/contexts/customers';
import CustomerPanel from 'waiter/components/CustomerPanel/CustomerPanel';
import { Icon } from 'react-native-elements';
import { isMobileMode } from 'common/utils/desktopMode';
import useSite from 'sites/contexts/sites';

const BookingFormCustomer = ({
  values, initialValues, form, setFormTab
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const {currentSite} = useSite();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme, isMobileMode(useWindowDimensions()));
  const [newCustomer, setNewCustomer] = useState();
  
  const setCustomer = useCallback((customer) => {
    setNewCustomer(customer);
  },[setNewCustomer]);

  const {
    items: customers, fetchItems: fetchCustomers,
  } = useCustomers();

  useEffect(() => {
    if (newCustomer) {
      form.mutators.setFieldValue({ 
        field: "customer", 
        value: newCustomer.id,
      });
    } 
  }, [newCustomer]);

  useEffect(() => {
    fetchCustomers({ pageSize: 100});
  }, [fetchCustomers])

  const getAutoCompleteSuggestions = async (newWord) => {
    /* const suggestions = customers?.filter((customer) =>
      customer.name.toLowerCase().includes(newWord.toLowerCase()) ||
      customer.firstName?.toLowerCase().includes(newWord.toLowerCase()) ||
      customer.email?.toLowerCase().includes(newWord.toLowerCase()) ||
      customer.phone?.includes(newWord)); */
    
    const suggestions = await fetchCustomers({ filters: {
      '[_or][0][name_contains]': newWord,
      '[_or][1][firstName_contains]': newWord,
      '[_or][2][email_contains]': newWord,
      '[_or][3][phone_contains]': newWord,
    }});

    const filteredSuggestions = suggestions?.map(({
      id, name, firstName, noShow, customer_category: customerCategory, civility
      }) => ({
      label: `${civility ? 
        `${t(`customer.civility${civility}`)} ` : ''}${name} ${firstName || ''} ${customerCategory && Object.keys(customerCategory).length > 0 ? 
        ` (${customerCategory.name})` : ''} ${noShow > 0 ? `, ${noShow} NOSHOW` : ''}`,
      value: id,
      filterMatch: name,
      })) || [];

    return filteredSuggestions;
  };

  const autocompleteOptions = useMemo(() => (customers)?.map(({
    id, name, firstName, noShow, customer_category: customerCategory, email, phone, civility
    }) => ({
    label: `${civility ? 
      `${t(`customer.civility${civility}`)} ` : ''}${name} ${firstName || ''} ${customerCategory && Object.keys(customerCategory).length > 0 ? ` (${customerCategory.name})` : ''} ${noShow > 0 ? `, ${noShow} NOSHOW` : ''}`,
    infos: `${civility ? 
      `${t(`customer.civility${civility}`)} ` : ''}${name} ${firstName || ''} ${customerCategory && Object.keys(customerCategory).length > 0 ? ` (${customerCategory.name})` : ''} ${noShow > 0 ? `, ${noShow} NOSHOW` : ''}
            ${email ? `${email}` : ''}${email && phone ? ' - ' : ''}${phone ? `${phone}` : ''}`,
    value: id,
    })), [customers]);

  const clientWithoutCustomerFile = !values?.customer && values?.clientName;
  const showCustomerField = !initialValues?.customer;

  const customerValue = useMemo(() => (
    customers?.find((c) => c.id === values?.customer)?.name
      ? `${customers?.find((c) => c.id === values?.customer)?.name} ${customers?.find((c) => c.id === values?.customer)?.firstName || ''}`
      :  null
  ), [customers, newCustomer]);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.columns}>
        {/* left part : customer fields */}
        <View style={styles.column}>
       
              
                  {Boolean(values?.created_by_customer && showCustomerField) && (
                    <View style={{marginVertical: theme.sizings.smallMedium}}>
                      <Text isBold>
                        {`${values.clientName} ${values.clientFirstName || ''} ${t('bookings.customerIsAuthor')}`}
                      </Text>
                      {clientWithoutCustomerFile ? (
                        <Text>{t('bookings.clientWithoutCustomerFileLegend')}</Text>
                      ):(
                        <Text>{t('bookings.clientWithCustomerFileLegend')}</Text>
                      )}
                    </View>
                  )}
                  {showCustomerField && (
                    <View style={{width: '100%', flexDirection: 'row', alignItems: 'flex-start',
                    justifyContent: 'space-between'}}>
                        <View style={{flex: 1}}>
                            <FormInput
                                placeholder={t('bookings.customerName')}
                                type={"autocomplete"}
                                icon="user"
                                name="customer"
                                label={t('bookings.customerName')}
                                required
                                initialOptions={autocompleteOptions}
                                onCreate={{
                                  handleCreate: (customerName, callback) => {
                                    navigation.navigate('CustomerEdit', {
                                      newCustomerData: { name: customerName },
                                      setCustomer: callback
                                    })},
                                  handleSubmit: setCustomer,
                                }}
                                customFiltering={getAutoCompleteSuggestions}
                                enableSearch
                                enableCreate
                                valueName={customerValue}
                            />
                        </View> 

                        {values?.customer && (
                            <TouchableHighlight
                             onPress={() => {
                              form.mutators.setFieldValue({ 
                                field: "customer", 
                                value: null,
                              });
                             }
                            }
                             style={[styles.actionButton, {
                              backgroundColor: theme.colors.greyAlpha
                             }]}
                             >
                             <Icon
                                 name="undo"
                                 type="font-awesome"
                                 size={theme.fontSizes.larger}
                                 color={theme.colors.light}
                             />
                         </TouchableHighlight>
                        )}

                        <TouchableHighlight
                            onPress={() => {
                              navigation.navigate('CustomerEdit', { newCustomerData : values?.clientName ? {
                                name: values?.clientName,
                                firstName: values?.clientFirstName,
                                phone: values?.clientPhone,
                                email: values?.clientEmail
                              }: null, setCustomer })
                            }}
                            style={styles.actionButton}
                            >
                            <Icon
                                name="plus"
                                type="font-awesome"
                                size={theme.fontSizes.larger}
                                color={theme.colors.light}
                            />
                        </TouchableHighlight>

                    
                      </View>
                  )}
         
                {Boolean(values?.clientName || values.customer) && (
                <CustomerPanel 
                  customerContent={
                    values.customer ? customers?.find(({id}) => id === values.customer) 
                  : {
                    name: values.clientName,
                    firstName: values.clientFirstName,
                    email: values.clientEmail,
                    phone: values.clientPhone,
                    notes: values.comments,
                  }}
                  inBookingEditor 
                />
                )}

          {Boolean(user.site?.canPayOnline && values?.payment?.online) && (
          <View style={styles.onlinePayment}>
            <FontAwesome
              name="money"
              color={values.payment.status === 'PAID' ? theme.colors.success : theme.colors.error}
              size={theme.sizings.large}
            />
            <Text
              isBold
              color={values.payment.status === 'PAID' ? 'success' : 'error'}
              size="larger"
            >
              {t(values.payment.status === 'PAID' ? 'bookings.onlinePaid' : 'bookings.onlinePending')}
            </Text>
          </View>
    )}

        </View>

        {/* right part: customer booking */}
        <View style={styles.column}>
          {Boolean(user.site?.canPayOnline && values?.payment?.online) && (
            <View style={styles.onlinePayment}>
              <FontAwesome
                name="money"
                color={values.payment.status === 'PAID' ? theme.colors.success : theme.colors.error}
                size={theme.sizings.large}
              />
              <Text
                isBold
                color={values.payment.status === 'PAID' ? 'success' : 'error'}
                size="larger"
              >
                {t(booking.payment.status === 'PAID' ? 'bookings.onlinePaid' : 'bookings.onlinePending')}
              </Text>
            </View>
        )}

          <FormInput
            name="hotel"
            label={t('bookings.customerType')}
            type="buttongroup"
            options={[{
              value: false,
              text: t('common.externalCustomer'),
            }, { 
              value: true,
              text: t('common.hotelCustomer'),
            }]}
          />
          {values.hotel && (
          <FormInput type="text" name="roomID" label={t('common.roomID')} required />
          )}
          <FormInput
            name="comments"
            label={t('bookings.description')}
          />
          <FormInput
            name="waiterAuthor"
            label={t('bookings.waiterAuthor')} 
            labelRequired={currentSite?.waiterAuthorRequired}
            // required  (not working)
          />
        </View>
      </View>

      <View style={{
        paddingVertical: theme.sizings.medium, alignItems: 'center', width: '100%', justifyContent: 'flex-end',
      }}
      >
        <Button
          variant={(!values.customer && !values.clientName) ? 'greyDarker' : 'gradient'}
          icon="arrow-right"
          iconColor="white"
          text={t('bookings.nextStep')}
          disabled={(!values.customer && !values.clientName) || 
            (!values.waiterAuthor && currentSite.waiterAuthorRequired)}
          type="submit"
          style={{ marginLeft: theme.sizings.medium }}
          onPress={() => setFormTab('dates')}
        />
      </View>

    </ScrollView>
  );
};

BookingFormCustomer.propTypes = {
  values: PropTypes.any.isRequired,
  initialValues: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  setFormTab: PropTypes.any.isRequired,
};

BookingFormCustomer.defaultProps = {
}



const makeStyles = (theme, isMobileMode) => StyleSheet.create({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
  },
  columns: {
    flex: 1,
    flexDirection: 'row',
    width: isMobileMode ? '100%' : theme.normalize(900),
    maxWidth: '100%',
    alignSelf: 'center',
    flexWrap: 'wrap',
  },
  column: {
    padding: theme.sizings.smallMedium,
    flex: 1,
    minWidth: isMobileMode ? '100%' : theme.normalize(400),
  },
  onlinePayment: {
    flexDirection: 'row',
    margin: 'auto',
  },
  actionButton: {
    minWidth: theme.normalize(36),
    minHeight: theme.normalize(36),
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.sizings.tiny,
    marginTop: theme.normalize(38),
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.primary,
  },

});

export default BookingFormCustomer;
