import React from 'react';
import {
  StyleSheet, TouchableHighlight,

  View,
  useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import { useNavigation } from '@react-navigation/native';
import useAuth from 'auth/contexts/auth';
import { useTranslation } from 'react-i18next';
import BadgeController from 'common/components/BadgeController/BadgeController';
import { FontAwesome5 } from '@expo/vector-icons';
import LogoutButton from 'common/components/LogoutButton/LogoutButton';
import { isMobileMode } from 'common/utils/desktopMode';
import useBeachVisualizer from '../../pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

const WaiterLeftMenu = () => {
  const navigation = useNavigation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { activeWaiterRoot, setActiveWaiterRoute } = useBeachVisualizer();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isMobile = isMobileMode({ width, height });
  const styles = makeStyles(theme, isMobile);

  const waiterRoutes = [
    {
      icon: 'map', path: 'maps', label: t('tabs.maps'), show: true,
    },
    {
      icon: 'utensils',
      path: 'waiterOrders',
      label: t('tabs.orders'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
    {
      icon: 'archive',
      path: 'stock',
      label: t('tabs.stock'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
    {
      icon: 'bell',
      path: 'calls',
      label: t('tabs.calls'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
    {
      icon: 'gift',
      path: 'offers',
      label: t('tabs.offers'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
    {
      icon: 'calendar',
      path: 'events',
      label: t('tabs.events'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
    {
      icon: 'users',
      path: 'customers',
      label: t('tabs.customers'),
      show: ['waiter', 'barman', 'manager'].includes(user?.role.type),
    },
  ];

  return (
    <View style={styles.waiterTopMenu}>
      {waiterRoutes.map((route, index) => {
        const isActive = activeWaiterRoot === route.path;

        return route.show ? (
          <TouchableHighlight
            key={`thl-${index}`}
            onPress={() => {
              setActiveWaiterRoute(route.path);
              navigation.reset({ index: 0, routes: [{ name: route.path }] });
              // navigation.navigate(route.path);
            }}
            style={{ flex: isMobileMode({ width, height }) ? 1 : undefined }}
          >
            <View style={[styles.waiterTopMenuItem, {
              opacity: isActive ? 1 : 0.7,
            }]}
            >
              <BadgeController name={route.path} />
              <FontAwesome5
                name={route.icon}
                size={theme.fontSizes.larger}
                color={isMobile ? (isActive ? theme.colors.darkBlue : theme.colors.darkGrey)
                  : isActive ? theme.colors.beach : theme.colors.light}
              />
            </View>
          </TouchableHighlight>
        ) : null;
      })}

      <LogoutButton waiterLogout />
    </View>
  );
};

WaiterLeftMenu.propTypes = {
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  waiterTopMenu: {
    backgroundColor: isMobile ? theme.colors.white : theme.colors.dark,
    flexDirection: isMobile ? 'row' : 'column',
    /* height: isMobile ? theme.sizings.waiterMenuSidebar : '100%', */
    width: isMobile ? '100%' : theme.sizings.waiterMenuSidebar,
    borderTopWidth: isMobile ? 2 : 0,
    borderTopColor: theme.colors.light,
  },
  waiterTopMenuItem: {
    height: theme.sizings.waiterMenuSidebar,
    justifyContent: 'center',
    alignItems: 'center',
    width: isMobile ? 'auto' : theme.sizings.waiterMenuSidebar,
  },
});

export default WaiterLeftMenu;
