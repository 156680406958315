import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import useBasket from 'basket/contexts/basket';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import Confirm from '../Confirm/Confirm';

const makeStyles = (theme) => StyleSheet.create({
  home: {
    marginLeft: theme.sizings.small,
  },
});

const HomeButton = () => {
  const { t } = useTranslation();
  const { setItems: setBasket } = useBasket();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const styles = makeStyles(theme);

  const goHome = () => {
    setBasket([]);
    navigation.reset({
      index: 0,
      routes: [{ name: 'HomePage' }],
    });
  };

  return (
    <Confirm confirmText={t('bookings.confirmExit')}>
      <TouchableOpacity style={[commonStyles.headerIcon, styles.home]} onPress={() => goHome()}>
        <FontAwesome
          color={theme.colors.light}
          size={theme.fontSizes.larger}
          name="home"
        />
      </TouchableOpacity>
    </Confirm>
  );
};

export default HomeButton;
