import { useHeaderHeight } from '@react-navigation/elements';
import { ImageBackground } from 'common/components/Images';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ScrollView, View, Dimensions, SafeAreaView, Text,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';

const Page = React.forwardRef(({
  children, safeArea, scroll, transparentHeader, padding, style,
  backgroundImage, backgroundSource, backgroundStyle,
  ...rest
}, ref) => {
  const headerHeight = useHeaderHeight();
  const { width, height } = Dimensions.get('window');
  const { theme } = useTheme();

  let paddingTop = transparentHeader ? headerHeight : 0;

  if (padding) {
    paddingTop += theme.sizings.medium;
  }

  const pageStyle = {
    flex: 1,
    position: 'relative',
    paddingHorizontal: padding ? theme.sizings.small : 0,
    paddingTop,
    marginBottom: safeArea ? 50 : scroll ? theme.sizings.small : 0,
  };

  const Component = scroll ? ScrollView : View;

  const content = (
    <Component style={[pageStyle, style]} ref={ref} {...rest}>
      {children}
    </Component>
  );

  return (
    <SafeAreaView>
      <View style={{
        flex: 1, backgroundColor: transparentHeader ? 'transparent' : theme.colors.white, width, height,
      }}
      >
        {(backgroundImage || backgroundSource) && (
        <ImageBackground image={backgroundImage} source={backgroundSource} style={backgroundStyle} />
        )}
        {content}
      </View>
    </SafeAreaView>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  transparentHeader: PropTypes.bool,
  padding: PropTypes.bool,
  scroll: PropTypes.bool,
  safeArea: PropTypes.bool,
  backgroundImage: PropTypes.any,
  backgroundSource: PropTypes.any,
  backgroundStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
};

Page.defaultProps = {
  style: null,
  transparentHeader: false,
  padding: false,
  scroll: false,
  safeArea: false,
  backgroundStyle: { width: '100%', height: '100%', position: 'absolute' },
  backgroundImage: null,
  backgroundSource: null,
};

export default Page;
