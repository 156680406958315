import React from 'react';
import PropTypes from 'prop-types';
import { LinearGradient } from 'expo-linear-gradient';

import useTheme from 'providers/ThemeProvider';

const Gradient = ({
  height, width, colors, styles,
}) => {
  const { theme } = useTheme();

  return (
    <LinearGradient
      colors={colors || [theme.colors.secondary, theme.colors.secondaryDark]}
      start={[0, 0.5]}
      end={[1, 0.5]}
      style={[styles, {
        zIndex: -10,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        height,
        width,
      }]}
    />
  );
};

Gradient.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styles: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  colors: PropTypes.array,
};

Gradient.defaultProps = {
  styles: null,
  height: null,
  width: null,
  colors: null,
};

export default Gradient;
