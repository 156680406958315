import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import formatPrice from 'common/utils/formatPrice';
import PropTypes from 'prop-types';
import useSiteProvider from 'providers/SiteProvider';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Gradient from 'common/components/Gradient/Gradient';
import getGeneralAreaPrices from 'common/utils/getGeneralAreaPrices';
import getAreaPrices from 'common/utils/getAreaPrices';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import { Image } from 'common/components/Images';
import Button from 'common/components/Button/Button';


const BookingContentSelector = ({
  values, currentDate, selectFurniture,
  bookedSeatsCount, bookingContent, initialBookingContent,
  bookingType, bookingItemIndex, seats, form, limitToCurrentMapId
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const {
    site,
    getPriceArea,
    getFurniture,
    getSeatInfo,
  } = useSiteProvider();

  const bookingWithSeats = bookingType === 'withSeats';

  const getOptionItemBackground = (currentAmount, initialAmount, currentItemAvailability) => {
    if (currentItemAvailability < 0 || (bookingWithSeats && initialAmount > 0 && currentAmount < initialAmount)) {
      return theme.colors.dangerLight;
    }

    if (currentAmount) {
      return theme.colors.lightPurple;
    }

    return theme.colors.greyLightest;
  };

  const currentSeats = useMemo(() => values.seats.map((s) => getSeatInfo(s)), [getSeatInfo, values]);

  return (
 <>
    {Object.entries(seats)?.map(([areaIdStr, furnitureList]) => {
                  const areaId = Number(areaIdStr);
                  const area = getPriceArea(areaId);
                  if (!area){
                    return;
                  }
                  const currentItemsPreFiltered = bookingContent?.filter((bc) => (
                    bc.price_area === areaId
                  ));
                  const prices = currentDate === null ?
                    getGeneralAreaPrices(area, 
                      values.booking_items, 
                      values.stayDuration, 
                      values.hotel, 
                      site.seasons) :
                    getAreaPrices(area, 
                      bookingItemIndex === -1 ? values.stayDuration : values.booking_items[bookingItemIndex].stayDuration, 
                      values.hotel,
                       currentDate, 
                       site.seasons);

                  const isAreaUsed = limitToCurrentMapId ? 
                  site?.maps?.find((m) => m.id === limitToCurrentMapId && m.seats?.find((s) => s.price_area?.id === area.id))
                  : site?.maps?.find((m) => m.active && m.seats?.find((s) => s.price_area?.id === area.id))
                  
                  // start area section
                    return isAreaUsed ? (
                  <View style={styles.areaListItem} key={`price-area-${area.id}`}>
                    <View style={[styles.areaTitle, {backgroundColor: area.color}]}>
                      <Text isBold color="white">{area.name}</Text>
                    </View>

                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>

                    {Object.keys(furnitureList).map(Number).map((furnitureId) => {
                      // start furniture item
                        const furniture = getFurniture(furnitureId);
                        if (!furniture) {
                          return;
                        }
                        const currentItem = currentItemsPreFiltered?.find((bc) => (
                          bc.furniture === furnitureId
                    ));
                        const initialCurrentItem = initialBookingContent?.find((bc) => (
                          bc.furniture === furnitureId && bc.price_area === areaId
                        ));
                        let currentPrice = prices.find(price => price.furnitureId === furniture.id);
                        if (!currentPrice) {
                          currentPrice = {
                            min: 0,
                            max: 0,
                            price: 0,
                          };
                        }
                        const currentItemAmount = values.bookingType === 'withSeats'
                          ? currentSeats.filter((s) => s.furniture === furnitureId && s.price_area === areaId)?.length
                          : currentItem?.amount ?? 0;
                        const initialCurrentItemAmount = initialCurrentItem?.amount ?? 0;
                        const currentItemTotalQuantity = seats[area.id]?.[furniture.id];
                        const currentItemAvailability = currentItemTotalQuantity - (bookedSeatsCount[area.id]?.[furniture.id] ?? 0);

                        const priceBase = currentDate === null ?
                        (currentPrice.min === currentPrice.max ? 
                        formatPrice(currentPrice.min * (currentItem?.amount ?? 0), site.currency) :
                         `${formatPrice(currentPrice.min * (currentItem?.amount ?? 0), site.currency)} - ${formatPrice(currentPrice.max * (currentItem?.amount ?? 0), site.currency)}`) 
                         : formatPrice(currentPrice.price * (currentItem?.amount ?? 0), site.currency)
                         
                         
                        const isFurnitureUsed = limitToCurrentMapId ? 
                        site?.maps?.find((m) => m.id === limitToCurrentMapId && m.seats?.find((s) => s.furniture?.id === furnitureId))
                        : site?.maps?.find((m) => m.active && m.seats?.find((s) => s.furniture?.id === furnitureId))

                        
                         return isFurnitureUsed ? (
                          <View style={[
                            styles.optionListItem,
                            {backgroundColor: getOptionItemBackground(currentItemAmount, initialCurrentItemAmount, currentItemAvailability)}
                            ]}
                            key={`price-area-${area.id}-${furnitureId}`}
                          >

                            <View style={styles.optionImage}>
                              <Image image={furniture.image} style={{ 
                                width: theme.normalize(60), height: theme.normalize(60)
                                }} />
                            </View>

                            <View style={styles.optionMain}>
                              <View style={{ flexDirection: 'row' }}>
                                <View style={{ flexDirection: 'column' }}>
                                  <TranslatedText value={furniture?.name} />
                                  <Text color="dangerBright">
                                    {currentDate === null ? (currentPrice.min === currentPrice.max ? formatPrice(currentPrice.min, site.currency) : `${formatPrice(currentPrice.min, site.currency)} - ${formatPrice(currentPrice.max, site.currency)}`) : formatPrice(currentPrice.price, site.currency)}
                                    {/* {currentDate === null ? (`${formatPrice(currentPrice.min, site.currency)} - ${formatPrice(currentPrice.max, site.currency)}`) : formatPrice(currentPrice.price, site.currency)} */}

                                  </Text>
                                </View>
                              </View>
                              <View>

                              {/* panier quantité */}
                              { !bookingWithSeats && (
                              <View style={styles.optionButtonsGroup}>
                                  <Gradient height={theme.normalize(40)} />

                                  <Button
                                    noPadding
                                    text="-"
                                    variant="transparent"
                                    iconSize="default"
                                    style={{...styles.optionButton, opacity: currentItemAmount > 0 ? 1 : 0.6}}
                                    onPress={(currentItemAmount > 0)
                                      ? () => selectFurniture(area, furniture, -1, form?.mutators || null)
                                      : null
                                    }
                                 
                                  />

                                  <Text size="large" color="light" style={styles.optionQuantity}>
                                    {currentItem?.amount || '0'}
                                    { Boolean(values.id) && (
                                      `/${initialCurrentItemAmount}`
                                    )}
                                  </Text>

                                  <Button
                                    noPadding
                                    text="+"
                                    variant="transparent"
                                    iconSize="default"
                                    style={{...styles.optionButton, opacity: currentItemAvailability > 0 ? 1 : 0.6}}
                                    onPress={(currentItemAvailability > 0)
                                      ? () => selectFurniture(area, furniture, 1, form?.mutators || null)
                                      : null
                                    }
                                  />

                                </View>
                                )}

                                {/* panier résumé */}
                                { bookingType === 'withSeats' && (
                                <View style={styles.optionResumeBox}>
                                  <View style={styles.optionResumeDone}>
                                    <Gradient colors={[theme.colors.darkBlue, theme.colors.darkerBlue]} 
                                    height={theme.normalize(24)}/>

                                    <Text  color="light" style={styles.optionQuantity}>
                                      {`${t('bookings.seatPositioned',{ nb: currentItemAmount || 0})}/${initialCurrentItemAmount}`}
                                    </Text> 
                                  </View>
                                  { Boolean(values.id) && (currentItemAmount !== initialCurrentItemAmount) &&  (
                                    <View style={styles.optionResumeWarning}>
                                      <Text color="dangerBright" size="small">
                                        { currentItemAmount > initialCurrentItemAmount ? 
                                          t('bookings.seatPositionedExtra',{ nb: currentItemAmount - initialCurrentItemAmount})
                                          :
                                          t('bookings.seatToPosition',{ nb: initialCurrentItemAmount - currentItemAmount})
                                        }
                                      </Text>
                                    </View>
                                    )}
                                </View>
                                )}
                              </View>
                            </View>
                            <View style={styles.optionRight}>
                              <Text color="grey" size="tiny">
                                {`${currentItemAvailability}/${currentItemTotalQuantity} ${t('bookings.seatsAvailable')}`} 
                              </Text>
                              <Text size="larger" isBold>
                                {priceBase}
                                {/* {currentDate === null ? (`${formatPrice(currentPrice.min * (currentItem?.amount ?? 0), site.currency)} - ${formatPrice(currentPrice.max * (currentItem?.amount ?? 0), site.currency)}`) : formatPrice(currentPrice.price * (currentItem?.amount ?? 0), site.currency)} */}
                              </Text>
                            </View>

                          </View>
                        ): null;
                      })}
                    </View>
                  </View>
                  ): null
                    })}</>
  );
};

BookingContentSelector.propTypes = {
  values: PropTypes.any.isRequired,
  currentDate: PropTypes.any,
  selectFurniture: PropTypes.func.isRequired,
  bookedSeatsCount: PropTypes.any.isRequired,
  bookingType: PropTypes.any.isRequired,
  bookingContent: PropTypes.array,
  initialBookingContent: PropTypes.array,
  bookingItemIndex: PropTypes.any.isRequired,
  seats: PropTypes.object,
  form: PropTypes.any,
  limitToCurrentMapId: PropTypes.any,

};

BookingContentSelector.defaultProps = {
  currentDate: null,
  bookingContent: [],
  initialBookingContent: [],
  seats: {},
  form: null,
  limitToCurrentMapId: null
};

const makeStyles = (theme) => StyleSheet.create({

  optionResumeBox: {

  },
  optionResumeDone: {
    marginBottom: theme.sizings.tiny,
    overflow: 'hidden',
    borderRadius: theme.radius.medium,
  },
  optionResumeWarning: {

  },
  areaListItem: {
    marginTop: theme.sizings.mediumLarge,
  },
  areaTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.small,
    marginHorizontal: '1%',
    borderRadius: theme.radius.medium,
  },
  areaCircleColor: {
    width: theme.sizings.large,
    height: theme.sizings.large,
    marginRight: theme.sizings.smallMedium,
    borderRadius: theme.radius.rounded,
  },
  optionListItem: {
    borderRadius: theme.radius.medium,
    marginTop: theme.radius.medium,
    padding: theme.sizings.small,
    flexDirection: 'row',
    width: '100%',
    marginHorizontal: '1%',
  },
  optionImage: {
    width: theme.normalize(60),
    height: theme.normalize(60),
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.greyLightest,
    marginRight: theme.sizings.medium,
  },
  optionMain: {
    flex: 1,
    marginRight: theme.sizings.smallMedium,
  },
  optionRight: {
    alignItems: 'flex-end',
    maxWidth: 100
  },
  optionButtonsGroup: {
    width: theme.normalize(100),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
  },
  optionQuantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },
  optionButton: {
    paddingHorizontal: theme.sizings.small,
  },
});

export default BookingContentSelector;
