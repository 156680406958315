import { NativeSwitch as Switch } from 'common/components/Switch/Switch';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const makeStyles = (theme) => StyleSheet.create({
  orderItem: {
    padding: theme.sizings.small,
    paddingTop: 12,
    marginTop: theme.sizings.small,
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.greyLightest,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alignLeft: {
    flexShrink: 1,
    paddingRight: 16,
  },
});

const OfferItem = ({ specialOffer }) => {
  const [loading, setLoading] = useState(false);
  const { update } = useSpecialOffers();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);
  const handleToggleActive = useCallback(async () => {
    setLoading(true);
    try {
      await update(specialOffer.id, { ...specialOffer, active: !specialOffer.active });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [update, specialOffer]);

  return (
    <View style={[styles.orderItem, commonStyles.shadowBottom]}>
      <TranslatedText style={styles.alignLeft} isBold size="medium" value={specialOffer.message} />
      <Switch
        loading={loading}
        activeColor={theme.colors.secondary}
        onChange={handleToggleActive}
        value={specialOffer.active}
      />
    </View>
  );
};

OfferItem.propTypes = {
  specialOffer: PropTypes.object.isRequired,
};

OfferItem.defaultProps = {
};

export default OfferItem;
