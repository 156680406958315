import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import useTranslation from 'common/contexts/translations';
import Text from 'common/components/Text/Text';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useAuth from 'auth/contexts/auth';
import useOrders from 'orders/contexts/orders';
import Page from 'common/components/Page/Page';
import ProfileOrderListItem from 'orders/pages/OrderList/ProfileOrderListItem';
import useTheme from 'providers/ThemeProvider';

const ProfileOrdersList = () => {
  const { user } = useAuth();
  const {
    items: orders,
    fetchItems: fetchOrders,
    setFilters,
    filters,
    isFetching,
    setSort,
  } = useOrders();
  const { t } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    if (user) {
      setFilters({
        user: user.id,
        active: true,
      });
    }
  }, [user, setFilters]);

  useEffect(() => {
    if (filters.user) {
      fetchOrders();
    }
  }, [filters, fetchOrders]);

  useEffect(() => {
    setSort('created_at:DESC');
  }, [setSort]);

  const currentOrders = useMemo(
    () => orders.filter(({ created_at: createdAt, updated_at: updatedAt }) => (
      dayjs(updatedAt || createdAt).startOf('day').isSame(dayjs().startOf('day')))),
    [orders],
  );

  const passedOrders = useMemo(
    () => orders.filter(({ created_at: createdAt, updated_at: updatedAt }) => (
      dayjs(updatedAt || createdAt).startOf('day').isBefore(dayjs().startOf('day')))),
    [orders],
  );

  const nextOrders = useMemo(
    () => orders.filter(({ created_at: createdAt, updated_at: updatedAt }) => (
      dayjs(updatedAt || createdAt).startOf('day').isAfter(dayjs().startOf('day')))),
    [orders],
  );

  return (
    <Page scroll safeArea padding>
      {currentOrders.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('common.today')}
          </Text>
          {currentOrders.length && currentOrders.map((order) => (
            <ProfileOrderListItem key={order.id} {...order} />
          ))}
        </>
      )}

      {nextOrders.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('common.nextToCome')}
          </Text>
          {nextOrders.length && nextOrders.map((order) => (
            <ProfileOrderListItem key={order.id} {...order} />
          ))}
        </>
      )}

      {passedOrders.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('common.history')}
          </Text>
          {passedOrders.length && passedOrders.map((order) => (
            <ProfileOrderListItem key={order.id} {...order} />
          ))}
        </>
      )}

      {!isFetching && !currentOrders.length > 0 && !nextOrders.length > 0 && !passedOrders.length > 0 && (
        <Text>{t('orders.noOrders')}</Text>
      )}

      <LoadingSpinner visible={isFetching} theme={theme} />
    </Page>
  );
};

export default ProfileOrdersList;
