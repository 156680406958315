/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';
import {
  Platform, StyleSheet, TouchableOpacity, View, useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';

import useBasket from 'basket/contexts/basket';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import { isMobileMode } from 'common/utils/desktopMode';
import ProductWithOptionFormModal from './ProductWithOptionFormModal';

const OrderProductItem = ({
  entity,
  type,
  editable,
  price,
  reduction,
}) => {
  const {
    addItem, removeAllOf, items: basket,
  } = useBasket();
  const [modalVisible, setModalVisible] = useState(false);
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const isMobile = isMobileMode(useWindowDimensions());
  const styles = makeStyles(editable, hasQuantity, theme, isMobile);

  const quantity = useMemo(
    () => basket.filter((p) => p[type] && p[type].id === entity.id)
      .map((p) => p.quantity)
      .reduce((a, b) => a + b, 0),
    [basket, entity, type],
  );
  const hasQuantity = useMemo(
    () => quantity > 0,
    [quantity],
  );

  return (
    <View style={[styles.itemView, commonStyles.shadowBottom]}>
      <TouchableOpacity
        onPress={(() => editable
          && (entity.options?.length ? setModalVisible(true) : addItem(entity, [], price, type)))}
        onLongPress={(() => editable && removeAllOf(entity))}
        style={styles.innerItem}
      >
        <View style={styles.productContent}>
          <View style={styles.productLabel}>
            <TranslatedText
              style={{ margin: 'auto', width: '100%' }}
              size="medium"
              color="dark"
              isBold
              value={entity.name}
              truncateLength={30}
            />
          </View>
          <View style={styles.bottom}>
            { hasQuantity ? (
              <View style={styles.quantityIndicator}>
                <Text size="small" color="light">x</Text>
                <Text size="small" color="light" style={styles.quantity}>
                  {quantity}
                </Text>
              </View>
            ) : <View style={styles.spacer} />}
            <ReductionPrice
              showOriginalPrice={false}
              containerStyle={styles.price}
              price={price}
              newPriceSize="medium"
              newPriceColor={hasQuantity ? 'white' : 'primary'}
              reduction={reduction}
            />
          </View>
        </View>
      </TouchableOpacity>
      { entity.options.length > 0 && (
      <ProductWithOptionFormModal
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        entity={entity}
        type={type}
      />
      )}
    </View>
  );
};

OrderProductItem.path = 'products';

OrderProductItem.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.string,
  editable: PropTypes.bool,
  price: PropTypes.number,
  reduction: PropTypes.object,
};

OrderProductItem.defaultProps = {
  editable: false,
  type: 'product',
  price: 0,
  reduction: null,
};

export default OrderProductItem;

const makeStyles = (editable, quantity, theme, isMobile) => StyleSheet.create({
  itemView: {
    // flex: 1,
    width: '100%',
    aspectRatio: isMobile ? 2 : null,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: quantity ? theme.colors.primary : theme.colors.greyLighter,
    borderRadius: theme.radius.medium,
    margin: theme.sizings.tiny,
  },

  innerItem: {
    width: '100%',
    height: '100%',
  },

  productContent: {
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
    width: '100%',
    padding: theme.sizings.tiny,

  },

  productLabel: {
    textAlign: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    padding: theme.sizings.small,
    borderBottomColor: 'rgba(0,0,0,0.2)',
    borderBottomWidth: 1,
    flex: 8,
  },

  variant: {
    marginBottom: 'auto',
    marginTop: 0,
    backgroundColor: theme.colors.secondaryLight,
    paddingHorizontal: theme.sizings.small,
    borderRadius: theme.radius.rounded,
  },

  quantityIndicator: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.tiny,
    textAlign: 'center',
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.darkerBlue,
  },
  spacer: {
    flex: 1,
  },

  badge: {
    width: Platform.OS === 'web' ? theme.normalize(40) : theme.normalize(20),
    height: '100%',
  },

  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },

  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.normalize(30),
    width: '100%',
    padding: theme.sizings.small,
  },

  price: {
    flex: quantity ? 3 : 1,
  },

  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});
