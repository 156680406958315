import React, {
  useCallback, useContext, createContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import AsyncStorage from '@react-native-async-storage/async-storage';

import fetchJSON from 'common/utils/fetchJSON';
import { getApiUrl } from 'common/utils/createUrl';
import useTranslation from 'common/contexts/translations';
import usePersistedState from 'common/utils/usePersistedState';
import useAlert from 'common/contexts/alert';
import useNotification from 'realtime/contexts/notification';
import usePersistedState2 from 'common/utils/usePersistedState2';

const AuthContext = createContext();

const setJwtInStorage = async (jwt) => {
  try {
    await AsyncStorage.setItem('jwt', jwt);
  } catch (error) {
    console.log('error while saving jwt');
  }
};

const removeJwtInStorage = async () => {
  try {
    await AsyncStorage.removeItem('jwt');
  } catch (error) {
    console.log('error while removing jwt');
  }
};

const forgotPassword = async (payload) => {
  const apiUrl = getApiUrl();

  const res = await fetch(`${apiUrl}/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 200) {
    const resJson = await res.json();

    return resJson;
  }

  throw new Error();
};

const resetPassword = async (payload) => {
  const apiUrl = getApiUrl();

  const res = await fetch(`${apiUrl}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 200) {
    const resJson = await res.json();

    return resJson;
  }

  throw new Error();
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = usePersistedState(null, 'user');
  const [seat, setSeat] = usePersistedState(null, 'seat');
  const [booking, setBooking] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { setAlert } = useAlert();
  const { t, lang } = useTranslation();
  const { pushToken } = useNotification();

  const signout = useCallback(() => {
    if (user) {
      fetchJSON({ url: 'users/logout', method: 'POST', payload: {} });
    }
    setUser(null);
    removeJwtInStorage();
  }, [setUser, user]);

  const refreshUser = useCallback(async () => {
    try {
      const token = await AsyncStorage.getItem('jwt');

      if (token) {
        const res = await fetchJSON({
          url: 'users/me',
          method: 'GET',
        });

        if (res) {
          setUser(res);
          return res;
        }
      }

      signout();

      return null;
    } catch {
      // if users/me is not accessible then the token expired
      signout();
    }
  }, [setUser, signout]);

  const signin = useCallback(async (email, password) => {
    const payload = {
      identifier: email,
      password,
    };
    const apiUrl = getApiUrl();

    setIsFetching(true);
    try {
      const res = await fetch(`${apiUrl}/auth/local`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (res.status === 200) {
        const resJson = await res.json();

        await setJwtInStorage(resJson.jwt);

        if (pushToken) {
          fetchJSON({ url: `users/${resJson.user.id}`, method: 'PUT', payload: { pushToken, language: lang } });
        }

        setUser(resJson.user);
        return (resJson.user);
      }

      throw new Error('BAD_CREDENTIALS');
    } catch {
      throw new Error('BAD_CREDENTIALS');
    } finally {
      setIsFetching(false);
    }
  }, [lang, pushToken, setUser]);

  const handleErrors = useCallback((errors) => {
    errors.forEach((err) => {
      err.messages.forEach((msg) => {
        switch (msg.id) {
          case 'Auth.form.error.email.taken':
            setAlert({ color: 'error', title: t('common.error'), message: t('auth.emailAlreadyTaken') });
            break;
          default:
            setAlert({ color: 'error', title: t('common.error'), message: t('auth.badCredentials') });
        }
      });
    });
  }, [setAlert, t]);

  const signup = useCallback(async ({
    name, email, password, phone,
  }) => {
    const payload = {
      username: email,
      name,
      email,
      phone,
      password,
      pushToken,
      language: lang,
    };
    const apiUrl = getApiUrl();

    setIsFetching(true);
    try {
      const res = await fetch(`${apiUrl}/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const resJson = await res.json();

      if (resJson.error) {
        handleErrors(resJson.message);
        throw new Error('BAD_CREDENTIALS');
      }

      setUser(resJson.user);
      await setJwtInStorage(resJson.jwt);

      return (resJson.user);
    } catch {
      throw new Error('BAD_CREDENTIALS');
    } finally {
      setIsFetching(false);
    }
  }, [pushToken, lang, handleErrors, setUser]);

  const deleteAccount = useCallback(async (password) => {
    setIsFetching(true);
    try {
      await fetchJSON({
        url: 'users/delete-my-account',
        method: 'PUT',
        payload: {
          password,
        },
      });

      signout();
    } catch {
      throw new Error('BAD_CREDENTIALS');
    } finally {
      setIsFetching(false);
    }
  }, [signout]);

  const updateUser = useCallback(async (payload) => {
    setIsFetching(true);
    try {
      const res = await fetchJSON({ url: `users/${user.id}`, method: 'PUT', payload });

      setUser(res);
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  }, [setUser, user]);

  const value = useMemo(() => ({
    user,
    signin,
    signup,
    signout,
    setUser,
    updateUser,
    forgotPassword,
    isFetching,
    seat,
    setSeat,
    booking,
    setBooking,
    deleteAccount,
    refreshUser,
    resetPassword,
  }), [
    user,
    signin,
    signup,
    signout,
    setUser,
    updateUser,
    isFetching,
    seat,
    setSeat,
    booking,
    setBooking,
    deleteAccount,
    refreshUser,
  ]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export const useLocalUser = () => {
  const [localUser, setLocalUser] = usePersistedState2(null, 'local-user', null);

  return { localUser, setLocalUser };
};

export default useAuth;
