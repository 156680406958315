import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View } from 'react-native';
import useTranslation from 'common/contexts/translations';

import useAuth from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import formatPrice from 'common/utils/formatPrice';
import useAlert from 'common/contexts/alert';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Text from 'common/components/Text/Text';
import Page from 'common/components/Page/Page';
import usePayment from 'payments/contexts/payments';
import BookingRecap from 'payments/components/BookingRecap';
import OrderRecap from 'payments/components/OrderRecap';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useSite from 'sites/contexts/sites';

const PaymentRecap = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { user, isFetching: authFetching } = useAuth();
  const { paymentId } = route.params;
  const { item: payment, fetchItem: fetchPayment, isFetching } = usePayment();
  const { setAlert } = useAlert();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  console.log({ site });

  const handlePay = useCallback(() => {
    if (!payment.site.canPayOnline) {
      setAlert({ color: 'success', title: t('common.success'), message: t('bookings.createdPayWaiter') });
      navigation.reset({
        index: 0,
        routes: [{
          name: 'HomePage',
        }, {
          name: 'ProfilePage',
        }],
      });
    } else if (user.cardId || payment?.site?.isDemo) {
      navigation.navigate('Payment', {
        payment,
        callback: async () => {
          navigation.reset({
            index: 0,
            routes: [{
              name: 'HomePage',
            }, {
              name: 'ProfilePage',
            }],
          });
        },
      });
    } else {
      navigation.navigate('CreditCardForm', {
        callback: () => {
          navigation.navigate('Payment', {
            payment,
            callback: async () => {
              navigation.reset({
                index: 0,
                routes: [{
                  name: 'HomePage',
                }, {
                  name: 'ProfilePage',
                }],
              });
            },
          });
        },
      });
    }
  }, [payment, user, setAlert, t, navigation]);

  useEffect(() => {
    fetchPayment(paymentId);
  }, [fetchPayment, paymentId]);

  return (
    <Page>
      <LoadingSpinner
        visible={(authFetching || isFetching)}
        theme={theme}
      />
      <ScrollView>
        {payment && (
        <View>
          {payment.booking && <BookingRecap paid={payment.status === 'PAID'} booking={payment.booking} />}
          {(payment.orders && payment.orders.length > 0) && payment.orders.map((order, index) => (
            <OrderRecap key={index} order={order} />))}
          {!!payment?.site?.onlineTransactionFees && payment.amount > 0 && (
            <Text
              size="large"
              isBold
              centered
            >
              {t('basket.transactionFees', {
                price: formatPrice(payment?.site?.onlineTransactionFees,
                  payment?.site.currency),
              })}
            </Text>
          )}
        </View>
        )}
        <View style={commonStyles.scrollViewWithButton} />
      </ScrollView>
      {payment && payment.status === 'PENDING' && payment.booking?.online && (
        <View style={commonStyles.buttonWrapper}>
          {payment.preAuthorizationId && (
            <View style={[commonStyles.buttonContainer, commonStyles.preAuthorizedMessage]}>
              <Text>
                {t('common.isPreAuthorized')}
              </Text>
            </View>
          )}
          <View style={commonStyles.buttonContainer}>
            <Button
              variant="gradient"
              icon="credit-card"
              text={t(payment.site.canPayOnline ? 'basket.pay' : 'basket.bookFor', {
                total: formatPrice(payment.amount > 0
                  ? payment.amount + payment?.site.onlineTransactionFees : payment.amount, payment?.site.currency),
              })}
              onPress={handlePay}
              disabled={!!(authFetching || isFetching || payment.preAuthorizationId)}
            />
          </View>
        </View>
      )}
    </Page>

  );
};

PaymentRecap.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default PaymentRecap;
