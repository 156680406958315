import React, { useCallback } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import Button from 'common/components/Button/Button';
import useTheme from 'providers/ThemeProvider';
import PropTypes from 'prop-types';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import { isMobileMode } from 'common/utils/desktopMode';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FontAwesome5 } from '@expo/vector-icons';

const BookButton = ({ selectedSeats, date, iconOnly }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme, isMobileMode(useWindowDimensions()));
  const { getSeatAvailableStayDuration } = useBeachVisualizer();

  const handleNewBooking = useCallback(() => {
    navigation.navigate('BookingEdit', {
      initialSeats: selectedSeats,
      initialStayDuration: getSeatAvailableStayDuration(selectedSeats, date),
      date,
      editedBooking: null,
    });
  }, [getSeatAvailableStayDuration, navigation, selectedSeats, date]);

  return iconOnly ? (
    <TouchableOpacity
      style={styles.buttonToggle}
      onPress={handleNewBooking}
    >
      <FontAwesome5
        name="book"
        color={theme.colors.white}
        size={theme.fontSizes.large * 1.2}
      />
    </TouchableOpacity>
  ) : (
    <View style={styles.container}>
      <Button
        onPress={handleNewBooking}
        text={
        selectedSeats.length
          ? t('waiter.bookXSeats', { seats: selectedSeats.length })
          : t('waiter.newBooking')
}
        variant="gradient"
        icon="book"
        small
      />
    </View>
  );
};

BookButton.propTypes = {
  selectedSeats: PropTypes.array,
  date: PropTypes.any.isRequired,
  iconOnly: PropTypes.bool,
};

BookButton.defaultProps = {
  selectedSeats: [],
  iconOnly: false,
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: isMobile ? 'auto' : 0,
    top: isMobile ? theme.normalize(55) : 'auto',
    left: '0%',
    width: '100%',
    backgroundColor: theme.colors.dark,
    padding: theme.sizings.medium,
  },
  buttonToggle: {
    backgroundColor: 'black',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
    cursor: 'pointer',
  },
});

export default BookButton;
