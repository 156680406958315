import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  View, TouchableOpacity, Image, Platform, SafeAreaView,
} from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import Text from 'common/components/Text/Text';
import Page from 'common/components/Page/Page';
import useSite from 'sites/contexts/sites';
import useBasket from 'basket/contexts/basket';
import LanguagePicker from 'common/components/LanguagePicker/LanguagePicker';
import useTheme from 'providers/ThemeProvider';
import Scanner from 'assets/scanner.png';
import useAuth from 'auth/contexts/auth';
import useTranslation from 'common/contexts/translations';
import makeCommonStyles from 'styles/commonStyles';
import useBooking from 'bookings/contexts/bookings';
import dayjs from 'dayjs';
import useAlert from 'common/contexts/alert';
import BookingCodeModal from 'bookings/components/BookingCodeModal/BookingCodeModal';
import SiteHeaderTitle from 'sites/components/SiteHeaderTitle/SiteHeaderTitle';
import makeStyles from './HomePage.styles';

const HomePage = () => {
  const { t, setLang } = useTranslation();
  const navigation = useNavigation();
  const { setItems: setBasket } = useBasket();
  const { user, updateUser } = useAuth();
  const { currentSite } = useSite();
  const { setAlert } = useAlert();
  const { setItem: setBooking, checkCode } = useBooking();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const styles = makeStyles(theme);

  const isAnonymousMode = currentSite?.anonymousMode;

  const handleBookPressed = useCallback(async () => {
    setBooking({
      site: currentSite.id,
    });
    if (currentSite.hotel) {
      navigation.navigate('UserTypePage', { siteId: currentSite.id });
    } else {
      navigation.navigate('DateSelect', { siteId: currentSite.id });
    }
  }, [navigation, setBooking, currentSite]);

  // j'ai un code de réservation

  const [modalCodeVisible, setModalCodeVisible] = useState(false);

  const handleCodeSubmit = useCallback(async ({ code }) => {
    try {
      const booking = await checkCode(code);

      if (booking === 'OK') {
        navigation.navigate('HomePage');
        setAlert({ color: 'success', title: t('common.success'), message: t('bookings.codeSuccess') });
      }
      navigation.navigate('SiteDetail', { siteId: booking.site.id, bookingId: booking.id });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('bookings.codeError') });
    } finally {
      setModalCodeVisible(false);
    }
  }, [checkCode, navigation, setAlert, t]);

  /* end changements du 11/04/2022 */

  const handleChange = useCallback((language) => {
    user && updateUser({ language });
  }, [user, updateUser]);

  // TODO C'est un quick fix relatif à un bug de usePersisted state
  // qui force à recharger la page 2 fois pour avoir la bonne valeur initiale
  // du basket à savoir []
  useEffect(() => {
    setBasket([]);
  }, [setBasket]);

  useEffect(() => {
    user?.language && setLang(user.language);
  }, [user, setLang]);

  const offlinks = useMemo(() => ([
    {
      title: t('homePage.pro'),
      action: () => {
        if (user) {
          navigation.navigate('ProfilePage');
        } else {
          navigation.navigate('SignInPage', {
            callback: (u) => {
              !Platform.OS === 'web' && ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock[(u && ['waiter', 'barman'].includes(u.role?.type))
                  ? 'DEFAULT'
                  : 'PORTRAIT_UP'],
              );
              const nextPage = ['waiter', 'barman'].includes(u.role.type) ? 'WaiterHome' : 'ProfilePage';

              navigation.navigate(nextPage);
            },
          });
        }
      },
    },
    // { title: t('beach.enterTicket'), action: () => setModalCodeVisible(true) },
    // { title: t('beach.iAmHere'), action: () => navigation.navigate({ name: 'QuickSitesList' }) },
  ]), [t, navigation, user]);

  // if currentSite TODO populate maps.length
  const siteLinks = useMemo(() => ([
    {
      title: t(dayjs(currentSite?.dateEnd).isBefore(dayjs()) ? 'beach.closedSeason' : 'beach.book'),
      hide: !currentSite?.canBookOnline || !currentSite?.is_open || !currentSite?.maps?.length > 0,
      disabled: dayjs(currentSite?.dateEnd).isBefore(dayjs()),
      action: handleBookPressed,
    },
    {
      title: t((!currentSite?.canBookOnline || currentSite?.maps?.length === 0)
        ? 'beach.noOnlineBooking' : 'beach.closed'),
      hide: currentSite?.canBookOnline && currentSite?.is_open && currentSite?.maps?.length > 0,
      disabled: true,
      action: () => {},
    },
    {
      title: t('homePage.account'),
      hide: isAnonymousMode,
      action: () => {
        if (user) {
          navigation.navigate('ProfilePage');
        } else {
          navigation.navigate('SignInPage', {
            callback: (u) => {
              ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock[(u && ['waiter', 'barman'].includes(u.role?.type))
                  ? 'DEFAULT'
                  : 'PORTRAIT_UP'],
              );
              const nextPage = ['waiter', 'barman'].includes(u.role.type) ? 'WaiterHome' : 'ProfilePage';

              navigation.navigate(nextPage);
            },
          });
        }
      },
    },
    { title: t('beach.enterTicket'), action: () => setModalCodeVisible(true) },
    // client historical
    { title: t('profilePage.myBookings'), action: () => navigation.navigate({ name: 'BookingsList' }) },
    // { title: t('orders.myOrders'), action: () => navigation.navigate({ name: 'ProfileOrders' }) },
    {
      title: t('homePage.pro'),
      action: () => {
        if (user) {
          navigation.navigate('ProfilePage');
        } else {
          navigation.navigate('SignInPage', {
            callback: (u) => {
              !Platform.OS === 'web' && ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock[(u && ['waiter', 'barman'].includes(u.role?.type))
                  ? 'DEFAULT'
                  : 'PORTRAIT_UP'],
              );
              const nextPage = ['waiter', 'barman'].includes(u.role.type) ? 'WaiterHome' : 'ProfilePage';

              navigation.navigate(nextPage);
            },
          });
        }
      },
    },

  ]), [t, navigation, user, currentSite, isAnonymousMode, handleBookPressed]);

  const links = currentSite ? siteLinks : offlinks;

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: theme.colors.dark }}>
      <View style={[currentSite ? styles.siteMainView : styles.mainView]}>
        <View style={currentSite ? styles.siteButtons : styles.buttons}>

          {currentSite
            ? (
              <View style={styles.titleContainer}>
                <View style={{ textAlign: 'center' }}>
                  <SiteHeaderTitle />
                </View>
              </View>
            )
            : (
              <View style={styles.qrcode}>
                <Image style={styles.scanner} source={Scanner} />
                <View style={styles.scanQR}>
                  <Text isBold color="purple" size="medium" style={{ textAlign: 'center' }}>
                    {t('beach.scanQRCode')}
                  </Text>
                </View>
              </View>
            )}

          {links.map((l, index) => {
            if (!l.hide) {
              return (
                <TouchableOpacity
                  key={`link-${index}`}
                  style={[styles.button, commonStyles.shadowBottom]}
                  onPress={l.action}
                  disabled={l.disabled}
                >
                  <Text isBold color="dark" size="large">{l.title}</Text>

                  {!l.disabled && (
                  <View style={styles.icon}>
                    <LinearGradient
                      colors={[theme.colors.secondary, theme.colors.secondaryDark]}
                      start={[0, 0.5]}
                      end={[1, 0.5]}
                      style={{
                        zIndex: -10,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: theme.normalize(30),
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesome name="arrow-right" color={theme.colors.light} size={theme.fontSizes.medium} />
                    </LinearGradient>
                  </View>
                  )}
                </TouchableOpacity>
              );
            }
            return null;
          })}
        </View>
        <View style={styles.footer}>
          <LanguagePicker onChange={handleChange} />
          <Text style={styles.footerVersion}>version 1.1.3</Text>
        </View>
      </View>

      <BookingCodeModal
        setModalVisible={setModalCodeVisible}
        modalVisible={modalCodeVisible}
        handleCodeSubmit={handleCodeSubmit}
      />
    </SafeAreaView>
  );
};

export default HomePage;
