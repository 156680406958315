import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import useTranslation from 'common/contexts/translations';
import useTheme from 'providers/ThemeProvider';
import styles from './Text.styles';

const StyledText = ({
  color, noLineHeight, isUppercase, isBold, size, centered, children, style, ...rest
}) => {
  const { lang } = useTranslation();
  const { theme } = useTheme();

  return (
    <Text
      {...rest}
      style={[styles(lang, color, size, noLineHeight, isUppercase, isBold, centered, theme).text, style]}
    >
      {children}
    </Text>
  );
};

StyledText.propTypes = {
  color: PropTypes.string,
  isUppercase: PropTypes.bool,
  isBold: PropTypes.bool,
  centered: PropTypes.bool,
  noLineHeight: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
};

StyledText.defaultProps = {
  children: null,
  color: 'dark',
  isUppercase: false,
  isBold: false,
  noLineHeight: false,
  size: 'default',
  centered: false,
  style: null,
};

export default StyledText;
