import React from 'react';
import { View, ScrollView, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';

const ScrollViewLarge = ({
  style,
  children,
}) => isMobileMode(useWindowDimensions()) ? (
  <View style={style}>
    {children}
  </View>
) : (
  <ScrollView style={style}>
    {children}
  </ScrollView>
);

ScrollViewLarge.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
};

ScrollViewLarge.defaultProps = {
  children: <></>,
  style: null,
};

export default ScrollViewLarge;
