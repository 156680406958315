import React from 'react';
import { View } from 'react-native';
import Button from 'common/components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';

const NavigationButton = ({
  link, title, params, variant, styles, icon, alsoOnPress,
}) => {
  const navigation = useNavigation();

  const handlePress = () => {
    alsoOnPress();
    navigation.navigate(link, params);
  };

  return (
    <View>
      <Button
        icon={icon}
        text={title}
        variant={variant}
        onPress={handlePress}
        style={styles}
      />
    </View>
  );
};

NavigationButton.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  variant: PropTypes.string,
  params: PropTypes.object,
  styles: PropTypes.object,
  alsoOnPress: PropTypes.func,
};

NavigationButton.defaultProps = {
  params: {},
  styles: {},
  variant: 'light',
  icon: '',
  alsoOnPress: () => {},
};

export default NavigationButton;
