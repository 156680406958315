import React from 'react';
import {
  View, StyleSheet,
} from 'react-native';
import Text from 'common/components/Text/Text';
import useTheme from 'providers/ThemeProvider';
import { PropTypes } from 'prop-types';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Button from '../Button/Button';

/**
 * Warning : If the height of the zone changes, it may create a infinite js loop
 * as it encounters the resize event for the map redimensionning.
 * (so carrefull with vertical padding, heights, font sizes and line returns)
 */

const Pagination = ({
  page,
  nbPages,
  onChange,
  showArrows,
  showFullPages,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const pageNumber = (pageNum) => (
    <TouchableOpacity
      key={`page-${pageNum}`}
      onPress={() => onChange(pageNum)}
      style={{
        ...styles.button,
        ...(pageNum === page ? styles.buttonActive : {}),
        ...(pageNum === 0 && !showArrows ? styles.buttonLeft : {}),
        ...(pageNum === nbPages - 1 && !showArrows ? styles.buttonRight : {}),
      }}
    >
      <Text
        centered
        isBold={pageNum === page}
        style={{ color: pageNum === page ? theme.colors.white : theme.colors.purple }}
      >
        {`${pageNum + 1}`}
      </Text>
    </TouchableOpacity>
  );

  const pageNumbersFull = () => (
    Array(nbPages).fill(null).map((el, pageNum) => pageNumber(pageNum))
  );

  const pageNumbersShort = () => {
    let centerPage = page;

    if (page > nbPages - 1 - 2) {
      centerPage = nbPages - 1 - 2;
    } else if (page < 2) {
      centerPage = 2;
    }

    return (
      <View style={{ paddingVertical: theme.sizings.medium }}>
        {pageNumber(0)}

        {nbPages > 5 && centerPage > 2 && (
          <Text centered style={{ marginLeft: theme.sizings.tiny, color: theme.colors.purple }}>...</Text>
        )}

        {nbPages > 2 && centerPage > 1 && pageNumber(centerPage - 1)}
        {nbPages > 3 && centerPage > 0 && centerPage < nbPages - 1 && pageNumber(centerPage)}
        {nbPages > 4 && centerPage > 1 && centerPage < nbPages - 2 && pageNumber(centerPage + 1)}

        {nbPages > 5 && centerPage < nbPages - 3 && (
          <Text centered style={{ marginLeft: theme.sizings.tiny, color: theme.colors.purple }}>...</Text>
        )}

        {nbPages > 1 && pageNumber(nbPages - 1)}
      </View>
    );
  };

  return nbPages > 0 ? (
    <View style={styles.paginationContainer}>
      {showArrows && (
      <Button
        onPress={page > 0 ? () => onChange(page - 1) : null}
        variant="white"
        iconColor="primary"
        icon="angle-left"
        small
        style={{
          ...styles.button,
          ...styles.buttonLeft,
        }}
      />
      )}
      {showFullPages ? pageNumbersFull() : pageNumbersShort()}

      {showArrows && (
      <Button
        onPress={page + 1 < nbPages ? () => onChange(page + 1) : null}
        variant="white"
        iconColor="primary"
        icon="angle-right"
        small
        style={{
          ...styles.button,
          ...styles.buttonRight,
        }}
      />
      )}
    </View>
  ) : null;
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  nbPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showArrows: PropTypes.bool,
  showFullPages: PropTypes.bool,
};

Pagination.defaultProps = {
  showArrows: false,
  showFullPages: false,
};

const makeStyles = (theme) => StyleSheet.create({
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    /* La ligne suivante cause une boucle sans fin sur le composant parent !? */
    /* paddingVertical: theme.sizings.small, */
  },
  button: {
    flexDirection: 'column',
    width: theme.sizings.large,
    height: theme.sizings.large,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.sizings.tiny,
    backgroundColor: theme.colors.light,
    borderColor: theme.colors.purple,
    borderWidth: 2,
    cursor: 'pointer',
  },
  buttonActive: {
    backgroundColor: theme.colors.purple,
  },

  buttonLeft: {
    borderTopLeftRadius: theme.radius.rounded,
    borderBottomLeftRadius: theme.radius.rounded,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginLeft: 0,
    paddingLeft: theme.sizings.tiny,
  },

  buttonRight: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: theme.radius.rounded,
    borderBottomRightRadius: theme.radius.rounded,
    paddingRight: theme.sizings.tiny,
  },

});

export default Pagination;
