import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { AuthProvider } from 'auth/contexts/auth';
import { SitesProvider } from 'sites/contexts/sites';
import { ChatProvider } from 'sites/contexts/chat';
import { BasketProvider } from 'basket/contexts/basket';
import { OrdersProvider } from 'orders/contexts/orders';
import { CategoriesProvider } from 'categories/contexts/categories';
import { CategoryServicesProvider } from 'categoryServices/contexts/categoryServices';
import { SeatsProvider } from 'seats/contexts/seats';
import { ProductsProvider } from 'products/contexts/products';
import { SeatSelectorProvider } from 'bookings/pages/SeatSelector/contexts/clientSeatSelector';
import { BeachVisualizerProvider } from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import { BookingsProvider } from 'bookings/contexts/bookings';
import { SocketProvider } from 'realtime/contexts/socket';
import { NotificationProvider } from 'realtime/contexts/notification';
import { AlertProvider } from 'common/contexts/alert';
import { LoadingProvider } from 'common/contexts/loading';
import { TranslationProvider } from 'common/contexts/translations';
import { PaymentsProvider } from 'payments/contexts/payments';
import { CallsProvider } from 'calls/contexts/calls';
import { SpecialOffersProvider } from 'specialOffers/contexts/specialOffers';

import AppStateHandler from 'common/components/AppStateHandler/AppStateHandler';
import { ListsProvider } from 'sites/contexts/lists';
import { CustomersProvider } from 'customers/contexts/customers';
import { CustomerCategoriesProvider } from 'customers/contexts/customerCategories';
import { SiteProvider } from 'providers/SiteProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { CallTypesProvider } from 'callTypes/contexts/callTypes';
import { EventsProvider } from 'events/contexts/events';

const AppProvider = ({ children }) => (
  <Suspense>
    <TranslationProvider>
      <SpecialOffersProvider>
        <LoadingProvider>
          <AlertProvider>
            <NotificationProvider>
              <AuthProvider>
                <ThemeProvider>
                  <SiteProvider>
                    <SitesProvider>
                      <ListsProvider>
                        <ChatProvider>
                          <BasketProvider>
                            <BeachVisualizerProvider>
                              <OrdersProvider>
                                <CategoriesProvider>
                                  <CategoryServicesProvider>
                                    <ProductsProvider>
                                      <SeatSelectorProvider>
                                        <SeatsProvider>
                                          <CustomerCategoriesProvider>
                                            <CustomersProvider>
                                              <EventsProvider>
                                                <CallsProvider>
                                                  <CallTypesProvider>
                                                    <BookingsProvider>
                                                      <PaymentsProvider>
                                                        <SocketProvider>
                                                          <AppStateHandler>
                                                            {children}
                                                          </AppStateHandler>
                                                        </SocketProvider>
                                                      </PaymentsProvider>
                                                    </BookingsProvider>
                                                  </CallTypesProvider>
                                                </CallsProvider>
                                              </EventsProvider>
                                            </CustomersProvider>
                                          </CustomerCategoriesProvider>
                                        </SeatsProvider>
                                      </SeatSelectorProvider>
                                    </ProductsProvider>
                                  </CategoryServicesProvider>
                                </CategoriesProvider>
                              </OrdersProvider>
                            </BeachVisualizerProvider>
                          </BasketProvider>
                        </ChatProvider>
                      </ListsProvider>
                    </SitesProvider>
                  </SiteProvider>
                </ThemeProvider>
              </AuthProvider>
            </NotificationProvider>
          </AlertProvider>
        </LoadingProvider>
      </SpecialOffersProvider>
    </TranslationProvider>
  </Suspense>
);

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
