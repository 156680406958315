import { Ionicons } from '@expo/vector-icons';
import BeachBackground from 'assets/beach.jpg';
import { useAuth } from 'auth/contexts/auth';
import Page from 'common/components/Page/Page';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import {
  View,
} from 'react-native';
import { GiftedChat, Send } from 'react-native-gifted-chat';
import useChat from 'sites/contexts/chat';
import useTheme from 'providers/ThemeProvider';

const Chat = ({ route }) => {
  const auth = useAuth();
  const {
    messages, fetchMessages, sendMessage, setChatWith, setChatActive,
  } = useChat();
  const { booking } = auth;
  const { user } = route.params;
  const { t, lang } = useTranslation();
  const { theme } = useTheme();

  useEffect(() => {
    setChatWith(user);
    fetchMessages();
    setChatActive(true);

    return () => { setChatWith(null); };
  }, [user, fetchMessages, setChatWith, setChatActive]);

  const onSend = useCallback((messages) => {
    sendMessage(messages[0], user);
  }, [sendMessage, user]);

  return (
    <Page transparentHeader backgroundSource={BeachBackground} style={{ marginBottom: 0 }}>
      <GiftedChat
        messages={messages[user.id]}
        onSend={onSend}
        alwaysShowSend
        isTyping
        locale={lang.split('-')[0]}
          // loadEarlier
          // isLoadingEarlier
          // renderFooter={() => t('chat.userIsTyping')}
        placeholder={t('chat.yourMessage')}
        renderSend={(props) => (
          <Send
            {...props}
          >
            <View style={{ marginRight: 10, marginBottom: 10 }}>
              <Ionicons
                color={theme.colors.primary}
                name="ios-send"
                size={theme.fontSizes.larger}
              />
            </View>
          </Send>
        )}
        user={{
          _id: booking?.chat_user?.id,
        }}
      />
    </Page>
  );
};

Chat.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Chat;
