import React from 'react';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import useBasket from 'basket/contexts/basket';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import { isMobileMode } from 'common/utils/desktopMode';

const AddOrderButton = () => {
  const { setItems: setBasket } = useBasket();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const isMobile = isMobileMode(useWindowDimensions());

  const addOrder = () => {
    setBasket([]);
    navigation.navigate('OrderSeatSelect');
  };

  return (
    <TouchableOpacity
      style={[commonStyles.headerIcon,
        !isMobile ? { position: 'absolute', top: 0, right: 0 } : null,
      ]}
      onPress={addOrder}
    >
      <FontAwesome5
        color={theme.colors.light}
        size={theme.fontSizes.larger}
        name="utensils"
        iconFamily="fontawsome5"
      />
    </TouchableOpacity>
  );
};

export default AddOrderButton;
