/**
 * Epson ePos for React documentation:
 *  - https://github.com/rubenruvalcabac/epson-epos-sdk-react
 *
 * Hack to import directly in component :
 *  - Add "var io = module.exports;" as first line in lib file
 *  - Import it in component : "import '<path-to-lib>';"
 *  - Use it as documented in official doc : window.epson...
 */
import epson from './epos-2.24.0';

export const printTicket = async (host, params) => {
  // eslint-disable-next-line new-cap
  const ePosDev = new window.epson.ePOSDevice();

  ePosDev.connect(
    host,
    80,
    (res) => cbConnect(ePosDev, res, params),
  );
};

export const checkPrinter = (host) => {
  if (!host) return false;

  // eslint-disable-next-line new-cap
  const ePosDev = new window.epson.ePOSDevice();

  return new Promise((resolve, reject) => {
    try {
      ePosDev.connect(
        host,
        80,
        (res) => {
          if (res === 'OK' || res === 'SSL_CONNECT_OK') {
            resolve(true);
          } else {
            resolve(false);
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};

function cbConnect(ePosDev, res, params) {
  if (res === 'OK' || res === 'SSL_CONNECT_OK') {
    ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER,
      { crypto: false, buffer: false }, (devobj, retcode) => cbCreateDevice(devobj, retcode, params));
  } else {
    console.log(res);
  }
}

function cbCreateDevice(devobj, retcode, params) {
  if (retcode === 'OK') {
    const printer = devobj;

    printer.timeout = 60000;
    // printer.onreceive = (res) => { printer.disconnect(); };
    printer.oncoveropen = () => { console.log('coveropen'); };
    print(printer, params);
  } else {
    console.log(retcode);
  }
}

function print(printer, params) {
  if (!params) {
    console.log('ERROR : No content to print provided');
    return;
  }
  if (params.title) {
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextSize(3, 3);
    printer.addTextFont(printer.FONT_SPECIAL_B);
    // printer.addTextSmooth(true);
    printer.addText(`${params.title}\n`);
  }
  if (params.qrCodeValue) {
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addSymbol(`${params.qrCodeValue}\n`, printer.SYMBOL_QRCODE_MICRO, printer.LEVEL_M, 7, 0, 0);
  }
  if (params.code) {
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextFont(printer.FONT_A);
    printer.addTextSize(2, 1);
    printer.addText(`Réservation ${params.code}\n`);
  }
  if (params.infos) {
    printer.addFeed();
    printer.addTextSize(1, 1);
    printer.addTextAlign(printer.ALIGN_LEFT);
    Object.entries(params.infos).forEach(([labels, values]) => {
      printer.addText(`${labels} : ${values}\n`);
    });
  }
  printer.addFeed();
  printer.addCut(printer.CUT_FEED);
  printer.send();
}

export default printTicket;
