import { useNavigation } from '@react-navigation/native';
import Page from 'common/components/Page/Page';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useCustomers from 'customers/contexts/customers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import CustomerForm from 'waiter/components/CustomerForm/CustomerForm';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';

const CustomerEdit = ({ route }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const {
    save, update, fetchItem, isFetching, item: customer, error,
  } = useCustomers();
  const navigation = useNavigation();

  const {
    id: customerId,
    setCustomer,
    newCustomerData,
  } = route.params;

  useEffect(() => {
    fetchItem(customerId);
  }, [fetchItem, customerId]);

  const handleSubmit = useCallback(
    async (data) => {
      const customer = {
        ...data,
        phone: data.phone || '',
        email: data.email || '',
      };

      if (customer.id) {
        await update(customer.id, customer);
        // fetchItems();

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('common.saveSuccess') });
          navigation.pop();
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('common.error') });
        }
      } else {
        const newUser = await save({
          ...customer,
        });

        // fetchItems();

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('common.saveSuccess') });
          if (setCustomer) {
            setCustomer(newUser);
          }
          navigation.pop();
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('common.error') });
        }
      }
    },
    [update, error, setAlert, t, save, navigation, setCustomer],
  );

  return (
    <WaiterLayout noPadding hideMenu boxed>

      {(customer || !isFetching) && (

      <CustomerForm
        onSubmit={handleSubmit}
        customer={customer?.id === customerId ? customer : {}}
        newCustomerData={newCustomerData}
      />
      )}

    </WaiterLayout>
  );
};

CustomerEdit.propTypes = {
  route: PropTypes.object.isRequired,
};

export default CustomerEdit;
