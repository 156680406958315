import React from 'react';
import PropTypes from 'prop-types';
import {
  View, ScrollView, Text,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import Button from 'common/components/Button/Button';
import makeCommonStyles from 'styles/commonStyles';
import Page from 'common/components/Page/Page';
import FormInput from 'common/components/FormInput/FormInput';
import { Form } from 'react-final-form';
import useBooking from 'bookings/contexts/bookings';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import { useLocalUser } from 'auth/contexts/auth';

const UserTypePage = ({ route }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const { siteId } = route.params;
  const { t } = useTranslation();
  const { setItem: setBooking } = useBooking();
  const { item: site } = useSite();
  const { localUser, setLocalUser } = useLocalUser();

  const handleSubmit = (values) => {
    if ((values.hotel || site.restrictedToHotelClients) && !values.roomID) {
      return {
        roomID: t('errors.required'),
      };
    }

    setLocalUser((prevUser) => (
      {
        ...prevUser,
        hotel: values.hotel,
        roomID: values.roomID,
      }
    ));

    setBooking((prevBooking) => ({
      ...prevBooking,
      hotel: values.hotel,
      roomID: values.roomID,
    }));
    navigation.navigate('DateSelect', { siteId });
  };

  return (
    <Page padding>
      <Form
        onSubmit={handleSubmit}
        initialValues={localUser
          ? { hotel: localUser.hotel || false, roomID: localUser.roomID || '' }
          : { hotel: false, roomID: '' }}
        render={({ handleSubmit, values }) => (
          <>
            <ScrollView>
              { !site.restrictedToHotelClients && (
              <FormInput
                name="hotel"
                label={t('common.youAre')}
                type="buttongroup"
                options={[{
                  value: false,
                  text: t('common.externalCustomer'),
                  iconName: 'user',
                }, {
                  value: true,
                  text: t('common.hotelCustomer'),
                  iconName: 'bed',
                }]}
                verticalMobile
              />
              )}
              {(values.hotel || site.restrictedToHotelClients) && (
              <>
                <FormInput type="text" name="roomID" label={t('common.roomID')} required />
                <View style={{ paddingHorizontal: theme.sizings.small }}>
                  <Text>{t('common.warningHotel')}</Text>
                </View>
              </>
              )}

              <View style={commonStyles.scrollViewWithButton} />
            </ScrollView>
            <View style={commonStyles.buttonWrapper}>
              <View style={commonStyles.buttonContainer}>
                <Button
                  variant="gradient"
                  icon="arrow-right"
                  text={t('orders.continue')}
                  disabled={values.hotel && values.roomID === ''}
                  onPress={handleSubmit}
                />
              </View>
            </View>
          </>
        )}
      />
    </Page>
  );
};

UserTypePage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default UserTypePage;
