import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import usePersistedState from 'common/utils/usePersistedState';
import ContextProvider from 'common/contexts/crud';

import fetchJSON from 'common/utils/fetchJSON';

const ListContext = createContext();

export const ListsProvider = ({ children }) => {
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [currentList, setCurrentList] = usePersistedState(null, 'list');
  const [lock, setLockOn] = usePersistedState(null, 'lock');

  const fetchListData = useCallback(async (listId) => {
    setIsDataFetching(true);
    try {
      const res = await fetchJSON({ url: `lists/${listId}`, method: 'GET' });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsDataFetching(false);
    }
  }, []);

  const value = useMemo(() => ({
    fetchListData,
    isDataFetching,
    currentList,
    setCurrentList,
    lock,
    setLockOn,
  }), [
    fetchListData,
    isDataFetching,
    currentList,
    setCurrentList,
    lock,
    setLockOn,
  ]);

  return (
    <ContextProvider
      url="lists"
      context={ListContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

ListsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useList = () => useContext(ListContext);

export default useList;
