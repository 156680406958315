import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import DateInput from 'common/components/DateInput/DateInput';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import Select from 'common/components/Select/Select';
import Text from 'common/components/Text/Text';
import TimePicker from 'common/components/TimePicker/TimePicker';
import useTranslation from 'common/contexts/translations';
import getFF from 'common/utils/getFF';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { StyleSheet, Switch, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import { AutoComplete } from '../AutoComplete/AutoComplete';

const Input = ({
  type, name, placeholder, errorMessage, children, label,
  labelStyle, containerStyle, inputStyle, inputContainerStyle,
  value, onChange, meta, editable, labelRequired,
  ...otherProps
}) => {
  const { lang } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(lang, theme);

  let fullLabel = label;

  if (labelRequired) {
    fullLabel = `${label} *`;
  }

  switch (type) {
    case 'date':
      return (
        <DateInput
          value={value}
          onChange={onChange}
          label={fullLabel}
          placeholder={placeholder}
          errorStyle={{ color: theme.colors.danger }}
          errorMessage={errorMessage}
          {...otherProps}
        />
      );
    case 'switch':
      return (
        <View style={[styles.horizontal, styles.containerStyle]}>
          <Text isBold style={styles.switchLabel} color="primary">{fullLabel}</Text>
          <Switch
            onValueChange={onChange}
            value={value}
          />
        </View>
      );
    case 'select':
      return (
        <View style={styles.containerStyle}>
          <Text isBold style={styles.label} color="primary">{fullLabel}</Text>
          <Select
            {...otherProps}
            onChange={onChange}
            value={value}
          />
        </View>
      );
    case 'autocomplete':
      return (
        <View style={styles.containerStyle}>
          <Text isBold style={styles.label} color="primary">{label}</Text>
          <AutoComplete
            {...otherProps}
            onChange={onChange}
            value={value}
          />
        </View>
      );
    case 'time':
      return (
        <View style={styles.containerStyle}>
          <Text isBold style={styles.label} color="primary">{fullLabel}</Text>
          <TimePicker
            {...otherProps}
            onChange={onChange}
            value={value}
          />
        </View>
      );
    case 'buttongroup':
      return (
        <View style={styles.containerStyle}>
          <Text isBold style={styles.label} color="primary">{fullLabel}</Text>
          <ButtonGroup
            {...otherProps}
            onChange={onChange}
            value={value}
          />
        </View>
      );
    case 'custom':
      return (
        <View style={styles.containerStyle}>
          <Text isBold style={styles.label} color="primary">{fullLabel}</Text>
          {React.cloneElement(children, { ...otherProps, value, onChange })}
        </View>
      );

    default:
      let keyboardType = 'default';

      switch (type) {
        case 'number': keyboardType = 'number-pad'; break;
        case 'phone': keyboardType = 'phone-pad '; break;
        case 'email': keyboardType = 'email-address'; break;
        case 'decimal': keyboardType = 'decimal-pad'; break;
        default: break;
      }

      return (
        <>
          <DebouncedInput
            value={value}
            secureTextEntry={type === 'password'}
            keyboardType={keyboardType}
            type={type}
            autoCapitalize={type === 'code' ? 'characters' : 'none'}
            onChange={onChange}
            label={fullLabel}
            placeholder={placeholder}
            errorStyle={{ color: theme.colors.danger }}
            errorMessage={errorMessage}
            inputStyle={{
              ...inputStyle,
              fontSize: theme.fontSizes.medium,
            }}
            inputContainerStyle={{
              borderBottomColor: theme.colors.primary,
              ...inputContainerStyle,
            }}
            containerStyle={{
              marginVertical: theme.sizings.small,
              ...containerStyle,
            }}
            labelStyle={{
              // textTransform: 'capitalize',
              fontFamily: getFF(lang, true, theme),
              color: theme.colors.primary,
              fontWeight: 'bold',
              fontSize: theme.fontSizes.medium,
              ...labelStyle,
            }}
            editable={editable}
          />
          {meta && meta.touched && meta.error && <Text>{meta.error}</Text>}
        </>

      );
  }
};

Input.propTypes = {
  meta: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  errorMessage: PropTypes.string,
  children: PropTypes.element,
  label: PropTypes.string,
  labelStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  labelRequired: PropTypes.bool,
  type: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  inputContainerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
};

Input.defaultProps = {
  meta: null,
  value: '',
  onChange: () => { },
  type: 'text',
  name: 'input',
  icon: '',
  placeholder: '',
  disabled: false,
  options: [],
  errorMessage: '',
  children: null,
  label: '',
  labelStyle: 0,
  labelRequired: false,
  inputStyle: 0,
  inputContainerStyle: 0,
  containerStyle: 0,
  editable: true,
};

const FormInput = ({
  name,
  finalForm,
  onChange,
  labelRequired,
  ...rest
}) => {
  if (finalForm === false) {
    return <Input name={name} onChange={onChange} {...rest} />;
  }
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Input
          {...rest}
          value={input.value}
          onChange={(val) => {
            input.onChange(val);
            onChange(val);
          }}
          meta={meta}
          labelRequired={labelRequired}
        />
      )}

    />
  );
};

const makeStyles = (lang, theme) => StyleSheet.create({
  inputContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.primary,
  },
  label: {
    paddingBottom: theme.sizings.small,
    fontSize: theme.fontSizes.medium,
    fontFamily: getFF(lang, true, theme),
  },
  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
  },

  switchLabel: {
    marginRight: theme.sizings.small,
    fontFamily: getFF(lang, true, theme),
  },
});

FormInput.propTypes = {
  finalForm: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  errorMessage: PropTypes.string,
  children: PropTypes.element,
  label: PropTypes.string,
  labelStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  labelRequired: PropTypes.bool,
  type: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  inputContainerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  editable: PropTypes.bool,
};

FormInput.defaultProps = {
  finalForm: true,
  value: '',
  onChange: () => { },
  type: 'text',
  name: 'input',
  icon: '',
  placeholder: '',
  disabled: false,
  options: [],
  errorMessage: '',
  children: null,
  label: '',
  labelStyle: 0,
  labelRequired: false,
  inputStyle: 0,
  inputContainerStyle: 0,
  containerStyle: 0,
  editable: true,
};

export default FormInput;
