import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import useTheme from 'providers/ThemeProvider';
import useBeachVisualizer from '../../WaiterHome/BeachVisualizer/contexts/beachVisualizer';

const ToggleCustomerButton = () => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { showCustomerBloc, setShowCustomerBloc } = useBeachVisualizer();

  return (
    <TouchableOpacity
      style={styles.buttonToggle}
      onPress={() => setShowCustomerBloc(!showCustomerBloc)}

    >
      <FontAwesome5
        name={showCustomerBloc ? 'book' : 'user'}
        color={theme.colors.white}
        size={theme.fontSizes.large * 1.2}
      />
    </TouchableOpacity>
  );
};

export default ToggleCustomerButton;

const makeStyles = (theme) => StyleSheet.create({
  buttonToggle: {
    backgroundColor: 'black',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
    cursor: 'pointer',
  },
});
