import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import theme from 'styles';

const OrderInProgress = ({
  ordersLength, color,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  return (
    <View style={styles.iconText}>
      <FontAwesome5
        name="utensils"
        color={theme.colors.dangerBright}
        size={theme.sizings.medium}
        style={styles.icon}
      />
      <View style={{ flex: 1, paddingLeft: theme.sizings.tiny }}>
        <Text
          isBold
          size="small"
          color={color}
        >
          {ordersLength}
          {' '}
          {t('waiter.orders').toLowerCase()}
        </Text>
      </View>
    </View>
  );
};

OrderInProgress.propTypes = {
  ordersLength: PropTypes.number,
  color: PropTypes.string,
};

OrderInProgress.defaultProps = {
  ordersLength: 0,
  color: theme.colors.dark,
};

const makeStyles = (theme) => StyleSheet.create({
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
});

export default OrderInProgress;
