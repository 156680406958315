import { useNavigation } from '@react-navigation/native';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  ActivityIndicator, View, Platform,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import useAuth from 'auth/contexts/auth';
import EventForm from 'events/components/EventForm';
import useEvents from 'events/contexts/events';
import dayjs from 'dayjs';

const EventEdit = ({ route }) => {
  const {
    editedEvent,
  } = route.params;

  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const {
    save, update, remove, isFetching, error, fetchItem, item: fetchedEvent, fetchEvents,
  } = useEvents();
  const {
    date: selectedDate,
  } = useBeachVisualizer();
  const { user } = useAuth();
  const navigation = useNavigation();
  const { theme } = useTheme();

  useEffect(() => {
    if (editedEvent?.id) {
      fetchItem(editedEvent.id);
    }
  }, [fetchItem, editedEvent]);

  const event = useMemo(() => {
    if ((fetchedEvent || !isFetching)
      && (editedEvent?.id === fetchedEvent?.id)) {
      return fetchedEvent;
    }
    return editedEvent;
  }, [editedEvent, fetchedEvent, isFetching]);

  const handleNavigate = useCallback(() => {
    /*  if (orderCreation) {
      navigation.navigate('OrderDetail', { seat: booking?.seats[0], booking });
    } else { */
    navigation.pop();
    /* } */
  }, [navigation]);

  const handleDelete = useCallback(async (bookingId) => {
    await remove(bookingId);

    setAlert({ color: 'success', title: t('common.success'), message: t('events.deletedEvent') });
    !Platform.OS === 'web' && ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock[(user && ['waiter', 'barman'].includes(user.role?.type))
        ? 'DEFAULT'
        : 'PORTRAIT_UP'],
    );
    navigation.reset({
      index: 0,
      routes: [{ name: ['waiter', 'barman'].includes(user.role.type) ? 'WaiterHome' : 'HomePage' }],
    });
  }, [remove, setAlert, t, user, navigation]);

  const handleSubmit = useCallback(
    async (data) => {
      // eslint-disable-next-line no-param-reassign
      data = {
        ...data,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      };
      if (data.id) {
        await update(data.id, data);

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('events.editSuccess') });
          fetchEvents();
          handleNavigate();
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('events.editError') });
        }
      } else {
        await save({
          ...data,
        });

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('events.createSuccess') });
          fetchEvents();
          handleNavigate();
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('events.createError') });
        }
      }
    },
    [error, fetchEvents, handleNavigate, save, setAlert, t, update],
  );

  return (
    <WaiterLayout noPadding noScroll>
      <>
        {isFetching && (
          <View style={{
            justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: theme.normalize(400),
          }}
          >
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        )}
        { !isFetching && (
          <EventForm
            onSubmit={handleSubmit}
            handleDelete={handleDelete}
            event={event}
            selectedDate={selectedDate}
          />
        )}
      </>
    </WaiterLayout>
  );
};

EventEdit.propTypes = {
  route: PropTypes.object.isRequired,
};

export default EventEdit;
