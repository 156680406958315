import useTheme from 'providers/ThemeProvider';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';
import Text from 'common/components/Text/Text';
import BookingListItem from 'waiter/components/BookingListItem/BookingListItem';
import Button from 'common/components/Button/Button';
import { FontAwesome5 } from '@expo/vector-icons';
import dayjs from 'dayjs';
import { isMobileMode } from 'common/utils/desktopMode';
import useTrans from 'common/hooks/use-trans';
import useBeachVisualizer from '../../contexts/beachVisualizer';

const FocusedSeatBooking = () => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const { theme } = useTheme();
  const styles = makeStyles(theme, isMobileMode(useWindowDimensions()));

  const {
    bookingItemFocused, setBookingItemFocused, switchSeat, setSwitchSeat,
    setSeatFocused, getSeatData, seatFocused, getRefreshedBookingItem,
  } = useBeachVisualizer();

  const [displayedBookingItem, setDisplayedBookingItem] = useState();

  useEffect(() => {
    setDisplayedBookingItem(getRefreshedBookingItem(bookingItemFocused));
  }, [bookingItemFocused, getRefreshedBookingItem]);

  const { orders, calls } = getSeatData(seatFocused?.id);

  const isOrdersLate = dayjs().diff(orders, 'minutes') > 10;

  if (!bookingItemFocused) {
    return null;
  }

  const bookingFocused = bookingItemFocused.booking;

  return displayedBookingItem && (
    <View style={styles.contextualSeatResume}>

      <View style={styles.columnLeft}>
        <BookingListItem key={bookingItemFocused.id} bookingItem={displayedBookingItem} isMapFooter />
      </View>

      {/* middle */}
      <View style={styles.columnCenter}>

        {Boolean(orders?.filter((o) => o.state !== 'done')?.length) && (
        <View style={styles.textIcon}>
          <FontAwesome5
            name="utensils"
            size={theme.fontSizes.larger}
            color={isOrdersLate ? theme.colors.dangerBright
              : theme.colors.orange}
            style={styles.icon}
          />
          <Text color="greyLighter">
            {t('waiter.ordersNb', { nb: orders.length })}
          </Text>
        </View>
        )}

        {Boolean(calls?.length) && (
        <View style={styles.textIcon}>
          <FontAwesome5
            name="bell"
            size={theme.fontSizes.larger}
            color={isOrdersLate ? theme.colors.dangerBright
              : theme.colors.purple}
            style={styles.icon}
          />
          <Text color="greyLighter">
            {t('socket.call')}
            {' '}
            {calls[0]?.call_type?.name ? `: ${translateText(calls[0]?.call_type?.name)}` : ''}
          </Text>
        </View>
        )}

        {displayedBookingItem.status !== 'reserved' && (
        <View style={styles.textIcon}>
          <FontAwesome5
            name="credit-card"
            size={theme.fontSizes.larger}
            color={!displayedBookingItem.isFullyPaid ? theme.colors.dangerBright
              : theme.colors.lighterGreen}
            style={styles.icon}
          />

          <Text color={!displayedBookingItem.isFullyPaid ? 'dangerBright' : 'lighterGreen'}>
            {' '}
            {t(`bookings.bookingAllPaid_${displayedBookingItem.isFullyPaid || false}`)}
          </Text>
        </View>
        )}

        <View style={styles.textIcon}>
          <FontAwesome5
            name="calendar"
            type="font-awesome"
            size={theme.fontSizes.larger}
            color={theme.colors.greyLighter}
            style={styles.icon}
          />
          <Text color="greyLighter">
            { t(`bookings.${bookingFocused?.periodType}Date`)}
          </Text>
        </View>

        <Button
          icon="arrows-alt"
          iconFamily="fontawsome5"
          iconColor={switchSeat ? 'white' : 'primary'}
          textColor={switchSeat ? 'white' : 'primary'}
          iconSize="medium"
          style={{
            backgroundColor: switchSeat ? theme.colors.dangerBright : theme.colors.white,
            marginTop: theme.sizings.small,
          }}
          onPress={() => setSwitchSeat(!switchSeat)}
          text={t('bookings.switchSeat')}
          small
        />
      </View>

      {/* right */}
      <View style={styles.columnRight}>
        <TouchableHighlight
          onPress={() => { setBookingItemFocused(null); setSeatFocused(null); }}
        >
          <FontAwesome5
            name="times"
            size={theme.fontSizes.larger}
            color={theme.colors.primary}
          />

        </TouchableHighlight>

      </View>

    </View>
  );
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  contextualSeatResume: {
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    width: '100%',
    backgroundColor: theme.colors.dark,
    flexDirection: isMobile ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
  },
  switchButton: {
    marginLeft: theme.sizings.mediumLarge,
    minWidth: theme.normalize(36),
    minHeight: theme.normalize(36),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.sizings.small,
    borderWidth: 1,
    borderColor: theme.colors.greyAlpha,
    borderRadius: theme.radius.rounded,
    backgroundColor: 'transparent',
  },
  columnLeft: {
    padding: theme.sizings.small,
  },
  columnCenter: {
    padding: theme.sizings.smallMedium,
    flex: 1,
  },
  columnRight: {
    paddingHorizontal: theme.sizings.medium,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.sizings.small,
  },
  textIcon: {
    flexDirection: 'row',
    marginBottom: theme.sizings.small,
  },
  icon: {
    width: theme.sizings.mediumLarge,
  },
});

export default FocusedSeatBooking;
