import useAuth from 'auth/contexts/auth';
import BasketItem from 'basket/components/BasketItem/BasketItem';
import PaymentModal from 'basket/components/PaymentModal/PaymentModal';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import formatPrice from 'common/utils/formatPrice';
import React, { useState } from 'react';
import {
  ScrollView, StyleSheet, View,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import useOffer from 'specialOffers/hooks/use-offer';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';

const makeStyles = (theme) => StyleSheet.create({
  totalWrapper: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.greyLighter,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.sizings.medium,
    padding: theme.sizings.medium,
  },

  totalLabel: {
    flex: 1,
  },

  items: {
    marginTop: theme.sizings.small,
  },

  basketContainer: {
    flex: 1,
    paddingTop: theme.sizings.large,
    backgroundColor: theme.colors.white,
  },

  basketInner: {
    paddingHorizontal: theme.sizings.small,
  },
});

const Basket = () => {
  const {
    items: basket,
  } = useBasket();
  const { booking } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const {
    findProductOffer,
    findServiceOffer,
  } = useOffer();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  let total = 0;

  const products = basket.filter((item) => item.product);
  const services = basket.filter((item) => item.service);

  return (
    <Page>
      <ScrollView>
        <View style={{ padding: theme.sizings.small }}>
          {basket && basket.length > 0
            ? (
              <View>
                {products && products.length > 0 && (
                <>
                  <Text size="large" color="dark" isBold>{t('common.products')}</Text>
                  <View style={styles.items}>
                    {products.map((productItem, index) => {
                      const reduction = findProductOffer(productItem.product);

                      const itemPrice = reduction ? (
                        Math.round((((100 - reduction.discount_rate) / 100) * productItem.price) * 100) / 100) : (
                        productItem.price);

                      total += itemPrice * productItem.quantity;

                      return (
                        <BasketItem
                          key={index}
                          price={productItem.price}
                          entity={productItem.product}
                          entityType="product"
                          number={productItem.quantity}
                          reduction={reduction}
                          selectedOptions={productItem.selected_options}
                          editable
                        />
                      );
                    })}
                  </View>
                </>
                )}
                {services && services.length > 0 && (
                <>
                  <Text size="large" color="dark" isBold>{t('basket.services')}</Text>
                  <View style={styles.items}>
                    {services.map((serviceItem, index) => {
                      const reduction = findServiceOffer(serviceItem.service);

                      const itemPrice = reduction ? (
                        Math.round((((100 - reduction.discount_rate) / 100) * serviceItem.price) * 100) / 100) : (
                        serviceItem.price);

                      total += itemPrice * serviceItem.quantity;
                      return (
                        <BasketItem
                          key={index}
                          price={serviceItem.price}
                          entity={serviceItem.service}
                          entityType="service"
                          number={serviceItem.quantity}
                          reduction={reduction}
                          selectedOptions={serviceItem.selected_options}
                          editable
                        />
                      );
                    })}
                  </View>
                </>
                )}
                <View style={styles.totalWrapper}>
                  <View style={styles.totalLabel}>
                    <Text size="large">{t('basket.total')}</Text>
                  </View>
                  <Price
                    price={total}
                    size="huge"
                  />
                </View>
              </View>
            ) : (
              <EmptyPage text={t('basket.empty')} />
            )}
          <View style={commonStyles.scrollViewWithButton} />
        </View>
      </ScrollView>
      { total ? (
        <View style={commonStyles.buttonWrapperIn}>
          <View style={commonStyles.buttonContainerIn}>
            <Button
              text={t('basket.pay', { total: formatPrice(total, site?.currency) })}
              icon="credit-card"
              variant="gradient"
              onPress={() => setModalVisible(true)}
            />
          </View>
        </View>
      ) : null }

      <PaymentModal
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        total={total}
      />
    </Page>
  );
};

Basket.path = 'basket';

Basket.propTypes = {
};

Basket.defaultProps = {
};

export default Basket;
