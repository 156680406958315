import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  schedule: {
    flexDirection: 'row',
  },

  days: {
    marginRight: theme.sizings.medium,
  },
});
