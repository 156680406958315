import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet, } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { FontAwesome5 } from '@expo/vector-icons';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';


const SeatsResume = ({
  seats,
  customerCame,
  isMapFooter
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const {seatFocused} = useBeachVisualizer()

  return Boolean(seats?.length) && (
    <View style={{ flexDirection: 'row' }}>
      <FontAwesome5
        name="chair"
        color={theme.colors.primary}
        size={theme.sizings.medium}
        style={styles.icon}
      />
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%',
       paddingLeft: theme.sizings.tiny, paddingRight: theme.sizings.small }}>
        {isMapFooter ? (
            <Text
               size="large"
               color={customerCame ? 'dark' : 'greyDarkest'}
            >
               {`#${seatFocused?.name}`}
            </Text>
        ) : seats?.sort((a, b) => a.name >= b.name ? 1 : -1)
          .map((seat, seatIndex) => (
            <Text
              key={seat.id}
              size="small"
              color={customerCame ? 'dark' : 'greyDarkest'}
            >
              {`#${seat.name}`}
              {seatIndex + 1 < seats.length && ', '}
            </Text>
          ))}
      </View>
    </View>
  );
};

SeatsResume.propTypes = {
  seats: PropTypes.array,
  customerCame: PropTypes.bool,
  isMapFooter: PropTypes.bool,
};

SeatsResume.defaultProps = {
    seats: [],
    customerCame: false,
    isMapFooter: false,
};

const makeStyles = (theme) => StyleSheet.create({
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge, 
    position: 'relative',
    top: -theme.sizings.tiny
   },
});

export default SeatsResume;
