import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import useAuth from 'auth/contexts/auth';
import useNotification from 'realtime/contexts/notification';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useSite from 'sites/contexts/sites';
import dayjs from 'dayjs';
import usePersistedState2 from 'common/utils/usePersistedState2';

const BookingContext = createContext();

export const BookingsProvider = ({ children }) => {
  const [isBookingFetching, setIsBookingFetching] = useState(false);
  const { setSeat, setBooking } = useAuth();
  const { pushToken } = useNotification();
  const { setAlert } = useAlert();
  const { t, lang } = useTranslation();
  const { setLockOn } = useSite();
  const [bookingConflicts, setBookingConflicts] = useState({});

  const validBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/valid?date=${dayjs().format('YYYY-MM-DD')}`,
        method: 'PUT',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const changeBookingItemFullyPaid = useCallback(async (bookingItemId, newIsFullyPaid) => {
    try {
      const res = await fetchJSON({
        url: `booking-items/${bookingItemId}`,
        method: 'PUT',
        payload: {
          isFullyPaid: newIsFullyPaid,
        },
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const toggleValidBooking = useCallback(async (bookingId, isValid) => {
    const date = dayjs().format('YYYY-MM-DD');

    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/valid?date=${date}`,
        method: 'PUT',
        payload: {
          valid: isValid,
        },
      });

      return res;
    } catch (e) {
      setAlert(e.message, 'danger');
    }
  }, [setAlert]);

  const deactivateBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/deactivate?date=${dayjs().format('YYYY-MM-DD')}`,
        method: 'PUT',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const deleteBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}`,
        method: 'DELETE',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const checkCode = useCallback(async (code) => {
    setIsBookingFetching(true);

    try {
      if (code === 'STOP') {
        setLockOn(null);

        return;
      }
      if (code.length <= 5) {
        const booking = await fetchJSON({
          url: `bookings/check-code/${code}?date=${dayjs().format('YYYY-MM-DD')}`,
          method: 'GET',
        });

        if (!booking || booking.error) {
          throw new Error('Error while checking code');
        }
        setBooking(booking);
        const currentBi = booking.booking_items.find((bi) => dayjs().isSame(bi.date, 'date'));

        const firstCurrentSeat = currentBi && currentBi.override
          ? currentBi.seats?.[0]
          : booking.seats[0];

        if (firstCurrentSeat) {
          setSeat(firstCurrentSeat);
        }

        // Store pushToken in booking
        if (pushToken) {
          fetchJSON({ url: `bookings/${booking.id}`, method: 'PUT', payload: { pushToken, language: lang } });
        }

        return booking;
      }
      const site = await fetchJSON({ url: `sites/check-code/${code}`, method: 'GET' });

      if (!site || site.error) {
        console.log(site.error);
        throw new Error('Error while checking code');
      }

      setLockOn(site.id);
      return ('OK');
    } catch (e) {
      console.log(e);
      throw new Error('BAD_REQUEST');
    } finally {
      setIsBookingFetching(false);
    }
  }, [lang, pushToken, setBooking, setLockOn, setSeat]);

  const payFullBooking = useCallback(async (bookingId, offlineMethod) => {
    try {
      const res = await fetchJSON({
        url: `bookings/${bookingId}/payAll`,
        method: 'PUT',
        payload: {
          offline_method: offlineMethod,
          status: 'PAID',
        },
      });

      // setAlert(t('bookings.updatePaymentStatus'), 'success');
      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const payBookingItem = useCallback(async (bookingItemId, offlineMethod) => {
    try {
      const res = await fetchJSON({
        url: `booking-items/${bookingItemId}/pay`,
        method: 'PUT',
        payload: {
          offline_method: offlineMethod,
          status: 'PAID',
        },
      });

      // setAlert(t('bookings.updatePaymentStatus'), 'success');
      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const fetchBookingAtPlace = useCallback(async (seatId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/findWithSeat/${seatId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const fetchBookingByRoom = useCallback(async (roomId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/findByRoom/${roomId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const notifyBooking = useCallback(async (bookingId) => {
    try {
      const res = await fetchJSON({
        url: `bookings/callBooking/${bookingId}`,
        method: 'GET',
      });

      if (res.error) {
        setAlert({ color: 'error', title: t('common.error'), message: res.message });
      } else {
        setAlert({ color: 'success', title: t('common.success'), message: t('info.notifiedClient') });
      }

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const changeBookingItemStatus = useCallback(async (bookingItemId, newStatus) => {
    try {
      const res = await fetchJSON({
        url: `booking-items/${bookingItemId}/${newStatus}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: e.message });
    }
  }, [setAlert, t]);

  const checkSameSeatBookings = useCallback((bookingItemList, currentDate) => {
    const newSameSeatBookings = {};

    const activeBookingItemList = bookingItemList.filter((bi) => (
      bi.status !== 'cancelled' && bi.status !== 'checked_out' && bi.status !== 'noShow'
    ));

    if (activeBookingItemList.length > 1) {
      for (let index1 = 0; index1 < activeBookingItemList.length - 1; index1 += 1) {
        for (let index2 = index1 + 1; index2 < activeBookingItemList.length; index2 += 1) {
          activeBookingItemList[index1].seats.forEach((seat) => {
            if (activeBookingItemList[index2].seats.map((s) => s.id).includes(seat.id)
              && (
                activeBookingItemList[index1].stayDuration === activeBookingItemList[index2].stayDuration
                || activeBookingItemList[index1].stayDuration === 'fullday'
                || activeBookingItemList[index2].stayDuration === 'fullday'
              )) {
              if (!newSameSeatBookings[activeBookingItemList[index1].id]) {
                newSameSeatBookings[activeBookingItemList[index1].id] = {
                  seats: [seat.id],
                  date: currentDate,
                };
              } else {
                newSameSeatBookings[activeBookingItemList[index1].id].seats.push(seat.id);
                newSameSeatBookings[activeBookingItemList[index1].id].date = currentDate;
              }
              if (!newSameSeatBookings[activeBookingItemList[index2].id]) {
                newSameSeatBookings[activeBookingItemList[index2].id] = {
                  seats: [seat.id],
                  date: currentDate,
                };
              } else {
                newSameSeatBookings[activeBookingItemList[index2].id].seats.push(seat.id);
                newSameSeatBookings[activeBookingItemList[index2].id].date = currentDate;
              }
            }
          });
        }
      }
    }
    return { [currentDate]: newSameSeatBookings };
  }, []);

  const calculateConflicts = useCallback((currentBooking, seatBookingItems, isToggle = false) => {
    const newBookingConflicts = {};

    currentBooking.booking_items.forEach((currentBookingItem) => {
      currentBookingItem.seats.forEach((seat) => {
        const formatedDate = dayjs(currentBookingItem.date).format('YYYY-MM-DD');

        if (seatBookingItems[seat]) {
          const conflictingBookingItems = seatBookingItems[seat]
            .filter((bi) => bi.booking.id !== currentBooking.id)
            .filter((bi) => (
              bi.status !== 'cancelled' && bi.status !== 'checked_out'
                && bi.seats.map(({ id }) => id).includes(seat)
                && dayjs(bi.date).isSame(formatedDate, 'date')
                && (
                  bi.stayDuration === 'fullday'
                  || currentBooking.stayDuration === 'fullday'
                  || bi.stayDuration === currentBooking.stayDuration
                )
            ));

          if (conflictingBookingItems.length) {
            if (!newBookingConflicts[formatedDate]) {
              newBookingConflicts[formatedDate] = {};
            }
            conflictingBookingItems.forEach((conflictingBookingItem) => {
              if (!newBookingConflicts[formatedDate][conflictingBookingItem.id]) {
                newBookingConflicts[formatedDate][conflictingBookingItem.id] = {};
              }
              if (!newBookingConflicts[formatedDate][conflictingBookingItem.id].seats) {
                newBookingConflicts[formatedDate][conflictingBookingItem.id].seats = [];
              }
              if (newBookingConflicts[formatedDate][conflictingBookingItem.id].seats.includes(seat)) {
                if (isToggle) {
                  // remove seat (already there)
                  const indexSeat = newBookingConflicts[formatedDate][conflictingBookingItem.id].seats
                    .findIndex((biSeat) => biSeat === seat);

                  newBookingConflicts[formatedDate][conflictingBookingItem.id].seats.splice(indexSeat, 1);
                  if (newBookingConflicts[formatedDate][conflictingBookingItem.id]?.seats.length === 0) {
                    delete newBookingConflicts[formatedDate][conflictingBookingItem.id];
                  }
                }
              } else {
                newBookingConflicts[formatedDate][conflictingBookingItem.id].seats.push(seat);
                newBookingConflicts[formatedDate][conflictingBookingItem.id].date = conflictingBookingItem.date;
              }
            });
          }
        }
      });
    });
    return newBookingConflicts;
  }, []);

  const isSeatConflicting = useCallback((seatId, currentDate, biConflicts = bookingConflicts) => (
    !!Object.entries(biConflicts).find(([date, biList]) => (
      (!currentDate || dayjs(date).isSame(currentDate, 'date'))
      && Object.values(biList).find((bi) => bi.seats?.includes(seatId))
    ))
  ), [bookingConflicts]);

  const conflictingBookings = useMemo(() => {
    const conflictingBiList = {};

    Object.values(bookingConflicts).forEach((biList) => {
      Object.entries(biList).forEach(([biId, biInfos]) => {
        if (!conflictingBiList[biId]) {
          conflictingBiList[biId] = { seats: biInfos.seats };
        } else {
          conflictingBiList[biId].seats = [...conflictingBiList[biId].seats, ...biInfos.seats];
        }
      });
    });
    return conflictingBiList;
  }, [bookingConflicts]);

  const value = useMemo(() => ({
    payFullBooking,
    payBookingItem,
    isBookingFetching,
    checkCode,
    deactivateBooking,
    deleteBooking,
    fetchBookingAtPlace,
    fetchBookingByRoom,
    validBooking,
    toggleValidBooking,
    notifyBooking,
    changeBookingItemFullyPaid,
    changeBookingItemStatus,
    calculateConflicts,
    bookingConflicts,
    setBookingConflicts,
    isSeatConflicting,
    conflictingBookings,
    checkSameSeatBookings,
  }), [
    payFullBooking,
    payBookingItem,
    isBookingFetching,
    fetchBookingAtPlace,
    fetchBookingByRoom,
    checkCode,
    deactivateBooking,
    deleteBooking,
    validBooking,
    toggleValidBooking,
    notifyBooking,
    changeBookingItemFullyPaid,
    changeBookingItemStatus,
    calculateConflicts,
    bookingConflicts,
    isSeatConflicting,
    conflictingBookings,
    checkSameSeatBookings,
  ]);

  return (
    <ContextProvider
      url="bookings"
      context={BookingContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

BookingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useBooking = () => useContext(BookingContext);

export const useLocalBooking = () => {
  const [localBookings, setLocalBookings] = usePersistedState2([], 'local-bookings', null);

  return { localBookings, setLocalBookings };
};

export default useBooking;
