import { Dimensions, StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({

  buttonContainer: {
    width: Dimensions.get('window').width,
    height: '60%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  waiterIconView: {
    position: 'relative',
    width: theme.normalize(100),
    height: theme.normalize(100),
    backgroundColor: theme.colors.secondaryLight,
    borderRadius: theme.radius.rounded,
    marginBottom: theme.sizings.medium,
  },

  waiterIcon: {
    position: 'absolute',
    width: theme.normalize(80),
    height: theme.normalize(80),
  },

  currentOrderHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  orderItems: {
    marginTop: theme.sizings.medium,
  },

  currentOrderItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.sizings.small,
  },

  itemQuantity: {
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    minWidth: theme.normalize(50),
    justifyContent: 'center',
    alignItems: 'center',
  },

  itemImage: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    marginRight: theme.sizings.small,
  },

  itemImageInner: {
    width: '100%',
    height: '100%',
  },

  absoluteContent: {
    width: Dimensions.get('window').width - theme.sizings.medium,
    position: 'absolute',
    paddingBottom: theme.sizings.small,
    bottom: 0,
    left: theme.sizings.small,
  },

  absoluteContainer: {
    borderRadius: theme.radius.medium,
    padding: theme.sizings.smallMedium,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    overflow: 'visible',
  },

  buttons: {
    margin: theme.sizings.small,
    textAlign: 'center',
  },

  bookingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.greyAlpha,
    padding: theme.sizings.medium,
    borderRadius: theme.radius.medium,
    width: '100%',
  },

  code: {
    marginLeft: theme.sizings.small,
  },
});
