import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useBooking from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import { ImageBackground } from 'common/components/Images';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Modal from 'common/components/Modal/Modal';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect,
} from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const SeatDetails = ({ seatBookingId, closeModal }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { item: booking, fetchItem: fetchBooking } = useBooking();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  useEffect(() => {
    if (seatBookingId) {
      fetchBooking(seatBookingId);
    }
    // bookings is in deps to refresh current to refresh booking
    // in case of booking updating which triggers fetchBookings
  }, [fetchBooking, seatBookingId]);

  const handleState = useCallback((state) => {
    switch (state) {
      case 'created':
        return t('orders.created');
      case 'in_preparation':
        return t('orders.inPreparation');
      case 'ready':
        return t('orders.ready');
      case 'done':
        return t('orders.done');
      default:
        return t('orders.created');
    }
  }, [t]);

  const goEditBooking = useCallback(() => {
    if (booking) {
      closeModal();
      navigation.navigate('WaiterBookingDetail', { bookingId: booking.id });
    }
  }, [booking, closeModal, navigation]);

  const goEditOrder = useCallback(() => {
    if (booking && booking.orders[0]) {
      closeModal();
      navigation.navigate('OrderSeatSelect', { orderId: booking.orders[0].id });
    }
  }, [booking, closeModal, navigation]);

  return (
    <Modal
      style={commonStyles.modal}
      transparent
      visible={!!seatBookingId}
      ariaHideApp={false}
    >
      <View style={commonStyles.modalInner}>
        <TouchableOpacity
          style={commonStyles.modalMask}
          onPress={() => closeModal()}
        />
        <LoadingSpinner
          isFetching
          theme={theme}
        />
        {(seatBookingId && booking)
        && (
        <View style={commonStyles.modalBox}>

          {/* HEAD */}

          <View style={styles.booking}>

            <View style={styles.bookingLeft}>
              {booking.seats?.map((seat) => (
                <Text
                  key={seat.id}
                  size="medium"
                  isBold
                >
                  {t('bookings.seatNbr', { nbr: seat?.name })}
                </Text>
              ))}
              <Text size="medium">
                {booking.booking_items?.map((bookingItem) => bookingItem.date).join(', ')}
              </Text>
              <Text size="small" isUppercase>
                {booking.booking_items.length > 0
                  ? t(`bookings.${booking.stayDuration}s`)
                  : t(`bookings.${booking.stayDuration}`)}
              </Text>
              {/* {booking.hotel && (
              <Text size="medium">{t('common.checkRoomID', { roomID: booking.roomID })}</Text>
              )} */}
              <Text
                isBold
                size="medium"
                style={{
                  borderColor: 'darkgray',
                  borderStyle: 'solid',
                  borderTopWidth: 1,
                  marginTop: 10,
                }}
              >
                {booking.customer?.name}
              </Text>
              {booking.roomID !== null && (<Text size="medium">{t('bookings.roomNbr', { nbr: booking.roomID })}</Text>)}
            </View>
            <View style={styles.bookingCode}>
              <Text noLineHeight size="larger">{booking.code}</Text>
              <Text noLineHeight size="large">{booking.checkin ? dayjs(booking.checkin).format('HH:mm') : ''}</Text>
            </View>
            <View style={styles.bookingButtonContainer}>
              <Button
                style={styles.bookingButton}
                variant="gradient"
                onPress={goEditBooking}
                icon="pencil"
              />
            </View>
          </View>
          {booking.orders && booking.orders.filter((o) => o.state !== 'done').map((o) => (
            <TouchableOpacity
              onPress={goEditOrder}
            >
              <View style={[commonStyles.textWithIcon]}>
                <View style={commonStyles.fullViewDetailsIcon}>
                  <FontAwesome size={theme.fontSizes.large} name="clock-o" color={theme.colors.secondaryLight} />
                </View>
                <View style={styles.optionsTitleText}>
                  <Text
                    size="large"
                    color="dark"
                  >
                    {`${t('orders.orderNbr', { orderId: o.id })} ${handleState(o.state)}`}
                  </Text>
                </View>
              </View>
              <View style={styles.orderItems}>
                {o.order_items.map((orderItem) => (
                  <View style={styles.currentOrderItem} key={orderItem.id}>
                    <View style={styles.itemImage}>
                      {orderItem.product ? (
                        <ImageBackground style={styles.itemImageInner} image={orderItem.product.images[0]} />
                      ) : (
                        <ImageBackground style={styles.itemImageInner} image={orderItem.service.images[0]} />
                      )}
                    </View>
                    {orderItem.quantity > 1 && (
                    <View style={styles.itemQuantity}>
                      <Gradient height={theme.normalize(50)} />
                      <View>
                        <Text noLineHeight size="large" color="dark">{`x ${orderItem.quantity}`}</Text>
                      </View>
                    </View>
                    )}
                    <View style={{ flex: 1 }}>
                      <TranslatedText isBold size="large" color="dark" value={orderItem.name} />
                    </View>
                    <Price
                      price={orderItem.price * orderItem.quantity}
                      color="secondaryDark"
                    />
                  </View>
                ))}
              </View>
            </TouchableOpacity>
          ))}
        </View>
        )}
      </View>
    </Modal>
  );
};

SeatDetails.propTypes = {
  seatBookingId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
};

SeatDetails.defaultProps = {
  seatBookingId: 0,
};

const makeStyles = (theme) => StyleSheet.create({
  booking: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    marginBottom: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'center',
  },
  bookingLeft: {
    flex: 1.75,
    flexDirection: 'column',
    borderRightWidth: 1,
    borderRightColor: theme.colors.greyLighter,
  },
  bookingCode: {
    flex: 1.25,
    paddingRight: theme.sizings.medium,
    flexDirection: 'column',
    alignItems: 'baseline',
    paddingLeft: theme.sizings.medium,
  },
  bookingDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bookingButtonContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.sizings.medium,
  },
  bookingButton: {
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.tiny,
  },
  currentOrderItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.sizings.small,
  },
  itemQuantity: {
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    minWidth: theme.normalize(50),
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemImage: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    marginRight: theme.sizings.small,
  },
  orderItems: {
    marginTop: theme.sizings.medium,
  },
  itemImageInner: {
    width: '100%',
    height: '100%',
  },
  optionsTitleText: {
    marginBottom: theme.sizings.medium,
  },
});

export default SeatDetails;
