import { Image as ImageDist } from 'common/components/Images';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  StyleSheet, TouchableHighlight, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { Icon } from 'react-native-elements';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import truncateText from 'common/utils/truncateText';

const BoardSquare = ({
  seat,
  currentStayDuration,
  searchBookingItem,
  selectedSeats,
  getSeatData,
  squareDimensions,
  handleClickSeat,
  map,
  backgroundColorWaiter,
  hasConflict,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const { bookingItems, orders, calls } = getSeatData(seat.id);

  const antiRotate = [{ rotate: `${seat.rotate ? -seat.rotate : 0}deg` }];

  const hasOrders = Boolean(orders?.filter((o) => o.state !== 'done')?.length > 0);

  const selectedInVisualizer = selectedSeats?.find((id) => id === seat.id);

  const truncText = hasConflict || calls?.length || hasOrders;

  const getBookingOverlayColor = useCallback((bookingItem) => {
    /* if (hasOrders) {
      return theme.colors.orange;
    } */

    if (bookingItem.status === 'checked_in') {
      if (bookingItem.isFullyPaid) {
        return theme.colors.darkerGreen;
      }
      return theme.colors.danger;
    }
    return 'gray';
  }, [theme]);

  const isOrdersLate = dayjs().diff(orders.filter((o) => o.state !== 'done'), 'minutes') > 10;
  const isCallLate = dayjs().diff(calls?.[0]?.created_at, 'minutes') > 10;

  const renderConflictIcon = (currentBookingItem) => (

    <View style={[
      styles.flag,
      {
        padding: proportionalStyles.padding,
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.colors.dangerBright,
      },
    ]}
    >
      <FontAwesome5
        name="exclamation"
        color={theme.colors.white}
        size={currentBookingItem.stayDuration === 'fullday'
          ? proportionalStyles.conflictIcon * 0.8 : proportionalStyles.conflictIcon / 2.5}
        style={{ transform: antiRotate }}
      />

    </View>

  );

  const renderSeatNumberText = (mode) => {
    let sizeName = proportionalStyles.textBig;

    if (seat.name.length > 2) {
      if (seat.name.length > 4) {
        sizeName = proportionalStyles.textSmall;
      } else {
        sizeName = proportionalStyles.textMedium;
      }
    }

    return (
      <Text
        style={{
          fontSize: sizeName,
          transform: antiRotate,
          textShadowColor: theme.colors.white,
          textShadowOffset: { height: proportionalStyles.borderWidth / 3, width: proportionalStyles.borderWidth / 3 },
          textShadowRadius: 0,
          // textShadowOpacity: 1,
          height: '100%',
          lineHeight: sizeName * 1.1,
        }}
        centered
        isBold
      >
        {seat.name}

      </Text>
    );
  };

  const renderSeatNumberButton = (mode) => {
    const isSearchedNumber = Boolean(
      searchBookingItem !== '' && seat?.name?.toLowerCase().includes(searchBookingItem?.toLowerCase()),
    );

    return (
      <TouchableHighlight
        style={[styles.numberContainer,
          {
            backgroundColor: 'rgba(255,255,255,0.2)',
            borderColor: itemAreaColor,
            borderWidth: proportionalStyles.borderWidth,
            padding: 0,
            width: mode !== 'full' && (hasBookings) ? '50%' : '100%',
            marginLeft: mode === 'right' && (hasBookings) ? '50%' : 0,
            alignItems: mode === 'right' ? 'flex-end' : 'flex-start',
          }]}
        onPress={() => handleClickSeat(seat, null)}
      >
        <View
          style={{
            backgroundColor: isSearchedNumber ? 'yellow' : (selectedInVisualizer ? itemAreaColor : 'transparent'),
            borderColor: isSearchedNumber ? theme.colors.black : itemAreaColor,
            borderWidth: 1,
            padding: proportionalStyles.padding,
            width: mode !== 'full' && (selectedInVisualizer && !hasBookings) ? '50%' : '100%',
            marginLeft: mode === 'right' && (selectedInVisualizer && !hasBookings) ? '50%' : 0,
            height: '100%',
            justifyContent: 'center',
            // margin: -1,
          }}
        >
          {selectedInVisualizer ? (
            <Icon
              name="check"
              size={mode === 'full' ? proportionalStyles.textBig : proportionalStyles.textMedium}
              color={isSearchedNumber ? theme.colors.black : theme.colors.light}
              style={{
                transform: antiRotate,
              }}
            />
          ) : (
            renderSeatNumberText(mode)
          )}
        </View>
      </TouchableHighlight>
    );
  };

  const squareDimensionRef = squareDimensions.height < squareDimensions.width
    ? squareDimensions.height : squareDimensions.width;

  const proportionalStyles = {
    borderWidth: squareDimensionRef / 15,
    padding: squareDimensionRef / 20,
    textBig: squareDimensionRef * 0.8,
    textSmall: squareDimensionRef * 0.25,
    textMedium: squareDimensionRef * 0.5,
    flag: squareDimensionRef * 0.5,
    conflictIcon: squareDimensionRef * 0.4,
  };

  const hasBookings = bookingItems?.length;
  const singleBooking = bookingItems?.length === 1;

  const itemAreaColor = backgroundColorWaiter ? seat.waiter_area.color : seat.price_area.color;

  return (
    <View
      style={styles.boardSquare}
      key={`seat-${seat?.name}-${map?.id}`}
    >
      <View style={{ flex: 1 }}>
        { seat?.furniture?.image && (
        <ImageDist image={seat.furniture.image} style={styles.item} />
        ) }
      </View>

      <View style={styles.infoBox}>
        {hasBookings ? (
          <>
            {(singleBooking && (
              bookingItems[0].stayDuration === 'morning'
              || bookingItems[0].stayDuration === 'afternoon'
            )) && (
              renderSeatNumberButton(bookingItems[0].stayDuration === 'morning' ? 'right' : 'left')
            )}

            {bookingItems.map((bookingItem) => {
              const searched = searchBookingItem.toLowerCase();

              const included = (words) => words?.find((word) => word?.toLowerCase().includes(searched));
              const { booking } = bookingItem;

              const isSearchedBookingItem = Boolean(searchBookingItem !== '' && (
                included([seat?.name, booking?.customer?.name,
                  booking?.customer?.firstName,
                  booking?.clientName,
                  booking?.clientFirstName,
                  booking?.code,
                  booking?.customer?.email,
                  booking?.roomID])
              ));

              const visitorName = truncateText(
                bookingItem.booking.customer?.name
                  ? `${bookingItem.booking.customer?.name} ${bookingItem.booking.customer?.firstName || ''}`
                  : `${bookingItem.booking.clientName} ${bookingItem.booking.clientFirstName}`,
                truncText ? 9 : 20,
              );

              return (
                <View
                  style={[
                    styles.bookingItemBox,
                    styles[`bookingItemBox_${bookingItem.stayDuration}`],
                  ]}
                  key={`booking-item${bookingItem.id}`}
                >
                  {(bookingItem.booking.customer?.name || bookingItem.booking.clientName) && (
                  <TouchableHighlight
                    style={[
                      styles.customerContainer,
                      {
                        borderColor: isSearchedBookingItem ? theme.colors.black : (hasConflict
                          ? theme.colors.dangerBright
                          : getBookingOverlayColor(bookingItem)),
                        borderWidth: proportionalStyles.borderWidth,
                        padding: proportionalStyles.padding,
                        backgroundColor: isSearchedBookingItem ? 'yellow' : theme.colors.white,
                      },
                      hasOrders && !isSearchedBookingItem && {
                        backgroundColor: isOrdersLate ? theme.colors.dangerBright : theme.colors.orange,
                      },
                      calls?.length && !isSearchedBookingItem && {
                        backgroundColor: isCallLate
                          ? theme.colors.dangerBright : theme.colors.purple,
                      },
                    ]}
                    onPress={() => handleClickSeat(seat, bookingItem)}
                  >
                    <>
                      <Text
                        style={{
                          fontSize: truncText
                            ? (bookingItem.stayDuration === 'fullday'
                              ? proportionalStyles.textSmall : proportionalStyles.textSmall / 2) * 0.7
                            : bookingItem.stayDuration === 'fullday'
                              ? proportionalStyles.textSmall : proportionalStyles.textSmall / 2,
                          transform: antiRotate,
                          textShadowOffset: {
                            height: proportionalStyles.borderWidth / 3,
                            width: proportionalStyles.borderWidth / 3,
                          },

                          paddingTop: truncText ? '70%' : 0,
                          height: truncText ? '100%' : 'auto',
                          textShadowRadius: 0,
                          // textShadowOpacity: 1,
                          textShadowColor: theme.colors.white,
                          lineHeight: (truncText
                            ? (bookingItem.stayDuration === 'fullday'
                              ? proportionalStyles.textSmall : proportionalStyles.textSmall / 2) * 0.7
                            : bookingItem.stayDuration === 'fullday'
                              ? proportionalStyles.textSmall : proportionalStyles.textSmall / 2) * 1.1,
                          flex: 1,
                          width: 1,
                          textAlign: 'center',
                          maxHeight: '100%',
                        }}
                        centered
                        isBold
                      >
                        {`${seat?.name} / ${visitorName}`}

                      </Text>
                      {hasConflict && renderConflictIcon(bookingItem)}
                    </>

                  </TouchableHighlight>
                  )}

                  {bookingItem?.booking?.booking_items?.length > 1 && (
                  <View style={[styles.iconOrderBox, {
                    top: 'auto',
                    bottom: 0,
                    width: proportionalStyles.flag / 2,
                    height: proportionalStyles.flag / 2,
                    backgroundColor: theme.colors.black,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }]}
                  >
                    <Text
                      style={{
                        fontSize: proportionalStyles.flag * 0.35,
                        color: theme.colors.white,
                        width: '100%',
                        textAlign: 'center',
                        transform: antiRotate,
                      }}
                      isBold
                    >
                      A
                    </Text>
                  </View>
                ) }

                  {calls?.length > 0 && (
                  <View style={[styles.iconOrderBox, {
                    right: 'auto',
                    left: 0,
                    width: proportionalStyles.flag,
                    height: proportionalStyles.flag,
                    backgroundColor: isCallLate
                      ? theme.colors.dangerBright : theme.colors.purple,
                  }]}
                  >
                    <FontAwesome5
                      name="bell"
                      color={theme.colors.white}
                      size={proportionalStyles.flag * 0.7}
                      style={{ transform: antiRotate }}
                    />
                  </View>
                ) }

                  { hasOrders && (

                  <View style={[styles.iconOrderBox, {
                    width: proportionalStyles.flag,
                    height: proportionalStyles.flag,
                    backgroundColor: isOrdersLate
                      ? theme.colors.dangerBright : theme.colors.orange,
                  }]}
                  >
                    <FontAwesome5
                      name="utensils"
                      color={theme.colors.white}
                      size={proportionalStyles.flag * 0.7}
                      style={{ transform: antiRotate }}
                    />
                  </View>

                  )}
                </View>
              );
            })}
          </>
        ) : (
          <>
            {renderSeatNumberButton(
              currentStayDuration === 'morning'
                ? 'left'
                : currentStayDuration === 'afternoon'
                  ? 'right'
                  : 'full',
            )}
          </>
        )}
      </View>

      {seat.outOfService && (
      <View style={[styles.iconLockBox, {
        width: proportionalStyles.flag,
        height: proportionalStyles.flag,
      }]}
      >
        <FontAwesome
          name="lock"
          color={theme.colors.secondary}
          size={proportionalStyles.flag * 0.7}
          style={{ transform: antiRotate }}
        />
      </View>
      )}

    </View>
  );
};

BoardSquare.propTypes = {
  seat: PropTypes.object.isRequired,
  squareDimensions: PropTypes.object.isRequired,
  currentStayDuration: PropTypes.any,
  selectedSeats: PropTypes.any.isRequired,
  handleClickSeat: PropTypes.any.isRequired,
  map: PropTypes.object.isRequired,
  backgroundColorWaiter: PropTypes.bool,
  getSeatData: PropTypes.func.isRequired,
  hasConflict: PropTypes.bool,
  searchBookingItem: PropTypes.string,
};

BoardSquare.defaultProps = {
  backgroundColorWaiter: false,
  hasConflict: false,
  currentStayDuration: null,
  searchBookingItem: '',
};

const makeStyles = (theme) => StyleSheet.create({
  boardSquare: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  square: {
    backgroundColor: theme.colors.beach,
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.secondary,
  },
  overlay: {
    top: 0,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  numberContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 20,
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 1,
    borderStyle: 'solid',
  },
  customerContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    borderStyle: 'solid',
    // hack word wrap
    flexDirection: 'row',
    flexGrow: 1,
  },
  flag: {
    zIndex: 21,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.colors.light,
  },
  callFlag: {

  },
  orderFlag: {

  },
  late: {
    backgroundColor: theme.colors.dangerBright,
  },

  infoBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
  },
  iconLockBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.colors.black,
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconOrderBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 30,
  },
  bookingItemBox: {
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  bookingItemBox_morning: {
    width: '50%',
    left: 0,
  },
  bookingItemBox_afternoon: {
    width: '50%',
    left: '50%',
  },
  bookingItemBox_fullday: {
    width: '100%',
    left: 0,

  },
});

export default BoardSquare;
