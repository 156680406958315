import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button/Button';
import BookingPaymentTypeModal from '../BookingPayments/BookingPaymentTypeModal';

const PaymentSwitcher = ({
  isPaid, payAction, elementID,
  visible, setVisible, isPaymentLoading,
  paymentText,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <View style={{ margin: theme.sizings.tiny, justifyContent: 'center' }}>
      {isPaymentLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Button
            icon="credit-card"
            text={isPaid ? t('bookings.bookingItemPaid_true') : t('bookings.bookingItemPaid_false')}
            onPress={!isPaid
              ? () => setVisible(true)
              : null}
            value={isPaid || false}
            style={{
              backgroundColor: isPaid ? theme.colors.success : theme.colors.danger,
              paddingVertical: theme.sizings.small * 0.8,
            }}
            small
          />
        </>
      )}

      <BookingPaymentTypeModal
        setModalVisible={setVisible}
        modalVisible={visible}
        handleValidate={(methodSelected) => {
          payAction(elementID, methodSelected);
        }}
        modalText={paymentText}
      />
    </View>
  );
};

PaymentSwitcher.propTypes = {
  isPaid: PropTypes.bool,
  payAction: PropTypes.func.isRequired,
  elementID: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.any.isRequired,
  isPaymentLoading: PropTypes.bool.isRequired,
  paymentText: PropTypes.element,
};

PaymentSwitcher.defaultProps = {
  isPaid: false,
  paymentText: null,
};

const makeStyles = (theme) => StyleSheet.create({

});

export default PaymentSwitcher;
