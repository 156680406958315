/* eslint-disable no-restricted-syntax */
import React, {
  useContext, useCallback, createContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import usePersistedState from 'common/utils/usePersistedState';

const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
  const [items, setItems] = usePersistedState([], 'basket');

  const addItem = useCallback((toAdd, selectedOptions, price, type = 'product', quantity = 1) => {
    const newList = [...items];

    const found = newList.find((item) => (
      item[type] && item[type].id === toAdd.id && _.isEqual(item.selected_options, selectedOptions)));

    if (found !== undefined) {
      found.quantity += 1;
    } else {
      const newItem = {
        name: toAdd.name,
        [type]: toAdd,
        selected_options: _.cloneDeep(selectedOptions),
        reservation: null,
        price,
        description: toAdd.description,
        quantity,
      };

      if (type === 'product') {
        newItem.service = null;
      } else {
        newItem.product = null;
      }

      newList.push(newItem);
    }
    setItems(newList);
  }, [setItems, items]);

  const removeItem = useCallback((toRemove, selectedOptions, type = 'product') => {
    const newList = [...items];
    const found = newList.find((item) => (
      item[type] && item[type].id === toRemove.id && _.isEqual(item.selected_options, selectedOptions)));

    if (found !== undefined && found.quantity > 1) {
      found.quantity -= 1;
      setItems(newList);
    } else if (found !== undefined && found.quantity <= 1) {
      setItems(newList.filter((item) => (
        !(item[type].id === toRemove.id && _.isEqual(item.selected_options, selectedOptions)))));
    }
  }, [setItems, items]);

  const removeAllOf = useCallback((toRemove, type = 'product') => {
    const newList = [...items];
    const found = newList.find((item) => (
      item[type] && item[type].id === toRemove?.id));

    if (found !== undefined) {
      setItems(newList.filter((item) => (
        !(item[type] && item[type].id === toRemove.id))));
    }
  }, [setItems, items]);

  const addForms = useCallback((product, productForms, type = 'product') => {
    const newList = [...items.filter((item) => (
      !(item[type] && item[type].id === product.id)))];

    productForms.forEach((form) => {
      const newItem = {
        name: product.name,
        [type]: product,
        selected_options: _.cloneDeep(form.selectedOptions),
        reservation: null,
        price: form.offsetPrice,
        description: product.description,
        quantity: form.quantity,
      };

      if (type === 'product') {
        newItem.service = null;
      } else {
        newItem.product = null;
      }

      newList.push(newItem);
    });
    setItems(newList);
  }, [setItems, items]);

  const value = useMemo(() => ({
    addItem,
    removeItem,
    items,
    setItems,
    removeAllOf,
    addForms,
  }), [
    addItem,
    removeItem,
    items,
    setItems,
    removeAllOf,
    addForms,
  ]);

  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};

BasketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

BasketProvider.defaultProps = {
};

export const useBasket = () => useContext(BasketContext);
export default useBasket;
