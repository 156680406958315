import { useNavigation } from '@react-navigation/native';
import { useAuth } from 'auth/contexts/auth';
import BasketItem from 'basket/components/BasketItem/BasketItem';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import useOffer from 'specialOffers/hooks/use-offer';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import FormInput from 'common/components/FormInput/FormInput';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';

const makeStyles = (theme) => StyleSheet.create({
  innerView: {
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.sizings.small,
  },

  title: {
    marginTop: theme.sizings.medium,
    marginBottom: theme.sizings.small,
  },

  totalView: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.greyLighter,
    paddingTop: theme.sizings.medium,
    marginTop: theme.sizings.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const OrderValidate = ({ route }) => {
  const {
    items: basket, setItems: setBasket,
  } = useBasket();
  const { user } = useAuth();
  const { t } = useTranslation();
  const {
    order, bookingId, seatId,
  } = route.params;
  const {
    save: saveOrder, update: updateOrder, isFetching: isOrdersFetching,
  } = useOrder();
  const { setAlert } = useAlert();
  const navigation = useNavigation();
  const {
    findProductOffer,
    findServiceOffer,
  } = useOffer();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const [comments, setComments] = useState(null);

  useEffect(() => {
    if (order?.id) {
      setComments(order.comments);
    }
  }, [order]);

  let total = 0;

  const saveAndRedirect = useCallback(async () => {
    try {
      if (!order) {
        const payload = {
          order_items: basket.map((orderItem) => {
            const offer = orderItem.product ? findProductOffer(orderItem.product) : findServiceOffer(orderItem.service);

            return ({
              ...orderItem,
              price: offer ? ((100 - offer.discount_rate) / 100) * orderItem.price : orderItem.price,
              reduction: offer ? offer.discount_rate : null,
            });
          }),
          price: total,
          booking: bookingId,
          seat: seatId,
          comments,
          site: user.site?.id, // TODO policies in API to force siteId in payload for PUT POST
          user, // creator (can be waiter), not customer
          active: true,

          payment: {
            online: false,
            offline_method: 'CASH', // TODO no way to chose method for now
            amount: total.toFixed(2),
            status: 'PENDING',
            site: user.site?.id, // TODO policies in API to force siteId in payload for PUT POST
          },
        };

        await saveOrder(payload);
      } else {
        const payload = {
          ...order,
          comments,
          seat: seatId,
          order_items: basket,
          price: total,
          booking: bookingId,
        };

        await updateOrder(
          order.id, payload,
        );
      }
      setBasket([]);
      navigation.reset({
        index: 0,
        routes: [
          { name: 'WaiterHome' },
        ],
      });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [basket, comments,
    navigation, bookingId, order, saveOrder,
    setAlert, setBasket, t, total, seatId,
    updateOrder, user, findProductOffer, findServiceOffer]);

  const products = basket.filter((item) => item.product);
  const services = basket.filter((item) => item.service);

  return (
    <WaiterLayout hideMenu>
      {isOrdersFetching && (
      <LoadingSpinner
        visible={isOrdersFetching}
        theme={theme}
      />
      )}
      {basket.length
        ? (
          <View style={styles.innerView}>
            { !!comments && (
              <>
                <View style={styles.title}>
                  <Text isBold size="large" color="primary">{t('common.comments')}</Text>
                </View>
                <View>
                  <Text>{comments}</Text>
                </View>
              </>
            )}
            { products.length > 0 && (
            <View style={styles.title}>
              <Text isBold size="large" color="primary">{t('common.products')}</Text>
            </View>
            )}
            { products.map((productItem, index) => {
              const reduction = findProductOffer(productItem.product);

              total += Math.round((reduction ? (
                ((100 - reduction.discount_rate) / 100) * productItem.price) : productItem.price
              ) * productItem.quantity * 100) / 100;

              return (
                <BasketItem
                  key={index}
                  selectedOptions={productItem.selected_options}
                  price={productItem.price}
                  entity={productItem.product}
                  entityType="product"
                  number={productItem.quantity}
                  reduction={reduction}
                  editable
                />
              );
            })}
            {services.length > 0 && (
            <View style={styles.title}>
              <Text isBold size="large" color="primary">{t('basket.services')}</Text>
            </View>
            ) }
            { services.map((serviceItem, index) => {
              const reduction = findServiceOffer(serviceItem.service);

              total += Math.round((reduction ? (
                ((100 - reduction.discount_rate) / 100) * serviceItem.price) : serviceItem.price
              ) * serviceItem.quantity * 100) / 100;

              return (
                <BasketItem
                  key={index}
                  selectedOptions={serviceItem.selected_options}
                  price={serviceItem.price}
                  entity={serviceItem.service}
                  entityType="service"
                  number={serviceItem.quantity}
                  reduction={reduction}
                  editable
                />
              );
            })}
            <View style={styles.totalView}>
              <View>
                <Text size="large">{t('basket.total', { price: total })}</Text>
              </View>
              <Price
                noLineHeight
                price={total}
                size="huge"
              />
            </View>

            <FormInput
              type="text"
              finalForm={false}
              value={comments}
              onChange={setComments}
              label={t('common.comments')}
              style={{ paddingHorizontal: 0 }}
            />

            <View style={commonStyles.scrollViewWithButton} />
          </View>
        )

        : <Text>{t('basket.empty')}</Text>}
      <View style={commonStyles.buttonWrapper}>
        <View style={commonStyles.buttonContainer}>
          <Button
            icon="check"
            text={t('orders.validateOrder')}
            variant="gradient"
            onPress={saveAndRedirect}
          />
        </View>
      </View>
    </WaiterLayout>
  );
};

OrderValidate.path = 'basket';

OrderValidate.propTypes = {
  route: PropTypes.object.isRequired,
};

OrderValidate.defaultProps = {
};

export default OrderValidate;
