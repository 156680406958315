import React, { useEffect, useMemo } from 'react';
import {
  View, ScrollView, StyleSheet,
  useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useAuth } from 'auth/contexts/auth';
import FormInput from 'common/components/FormInput/FormInput';
import Button from 'common/components/Button/Button';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useTranslation from 'common/contexts/translations';
import useCustomerCategories from 'customers/contexts/customerCategories';
import useSiteProvider from 'providers/SiteProvider';
import { values } from 'lodash';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import { isMobileMode } from 'common/utils/desktopMode';
import CustomerBookingItemsList from '../CustomerBookingList/CustomerBookingList';

const CustomerForm = ({
  onSubmit, customer, newCustomerData,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { theme } = useTheme();
  const { site } = useSiteProvider();
  const commonStyles = makeCommonStyles(theme);

  const isMobile = isMobileMode(useWindowDimensions());
  const styles = makeStyles(theme, isMobile);

  const {
    fetchItems: fetchCustomerCategories, items: customerCategories,
  } = useCustomerCategories();

  useEffect(() => {
    fetchCustomerCategories();
  }, [fetchCustomerCategories]);

  const initialValues = useMemo(() => customer?.id
    ? {
      ...customer,
      site: customer.site.id,
      customer_category: customer.customer_category?.id,
    }
    : {
      site: user.site?.id,
      name: newCustomerData?.name,
      firstName: newCustomerData?.firstName,
      phone: newCustomerData?.phone,
      email: newCustomerData?.email,
    }, [customer, user.site.id, newCustomerData]);

  const customerCategoriesOptions = [
    ...customerCategories?.filter((cat) => cat.site.id === user.site?.id).map((cat) => ({
      value: cat.id,
      text: cat.name,
    })),
    {
      value: null,
      text: t('customer.noCategory'),
    },
  ];

  const customerCivilityOptions = [
    {
      value: null,
      text: t('customer.noCivility'),
    },
    {
      value: 'M',
      text: t('customer.civilityM'),
    },
    {
      value: 'W',
      text: t('customer.civilityW'),
    },
    {
      value: 'A',
      text: t('customer.civilityA'),
    },
  ];

  const missingFields = (values) => {
    const missing = !values.name || !values.firstName
    || (site.phoneRequired && !values.phone)
    || (site.emailRequired && !values.email);

    return missing;
  };

  return (
    <View style={styles.splitPage}>
      <View style={styles.mainPart}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({
            handleSubmit, submitting, values,
          }) => (
            <View style={{ height: '100%' }}>
              <ScrollViewLarge style={{ padding: theme.sizings.medium }}>
                <FormInput
                  type="buttongroup"
                  name="civility"
                  label={t('customer.civility')}
                  options={customerCivilityOptions}
                  verticalMobile
                />

                <FormInput
                  name="name"
                  label={t('auth.name')}
                  labelRequired
              // required  (not working)
                  icon="user"
                />
                <FormInput
                  name="firstName"
                  label={t('auth.firstName')}
                  labelRequired
              // required  (not working)
                  icon="user"
                />
                <FormInput
                  type="number"
                  name="phone"
                  label={t('auth.phone')}
                  labelRequired={Boolean(site.phoneRequired)}
                  icon="phone"
                />
                <FormInput
                  type="email"
                  name="email"
                  label={t('auth.email')}
                  labelRequired={Boolean(site.emailRequired)}
                  icon="envelope"
                />

                {customerCategoriesOptions?.length > 0 && (
                <FormInput
                  type="buttongroup"
                  name="customer_category"
                  label={t('customer.customerCategory')}
                  options={customerCategoriesOptions}
                  verticalMobile
                />
            )}
                <FormInput
                  type="textarea"
                  name="notes"
                  label={t('customer.notes')}
                />

                <View style={commonStyles.scrollViewWithButton} />

              </ScrollViewLarge>

              <View style={commonStyles.buttonWrapper}>
                <View style={commonStyles.buttonContainer}>
                  <Button
                    variant={submitting ? 'greyDarker' : 'gradient'}
                    icon="book"
                    iconColor={theme.colors.light}
                    text={t(
                      `customer.${
                        customer && customer.id ? 'edit' : 'add'
                      }Customer`,
                    )}
                    onPress={handleSubmit}
                    disabled={
                  missingFields(values)
                  || submitting
                }
                    type="submit"
                  />
                </View>
              </View>
            </View>
          )}
        />
      </View>

      {customer?.id && (
      <ScrollViewLarge style={styles.customerPart}>
        <View style={[{
          padding: theme.sizings.medium,
        }]}
        >
          <CustomerBookingItemsList
            customer={customer}
          />
        </View>
      </ScrollViewLarge>
  )}
    </View>

  );
};

CustomerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customer: PropTypes.object,
  newCustomerData: PropTypes.object,
};

CustomerForm.defaultProps = {
  customer: {},
  newCustomerData: null,
};

export default CustomerForm;

const makeStyles = (theme, isMobile) => StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
  },
  splitPage: {
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    height: '100%',
  },
  mainPart: {
    flex: isMobile ? undefined : 1,

  },
  customerPart: {
    width: isMobile ? '100%' : '35%',
    maxWidth: isMobile ? undefined : '35%',
    backgroundColor: theme.colors.white,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: {
      width: -2,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 1,
  },
});
