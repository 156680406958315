import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useCallback,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const CallTypeContext = createContext();

export const CallTypesProvider = ({ children }) => {
  const fetchCallTypes = useCallback(async (filters = {}) => {
    try {
      const esc = encodeURIComponent;

      const queryParams = Object.keys(filters)
        .map((key) => `${esc(key)}=${esc(filters[key])}`)
        .join('&');
      const res = await fetchJSON({
        url: `call-types${queryParams ? '?' : ''}${queryParams}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, []);

  return (
    <ContextProvider
      url="call-types"
      context={CallTypeContext}
      value={{
        fetchCallTypes,
      }}
    >
      {children}
    </ContextProvider>
  );
};

CallTypesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCallTypes = () => {
  const {
    setIsFetching, fetchCallTypes, setItems, items, ...rest
  } = useContext(CallTypeContext);

  return {
    setIsFetching,
    setItems,
    items,
    fetchCallTypes,
    ...rest,
  };
};

export default useCallTypes;
