import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Localization from 'expo-localization';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import 'dayjs/locale/en';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';

import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationDE from './translations/de.json';
import translationES from './translations/es.json';
import translationPT from './translations/pt.json';
import translationRU from './translations/ru.json';
import translationIT from './translations/it.json';
import translationAR from './translations/ar.json';
import translationZH from './translations/zh.json';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const languageDetector = {
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: (callback) => Localization.getLocalizationAsync().then(({ locale }) => {
    dayjs.locale(locale.split('-')[0]);
    callback(locale);
  }),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      de: {
        translation: translationDE,
      },
      es: {
        translation: translationES,
      },
      pt: {
        translation: translationPT,
      },
      ru: {
        translation: translationRU,
      },
      it: {
        translation: translationIT,
      },
      ar: {
        translation: translationAR,
      },
      zh: {
        translation: translationZH,
      },
    },
  });

export default i18n;
