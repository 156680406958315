import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import { FontAwesome5 } from '@expo/vector-icons';
import useTranslation from 'common/contexts/translations';
import Text from 'common/components/Text/Text';
import { useNavigation } from '@react-navigation/native';
import useOrders from 'orders/contexts/orders';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import dayjs from 'dayjs';
import BasketItem from 'basket/components/BasketItem/BasketItem';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';

const CustomerPanel = ({ customerContent, inBookingEditor }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { item: site } = useSite();

  const isUnknown = !customerContent?.id;

  const {
    customerHistoricalBookingItems,
    fetchCustomerHistoricalBookingItems,
    isCustomBookingsFetching,
  } = useBeachVisualizer();

  const {
    customerHistoricalOrders,
    fetchCustomerHistoricalOrders,
    isCustomOrdersFetching,
  } = useOrders();

  useEffect(() => {
    if (!isUnknown && customerContent.id) {
      fetchCustomerHistoricalBookingItems(customerContent.id);
      fetchCustomerHistoricalOrders(customerContent.id);
    }
  }, [fetchCustomerHistoricalBookingItems, fetchCustomerHistoricalOrders, customerContent, isUnknown]);

  const customerShows = customerHistoricalBookingItems?.filter(
    (bi) => bi.booking.customer?.id === customerContent.id,
  ) || [];
  const customerMonth = customerShows?.filter(
    (bi) => !dayjs(bi.date).startOf('day').isBefore(dayjs().subtract(1, 'month')),
  );
  const customerWeek = customerShows?.filter(
    (bi) => !dayjs(bi.date).startOf('day').isBefore(dayjs().subtract(7, 'day')),
  );
  const customerYear = customerShows?.filter(
    (bi) => !dayjs(bi.date).startOf('day').isBefore(dayjs().subtract(1, 'year')),
  );

  const totalOrders = customerHistoricalOrders?.length || 0;

  const orderPrices = customerHistoricalOrders?.map((order) => order.price);
  const orderPriceMin = orderPrices.reduce(
    (previousValue, currentValue) => Math.min(previousValue, currentValue),
    +Infinity,
  );

  const orderPriceMax = orderPrices.reduce(
    (previousValue, currentValue) => Math.max(previousValue, currentValue),
    -Infinity,
  );

  const orderPriceMoy = orderPrices.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  ) / orderPrices.length;

  const customerHistoricalProducts = [];

  customerHistoricalOrders.map((order) => {
    order.order_items.map((orderItem) => {
      const existing = customerHistoricalProducts.find((item) => item.product.id === orderItem.product.id);
      // todo later : comparer aussi sur selectedOptions pour savoir les variantes les + achetées

      if (!existing && orderItem.product) {
        customerHistoricalProducts.push({
          product: orderItem.product,
          occurences: orderItem.quantity,
          // selectedOptions: orderItem.selectedOptions
        });
      } else if (existing?.occurences) {
        existing.occurences += orderItem.quantity;
      }
      return null;
    });
    return null;
  });

  return (
    <View style={[styles.container, {
      padding: inBookingEditor ? 0 : theme.sizings.medium,
      marginTop: inBookingEditor ? theme.sizings.medium : 0,
    }]}
    >
      {!isUnknown && (
      <>
        <View style={styles.header}>
          <TouchableHighlight
            onPress={() => navigation.navigate('CustomerEdit', { id: customerContent.id })}
            style={styles.actionButton}
          >
            <FontAwesome5
              name="pen"
              size={theme.fontSizes.medium}
              color={theme.colors.white}
            />
          </TouchableHighlight>

          { inBookingEditor ? (
            <Text isBold style={styles.textIcon}>
              {customerContent?.civility ? `${t(`customer.civility${customerContent.civility}`)} ` : ''}
              {customerContent?.name}
              {' '}
              {customerContent?.firstName || ''}
            </Text>
          ) : (
            <Text isBold style={styles.textIcon}>{t('customer.customerPanel')}</Text>
          ) }

          <View style={styles.customerBadge}>
            {customerContent?.customer_category?.name === 'VIP' && (
            <FontAwesome5
              name="crown"
              color={theme.colors.secondaryDark}
              size={theme.fontSizes.medium}
              style={{
                marginRight: theme.sizings.small,
              }}
            />

          )}

            <Text>{customerContent.customer_category?.name || t('customer.noCategory')}</Text>

            {Boolean(customerContent.noShow > 0) && (
            <>
              <FontAwesome5
                name="ghost"
                color={theme.colors.dangerBright}
                size={theme.fontSizes.medium}
                style={{
                  marginHorizontal: theme.sizings.small,
                }}
              />

              <Text>
                {customerContent.noShow.toString()}
              </Text>
            </>
            )}
          </View>

        </View>

        <View style={styles.hr} />
      </>
      )}

      {customerContent.email !== '' && customerContent.email !== null && (
        <>
          <View style={styles.bloc}>
            <View style={styles.inlineBox}>

              <TouchableHighlight
                onPress={() => {}}
                style={styles.infoButton}
              >
                <FontAwesome5
                  name="phone"
                  size={theme.fontSizes.medium}
                  color={theme.colors.white}
                />
              </TouchableHighlight>

              <Text style={styles.textIcon}>
                {customerContent.phone}
              </Text>

            </View>
          </View>

          <View style={styles.hr} />

        </>
      )}

      {customerContent.email !== '' && customerContent.email !== null && (
        <>
          <View style={styles.bloc}>
            <View style={styles.inlineBox}>

              <TouchableHighlight
                onPress={() => {}}
                style={styles.infoButton}
              >
                <FontAwesome5
                  name="envelope"
                  size={theme.fontSizes.medium}
                  color={theme.colors.white}
                />
              </TouchableHighlight>

              <Text style={styles.textIcon}>
                {customerContent.email}
              </Text>
            </View>

          </View>

          <View style={styles.hr} />

        </>
      )}

      <View style={styles.bloc}>
        <View style={styles.inlineBox}>

          <TouchableHighlight
            onPress={() => {}}
            style={styles.infoButton}
          >
            <FontAwesome5
              name="file"
              size={theme.fontSizes.medium}
              color={theme.colors.white}
            />
          </TouchableHighlight>

          <Text style={styles.textIcon}>
            {customerContent.notes || t('customer.noNotes')}
          </Text>
        </View>
      </View>

      <View style={styles.hr} />

      {!isUnknown && Boolean(customerShows?.length > 0) && (
        isCustomOrdersFetching || isCustomBookingsFetching ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.blocRow}>
            <View>
              <Text isBold>{t('customer.totalShows', { number: customerShows?.length })}</Text>
              <Text>{t('customer.bookingWeek', { number: customerWeek?.length })}</Text>
              <Text>{t('customer.bookingMonth', { number: customerMonth?.length })}</Text>
              <Text>{t('customer.bookingYear', { number: customerYear?.length })}</Text>
            </View>
            <View>
              <Text isBold>{t('customer.totalOrders', { number: totalOrders })}</Text>
              {totalOrders > 0 && (
              <>
                <Text>{t('customer.ordersMin', { number: formatPrice(orderPriceMin, site?.currency) })}</Text>
                <Text>{t('customer.ordersMax', { number: formatPrice(orderPriceMax, site?.currency) })}</Text>
                <Text>{t('customer.ordersMoy', { number: formatPrice(orderPriceMoy, site?.currency) })}</Text>
              </>
              )}

            </View>
          </View>
        )
      )}

      {!isUnknown && (
        Boolean(customerHistoricalProducts?.length) && !inBookingEditor && (
        <>
          <View style={styles.hr} />
          <View style={styles.bloc}>
            <Text isBold style={{ marginBottom: theme.sizings.medium }}>
              {t('customer.favoriteProducts')}
            </Text>
            {customerHistoricalProducts
              .sort((a, b) => b.occurences - a.occurences)
              .slice(0, 5)
              .map((item, itemIndex) => item.product ? (
                <View key={`customer-favorite-product-${itemIndex}`}>
                  <BasketItem
                    selectedOptions={[]}
                    price={item.product.price}
                    entity={item.product}
                    entityType="product"
                    number={item.occurences}
                  />
                </View>
              ) : null)}
          </View>

        </>
        )
      )}

    </View>
  );
};

CustomerPanel.propTypes = {
  customerContent: PropTypes.object,
  inBookingEditor: PropTypes.bool,
};

CustomerPanel.defaultProps = {
  customerContent: {},
  inBookingEditor: false,
};

const makeStyles = (theme) => StyleSheet.create({
  container: {

  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.sizings.smallMedium,

  },
  customerBadge: {
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    flexDirection: 'row',
    alignItems: 'center',
  },
  hr: {
    height: 1,
    width: '100%',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.greyLighter,
  },
  bloc: {
    marginVertical: theme.sizings.smallMedium,
  },
  blocRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: theme.sizings.smallMedium,

  },
  inlineBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionButton: {
    minWidth: theme.normalize(36),
    minHeight: theme.normalize(36),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.primary,
  },
  infoButton: {
    minWidth: theme.normalize(36),
    minHeight: theme.normalize(36),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.sizings.small,
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.purple,
  },
  textIcon: {
    lineHeight: theme.normalize(36),
  },
});

export default CustomerPanel;
