import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Leaves from 'assets/leaves.png';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import makeStyles from './styles/CategoriesList.styles';

const Categories = ({ categories, categoryServices }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    items: specialOffers,
  } = useSpecialOffers();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const findReduction = useCallback((categoryId, type) => {
    const reduction = specialOffers.find((offer) => offer[type].findIndex((c) => c.id === categoryId) >= 0);

    if (reduction) {
      return (
        <View style={styles.reductionBadge}>
          <Text
            color="white"
            size="medium"
          >
            {t('beach.currentlyDiscounted', { discountRate: reduction.discount_rate })}
          </Text>
        </View>
      );
    }
    return null;
  },
  [specialOffers, t, styles]);

  const categoriesWithProducts = categories.filter(({ parent }) => !parent).reduce((acc, category) => {
    // check if children categories of current category has products
    const count = category.children.reduce((prev, curr) => prev + curr.products.length, category.products.length);

    // if current category and its children doesn't have any products, we don't display it
    if (count > 0) {
      return [...acc, { ...category, count }];
    }

    return acc;
  }, []).sort((a, b) => Number(a.index || 0) - Number(b.index || 0));

  const categoriesWithServices = categoryServices.filter(({ parent }) => !parent).reduce((acc, categoryService) => {
    // check if children categories of current category has services
    const count = categoryService.children.reduce(
      (prev, curr) => prev + curr.services.length,
      categoryService.services.length,
    );

    // if current category and its children doesn't have any services, we don't display it
    if (count > 0) {
      return [...acc, { ...categoryService, count }];
    }

    return acc;
  }, []);

  return (
    <Page
      scroll
      padding
      backgroundSource={Leaves}
      backgroundStyle={styles.bottomImage}
      style={{ marginBottom: 0 }}
    >

      { categories.length > 0 && <Text isBold size="large" style={styles.title}>{t('beach.products')}</Text>}
      { categoriesWithProducts.filter(({ parent }) => !parent).map((category) => (
        <TouchableOpacity
          style={[styles.categoryView, commonStyles.shadowBottom]}
          key={category.id}
          onPress={() => navigation.navigate('CategoryDetail', { category })}
        >
          <TranslatedText centered isBold color="greyDarkest" size="large" value={category.name} />
          {findReduction(category.id, 'categories')}
          <View style={styles.productsCountView}>
            <LinearGradient
              colors={[theme.colors.secondary, theme.colors.secondaryDark]}
              start={[0, 0.5]}
              end={[1, 0.5]}
              style={{
                zIndex: -10,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                width: '100%',
                height: theme.normalize(30),
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={styles.productCount} color="light" size="large">
                {category.children.reduce((prev, curr) => prev + curr.products.length, category.products.length)}
              </Text>
              <FontAwesome name="arrow-right" size={theme.fontSizes.default} color={theme.colors.light} />
            </LinearGradient>
          </View>
        </TouchableOpacity>
      ))}
      { categoryServices.length > 0 && <Text isBold size="large" style={styles.title}>{t('beach.services')}</Text>}
      { categoriesWithServices.filter(({ parent }) => !parent).map((category) => (
        <TouchableOpacity
          style={[styles.categoryView, commonStyles.shadowBottom]}
          key={category.id}
          onPress={() => navigation.navigate('CategoryServiceDetail', { category })}
        >
          <TranslatedText centered isBold color="greyDarkest" size="large" value={category.name} />
          {findReduction(category.id, 'category_services')}
          <View style={styles.productsCountView}>
            <LinearGradient
              colors={[theme.colors.secondary, theme.colors.secondaryDark]}
              start={[0, 0.5]}
              end={[1, 0.5]}
              style={{
                zIndex: -10,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                width: '100%',
                height: theme.normalize(30),
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={styles.productCount} color="light" size="large">
                {category.children.reduce((prev, curr) => prev + curr.services.length, category.services.length)}
              </Text>
              <FontAwesome name="arrow-right" size={theme.fontSizes.default} color={theme.colors.light} />
            </LinearGradient>
          </View>
        </TouchableOpacity>
      ))}
    </Page>
  );
};

Categories.propTypes = {
  categories: PropTypes.array,
  categoryServices: PropTypes.array,
};

Categories.defaultProps = {
  categories: [],
  categoryServices: [],
};

export default Categories;
