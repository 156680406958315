import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTranslation from 'common/contexts/translations';
import { useNavigation } from '@react-navigation/native';
import useTheme from 'providers/ThemeProvider';

import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';

const AccountDeleted = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  return (
    <View style={styles.mainView}>
      <Text
        isBold
        size="large"
        centered
      >
        {t('auth.accountSuccessfullyDeleted')}
      </Text>
      <Button
        style={styles.button}
        text={t('auth.goHome')}
        onPress={() => navigation.navigate({
          name: 'HomePage',
        })}
      />
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  mainView: {
    margin: 'auto',
  },
  button: {
    margin: theme.sizings.medium,
    width: '100%',
  },
});

export default AccountDeleted;
