import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HTML from 'react-native-render-html';

import useTrans from 'common/hooks/use-trans';
import Text from 'common/components/Text/Text';
import truncateText from 'common/utils/truncateText';

const TranslatedText = ({
  value, truncateLength, tagsStyles, suffix, ...rest
}) => {
  const { translateText } = useTrans();

  const formattedValue = useMemo(() => {
    const translatedValue = translateText(value);

    return truncateLength ? truncateText(translatedValue, truncateLength) : translatedValue;
  }, [value, truncateLength, translateText]);

  return (
    tagsStyles ? (
      <HTML source={{ html: formattedValue }} tagsStyles={tagsStyles} {...rest} />
    ) : (
      <Text {...rest}>
        {formattedValue}
        {' '}
        {suffix }
      </Text>
    )
  );
};

TranslatedText.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  suffix: PropTypes.string,
  truncateLength: PropTypes.number,
  tagsStyles: PropTypes.object,
};

TranslatedText.defaultProps = {
  value: '',
  suffix: null,
  truncateLength: null,
  tagsStyles: null,
};

export default TranslatedText;
