import React, { forwardRef, useMemo, useState } from 'react';
import { Dimensions, useWindowDimensions, View } from 'react-native';
import { ImageBackground } from 'common/components/Images';
import PropTypes from 'prop-types';

import BeachBG from 'assets/beach.jpg';
import useTheme from 'providers/ThemeProvider';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { isMobileMode } from 'common/utils/desktopMode';
import useBooking from 'bookings/contexts/bookings';
import BoardSquare from '../BoardSquare/BoardSquare';
// eslint-disable-next-line import/extensions
import ZoomView from './ZoomView';
import useBeachVisualizer from '../../contexts/beachVisualizer';
import CustomerBoardSquare from '../BoardSquare/CustomerBoardSquare';

const applyStyle = {
  position: 'absolute',
  borderWidth: 1,
  borderColor: 'grey',
  borderStyle: 'dashed',
  padding: 0,
};

const meterToPx = 80;

const RenderSquare = ({
  seat, handleClickSeat, getSeatData,
  currentDate, currentStayDuration, selectedSeats,
  map, backgroundColorWaiter, customerView, searchBookingItem,
}) => {
  const { theme } = useTheme();
  const itemWidth = seat?.furniture?.width * meterToPx * seat?.scale;
  const itemHeight = seat?.furniture?.height * meterToPx * seat?.scale;

  const { seatFocused } = useBeachVisualizer();

  const {
    isSeatConflicting,
  } = useBooking();

  const focusedShadowStyle = {
    shadowColor: theme.colors.black,
    shadowRadius: 100,
    shadowOffset: {
      height: theme.normalize(4),
      width: 0,
    },
    shadowOpacity: 10,
  };

  const isFocused = seat.id === seatFocused?.id;

  const hasConflict = useMemo(() => {
    const isConflict = isSeatConflicting(seat.id, currentDate);

    return isConflict;
  }, [currentDate, isSeatConflicting, seat]);

  const showSquare = customerView ? (seat?.furniture && !seat?.outOfOrder && !seat?.outOfService)
    : (seat?.furniture && !seat?.outOfOrder);

  return showSquare ? (
    <View style={[{
      ...applyStyle,
      width: itemWidth,
      height: itemHeight,
      left: seat.x,
      top: seat.y,
      transform: [{ rotate: `${seat.rotate ? seat.rotate : 0}deg` }],
    }, isFocused && focusedShadowStyle,
    ]}
    >
      {customerView ? (
        <CustomerBoardSquare
          seat={seat}
          squareDimensions={{
            width: itemWidth,
            height: itemHeight,
          }}
          handleClickSeat={handleClickSeat}
          selectedSeats={selectedSeats}
          map={map}
        />
      ) : (
        <BoardSquare
          seat={seat}
          squareDimensions={{
            width: itemWidth,
            height: itemHeight,
          }}
          handleClickSeat={handleClickSeat}
          currentStayDuration={currentStayDuration}
          searchBookingItem={searchBookingItem}
          selectedSeats={selectedSeats}
          map={map}
          backgroundColorWaiter={backgroundColorWaiter}
          getSeatData={getSeatData}
          hasConflict={hasConflict}
        />
      )}

    </View>
  ) : null;
};

RenderSquare.propTypes = {
  seat: PropTypes.object.isRequired,
  handleClickSeat: PropTypes.func.isRequired,
  searchBookingItem: PropTypes.string,
  currentDate: PropTypes.any,
  currentStayDuration: PropTypes.any,
  selectedSeats: PropTypes.any.isRequired,
  map: PropTypes.object.isRequired,
  backgroundColorWaiter: PropTypes.bool,
  getSeatData: PropTypes.func.isRequired,
  customerView: PropTypes.bool,
};

RenderSquare.defaultProps = {
  currentDate: null,
  currentStayDuration: null,
  backgroundColorWaiter: false,
  customerView: false,
  searchBookingItem: '',
};

const Board = forwardRef(({
  map, style, handleClickSeat, bookingItemFocused,
  currentDate, searchBookingItem, selectedSeats,
  currentStayDuration,
  disabled, switchAreasColorType, getSeatData, bookingConflicts,
  customerView,
}, innerRef) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  /** **************  MEMO ***************** */
  /*
  boardDimensions = taille disponible à l'écran, calculée en dur format tablette
  mapScreenDimensons = taille numérique de la carte, pour 1m = 80px sans prise en compte du zoom
  */
  const mapScreenDimensions = useMemo(() => ({
    height: (map?.backgroundHeight * meterToPx) || 800,
    width: (map?.backgroundWidth * meterToPx) || 800,
  }), [map]);

  const [boardDimensions, setBoardDimensions] = useState(
    { width: 1, height: 1 },
  );

  const centerMapInArea = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: mapScreenDimensions.width,
    height: mapScreenDimensions.height,
    transform: [
      {
        translateX: -mapScreenDimensions.width / 2,
      },
      {
        translateY: -mapScreenDimensions.height / 2,
      },
    ],
  };

  const imageSrc = map?.backgroundImage
    ? {
      image: map.backgroundImage,
    }
    : {
      source: BeachBG,
    };

  const [backgroundColorWaiter, setBackgroundColorWaiter] = useState(false);

  const isMobile = isMobileMode(useWindowDimensions());

  return (
    <View
      style={[{
        overflow: 'hidden',
        backgroundColor: 'black',
        width: '100%',
        flex: 1,
      }, style]}
      onLayout={(event) => {
        const {
          width, height,
        } = event.nativeEvent.layout;

        setBoardDimensions({ width, height });
      }}
    >

      <ZoomView
        key={`${boardDimensions.width}_${boardDimensions.height}`}
        style={{
          width: boardDimensions.width,
          height: boardDimensions.height,
          maxHeight: boardDimensions.height,
          // backgroundColor: 'purple', // (repere)
        }}
        initialZoom={
          boardDimensions.width / mapScreenDimensions.width < boardDimensions.height / mapScreenDimensions.height
            ? boardDimensions.width / mapScreenDimensions.width : boardDimensions.height / mapScreenDimensions.height
          }
        contentWidth={mapScreenDimensions.width}
        contentHeight={mapScreenDimensions.height}
      >
        <View
          ref={innerRef}
          style={{
            width: mapScreenDimensions.width,
            height: mapScreenDimensions.height,
            maxHeight: mapScreenDimensions.height,
            position: 'relative',
            backgroundColor: theme.colors.beach,
          }}
        >

          <ImageBackground style={{ flex: 1 }} {...imageSrc} resizeMode="cover" />
          { map?.seats?.map((item) => (
            <RenderSquare
              key={item.id}
              x={item.x}
              y={item.y}
              seat={item}
              handleClickSeat={handleClickSeat}
              bookingItemFocused={bookingItemFocused}
              currentDate={currentDate}
              currentStayDuration={currentStayDuration}
              selectedSeats={selectedSeats}
              searchBookingItem={searchBookingItem}
              map={map}
              bookingConflicts={bookingConflicts}
              backgroundColorWaiter={backgroundColorWaiter}
              getSeatData={getSeatData}
              customerView={customerView}
            />
          ))}
          { disabled && (<View style={[centerMapInArea, { backgroundColor: 'white', opacity: 0.8 }]} />)}

        </View>
      </ZoomView>

      {switchAreasColorType && (
      <View style={{
        paddingHorizontal: theme.sizings.medium,
        maxWidth: theme.normalize(200),
        minWidth: theme.normalize(200),
        margin: 'auto',
        position: 'absolute',
        top: 0,
        right: 0,
      }}
      >
        <ButtonGroup
          options={[{
            value: false,
            text: t('waiter.showPriceArea'),
          }, {
            value: true,
            text: t('waiter.showWaiterArea'),
          }]}
          onChange={setBackgroundColorWaiter}
          value={backgroundColorWaiter}
          small
        />

      </View>
      )}
    </View>
  );
});

Board.propTypes = {
  map: PropTypes.object.isRequired,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  handleClickSeat: PropTypes.func.isRequired,
  bookingItemFocused: PropTypes.object,
  searchBookingItem: PropTypes.string,
  currentDate: PropTypes.any,
  currentStayDuration: PropTypes.any,
  selectedSeats: PropTypes.any.isRequired,
  switchAreasColorType: PropTypes.bool,
  getSeatData: PropTypes.func.isRequired,
  bookingConflicts: PropTypes.object,
  customerView: PropTypes.bool,
};

Board.defaultProps = {
  style: null,
  disabled: false,
  bookingItemFocused: null,
  switchAreasColorType: false,
  currentDate: null,
  currentStayDuration: null,
  bookingConflicts: {},
  customerView: false,
  searchBookingItem: '',
};

export default Board;
