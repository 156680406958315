import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'react-native-elements';
import debounce from 'lodash/debounce';

export const DebouncedInput = ({
  value, onChange, debounceTimeout, style, inputStyle, ...rest
}) => {
  const [localValue, setValue] = useState(value);

  const setDebouncedChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const handleInputChange = useCallback((e) => {
    setValue(e.nativeEvent.text);
    setDebouncedChange(e.nativeEvent.text);
  }, [setDebouncedChange]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Input
      value={localValue || ''}
      onChange={handleInputChange}
      containerStyle={style}
      inputStyle={inputStyle}
      {...rest}
    />
  );
};

DebouncedInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  style: PropTypes.any,
  inputStyle: PropTypes.any,

};

DebouncedInput.defaultProps = {
  debounceTimeout: 300,
  value: '',
  style: null,
  inputStyle: null,
};

export default DebouncedInput;
