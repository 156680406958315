import getFurniturePrice from './getFurniturePrice';
import getActiveSeason from './getActiveSeason';

const getBookingPrices = (bookingItems, areas, seasons, hotel) => {
  const prices = bookingItems.map((bi) => {
    let price = 0;

    let errorSeason = false;

    let errorFurniturePrice = false;

    bi.bookingContent.forEach((bc) => {
      const bcPriceAreaId = bc.price_area?.id ?? bc.price_area;
      const area = areas.find((a) => a.id === bcPriceAreaId);

      const season = getActiveSeason(bi.date, seasons);

      if (!season) {
        errorSeason = true;
      }

      const furniturePrice = getFurniturePrice({
        area,
        furnitureId: bc.furniture?.id ?? bc.furniture,
        stayDuration: bi.stayDuration,
        hotel,
        season,
      });

      if (bc.amount && (!furniturePrice || furniturePrice === 0)) {
        errorFurniturePrice = true;
      }

      price += furniturePrice * bc.amount;
    });

    // end foreach mobiliers
    return ({
      date: bi.date,
      price,
      errorSeason,
      errorFurniturePrice,
    });
  });

  // end foreach dates

  return prices;
};

export default getBookingPrices;
