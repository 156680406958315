import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const ZoomView = ({
  children, initialZoom, contentWidth, contentHeight, ...rest
}) => (
  <TransformWrapper
    initialScale={1}
    limitToBounds={false}
  >
    <TransformComponent>
      <View {...rest}>
        <View style={{
          width: contentWidth,
          height: contentHeight,
          transform: [
            { scale: initialZoom },
          ],
          transformOrigin: 'top left',
        }}
        >
          {children}
        </View>
      </View>
    </TransformComponent>
  </TransformWrapper>
);

ZoomView.propTypes = {
  initialZoom: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  contentWidth: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
};

export default ZoomView;
