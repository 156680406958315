import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  TouchableOpacity, View,
} from 'react-native';
import Modal from 'common/components/Modal/Modal';
import Button from 'common/components/Button/Button';
import useTranslation from 'common/contexts/translations';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';

const BookingPaymentTypeModal = ({
  setModalVisible, modalVisible,
  modalText, handleValidate,
}) => {
  const { t } = useTranslation();

  const [methodSelected, setMethodSelected] = useState('CB');

  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <View style={commonStyles.modalInner}>
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={commonStyles.modalMask}
        />
        <View style={[commonStyles.modalBox, { minWidth: theme.normalize(500) }]}>
          {modalText}
          <View style={{ marginVertical: theme.sizings.large, width: '100%' }}>
            <ButtonGroup
              options={[{
                value: 'CASH',
                text: t('waiter.cash'),
              }, {
                value: 'CB',
                text: t('waiter.cb'),
              }, {
                value: 'ROOM',
                text: t('waiter.hotelNote'),
              },
              ]}
              onChange={(value) => {
                setMethodSelected(value);
              }}
              value={methodSelected}
              verticalMobile
            />
          </View>
          <Button
            disabled={!methodSelected}
            variant="primary"
            text={t('common.confirm')}
            onPress={() => handleValidate(methodSelected)}
          />
        </View>
      </View>
    </Modal>
  );
};

BookingPaymentTypeModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
  modalText: PropTypes.element,
  handleValidate: PropTypes.func.isRequired,
};

BookingPaymentTypeModal.defaultProps = {
  modalVisible: false,
  modalText: null,
};

export default BookingPaymentTypeModal;
