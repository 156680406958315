import React, { useCallback, useEffect, useState } from 'react';

import useEvents from 'events/contexts/events';
import EventItem from 'waiter/components/EventItem/EventItem';
import WaiterLayout, { WaiterSubheader } from 'waiter/components/WaiterLayout/WaiterLayout';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import { useTranslation } from 'react-i18next';
import useTheme from 'providers/ThemeProvider';
import {
  ScrollView, Dimensions, ActivityIndicator, View,
  useWindowDimensions, StyleSheet,
} from 'react-native';
import { isMobileMode } from 'common/utils/desktopMode';
import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import Text from 'common/components/Text/Text';
import Switch from 'common/components/Switch/Switch';
import dayjs from 'dayjs';
import Pagination from 'common/components/Pagination/Pagination';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import getFF from 'common/utils/getFF';

const Calendar = () => {
  const { t, lang } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme, isMobileMode({ width, height }, lang));
  const { width, height } = useWindowDimensions();

  /* globalSearch */
  const [globalSearch, setGlobalSearch] = useState('');
  const [seePassedEvents, setSeePassedEvents] = useState(false);

  const {
    date: selectedDate,
  } = useBeachVisualizer();
  const {
    fetchEvents, items: events = [], isFetching, pageCount, setSort, page, setPage, setFilter,
  } = useEvents();

  useEffect(() => {
    setSort('startDate:DESC');
    setFilter('endDate_gte', seePassedEvents ? null : dayjs(selectedDate).format('YYYY-MM-DD'));
  }, [seePassedEvents, selectedDate, setFilter, setSort]);

  useEffect(() => {
    setPage(0);
    fetchEvents();
  }, [fetchEvents, setPage]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, [setPage]);

  const addEvent = () => {
    navigation.navigate('EventEdit');
  };

  return (
    <WaiterLayout noPadding noScroll>
      <>
        <WaiterSubheader centered>
          <>
            <Button
              onPress={addEvent}
              icon="calendar-plus"
              iconFamily="fontawsome5"
              iconColor="primary"
              variant="white"
              text={t('events.addEvent')}
              small
              style={styles.buttonHeader}
            />
          </>
        </WaiterSubheader>

        {!isFetching && (
        <View style={styles.searchBar}>
          <DebouncedInput
            value={globalSearch}
            onChange={setGlobalSearch}
            placeholder={t('common.globalSearch')}
            containerStyle={{ padding: 0, marginRight: theme.sizings.medium, width: '50%' }}
          />

          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Text isBold style={styles.switchLabel} color="primary">{t('events.seePassedEvents')}</Text>
            <Switch
              onChange={setSeePassedEvents}
              value={seePassedEvents}
            />
          </View>
        </View>
        )}

        <ScrollView style={{
          padding: isMobileMode({ width, height }) ? theme.sizings.smallMedium : theme.sizings.mediumLarge,
          maxHeight: Dimensions.get('window').height - theme.normalize(10),
        }}
        >
          {isFetching ? (
            <View style={{ height: theme.normalize(300), width: '100%', alignItems: 'center' }}>
              <ActivityIndicator />
            </View>
          ) : (
          events?.length > 0 ? events.map((event) => (
            <>
              <EventItem key={event.id} event={event} />
            </>
          )) : (
            <EmptyPage text={t('events.empty')} />
          )
          )}
        </ScrollView>
        <Pagination page={page} nbPages={pageCount} onChange={handlePageChange} showArrows />
      </>
    </WaiterLayout>
  );
};

const makeStyles = (theme, isMobile, lang) => StyleSheet.create({
  searchBar: {
    backgroundColor: theme.colors.greyLighter,
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.small,
    flexDirection: 'row',
  },
  buttonHeader: {
    marginHorizontal: isMobile ? undefined : theme.sizings.tiny,
    marginVertical: isMobile ? theme.sizings.tiny : undefined,
  },
  switchLabel: {
    marginRight: theme.sizings.small,
    fontFamily: getFF(lang, true, theme),
    alignSelf: 'center',
  },

});

export default Calendar;
