import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import { useTranslation } from 'react-i18next';

const StatusSwitcher = ({
  initialStatus, handleChangeStatus,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(initialStatus);

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <ButtonGroup
          options={[{
            value: 'reserved',
            text: t('bookings.bookingItemStatus_reserved'), // same wording in twice cases
          }, {
            value: 'checked_in',
            text: status === 'checked_in'
              ? t('bookings.bookingItemStatus_checked_in') : t('bookings.bookingItemStatus_do_checked_in'),
          }, {
            value: 'checked_out',
            text: status === 'checked_out'
              ? t('bookings.bookingItemStatus_checked_out') : t('bookings.bookingItemStatus_do_checked_out'),
          },
          {
            value: 'noShow',
            text: status === 'noShow'
              ? t('bookings.bookingItemStatus_noShow') : t('bookings.bookingItemStatus_do_noShow'),
          },
          {
            value: 'cancelled',
            text: status === 'cancelled'
              ? t('bookings.bookingItemStatus_cancelled') : t('bookings.bookingItemStatus_do_cancelled'),
          }]}
          onChange={async (value) => {
            setIsLoading(true);
            await handleChangeStatus(value);
            setStatus(value);
            setIsLoading(false);
          }}
          value={status}
          backgroundColor={status === 'noShow'
            ? theme.colors.dangerBright : undefined}
          verticalMobile
        />
      )}

    </View>
  );
};

StatusSwitcher.propTypes = {
  initialStatus: PropTypes.string.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
};

StatusSwitcher.defaultProps = {
};

const makeStyles = (theme) => StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: theme.normalize(400),
    margin: theme.sizings.tiny,
  },
});

export default StatusSwitcher;
