import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import useAuth from 'auth/contexts/auth';
import useScreenOrientation from 'auth/hooks/use-screen-orientation';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import OrderListItem from 'orders/components/OrderListItem/OrderListItem';
import useOrders from 'orders/contexts/orders';
import useProduct from 'products/contexts/products';
import useTheme from 'providers/ThemeProvider';
import WaiterLayout, { WaiterSubheader } from 'waiter/components/WaiterLayout/WaiterLayout';
import AddOrderButton from 'orders/components/AddOrderButton/AddOrderButton';
import { TouchableOpacity } from 'react-native-gesture-handler';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import { isMobileMode } from 'common/utils/desktopMode';

const ordersStateSortByRole = {
  barman: [
    {
      categoryName: 'newOrder',
      states: ['created'],
    },
    {
      categoryName: 'stateCreated',
      states: ['in_preparation'],
    },
    {
      categoryName: 'stateReady',
      states: ['ready', 'done'],
    },
  ],
  waiter: ['ready', 'in_preparation', 'created', 'done'],
};

const OrdersList = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    items: orders,
    // activeOrdersFilters,
    // handleActiveOrdersFilters,
    isFetching: isFetchingOrders,
    refreshOrders,
  } = useOrders();
  const { isLandscape } = useScreenOrientation();
  const isMobile = isMobileMode(useWindowDimensions());
  const { items: products, fetchItems: fetchProducts } = useProduct();
  const [showMyArea, setShowMyArea] = useState(true);
  const { fetchBarmanList, barmanList } = useOrders();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const areaIds = useMemo(() => user && user.areas ? user.areas.map(({ id }) => id) : [], [user]);
  const barmanListWithNone = useMemo(() => [...barmanList, { value: null, label: t('common.none') }], [barmanList, t]);

  const renderOrders = useCallback((orders, categoryName) => {
    const orderItems = orders.filter((order) => {
      if (currentOrderState) {
        if (currentOrderState === 'done_and_paid') {
          return order.state === 'done' && order.payment.status !== 'PENDING';
        }
        if (currentOrderState === 'pending_or_unpaid') {
          return order.state !== 'done' || order.payment.status === 'PENDING';
        }
        return order.state === currentOrderState;
      }
      return true;
    }).map((order) => (
      <OrderListItem
        style={styles.orderFull}
        key={order.id}
        products={products}
        barmanList={barmanListWithNone}
        {...order}
      />
    ));

    if (isLandscape) {
      return (
        <View style={styles.orderColumn} key={categoryName}>
          {categoryName && (
          <Text>
            { t(`orders.${categoryName}`) }
          </Text>
          )}
          {orderItems}
        </View>
      );
    }
    return orderItems;
  }, [isLandscape, products, barmanListWithNone, t, styles, currentOrderState]);

  const handleShowMyArea = useCallback((showMyArea) => {
    setShowMyArea(showMyArea);
    // handleActiveOrdersFilters('state_ne');
  }, []);

  useEffect(() => {
    refreshOrders();
  }, [refreshOrders]);

  useEffect(() => {
    fetchProducts();
    fetchBarmanList();
  }, [fetchProducts, fetchBarmanList]);

  const [currentOrderState, setCurrentOrderState] = useState('pending_or_unpaid');

  const ordersByState = useMemo(() => {
    let activeOrders = orders;

    if (user.role.type === 'waiter'
    && currentOrderState !== 'done'
    // activeOrdersFilters?.state_ne !== 'done'
    ) {
      if (showMyArea) {
        activeOrders = orders.filter((order) => areaIds.includes(order.seat?.waiter_area));
      } else {
        activeOrders = orders.filter((order) => !areaIds.includes(order.seat?.waiter_area));
      }
    }
    const ordersByState = activeOrders.reduce((acc, value) => {
      if (!acc[value.state]) {
        acc[value.state] = [];
      }
      acc[value.state].push(value);
      return acc;
    }, {});

    return ordersByState;
  }, [orders, showMyArea, areaIds, user, currentOrderState]);

  return (
    <WaiterLayout noPadding>
      <>
        <WaiterSubheader>
          <>
            <View style={styles.statusView}>

              {user.role?.name === 'barman' ? (
                <>
                  <TouchableOpacity
                    style={[styles.headerTab, {
                    // borderBottomWidth: activeOrdersFilters?.state_ne !== 'done' ? theme.normalize(4) : 0,
                      borderBottomWidth: currentOrderState === 'pending_or_unpaid' ? theme.normalize(4) : 0,
                    }]}
                    // onPress={() => handleActiveOrdersFilters('state_ne')}
                    onPress={() => setCurrentOrderState('pending_or_unpaid')}
                  >
                    <Text
                      // isBold={activeOrdersFilters?.state_ne !== 'done'}
                      isBold={currentOrderState === 'pending_or_unpaid'}
                      color="light"
                      size="medium"
                    >
                      {`${t('waiter.orders')}`}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={[styles.headerTab, {
                    // borderBottomWidth: activeOrdersFilters?.state_ne === 'done' ? theme.normalize(4) : 0,
                      borderBottomWidth: currentOrderState === 'done_and_paid' ? theme.normalize(4) : 0,
                    }]}
                    // onPress={() => handleActiveOrdersFilters('state_ne', 'done')}
                    onPress={() => setCurrentOrderState('done_and_paid')}
                  >
                    <Text
                      // isBold={activeOrdersFilters?.state_ne === 'done'}
                      isBold={currentOrderState === 'done_and_paid'}
                      color="light"
                      size="medium"
                    >
                      {`${t('waiter.ordersHistory')}`}
                    </Text>
                  </TouchableOpacity>
                </>
            ) : (
              <>
                <TouchableOpacity
                  style={[styles.headerTab, {
                    borderBottomWidth: showMyArea
                    && currentOrderState === 'pending_or_unpaid'
                    // activeOrdersFilters?.state_ne !== 'done'
                      ? theme.normalize(4) : 0,
                  }]}
                  onPress={() => {
                    handleShowMyArea(true);
                    setCurrentOrderState('pending_or_unpaid');
                  }}
                >
                  <Text
                    isBold={showMyArea && currentOrderState === 'pending_or_unpaid'}
                    color="light"
                    size="medium"
                  >
                    {`${t('waiter.myOrders')}`}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[styles.headerTab, {
                    borderBottomWidth: !showMyArea
                    && currentOrderState === 'pending_or_unpaid'
                    // activeOrdersFilters?.state_ne !== 'done'
                      ? theme.normalize(4) : 0,
                  }]}
                  onPress={() => {
                    handleShowMyArea(false);
                    setCurrentOrderState('pending_or_unpaid');
                  }}
                >
                  <Text
                    isBold={!showMyArea && currentOrderState === 'pending_or_unpaid'}
                    color="light"
                    size="medium"
                  >
                    {`${t('waiter.otherOrders')}`}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[styles.headerTab, {
                    borderBottomWidth:
                    // activeOrdersFilters?.state_ne === 'done'
                    currentOrderState === 'done_and_paid'
                      ? theme.normalize(4) : 0,
                  }]}
                  onPress={() => {
                    setCurrentOrderState('done_and_paid');
                  }}
                >
                  <Text
                    isBold={currentOrderState === 'done_and_paid'}
                    color="light"
                    size="medium"
                  >
                    {`${t('waiter.ordersHistory')}`}
                  </Text>
                </TouchableOpacity>
              </>
            )}
            </View>

            {!isMobile && (
            <AddOrderButton />
            )}
          </>
        </WaiterSubheader>

        <LoadingSpinner
          visible={isFetchingOrders}
          theme={theme}
        />

        <View style={styles.orderList}>
          {
          Object.keys(ordersByState).length > 0 ? ordersStateSortByRole[user.role.type].map((orderState) => {
            let orders = [];

            // waiter view
            if (typeof orderState === 'string') {
              orders = ordersByState[orderState] ? ordersByState[orderState] : [];
            } else {
              // barman view regroup some order states in same column
              orderState.states.forEach((state) => {
                if (ordersByState[state]) {
                  orders = orders.concat(ordersByState[state]);
                }
              });
            }

            return orders.length > 0 ? renderOrders(orders, orderState?.categoryName) : null;
          })
            : !isFetchingOrders && (
              <EmptyPage text={t('orders.noOrders')} />
            )
        }
        </View>
      </>
    </WaiterLayout>
  );
};

OrdersList.path = 'orders-list';

const makeStyles = (theme) => StyleSheet.create({
  headerBox: {
    // flexDirection: 'row',
    // paddingTop: 65,

  },
  statusView: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowX: 'auto',
    width: '100%',
    height: '100%',
    maxHeight: theme.normalize(55),
    paddingHorizontal: theme.sizings.tiny,
  },
  headerTab: {
    borderBottomColor: theme.colors.light,
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.medium,
    height: theme.normalize(40),
    display: 'flex',
    alignItems: 'center',
  },

  titleArea: {
    marginBottom: theme.sizings.small,
  },

  orderFull: {
    width: '100%',
  },

  orderList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.sizings.small,
  },

  orderListLandscape: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: theme.sizings.small,
  },

  orderColumn: {
    width: '100%',
    flexGrow: 1,
  },
});

export default OrdersList;
