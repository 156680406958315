import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';

import {
  ScrollView, StyleSheet, View, TouchableHighlight, useWindowDimensions,
} from 'react-native';
import useTranslation from 'common/contexts/translations';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FontAwesome5 } from '@expo/vector-icons';
import { isMobileMode } from 'common/utils/desktopMode';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner.native';
import useBeachVisualizer from './../../contexts/beachVisualizer';
import ToggleMapButton from 'maps/components/ToggleMapButton/ToggleMapButton';


const MapsSwitch = ({
  maps,
  mapId,
  mapSeatCounts,
  setMapId,
  printMap,
  isPrinting,
  setIsPrinting,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const { width, height } = useWindowDimensions();

  const selectMap = useCallback((map) => {
    setMapId(map.id);
  }, [setMapId]);

  return (
    <View style={styles.superContainer}>
    <ScrollView style={styles.container} horizontal alignContent="center" justifyContent="center">
      <View style={styles.mapsBar}>
        <View style={styles.mapsBarInner}>
          {maps?.map((map) => map.active && (
          <TouchableHighlight
            onPress={() => {
              selectMap(map);
            }}
            style={[styles.mapSelectorButton, mapId === map.id && {
              backgroundColor: theme.colors.whiteAlpha,
            }]}
            key={`map-${map.id}`}
          >
            <View style={{flexDirection: 'row'}}>
              <TranslatedText size="medium" value={map.name} color="light" />
              {mapSeatCounts && mapSeatCounts[map.id] > 0 && (
                <View style={styles.badge}>
                  <Text
                    color="black"
                    size="small"
                    isBold
                    centered
                  >
                    {mapSeatCounts[map.id]}
                  </Text>
                </View>
              )}
            </View>
          </TouchableHighlight>
          ))}
        </View>
      </View>

    </ScrollView>

    <TouchableOpacity
      style={styles.buttonToggle}
      onPress={() => {
        setIsPrinting(true);
        printMap();
      }}
    >
      <LoadingSpinner
        visible={isPrinting}
        theme={theme} 
      />
      <FontAwesome5
        name={"file-download"}
        color={theme.colors.white}
        size={theme.fontSizes.large * 1.2}
      />
    </TouchableOpacity>

    {!isMobileMode({ width, height }) && (
      <ToggleMapButton />
    )}
    </View>
  );
};

MapsSwitch.propTypes = {
  maps: PropTypes.array,
  mapId: PropTypes.number.isRequired,
  setMapId: PropTypes.func.isRequired,
  mapSeatCounts: PropTypes.any,
  printMap: PropTypes.func,
  isPrinting: PropTypes.bool,
  setIsPrinting: PropTypes.func,
};

MapsSwitch.defaultProps = {
  maps: [],
  mapSeatCounts: null,      
  isPrinting: false,
  setIsPrinting: null,
};

const makeStyles = (theme) => StyleSheet.create({
  superContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: theme.colors.darkBlue,
  },
  container: {
    //backgroundColor: theme.colors.darkBlue,
    zIndex: 10,
    width: '100%',
    height: theme.normalize(55),
    maxHeight: theme.normalize(55),
    flex: 1
  },
  mapsBar: {
    width: '100%',
    height: '100%',
    paddingRight: theme.sizings.medium,
    flex: 1,
  },
  mapsBarInner: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: theme.sizings.small,
  },
  tabSelectorButton: {
    backgroundColor: theme.colors.light,
    marginHorizontal: theme.sizings.tiny,
    marginVertical: theme.sizings.tiny,
    borderRadius: theme.radius.small,
    paddingHorizontal: theme.sizings.medium,
    justifyContent: 'center',
  },
  mapSelectorButton: {
    backgroundColor: 'transparent',
    marginHorizontal: theme.sizings.tiny,
    marginVertical: theme.sizings.tiny,
    borderRadius: theme.radius.rounded,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.tiny,
    justifyContent: 'center',
  },
  badge: {
    backgroundColor: theme.colors.secondary,
    borderRadius: theme.radius.rounded,
    width: theme.sizings.mediumLarge,
    height: theme.sizings.mediumLarge,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.sizings.small
  },
  buttonToggle: {
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
    cursor: 'pointer'
  },
});

export default MapsSwitch;
