import React, {
  createContext, useCallback, useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';
import dayjs from 'dayjs';
import fetchJSON from 'common/utils/fetchJSON';

const EventsContext = createContext();

export const EventsProvider = ({ children }) => (
  <ContextProvider url="events" context={EventsContext}>
    {children}
  </ContextProvider>
);

EventsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useEvent = () => {
  const context = useContext(EventsContext);
  const { fetchItems } = context;
  /* pagination */
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const fetchEvents = useCallback(async ({ pageParam = page, pageSizeParam = pageSize, filtersParam } = {}) => {
    try {
      const res = await fetchItems({ pageParam, pageSizeParam, filtersParam });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, [fetchItems, page, pageSize]);

  const fetchEventsByDay = useCallback(async (date = null) => {
    try {
      const filters = {
        active: true,
        endDate_gte: dayjs(date).format('YYYY-MM-DD'),
        startDate_lte: dayjs(date).format('YYYY-MM-DD'),
      };

      const esc = encodeURIComponent;
      const queryParams = Object.keys(filters)
        .map((key) => {
          if (Array.isArray(filters[key])) {
            return filters[key].map((p) => `${esc(key)}=${esc(p)}`).join('&');
          }
          return `${esc(key)}=${esc(filters[key])}`;
        })
        .join('&');

      const res = await fetchJSON({
        url: `events${queryParams ? '?' : ''}${queryParams}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, []);

  return {
    ...context, fetchEvents, setPage, setPageSize, fetchEventsByDay,
  };
};

export default useEvent;
