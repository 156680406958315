import { StyleSheet } from 'react-native';

export default (variant, isFullwidth, noPadding, disabled, theme, small) => StyleSheet.create({
  button: {
    backgroundColor: disabled ? theme.colors.greyDarker : (
      variant === 'transparent' ? 'transparent' : (
        variant === 'light' ? 'rgba(255, 255, 255, 0.32)' : theme.colors[variant])),
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : (small ? theme.sizings.tiny : theme.sizings.small),
    paddingHorizontal: noPadding ? 0 : (small ? theme.sizings.tiny : theme.sizings.large),
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconButton: {
    backgroundColor: disabled ? theme.colors.greyDarker : (
      variant === 'transparent' ? 'transparent' : (
        variant === 'light' ? 'rgba(255, 255, 255, 0.32)' : theme.colors[variant])),
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : (small ? theme.sizings.tiny : theme.sizings.small),
    paddingHorizontal: noPadding ? 0 : (small ? theme.sizings.small : theme.sizings.large),
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconOnly: {
    width: theme.normalize(30),
    height: theme.normalize(30),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
    paddingVertical: noPadding ? 0 : (small ? theme.sizings.tiny : theme.sizings.small),
    paddingHorizontal: noPadding ? 0 : (small ? theme.sizings.small : theme.sizings.large),
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconWrapper: {
    marginRight: 0,
    height: small ? theme.sizings.tiny : theme.sizings.large,
    width: small ? theme.sizings.tiny : theme.sizings.large,
  },

});
