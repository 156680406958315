/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  View, StyleSheet, Dimensions, useWindowDimensions,
} from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Text from 'common/components/Text/Text';
import useTheme from 'providers/ThemeProvider';
import useTranslation from 'common/contexts/translations';
import isDesktopMode, { isHorizontalMode, isMobileMode } from 'common/utils/desktopMode';

const DateInput = ({
  value, onChange, label, multiple, mode, disabledPassedDays, lockedDates, initialDate,
  disabledDates, showDateList,
  ...rest
}) => {
  const { t } = useTranslation();
  const dates = multiple ? value || [] : [value] || [];
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { width, height } = useWindowDimensions();
  const isTablet = !isMobileMode({ width, height }) && !isDesktopMode({ width, height });
  const isHorizontal = isHorizontalMode({ width, height });

  const customDatesStyles = dates.map((date) => (
    { date: dayjs(date).format(), style: styles.selectedDay }
  ));

  const modifiersStyles = {
    selected: {
      borderRadius: 0,
      backgroundColor: '#009ad4',
    },
    today: {
      color: '#ffab41',
    },
    firstDate: {
      // borderRadius: '50% 0 0 50% !important',

    },
    lastDate: {
      // borderRadius: '0 50% 50% 0 !important',
    },

  };

  const getDaysBetweenDates = (startDate, endDate) => {
    let now = dayjs(startDate);
    const lastDay = dayjs(endDate);

    const dates = [];

    while (now.isSameOrBefore(lastDay)) {
      dates.push(now.toDate());
      now = now.add(1, 'day');
    }
    return dates;
  };

  const handleDayClick = (day, type) => {
    if (!day) { return }

    const dates = mode === 'multiple' ? value : [value];

    if (mode === 'multiple') {
      let days = [];
      const newDay = day ? dayjs(day).startOf('day').toDate() : null;

      if (Array.isArray(dates)) {
        days = dates.filter((d) => !dayjs(d).isSame(newDay));

        if (days.length === dates.length) {
          days = [...dates, newDay];
        }
        onChange(days);
      } else {
        onChange([newDay]);
      }
    }

    if (mode === 'single') {
      const newDay = day ? dayjs(day).startOf('day').toDate() : null;
      onChange([newDay]);
    }

    if (mode === 'range') {
      
        const newDay = day ? dayjs(day).startOf('day').toDate() : null;
        if (type === "START_DATE") {
          onChange([newDay]);
        }
        else if (type === "END_DATE") {
          const initialDates = dates[0];
          const firstDate = dayjs(initialDates[initialDates.length - 1]).startOf('day');
          const betweenAndNewDays = getDaysBetweenDates(firstDate, newDay);
          onChange(betweenAndNewDays);
        }
      
    }
  };

  let modifiers = {};

  if (Array.isArray(value) && value.length > 1) {
    modifiers = {
      firstDate: value[0],
      lastDate: value[value.length - 1],
    };
  }

  const customDisabledDate = (date) => {
    // if passed day
    if(disabledPassedDays && date.isBefore(dayjs().startOf('day'))){
      return true;
    }

    // by locked dates list
    if(Boolean(lockedDates.length)){
      const isLockedDate = lockedDates.find((lockedDate) => dayjs(lockedDate).isSame(dayjs(date), 'date'));
      if(isLockedDate){
        return true
      }
    }

    // by function
    if(disabledDates){
      return disabledDates(date);
    }
    
    return false

  }


  return (
    <View style={styles.containerStyle}>
      <Text color="primary" isBold style={styles.labelStyle}>{label}</Text>
      <View>
        <CalendarPicker
          allowRangeSelection={mode === 'range'}
          onDateChange={(day, type) => {handleDayClick(day,type)}}
          weekdays={t('common.weekdays').split(',')}
          months={t('common.months').split(',')}
          startFromMonday
          previousTitle={t('common.previous')}
          nextTitle={t('common.next')}
          selectedDayColor={theme.colors.dark}
          selectedDayStyle={styles.showSelectedDay}
          todayBackgroundColor={theme.colors.lightPurple}
          customDatesStyles={customDatesStyles}
          modifiersStyles={modifiersStyles}
          modifiers={modifiers}
          width={isTablet || isHorizontal
            ? theme.normalize(400)
            : Dimensions.get('window').width - theme.sizings.medium * 3}
          disabledDates={(date) => customDisabledDate(date)}
          initialDate={initialDate || []}
          {...rest}
        />
      </View>
      {showDateList && dates?.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
          .map((date, index) => (
        <Text
          style={styles.inputContainerStyle}
          key={`date-${index}`}
        >{dayjs(date).format('LL')}</Text>
      ))}
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  inputContainerStyle: {
    borderBottomColor: theme.colors.primary,
    borderBottomWidth: 1,
    alignSelf: 'stretch',
    paddingTop: theme.sizings.small,
    fontSize: theme.fontSizes.medium,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
    alignItems: 'center'
  },

  labelStyle: {
    textTransform: 'capitalize',
    color: theme.colors.primary,
    fontSize: theme.fontSizes.medium,
  },

  showSelectedDay: {
    width: theme.normalize(35),
    height: theme.normalize(35),
    backgroundColor: theme.colors.secondary
  },
});

DateInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  mode: PropTypes.string,
  disabledPassedDays: PropTypes.bool,
  lockedDates: PropTypes.array,
  disabledDates: PropTypes.func,
  initialDate: PropTypes.any,
  showDateList: PropTypes.bool,
};

DateInput.defaultProps = {
  value: [],
  label: '',
  multiple: false,
  mode: 'multiple',
  disabledPassedDays: false,
  initialDate: null,
  lockedDates: [],
  disabledDates: null,
  showDateList: true,
};

export default DateInput;
