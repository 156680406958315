import { useNavigation } from '@react-navigation/native';
import useAuth, { useLocalUser } from 'auth/contexts/auth';
import useBooking, { useLocalBooking } from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import Page from 'common/components/Page/Page';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { ScrollView, Text, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const HourSelect = ({ route }) => {
  const { setAlert } = useAlert();
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const { siteId } = route.params;

  const {
    save: saveBooking,
    update: updateBooking,
    item: booking,
    setItem: setBooking,
    checkCode,
  } = useBooking();

  const { localUser, setLocalUser } = useLocalUser();

  const { setLocalBookings } = useLocalBooking();

  const {
    item: site,
  } = useSite();

  const handleSubmit = (values) => {
    setLocalUser((prevUser) => (
      {
        ...prevUser,
        clientName: values.clientName,
        clientFirstName: values.clientFirstName,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        description: values.description,
      }
    ));
    const saveAndRedirect = async () => {
      console.log({ booking, values });
      try {
        booking.dates = booking.dates.map((date) => dayjs(date).format('YYYY-MM-DD'));
        booking.booking_items = booking.booking_items.map((item) => ({
          ...item,
          date: dayjs(item.date).format('YYYY-MM-DD'),
        }));
        const payload = {
          ...booking,
          description: values.description,
          active: true,
          valid: Boolean(site?.autoValidation),
          user: -1,
          clientName: values.clientName,
          clientFirstName: values.clientFirstName,
          clientPhone: values.clientPhone,
          clientEmail: values.clientEmail,
          created_by_customer: true,
          bookingType: site?.bookingsWithSeats ? 'withSeats' : 'withoutSeats',
        };

        console.log({ payload });
        let newBooking = null;

        if (booking?.id) {
          payload.payment = booking.payment.id;
          newBooking = await updateBooking(booking.id, payload);
        } else {
          payload.payment = {
            online: site?.canPayOnline,
            offline_method: 'CASH',
            transactionFees: site?.canPayOnline ? site.onlineTransactionFees : 0,
            status: 'PENDING',
            site: siteId,
          };
          newBooking = await saveBooking(payload);
          setLocalBookings((oldBookings) => Array.isArray(oldBookings) ? [...oldBookings, newBooking] : [newBooking]);
        }

        setBooking(newBooking);

        // if there is no product flagged as complementary,
        // then there is no need to got to the complementary page selection
        /* if (site.complementaryProducts.length > 0 && booking.dates?.length === 1) { //booking_items
          navigation.navigate('ComplementaryProducts', {
            bookingId: newBooking.id,
            seatId: newBooking.seats[0].id,
            paymentId: newBooking.payment.id,
            siteId: site.id,
          });
        } else */

        if (site?.canPayOnline) {
          navigation.navigate('PaymentRecap', { paymentId: newBooking.payment.id });
        } else {
          setAlert({ color: 'success', title: t('common.success'), message: t('bookings.createSuccess') });

          // console.log({ newBooking });
          if (dayjs().isSame(dayjs(newBooking?.booking_items?.[0]?.date), 'day')) {
            try {
              const codeReturn = await checkCode(newBooking.code);

              console.log('code :', { codeReturn });

              navigation.reset({
                index: 0,
                routes: [
                  { name: 'HomePage', params: {} },
                  { name: 'SiteDetail', params: { siteId: codeReturn.site.id, bookingId: codeReturn.id } },
                ],
              });
              return;
            } catch {
              setAlert({ color: 'error', title: t('common.error'), message: t('bookings.codeError') });
            }
          }

          if (user) {
            navigation.reset({
              index: 0,
              routes: [{
                name: 'HomePage',
              }, {
                name: 'ProfilePage',
              }],
            });
          } else {
            navigation.reset({
              index: 0,
              routes: [{
                name: 'HomePage',
              }],
            });
          }
        }
      } catch (error) {
        console.error(error);
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.createError') });
      }
    };
    // const anonym = Boolean(site.anonymousMode);

    saveAndRedirect();

    /* if (user || localUser || anonym) {
      saveAndRedirect(user || localUser);
    } else {
      navigation.navigate('SignUpPage', { callback: saveAndRedirect });
    } */
  };

  return (
    <Page padding>
      <Form
        onSubmit={handleSubmit}
        initialValues={localUser ? {
          clientName: localUser.clientName,
          clientFirstName: localUser.clientFirstName,
          clientPhone: localUser.clientPhone,
          description: localUser.description,
          clientEmail: localUser.clientEmail,
        } : {}}
        render={({ handleSubmit, values }) => (
          <>
            <ScrollView>
              {site.anonymousMode && (
                <>
                  <FormInput
                    name="clientName"
                    label={t('bookings.clientName')}
                    icon="user"
                    required
                  />

                  <FormInput
                    name="clientFirstName"
                    label={t('bookings.clientFirstName')}
                    icon="user"
                    required
                  />

                  <FormInput
                    name="clientPhone"
                    label={t('auth.phone')}
                    icon="phone"
                    type="text"
                    required={site?.phoneRequired}
                  />

                  <FormInput
                    name="clientEmail"
                    label={t('auth.email')}
                    icon="phone"
                    type="text"
                    required={site?.emailRequired}
                  />

                </>
              )}
              {/*
              <FormInput
                name="checkin"
                label={t('bookings.checkinTime')}
                type="time"
              />
              */}
              { site.canPayOnline === false && (
                <Text style={commonStyles.singleText}>{t('bookings.arrivalNotice')}</Text>
              )}
              <FormInput
                name="description"
                label={t('bookings.description')}
                type="text"
              />
              <View style={commonStyles.scrollViewWithButton} />
            </ScrollView>
            <View style={commonStyles.buttonWrapper}>
              <View style={commonStyles.buttonContainer}>
                <Button
                  variant="gradient"
                  icon="arrow-right"
                  text={t('orders.continue')}
                  onPress={handleSubmit}
                  disabled={
                    (site?.emailRequired && !values.clientEmail) || (site?.phoneRequired && !values.clientPhone)
                    || !values.clientName || !values.clientFirstName
                  }
                />
              </View>
            </View>
          </>
        )}
      />
    </Page>
  );
};

HourSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default HourSelect;
