/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback, useMemo,
} from 'react';
import {
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import PropTypes from 'prop-types';
import isDesktopMode, { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import FormInput from 'common/components/FormInput/FormInput';
import useSiteProvider from 'providers/SiteProvider';
import BookingContentSelector from '../../components/BookingContentSelector';

const ColumnContents = ({
  form,
  values,
  initialValues,
  currentDate,
  checkConflict,
  siteBookingItems,
  onUpdateContent,
  bookingItemIndex,
  bookingWithSeats,
  allowCurrentBookingItemEdition,
  bookingContent,
  updateAllBookingItems,
  bookingType,
  mapId,
}) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const isDesktop = isDesktopMode(useWindowDimensions());

  const styles = makeStyles(theme, isMobile, isDesktop);
  const { t } = useTranslation();

  const {
    countBookedSeats,
    totalSeats,
  } = useSiteProvider();

  const initialBookingContent = useMemo(() => (
    currentDate ? initialValues.booking_items.find((bi) => (
      dayjs(bi.date).isSame(currentDate, 'date')
    ))?.bookingContent : initialValues.bookingContent
  ), [currentDate, initialValues]);

  /* a mdifier  il faut juste filtrer les bookings par rapport
  aux dates pas besoin de faire un fetch car on les a déjà !!!
  // donc ici un useMemo qui dépend de currentDate
  // attention il faut filtrer les bookings aussi par rapport aux stayDuration

  // useMemo (de getCountBookiSeats(currentDate === null ? values.dates : [currentDate]))
  // Attention il manque dans le code de getCountBook deux choses
  // il ne faut pas compter le booking actuellement en edition dans les bookseats
  // 15 10 book (3 de ma reservation)   total - countBOook - currentBook
  // il faut tenir compte surement des stayDurations (si journée on les prend tous,
  si matin : matin/journée si après midi : aprèsmidi/journée)
  // 2 dispo sur 15 total
  // les out of service sont reservable pour le serveur les out of order non (dans le calcul du total seat aviaalbable)
  // il faudrait peut etre un calcul avevc ou sans out of service (genre 10 dispo + 2 out of service) */

  const handleChangeStayDuration = (index, stayDuration) => {
    const newBookingItem = {
      ...values.booking_items[index],
      stayDuration,
      override: true,
    };
    // re-Vérification des conflits

    checkConflict(
      {
        ...values,
        booking_items: [
          ...values.booking_items.filter((bi, iBi) => iBi !== index),
          newBookingItem,
        ],
      },
      siteBookingItems,
    );

    const field = `booking_items[${index}]`;

    form.mutators.setFieldValue({
      field,
      value: {
        ...values.booking_items[index],
        stayDuration,
        override: true,
      },
    });
  };

  const handleSelectFurniture = (priceArea, furniture, nb, mutators) => {
    let newBookingContent = [];

    let index = 0;

    let currentBookingContent;

    if (currentDate) {
      index = values.booking_items.findIndex((bi) => dayjs(bi.date).isSame(currentDate, 'date'));
      currentBookingContent = values.booking_items[index].bookingContent;
    } else {
      currentBookingContent = values.bookingContent;
    }

    let found = false;

    if (currentBookingContent?.length > 0) {
      newBookingContent = currentBookingContent.map((bc) => {
        if (bc.furniture === furniture.id && bc.price_area === priceArea.id) {
          found = true;
          const newBc = { ...bc };

          newBc.amount = bc.amount + nb > 0 ? bc.amount + nb : 0;
          return newBc;
        }
        return bc;
      });
    }
    if (!found) {
      newBookingContent.push({ furniture: furniture.id, price_area: priceArea.id, amount: nb > 0 ? nb : 0 });
    }

    if (currentDate) {
      const field = `booking_items[${index}]`;

      mutators.setFieldValue({
        field,
        value: {
          ...values.booking_items[index],
          bookingContent: newBookingContent,
          override: true,
        },
      });
    } else {
      mutators.setFieldValue({ field: 'bookingContent', value: newBookingContent });
      onUpdateContent(newBookingContent, values, form);
    }
  };

  const setContentMode = useCallback(() => {
    if (bookingItemIndex !== -1) {
      const field = `booking_items[${bookingItemIndex}]`;

      const newBookingContent = initialBookingContent
        ? [...initialBookingContent]
        : [];

      form.mutators.setFieldValue({
        field,
        value: {
          ...values.booking_items[bookingItemIndex],
          bookingContent: newBookingContent,
          seats: [],
          bookingType: 'withoutSeats',
          override: true,
        },
      });
    } else {
      let field = 'seats';

      form.mutators.setFieldValue({
        field,
        value: [],
      });

      field = 'bookingType';
      form.mutators.setFieldValue({
        field,
        value: 'withoutSeats',
      });

      field = 'bookingContent';
      const newBookingContent = [...initialValues.bookingContent ?? []];

      form.mutators.setFieldValue({
        field,
        value: newBookingContent,
      });

      const newBookingItems = updateAllBookingItems([], newBookingContent, values, form);

      form.mutators.setFieldValue({ field: 'booking_items', value: newBookingItems });
    }
  }, [bookingItemIndex, form, initialBookingContent, initialValues, updateAllBookingItems, values]);

  const bookedSeatsCount = useMemo(() => (
    countBookedSeats(
      currentDate
        ? [currentDate]
        : values.dates,
      siteBookingItems,
      values.stayDuration,
      values,
      mapId,
    )
  ), [countBookedSeats, currentDate, values, siteBookingItems, mapId]);

  return (
    <>
      {values.booking_items?.length > 1 && !isMobile && (
      <Text
        isBold
        color={bookingItemIndex !== -1 ? 'purple' : 'dark'}
        centered
        style={{
          marginTop: theme.sizings.mediumLarge,
          fontSize: theme.fontSizes.medium,
        }}
      >
        {bookingItemIndex !== -1 ? dayjs(values.booking_items[bookingItemIndex].date).format('LL')
          : t('bookings.genericDates')}
      </Text>
      )}

      <ScrollViewLarge>

        {bookingItemIndex !== -1
                && allowCurrentBookingItemEdition
                && (
                <View style={{ width: '100%' }}>

                  <FormInput
                    type="buttongroup"
                    name={`booking_items[${bookingItemIndex}].stayDuration`}
                    onChange={(val) => { handleChangeStayDuration(bookingItemIndex, val); }}
                    options={[{
                      value: 'fullday',
                      text: t('bookings.fullday'),
                    }, {
                      value: 'morning',
                      text: t('bookings.morning'),
                    }, {
                      value: 'afternoon',
                      text: t('bookings.afternoon'),
                    }]}
                    required
                  />
                </View>
                )}

        <BookingContentSelector
          values={values}
          currentDate={currentDate}
          selectFurniture={handleSelectFurniture}
          bookedSeatsCount={bookedSeatsCount}
          bookingContent={bookingContent}
          initialBookingContent={initialBookingContent}
          bookingType={bookingType}
          bookingItemIndex={bookingItemIndex}
          seats={totalSeats}
          form={form}
          limitToCurrentMapId={mapId}
        />

        {bookingWithSeats && (
        <View style={styles.cancelPositions}>
          <Button
            variant="gradient"
            icon="chair"
            iconFamily="fontawsome5"
            text={t('bookings.cancelPositionedSeats')}
            onPress={setContentMode}
            disabled={!allowCurrentBookingItemEdition}
            small
          />
        </View>
        )}
      </ScrollViewLarge>
    </>
  );
};

export default ColumnContents;

ColumnContents.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  currentDate: PropTypes.any.isRequired,
  checkConflict: PropTypes.func.isRequired,
  siteBookingItems: PropTypes.array.isRequired,
  onUpdateContent: PropTypes.func.isRequired,
  bookingItemIndex: PropTypes.number.isRequired,
  bookingWithSeats: PropTypes.any.isRequired,
  allowCurrentBookingItemEdition: PropTypes.bool.isRequired,
  bookingContent: PropTypes.array.isRequired,
  updateAllBookingItems: PropTypes.func.isRequired,
  bookingType: PropTypes.string.isRequired,
  mapId: PropTypes.number.isRequired,
};

ColumnContents.defaultProps = {
};

const makeStyles = (theme, isMobile, isDesktop) => StyleSheet.create({
  cancelPositions: {
    width: '100%',
    paddingVertical: theme.sizings.small,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.greyLightest,
    position: isDesktop ? 'absolute' : 'relative',
    bottom: 0,
    borderRadius: theme.radius.medium,
  },
});
