import CardBG from 'assets/card.png';
import useAuth from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import { ImageBackground } from 'common/components/Images';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import fetchJSON from 'common/utils/fetchJSON';
import mangoPay from 'mangopay-cardregistration-js-kit/kit/mangopay-kit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Platform, StyleSheet, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Constants from 'expo-constants';

const makeStyles = (theme) => StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.large,
  },

  cardContainer: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,

  },

  cardBG: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  image: {
    borderRadius: theme.radius.medium,
  },

  inputWrapper: {
    marginVertical: 0,
  },

  inputContainer: {
    borderBottomColor: theme.colors.light,
  },

  input: {
    color: theme.colors.light,
    marginTop: theme.sizings.tiny,
    fontSize: theme.sizings.medium,
  },

  formHorizontal: {
    width: Platform.OS === 'ios' ? theme.normalize(200)
      : Platform.OS === 'web' ? theme.normalize(180) : theme.normalize(170),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  label: {
    color: theme.colors.light,
    display: 'none',
  },

  submit: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: theme.normalize(-140),
  },
});

const CreditCardForm = ({ route, navigation }) => {
  const { callback } = route.params;
  const [cardRegistration, setCardRegistration] = useState({});
  const { updateUser } = useAuth();
  const { setAlert } = useAlert();

  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  useEffect(() => {
    mangoPay.cardRegistration.baseURL = Constants.expoConfig.extra.MANGOPAY_URL;
    mangoPay.cardRegistration.clientId = Constants.expoConfig.extra.MANGOPAY_CLIENT_ID;

    fetchJSON({
      url: 'users/card-registration',
      method: 'POST',
      payload: {},
    }).then((result) => {
      setCardRegistration(result);
    });
  }, []);

  const onSubmit = useCallback(
    (values) => {
      const cardData = {
        cardNumber: values.cardNumber,
        cardExpirationDate: values.cardExpirationDate,
        cardCvx: values.cardCvx,
        cardType: 'CB_VISA_MASTERCARD',
      };

      mangoPay.cardRegistration.init({
        cardRegistrationURL: cardRegistration.CardRegistrationURL,
        preregistrationData: cardRegistration.PreregistrationData,
        accessKey: cardRegistration.AccessKey,
        Id: cardRegistration.Id,
      });

      mangoPay.cardRegistration.registerCard(
        cardData,
        async (res) => {
          await fetchJSON({
            url: 'users/save-card',
            method: 'POST',
            payload: res,
          });

          await updateUser({});

          navigation.pop(1);

          if (callback) {
            callback();
          }
        },
        (error) => {
          console.log({ error });
          setAlert({
            color: 'error',
            title: t('common.error'),
            message: t('auth.badCredentials'),
          });
        },
      );
    },
    [updateUser, setAlert, callback, cardRegistration, navigation, t],
  );

  return (
    <View style={styles.wrapper}>
      <View style={styles.cardContainer}>
        <ImageBackground image={CardBG} style={styles.cardBG} imageStyle={styles.image} />
        <Form
          onSubmit={onSubmit}
          initialValues={{
            cardHolder: 'Patrick Chirac', cardNumber: '4972485830400049', cardCvx: '123', cardExpirationDate: '1020',
          }}
          render={({ handleSubmit, submitting }) => (
            <>
              <FormInput
                name="cardHolder"
                placeholder={t('creditCard.cardHolder')}
                containerStyle={styles.inputWrapper}
                inputContainerStyle={styles.inputContainer}
                inputStyle={styles.input}
              />
              <FormInput
                type="number"
                name="cardNumber"
                placeholder={t('creditCard.cardNumber')}
                containerStyle={styles.inputWrapper}
                inputContainerStyle={styles.inputContainer}
                inputStyle={styles.input}
              />
              <View style={styles.formHorizontal}>
                <FormInput
                  type="number"
                  name="cardExpirationDate"
                  placeholder={t('creditCard.expirationDate')}
                  containerStyle={styles.inputWrapper}
                  inputContainerStyle={styles.inputContainer}
                  inputStyle={styles.input}
                />
                <FormInput
                  type="number"
                  name="cardCvx"
                  placeholder={t('creditCard.ccv')}
                  containerStyle={styles.inputWrapper}
                  inputContainerStyle={styles.inputContainer}
                  inputStyle={styles.input}
                />
              </View>
              <View style={styles.submit}>
                <Button
                  data-cy="cypress_pay_card"
                  variant="gradient"
                  icon="credit-card"
                  text={t('common.submit')}
                  onPress={handleSubmit}
                  disabled={submitting}
                  type="submit"
                />
              </View>
            </>
          )}
        />
      </View>

      <Text>{t('creditCard.disclaimer')}</Text>

    </View>
  );
};

CreditCardForm.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default CreditCardForm;
