/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback, useMemo,
} from 'react';
import {
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import ExpectedSeatsResume from 'waiter/components/booking-detail-sections/ExpectedSeatsResume';
import StayDuration from 'waiter/components/booking-detail-sections/StayDuration';
import SeatsResume from 'waiter/components/booking-detail-sections/SeatsResume';
import Room from 'waiter/components/booking-detail-sections/Room';
import PropTypes from 'prop-types';
import OrderInProgress from 'waiter/components/booking-detail-sections/OrderInProgress';
import QRCode from 'react-native-qrcode-svg';
import baseUrl from 'common/utils/getBaseUrl';
import { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

const Introduction = ({ completeBooking, currentBookingItem }) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const styles = makeStyles(theme, isMobile);

  const ordersLength = useMemo(() => (
    currentBookingItem?.orders?.find((order) => order.state === 'created' && order.active)?.length
  ), [currentBookingItem]);

  const goEditBooking = useCallback(() => {
    navigate(
      'BookingEdit',
      { editedBooking: completeBooking },
    );
  }, [completeBooking, navigate]);

  return (
    <>
      {/* bloc 1 */}
      <View style={styles.bookingItemInfo}>
        {/* bloc 1 left */}
        <View style={styles.clientInfo}>
          <Text isBold size="large">
            {`${completeBooking.customer?.name
              ? `${completeBooking.customer.civility
                ? `${t(`customer.civility${completeBooking.customer.civility}`)} `
                : ''}${completeBooking?.customer.name} ${completeBooking?.customer.firstName || ''}`
              : `${completeBooking?.clientName} ${completeBooking?.clientFirstName || ''}`}`}
          </Text>

          <Text isBold size="large">
            {`${completeBooking?.created_by_customer ? ` ${t('bookings.customerIsAuthor')}` : ''} - ${completeBooking?.code}`}
          </Text>

          {completeBooking?.comments && (
            <Text size="small" style={{ marginBottom: completeBooking?.waiterAuthor ? 0 : theme.sizings.small }}>
              {completeBooking.comments}
            </Text>
          )}

          {completeBooking?.waiterAuthor && (
            <Text size="small" style={{ marginBottom: theme.sizings.small }}>
              {`${t('bookings.waiterAuthor')}: ${completeBooking.waiterAuthor}`}
            </Text>
          )}

          <StayDuration stayDuration={currentBookingItem?.stayDuration} />

          {Boolean(ordersLength) && (
            <OrderInProgress ordersLength={completeBooking?.orders.length} />
          )}

          {completeBooking?.hotel && (
            <Room roomID={completeBooking.roomID} customerCame={currentBookingItem?.status === 'checked_in'} />
          )}
          {Boolean(completeBooking?.seats?.length) && (
            <SeatsResume seats={currentBookingItem?.seats} />
          )}

          {completeBooking
          && !currentBookingItem?.isFullyPlaced
          && Boolean(completeBooking?.bookingContent?.length) && (
            <ExpectedSeatsResume
              expectedSeats={currentBookingItem?.bookingContent}
              customerCame={currentBookingItem?.status === 'checked_in'}
            />
          )}
        </View>

        {/* bloc 1 right */}

        {completeBooking && (
          <View style={{ alignItems: isMobile ? 'center' : 'flex-end' }}>
            <Button
              variant="light"
              icon="pencil"
              text={t('bookings.edit')}
              onPress={goEditBooking}
              iconColor="primary"
              style={{ marginBottom: theme.sizings.smallMedium }}
              small
            />
            <QRCode
              size={100}
              value={`${baseUrl}?code=${completeBooking?.code}`}
            />
          </View>
        )}

      </View>
    </>
  );
};

export default Introduction;

Introduction.propTypes = {
  completeBooking: PropTypes.object.isRequired,
  currentBookingItem: PropTypes.object.isRequired,
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  splitPage: {
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    height: '100%',
  },
  mainPart: {
    flex: isMobile ? undefined : 1,
  },
  customerPart: {
    width: isMobile ? '100%' : '35%',
    maxWidth: isMobile ? undefined : '35%',
    backgroundColor: theme.colors.white,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: {
      width: -2,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 1,
  },
  clientInfo: {
    marginBottom: theme.sizings.medium,
  },

  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingItemInfo: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: isMobile ? 'column' : 'row',
  },
  bookingItemSituation: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  bookingFullPeriod: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  bookingActions: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',

  },

  detailOrders: {
    padding: theme.sizings.medium,
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginVertical: theme.sizings.medium,
  },

  call: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: theme.sizings.small,
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrdersTitle: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: theme.sizings.medium,
    flexDirection: isMobile ? 'column' : 'row',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addOrderLabel: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
  switchLabelIcon: {
    marginRight: theme.sizings.small,
    opacity: 0.6,
  },
  switchBooleanBox: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    borderRadius: theme.radius.rounded,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonBar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? theme.sizings.medium : undefined,
  },
  buttonOrder: {
    marginLeft: theme.sizings.medium,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: theme.sizings.tiny,
  },
  buttonEditItem: {
    height: theme.normalize(38),
    width: theme.normalize(38),
  },
  bookingItemRow: {
    marginTop: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    borderTopColor: theme.colors.greyLighter,
    borderTopWidth: 1,
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'space-between',
  },
  dirRow: {
    flexDirection: 'row',
  },
  dirColumn: {
    flexDirection: 'column',
  },
});
