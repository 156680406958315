import { FontAwesome } from '@expo/vector-icons';
import Modal from 'common/components/Modal/Modal';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useOrders from 'orders/contexts/orders';
import usePayment from 'payments/contexts/payments';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  StyleSheet, TouchableHighlight, TouchableOpacity, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useSiteProvider from 'providers/SiteProvider';
import ModalContent from './ModalContent';

const PaymentState = ({ payment }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const {
    update,
  } = usePayment();
  const { setAlert } = useAlert();
  const { refreshOrders } = useOrders();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);
  const { site } = useSiteProvider();

  const handleUpdatePayment = useCallback(async (payload) => {
    try {
      await update(payment.id, { ...payload, site });

      refreshOrders();
    } catch {
      setAlert({ color: 'error', title: t('common.succes'), message: t('orders.errorUpdate') });
    } finally {
      setModalVisible(false);
    }
  }, [payment.id, setAlert, t, update, refreshOrders, site]);

  return (
    <View>
      <TouchableHighlight
        onPress={() => setModalVisible(!payment.online)}
      >
        <View style={styles.wrapper}>
          <FontAwesome
            name={payment.online ? 'wifi' : 'handshake-o'}
            color={theme.colors.greyDarkest}
            size={theme.sizings.mediumLarge}
            style={styles.paymentIcon}
          />
          <FontAwesome
            name={payment.status === 'PENDING' ? 'clock-o' : 'check'}
            color={payment.status === 'PENDING' ? theme.colors.warning : theme.colors.success}
            size={theme.sizings.mediumLarge}
            style={styles.paymentIcon}
          />
          {payment.online === false && (
            <FontAwesome
              name={payment.offline_method === 'CB' ? 'credit-card' : 'money'}
              color={theme.colors.greyDarkest}
              size={theme.sizings.mediumLarge}
              style={styles.paymentIcon}
            />
          )}
        </View>
      </TouchableHighlight>
      <Modal
        style={{ margin: 10 }}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        <View style={commonStyles.modalInner}>
          <TouchableOpacity
            onPress={() => setModalVisible(false)}
            style={commonStyles.modalMask}
          />
          <ModalContent
            payment={payment}
            onSubmit={handleUpdatePayment}
          />
        </View>
      </Modal>
    </View>
  );
};

PaymentState.propTypes = {
  payment: PropTypes.object.isRequired,
};

const makeStyles = (theme) => StyleSheet.create({
  paymentIcon: {
    margin: 5,
  },
  iconText: {
    padding: 6,
    margin: 10,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 0,
    paddingHorizontal: theme.sizings.smallMedium,
    borderColor: 'rgba(0,0,0,0.1)',
    borderWidth: 1,
    borderRadius: theme.radius.medium,
  },
});

export default PaymentState;
