import { StyleSheet } from 'react-native';

export default (active, theme) => StyleSheet.create({
  header: {
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },

  title: {
    paddingRight: theme.sizings.small,
  },

  content: {
    paddingHorizontal: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    paddingBottom: theme.sizings.tiny,
  },

  metas: {
    flexDirection: 'row',
    marginBottom: theme.sizings.medium,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  distance: {
    flexDirection: 'row',
  },

  distanceText: {
    marginLeft: theme.sizings.small,
  },

  description: {
    flex: 2.25,
  },

  state: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },

  stateText: {
    marginRight: theme.sizings.small,
  },

  stateIcon: {
    width: theme.fontSizes.tiny,
    height: theme.fontSizes.tiny,
    borderRadius: theme.radius.rounded,
    backgroundColor: active ? theme.colors.success : theme.colors.danger,
  },
});
