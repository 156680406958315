import React from 'react';
import { StyleSheet } from 'react-native';
import Text from 'common/components/Text/Text';
import useSite from 'sites/contexts/sites';

const SiteHeaderTitle = () => {
  const { item: site } = useSite();

  return (
    <Text adjustsFontSizeToFit style={styles.title} isBold size="larger" color="light">{site?.name}</Text>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: 'MontserratBold',
  },
});

export default SiteHeaderTitle;
