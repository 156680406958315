import React, {
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';

const CustomersContext = createContext();

export const CustomersProvider = ({ children }) => (
  <ContextProvider url="customers" context={CustomersContext}>
    {children}
  </ContextProvider>
);

CustomersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCustomers = () => {
  const {
    ...rest
  } = useContext(CustomersContext);

  return {
    ...rest,
  };
};

export default useCustomers;
