import React, { useCallback, useState } from 'react';
import {
  View, StyleSheet, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import { useAuth } from 'auth/contexts/auth';
import useBasket from 'basket/contexts/basket';
import BasketItem from 'basket/components/BasketItem/BasketItem';
import useAlert from 'common/contexts/alert';
import Text from 'common/components/Text/Text';
import Page from 'common/components/Page/Page';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useOrder from 'orders/contexts/orders';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import formatPrice from 'common/utils/formatPrice';

const ComplementaryProducts = ({ route }) => {
  const { items: basket, setItems: setBasket } = useBasket();
  const { user } = useAuth();
  const {
    paymentId,
    siteId,
    bookingId,
    seatId,
  } = route.params;
  const navigation = useNavigation();
  const {
    save: saveOrder, update: updateOrder, remove: removeOrder, isFetching: isOrderFetching,
  } = useOrder();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const [order, setOrder] = useState(null);
  const { item: site } = useSite();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  let total = 0;

  const saveAndRedirect = useCallback(async () => {
    try {
      let res = null;
      const payload = {
        payment: paymentId,
        order_items: basket,
        price: total,
        site: siteId,
        user,
        booking: bookingId,
        seat: seatId,
        active: true,
      };

      if (order) {
        res = await updateOrder(order.id, payload);
      } else {
        res = await saveOrder(payload);
      }
      setOrder(res);
      setBasket(res.order_items);
      navigation.navigate('PaymentRecap', { paymentId });
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [basket, bookingId, navigation, order, paymentId, saveOrder, seatId,
    setAlert, setBasket, siteId, t, total, updateOrder, user]);

  const skipProducts = useCallback(async () => {
    if (order) {
      await removeOrder(order.id);
      setOrder(null);
    }
    navigation.navigate('PaymentRecap', { paymentId });
  }, [navigation, order, paymentId, removeOrder]);

  return (
    <Page>
      <LoadingSpinner
        visible={isOrderFetching}
        theme={theme}
      />
      <ScrollView style={styles.pageContent}>
        <Text size="large">{t('basket.complementaryProductSelect')}</Text>
        <View style={styles.items}>
          {/* TODO fix options */}
          {console.log(site.complementaryProducts)}
          {site.complementaryProducts && site.complementaryProducts.map((product, index) => {
            if (product.option === null || product.variant_prices.length === 0) {
              const productItem = basket.find((p) => p.product.id === product.id);

              total += productItem ? Math.round(productItem.price * productItem.quantity * 100) / 100 : 0;
              return (
                <BasketItem
                  key={index}
                  entity={productItem.product}
                  entityType="product"
                  number={productItem ? productItem.quantity : 0}
                  price={product.price}
                  editable
                />
              );
            }
            return (
              <View key={index}>
                {product.variant_prices.filter(({ price }) => price !== null).map((variant, index2) => {
                  const productItem = basket.find((p) => (
                    p.product.id === product.id && variant.option_variant.id === p.option_variant.id));

                  total += Math.round(productItem ? ((productItem.price * productItem.quantity * 100) / 100) : 0);
                  return (
                    <BasketItem
                      key={`${index}_${index2}`}
                      variant={variant.option_variant}
                      price={variant.price}
                      entity={product}
                      entityType="product"
                      number={productItem ? productItem.quantity : 0}
                      editable
                    />
                  );
                })}
              </View>
            );
          })}
        </View>
        <View style={commonStyles.scrollViewWithButton} />
      </ScrollView>
      <View style={commonStyles.buttonWrapper}>
        <View style={commonStyles.buttonContainer}>
          <Button
            variant={basket.length ? 'gradient' : 'primary'}
            text={basket.length ? t('basket.addTotal', { total: formatPrice(total, site?.currency) })
              : t('common.skip')}
            icon={basket.length ? 'credit-card' : ''}
            onPress={basket.length ? saveAndRedirect : skipProducts}
            disabled={isOrderFetching}
          />
        </View>
      </View>
    </Page>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  items: {
    marginTop: theme.sizings.medium,
  },
  pageContent: {
    paddingHorizontal: theme.sizings.small,
  },
});

ComplementaryProducts.propTypes = {
  route: PropTypes.object.isRequired,
};

export default ComplementaryProducts;
