import getFurniturePrice from './getFurniturePrice';
import getActiveSeason from './getActiveSeason';

const getAreaPrices = (
  area, stayDuration, hotel, date, seasons,
) => {
  const activeSeason = getActiveSeason(date, seasons);

  const furnitureIds = [...new Set(area.prices.map((p) => p.furniture))];
  const prices = furnitureIds.map((furnitureId) => {
    const price = getFurniturePrice({
      area, furnitureId, stayDuration, hotel, season: activeSeason,
    });

    return { furnitureId, price };
  });

  return prices;
};

export default getAreaPrices;
