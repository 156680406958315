import ItemCard from 'common/components/ItemCard/ItemCard';
import NavigationButton from 'common/components/NavigationButton/NavigationButton';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import makeCommonStyles from 'styles/commonStyles';
import useTheme from 'providers/ThemeProvider';
import styles from './MapListItemStyle';

const MapListItem = ({
  navigation,
  name,
  id,
  active,
  backgroundImage,
  siteId,
  edit,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const handlePress = () => {
    navigation.navigate('SeatSelector', { siteId, mapId: id, edit });
  };

  return (
    <View style={styles(active, theme).siteItem}>
      <View style={styles(active, theme).header}>
        <TranslatedText style={styles(active, theme).title} isBold size="large" value={name} />
      </View>
      <ItemCard onPress={handlePress} image={backgroundImage}>
        <View style={commonStyles.cardButtonWrapper}>
          <NavigationButton
            title={t('beach.book')}
            link="SeatSelector"
            icon="eye"
            params={{ siteId, mapId: id, edit }}
            variant="gradient"
          />
        </View>
      </ItemCard>
    </View>
  );
};

MapListItem.propTypes = {
  name: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  backgroundImage: PropTypes.object,
  id: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
};

MapListItem.defaultProps = {
  backgroundImage: {},
  edit: false,
};

export default MapListItem;
