import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';

import { FontAwesome } from '@expo/vector-icons';

const ExpectedSeatsResume = ({
  expectedSeats,
  customerCame,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  return Boolean(expectedSeats?.length) && (
    <View style={{ flexDirection: 'row' }}>
      <FontAwesome
        name="flag"
        color={theme.colors.dangerBright}
        size={theme.sizings.medium}
        style={styles.icon}
      />
      <View style={{ flex: 1, paddingLeft: theme.sizings.tiny, paddingRight: theme.sizings.small }}>
        {expectedSeats.map((seat) => (
          <View
            key={seat.id}
            style={{
              flexDirection: 'row', flexWrap: 'wrap', flexGrow: 1, width: '100%',
            }}
          >
            <Text color={customerCame ? 'dark' : 'greyDarkest'} size="small">
              {`${seat.amount} `}
            </Text>
            <TranslatedText
              value={seat.furniture.name}
              color={customerCame ? 'dark' : 'greyDarkest'}
              size="small"
            />
            <Text color={customerCame ? 'dark' : 'greyDarkest'} size="small">
              {`, ${seat.price_area.name}`}
            </Text>
          </View>

        ))}
      </View>
    </View>
  );
};

ExpectedSeatsResume.propTypes = {
  expectedSeats: PropTypes.array,
  customerCame: PropTypes.bool,
};

ExpectedSeatsResume.defaultProps = {
  expectedSeats: [],
  customerCame: false,
};

const makeStyles = (theme) => StyleSheet.create({
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
});

export default ExpectedSeatsResume;
