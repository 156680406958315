import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { FontAwesome5 } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';

const Room = ({
  customerCame, roomID,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  return (
    <View style={styles.iconText}>
      <FontAwesome5
        name="bed"
        color={theme.colors.greyAlpha}
        size={theme.sizings.smallMedium}
        style={styles.icon}
      />
      <Text
        size="small"
        color={customerCame ? 'dark' : 'greyDarkest'}
        noLineHeight
        style={{ fontStyle: 'italic' }}
      >
        {t('common.roomId', { id: roomID })}
      </Text>
    </View>
  );
};

Room.propTypes = {
  roomID: PropTypes.string,
  customerCame: PropTypes.bool,
};

Room.defaultProps = {
  roomID: null,
  customerCame: false,
};

const makeStyles = (theme) => StyleSheet.create({
  iconText: {
    flexDirection: 'row',
  },
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
});

export default Room;
