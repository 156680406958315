import {
  FontAwesome, FontAwesome5, MaterialIcons,
} from '@expo/vector-icons';
import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import styles from './Button.styles';

const Button = ({
  style, variant, text, textColor, isFullwidth, onPress, icon, iconFamily, iconColor, iconSize,
  noPadding, disabled, height, small, ...rest
}) => {
  const { lang } = useTranslation();
  const { theme } = useTheme();

  let textNode = <></>;

  if (text && text.length > 0 && text !== '') {
    textNode = (
      <>
        {variant === 'gradient' ? (
          <Text color={disabled ? 'greyDarkest' : 'light'} size={small ? 'medium' : 'large'} centered={!icon}>
            {text}
          </Text>
        ) : (
          <Text
            color={disabled ? 'greyDarkest'
              : (textColor || (['greyLighter', 'greyDarker', 'light', 'white'].find((option) => option === variant)
                ? 'dark' : 'light'))}
            size={small ? 'medium' : 'large'}
            centered={!icon}
          >
            {text}
          </Text>
        )}
      </>
    );
  }

  return (
    <>
      {icon ? (
        <TouchableOpacity
          type="submit"
          onPress={onPress}
          disabled={disabled || onPress === null}
          style={[
            (text && text.length > 0 && text !== '')
              ? styles(variant, isFullwidth, noPadding, disabled, theme, small).iconButton
              : styles(variant, isFullwidth, noPadding, disabled, theme, small).iconOnly,
            {
              borderStyle: variant === 'light' ? 'solid' : undefined,
              borderColor: variant === 'light' ? 'rgba(0,0,0,0.2)' : undefined,
              borderWidth: variant === 'light' ? theme.normalize(2) : undefined,
            },
            style,

          ]}
          hitSlop={{
            top: 10, bottom: 10, left: 10, right: 10,
          }}
          {...rest}
        >
          {!disabled && variant === 'gradient' && (
          <Gradient height={small ? theme.normalize(46)
            : (height || theme.normalize(50))}
          />
          )}
          <View style={{ marginRight: (lang !== 'ar' && text) ? theme.sizings.small : 0 }}>
            {
              // a etendre en ajoutant chaque iconFamily
              (iconFamily.toLowerCase() === 'fontawsome5') && (
                <FontAwesome5
                  name={icon}
                  color={disabled ? theme.colors.greyDarkest : theme.colors[iconColor]}
                  size={theme.fontSizes[iconSize]}
                />
              )
            }
            {iconFamily.toLowerCase() === 'materialicons' && (
              <MaterialIcons
                name={icon}
                color={disabled ? theme.colors.greyDarkest : theme.colors[iconColor]}
                size={theme.fontSizes[iconSize]}
              />
            )}
            {iconFamily.toLowerCase() === 'fontawsome' && (
              <FontAwesome
                name={icon}
                color={disabled ? theme.colors.greyDarkest : theme.colors[iconColor]}
                size={theme.fontSizes[iconSize]}
              />
            )}

          </View>

          {textNode}
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          type="submit"
          style={[
            styles(variant, isFullwidth, noPadding, disabled, theme, small).button,
            {
              borderStyle: variant === 'light' ? 'solid' : undefined,
              borderColor: variant === 'light' ? 'rgba(0,0,0,0.2)' : undefined,
              borderWidth: variant === 'light' ? theme.normalize(2) : undefined,
            },
            style,
          ]}
          onPress={onPress}
          disabled={disabled || onPress === null}
          {...rest}
        >
          {variant === 'gradient' ? (
            <>
              {!disabled && <Gradient height={small ? theme.normalize(46) : (height || theme.normalize(50))} />}
              {textNode}
            </>
          ) : (
            <>
              { textNode }
            </>
          )}
        </TouchableOpacity>
      )}
    </>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.node,
  icon: PropTypes.string,
  iconFamily: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  iconSize: PropTypes.string,
  variant: PropTypes.string,
  isFullwidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  height: PropTypes.number,
  small: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  variant: 'primary',
  icon: '',
  iconFamily: 'fontawsome',
  iconColor: 'light',
  textColor: null,
  iconSize: 'large',
  isFullwidth: false,
  noPadding: false,
  style: {},
  onPress: null,
  text: '',
  height: null,
  small: false,
};

export default Button;
