import getAreaPrices from './getAreaPrices';

const getGeneralAreaPrices = (area, bookingItems, stayDuration, hotel, seasons) => {
  const bookingPrices = bookingItems.map((item) => getAreaPrices(area, stayDuration, hotel, item.date, seasons));

  const furnitureIds = [...new Set(area.prices.map((p) => p.furniture))];
  const prices = furnitureIds.map((furnitureId) => {
    const prices = bookingPrices.map((p) => p.find((f) => f.furnitureId === furnitureId).price);
    const min = Math.min(...prices);
    const max = Math.max(...prices);

    return { furnitureId, min, max };
  });

  return prices;
};

export default getGeneralAreaPrices;
