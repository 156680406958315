import { Dimensions, StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: theme.colors.white,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  siteMainView: {
    // paddingVertical: theme.sizings.mediumLarge
    flex: 1,
  },
  siteTitle: {
    backgroundColor: theme.colors.greyAlpha,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.sizings.mediumLarge,
    marginBottom: theme.sizings.large,
  },
  siteTitleText: {
    color: theme.colors.light,
    fontSize: theme.fontSizes.larger,
    textAlign: 'center',
    width: '100%',
  },
  bottomImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: theme.normalize(300),
  },
  topImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: theme.normalize(300),
  },
  qrcode: {
    alignItems: 'center',
    marginBottom: theme.normalize(50),
    marginTop: theme.sizings.mediumLarge,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.medium,
    padding: theme.sizings.smallMedium,
  },
  scanQR: {
    marginTop: theme.sizings.medium,
  },
  scanner: {
    height: theme.normalize(100),
    width: theme.normalize(100),
  },
  logo: {
    width: '75%',
    height: '50%',
    resizeMode: 'contain',
  },
  buttons: {
    marginTop: theme.normalize(45),
    width: Dimensions.get('window').width,
    paddingHorizontal: theme.sizings.small,
  },
  siteButtons: {
    width: Dimensions.get('window').width,
    paddingHorizontal: theme.sizings.small,
    flex: 1,
  },
  button: {
    alignSelf: 'stretch',
    backgroundColor: theme.colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.medium,
    paddingHorizontal: theme.radius.large,
    marginBottom: theme.sizings.small,
    borderRadius: theme.radius.small,
  },

  icon: {
    width: theme.normalize(30),
    height: theme.normalize(30),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.radius.rounded,
  },
  footer: {
    backgroundColor: theme.colors.light,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.sizings.smallMedium,
    color: theme.colors.light,
    flexDirection: 'row',
    width: '100%',
  },
  footerVersion: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.greyAlpha,
    padding: theme.sizings.medium,
    borderRadius: theme.radius.medium,
    width: '100%',
    marginVertical: theme.sizings.larger,
  },

});
