import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import Card from 'common/components/Card/Card';
import { Image } from 'common/components/Images';
import useTheme from 'providers/ThemeProvider';
import makeStyles from './HorizontalItemCard.styles';

const HorizontalItemCard = ({
  children, image, onPress,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  return (
    <Card isClickable onPress={onPress} noPadding noShadow style={styles.card}>
      <View style={styles.imageWrapper}>
        <Image
          style={styles.image}
          image={image}
        />
      </View>
      <View style={styles.content}>{children}</View>
    </Card>
  );
};

HorizontalItemCard.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object,
  onPress: PropTypes.func.isRequired,
};

HorizontalItemCard.defaultProps = {
  image: {},
};

export default HorizontalItemCard;
