import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity } from 'react-native';
import useTranslation from 'common/contexts/translations';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import Page from 'common/components/Page/Page';
import useAlert from 'common/contexts/alert';
import { Image } from 'common/components/Images';
import formatPrice from 'common/utils/formatPrice';
import Price from 'common/components/Price/Price';
import usePayment from 'payments/contexts/payments';

import useAuth from 'auth/contexts/auth';
import PaymentIcon from 'assets/payment.png';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

import useSite from 'sites/contexts/sites';
import makeStyles from './Payment.styles';

const Payment = ({ route }) => {
  const { t } = useTranslation();
  const { payment, callback = () => {} } = route.params;
  const { isPayFetching, pay } = usePayment();
  const { setAlert } = useAlert();
  const navigation = useNavigation();
  const { user } = useAuth();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const handlePay = useCallback(async () => {
    const response = await pay(payment.id);

    if (response.ResultCode) {
      setAlert({ color: 'error', title: t('common.error'), message: response.ResultMessage });
    } else {
      setAlert({ color: 'success', title: t('common.success'), message: t('common.success') });
    }

    callback();
  }, [pay, payment, callback, setAlert, t]);

  const goCreditCardForm = () => payment.site.isDemo ? callback() : navigation.navigate('CreditCardForm');

  return (
    <Page padding>
      <Image style={styles.image} image={PaymentIcon} />
      <View style={styles.price}>
        <Text centered size="large" isBold>{t('bookings.amountToPay')}</Text>
        <Price
          price={payment.amount > 0 ? payment.amount + payment.transactionFees : payment.amount}
          centered
          color="greyDarker"
          size="huge"
        />
      </View>
      <View>
        <Text size="large" isBold centered>{t('bookings.paymentMean')}</Text>
      </View>
      <TouchableOpacity onPress={goCreditCardForm}>
        <View style={[styles.creditCardContainer, commonStyles.shadowBottom]}>
          <View style={styles.creditCardView}>
            <FontAwesome name="credit-card" color={theme.colors.primary} size={60} />
            <View style={styles.creditCardInfo}>
              <Text>{t('profilePage.cardNumber')}</Text>
              {!payment.site.isDemo && <Text size="larger" color="primary">{user?.cardAlias}</Text>}
              {payment.site.isDemo && <Text size="larger" color="primary">{t('beach.demo')}</Text>}
            </View>
          </View>
          <View style={styles.activeBookingButtonContainer}>
            <Button
              onPress={goCreditCardForm}
              disabled={payment.site.isDemo}
              noPadding
              icon="pencil"
              variant="gradient"
              iconSize="default"
              style={styles.activeBookingButton}
            />
          </View>
        </View>
      </TouchableOpacity>
      <View style={commonStyles.buttonWrapper}>
        <View style={commonStyles.buttonContainer}>
          <Button
            data-cy="cypress_pay"
            variant="gradient"
            icon="credit-card"
            text={t('basket.pay', {
              total: formatPrice(payment.amount > 0
                ? payment.amount + payment.transactionFees : payment.amount,
              site.currency),
            })}
            onPress={handlePay}
            disabled={(isPayFetching)}
          />
        </View>
      </View>
    </Page>
  );
};

Payment.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Payment;
