import { Image as ImageDist } from 'common/components/Images';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  StyleSheet, TouchableHighlight, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { Icon } from 'react-native-elements';

const CustomerBoardSquare = ({
  seat,
  selectedSeats,
  squareDimensions,
  handleClickSeat,
  map,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const selectedInVisualizer = !!selectedSeats?.map(({ id }) => id).includes(seat.id);

  /* const renderSeatNumberText = () => {
    let sizeName = proportionalStyles.textBig;

    if (seat.name.length > 2) {
      if (seat.name.length > 4) {
        sizeName = proportionalStyles.textSmall;
      } else {
        sizeName = proportionalStyles.textMedium;
      }
    }
    return (
      <Text
        style={{
          fontSize: sizeName,
          transform: [{ rotate: `${seat.rotate ? -seat.rotate : 0}deg` }],
          textShadowColor: theme.colors.white,
          textShadowOffset: { height: proportionalStyles.borderWidth / 3, width: proportionalStyles.borderWidth / 3 },
          textShadowRadius: 0,
          // CLARA  textShadowOpacity: 1,
        }}
        centered
        isBold
      >
        {seat.name}

      </Text>
    );
  }; */

  const renderSeatNumberButton = () => (
    <TouchableHighlight
      style={[styles.numberContainer,
        {
          backgroundColor: selectedInVisualizer ? itemAreaColor : 'transparent',
          borderColor: itemAreaColor,
          borderWidth: seat.available ? proportionalStyles.borderWidth : 0,
          padding: proportionalStyles.padding,
        }]}
      onPress={() => handleClickSeat(seat, null)}
    >
      {selectedInVisualizer ? (
        <Icon
          name="check"
          size={proportionalStyles.textBig}
          color={theme.colors.light}
          style={{
            transform: [{ rotate: `${seat.rotate ? -seat.rotate : 0}deg` }],
          }}
        />
      ) : (
        <></>
        // renderSeatNumberText()
      )}
    </TouchableHighlight>
  );

  const squareDimensionRef = squareDimensions.height < squareDimensions.width
    ? squareDimensions.height : squareDimensions.width;

  const proportionalStyles = {
    borderWidth: squareDimensionRef / 15,
    padding: squareDimensionRef / 20,
    textBig: squareDimensionRef * 0.8,
    textSmall: squareDimensionRef * 0.25,
    textMedium: squareDimensionRef * 0.5,
    flag: squareDimensionRef * 0.5,
    conflictIcon: squareDimensionRef * 0.4,
  };

  const itemAreaColor = seat.price_area.color;

  return (
    <View
      style={styles.boardSquare}
      key={`seat-${seat?.name}-${map?.id}`}
    >
      <View style={{ flex: 1 }}>
        { seat?.furniture?.image && (
        <ImageDist
          image={seat.furniture.image}
          style={[styles.item,
            { opacity: seat.available ? 1 : 0.3 }]}
        />
        ) }
      </View>

      <View style={styles.infoBox}>
        {renderSeatNumberButton('full')}
      </View>

    </View>
  );
};

CustomerBoardSquare.propTypes = {
  seat: PropTypes.object.isRequired,
  squareDimensions: PropTypes.object.isRequired,
  selectedSeats: PropTypes.any.isRequired,
  handleClickSeat: PropTypes.any.isRequired,
  map: PropTypes.object.isRequired,
};

CustomerBoardSquare.defaultProps = {
};

const makeStyles = (theme) => StyleSheet.create({
  boardSquare: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  square: {
    backgroundColor: theme.colors.beach,
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.secondary,
  },
  overlay: {
    top: 0,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  numberContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    opacity: 1,
    borderStyle: 'solid',
  },
  customerContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    opacity: 1,
    borderStyle: 'solid',
    // hack word wrap
    flexDirection: 'row',
    flexGrow: 1,
  },

  infoBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
  },
});

export default CustomerBoardSquare;
