import { StyleSheet } from 'react-native';

export default (editable, theme) => StyleSheet.create({
  itemView: {
    height: theme.normalize(80),
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.greyLightest,
    marginBottom: theme.sizings.small,
    borderRadius: theme.radius.medium,
    paddingRight: theme.sizings.medium,
  },

  productImage: {
    width: theme.normalize(80),
    height: '100%',
    overflow: 'hidden',
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    backgroundColor: theme.colors.greyLighter,
  },

  productContent: {
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    flex: 1,
    marginLeft: theme.sizings.medium,
  },

  productLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  variant: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  variants: {
    marginBottom: theme.sizings.medium,
  },

  buttonsGroup: {
    width: editable ? theme.normalize(100) : theme.normalize(50),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
  },

  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },

  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});
