import React, {
  useCallback, useEffect, useState,
} from 'react';
import useTranslation from 'common/contexts/translations';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import {
  ScrollView, StyleSheet, TouchableOpacity, View,
} from 'react-native';
import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import fetchJSON from 'common/utils/fetchJSON';
import useSiteProvider from 'providers/SiteProvider';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';

const dateFormat = 'LL';

const makeStyles = (theme) => StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.sizings.smallMedium,
  },
  wrapper: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
  },
  bookingRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.small,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.greyLighter,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.tiny,
  },
  pictoWrapper: {
    flexDirection: 'row',
  },
  picto: {
    padding: theme.sizings.small,
  },
  flex1: {
    flex: 1,
    paddingRight: theme.sizings.medium,
  },
  flex2: {
    flex: 2,
    paddingRight: theme.sizings.medium,
  },
});

const CustomerBookingItemsList = ({
  customer,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { site } = useSiteProvider();
  const [bookings, setBooking] = useState([]);

  useEffect(() => {
    if (customer.id) {
      const getBookings = async () => {
        const params = {
          _limit: -1,
          'site.id': site?.id,
          customer: customer.id,
          // _sort:
        };

        const esc = encodeURIComponent;
        const queryParams = Object.keys(params)
          .map((key) => {
            if (Array.isArray(params[key])) {
              return params[key].map((p) => `${esc(key)}=${esc(p)}`).join('&');
            }
            return `${esc(key)}=${esc(params[key])}`;
          })
          .join('&');

        try {
          const res = await fetchJSON({
            url: `bookings?${queryParams}`,
            method: 'GET',
          });

          setBooking(res);
        } catch (e) {
          console.log(e);
        }
      };

      getBookings();
    }
  }, [customer, site]);

  const displayBookingItemRow = useCallback((booking) => {
    // Sort booking items by date
    const sortedBookingItems = booking.booking_items.sort((bi1, bi2) => (
      dayjs(bi1.date).isAfter(dayjs(bi2.date)) ? 1 : -1
    ));

    const isFuture = dayjs().isBefore(sortedBookingItems[0].date, 'day');
    const isPast = sortedBookingItems.length > 1
      ? dayjs().isAfter(sortedBookingItems[1].date, 'day')
      : dayjs().isAfter(sortedBookingItems[0].date, 'day');

    return (
      <TouchableOpacity
        key={`b-${booking.id}`}
        style={{
          ...styles.bookingRow,
          backgroundColor: isPast ? theme.colors.greyLighter
            : isFuture ? 'transparent' : theme.colors.secondary,
        }}
        onPress={() => navigation.navigate('WaiterBookingDetail', { bookingId: booking.id })}
      >
        {sortedBookingItems.length < 2
          ? (
            <View style={styles.flex1}>
              <Text isBold>
                {sortedBookingItems?.[0]?.date ? dayjs(sortedBookingItems[0].date).format(dateFormat) : '?'}

              </Text>
            </View>
          )
          : (
            <View style={styles.flex1}>
              <Text isBold>{dayjs(sortedBookingItems[0].date).format(dateFormat)}</Text>
              <Text isBold>{dayjs(sortedBookingItems[sortedBookingItems.length - 1].date).format(dateFormat)}</Text>
            </View>
          )}
        <Text style={styles.flex1}>{t('common.nbDays', { nb: sortedBookingItems.length })}</Text>
        <Text size="medium" style={styles.flex1}>{booking.code}</Text>
        <View style={styles.pictoWrapper}>
          <FontAwesome5
            name="ghost"
            color={sortedBookingItems.find((bi) => bi.status === 'noShow') ? theme.colors.dangerLight : 'transparent'}
            size={theme.sizings.medium}
            style={styles.picto}
          />
          <FontAwesome5
            name="credit-card"
            color={booking.isFullyPaid ? theme.colors.success : theme.colors.danger}
            size={theme.sizings.medium}
            style={styles.picto}
          />
        </View>
      </TouchableOpacity>
    );
  }, [navigation, styles, t, theme]);

  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text size="large" isBold>{t('waiter.bookings')}</Text>
      </View>
      <ScrollView style={styles.wrapper}>
        {
          bookings.length > 0 ? bookings.sort((b1, b2) => (
            dayjs(b1.booking_items[0]?.date).isBefore(dayjs(b2.booking_items[0]?.date)) ? 1 : -1
          )).map((b) => displayBookingItemRow(b)) : <EmptyPage text={t('bookings.empty')} />
        }
      </ScrollView>
    </View>
  );
};

CustomerBookingItemsList.propTypes = {
  customer: PropTypes.object,
};
CustomerBookingItemsList.defaultProps = {
  customer: null,
};

export default CustomerBookingItemsList;
