import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/Button/Button';
import Confirm from '../Confirm/Confirm';

const ConfirmButton = ({ confirmText, ...rest }) => (
  <Confirm confirmText={confirmText}>
    <Button
      {...rest}
    />
  </Confirm>
);

ConfirmButton.propTypes = {
  confirmText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

ConfirmButton.defaultProps = {
  confirmText: null,
};

export default ConfirmButton;
