import { useEffect, useState } from 'react';
import * as ScreenOrientation from 'expo-screen-orientation';

const useScreenOrientation = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const orientationChangeListener = ScreenOrientation.addOrientationChangeListener((event) => {
      const screenOrientation = event.orientationInfo.orientation;

      setIsLandscape(screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT
        || screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT);
    });

    return () => {
      ScreenOrientation.removeOrientationChangeListener(orientationChangeListener);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const screenOrientation = await ScreenOrientation.getOrientationAsync();

      setIsLandscape(screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT
        || screenOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT);
    })();
  }, []);

  return { isLandscape };
};

export default useScreenOrientation;
