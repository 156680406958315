import {
  useState, useEffect,
  useCallback,
} from 'react'; // createContext
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { Vibration } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Audio } from 'expo-av';

import useTranslation from 'common/contexts/translations';
import useAlert from 'common/contexts/alert';
import { getApiUrl } from 'common/utils/createUrl';
import useCategories from 'categories/contexts/categories';
import useAuth from 'auth/contexts/auth';
import useCall from 'calls/contexts/calls';
import useOrders from 'orders/contexts/orders';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useChat from 'sites/contexts/chat';
import useSite from 'sites/contexts/sites';
import useBooking from 'bookings/contexts/bookings';

import notificationSound from 'assets/sounds/cheerfull.mp3';
import { resetNavigation } from 'common/components/AppStateHandler/RootNavigation';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

// const SocketContext = createContext();

const soundObject = new Audio.Sound();

soundObject.loadAsync(notificationSound);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { user, booking: authBooking, setBooking } = useAuth();
  const {
    fetchItems: fetchCalls, currentCall,
  } = useCall();
  const {
    fetchItems: fetchBookings,
    checkCode,
    fetchItem: fetchBooking,
    item: booking,
  } = useBooking();
  const {
    fetchItems: fetchOrders,
    filters: orderFilters,
    refreshOrders,
    fetchBarmanList,
  } = useOrders();
  const {
    fetchUsers,
    fetchMessages,
    chatActive,
  } = useChat();
  const {
    fetchSiteData,
    setItem: setSite,
  } = useSite();
  const {
    fetchItems: fetchCategories,
  } = useCategories();
  const {
    fetchBookingItems: visualizerfetchBookingItems,
    fetchOrders: visualizerfetchOrders,
    fetchCalls: visualizerFetchCalls,
  } = useBeachVisualizer();

  const {
    setItems: setSpecialOffers,
    fetchSpecialOffersApplicable,
    fetchItems: fetchSpecialOffers,
  } = useSpecialOffers();

  const authenticate = useCallback(async () => {
    const token = await AsyncStorage.getItem('jwt');

    socket.emit('authenticate', { token });
  }, [socket]);

  const authenticateUser = useCallback(async (bookingId, siteId) => {
    socket.emit('authenticateUser', { bookingId, siteId });
  }, [socket]);

  // init socket
  useEffect(() => {
    setSocket(io(getApiUrl()));
  }, []);

  // setAlert not available the first time, so we have to reset events callback
  useEffect(() => {
    if (socket) {
      socket.off('newCall');
      socket.off('newBooking');
      socket.off('updateBooking');
      socket.off('seatChanged');
      socket.off('deactivateBooking');
      socket.off('callDeleted');
      socket.off('orderConfirmed');
      socket.off('newOrder');
      socket.off('orderStateChanged');
      socket.off('specialOffer');
      socket.off('chatUpdate');
      socket.off('productUpdate');
      socket.off('reconnect');
      socket.off('paymentError');
      socket.off('clientCall');
      socket.off('newStaffMember');

      socket.on('newStaffMember', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchBarmanList();
        }
      });
      socket.on('clientCall', () => {
        setAlert({ message: t('info.clientNotification') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
      });
      socket.on('paymentError', () => {
        setAlert({ color: 'error', title: t('common.error'), message: t('socket.paymentError') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
        if (['waiter', 'barman'].includes(user?.role.type)) {
          visualizerfetchBookingItems();
        }
      });
      socket.on('newCall', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          setAlert({ message: t('socket.call') });
          Vibration.vibrate(400);
          soundObject.replayAsync();
          fetchCalls();
          visualizerFetchCalls();
        }
      });
      socket.on('newBooking', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          soundObject.replayAsync();
          visualizerfetchBookingItems();
        }
      });
      socket.on('updateBooking', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          visualizerfetchBookingItems();
        } else if (authBooking) {
          const refreshBooking = async () => {
            const result = await checkCode(authBooking.code);

            fetchBooking(authBooking.id);
            setBooking(result);
            setAlert({ message: t('socket.orderUpdated') });
          };

          refreshBooking();
        }
      });
      socket.on('seatChanged', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          visualizerfetchBookingItems();
        } else if (authBooking) {
          const refreshBooking = async () => {
            const result = await checkCode(authBooking.code);

            setBooking(result);
          };

          refreshBooking();
        }
        setAlert({ message: t('info.seatChanged') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
      });
      socket.on('deactivateBooking', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          visualizerfetchBookingItems();
        } else {
          setAlert(t('socket.closedYourBooking', 'error'));
          resetNavigation({
            index: 0,
            routes: [{ name: 'HomePage' }],
          });
        }
      });
      socket.on('callDeleted', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchCalls();
          visualizerFetchCalls();
        } else if (currentCall) {
          setAlert({ message: t('socket.waiterComming') });
          fetchCalls();
        }
      });
      socket.on('orderConfirmed', () => {
        Vibration.vibrate(400);
        soundObject.replayAsync();
        setAlert({ message: t('socket.orderConfirmed') });
      });
      socket.on('newOrder', () => {
        setAlert({ message: t('socket.newOrder') });
        Vibration.vibrate(400);
        soundObject.replayAsync();
        if (['waiter', 'barman'].includes(user?.role.type)) {
          refreshOrders();
          fetchBooking(booking?.id);
          visualizerfetchOrders();
        } else if (orderFilters?.booking) { // si user !== waiter && dans une booking
          fetchOrders();
        }
      });
      socket.on('orderStateChanged', () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          soundObject.replayAsync();
          refreshOrders();
          visualizerfetchOrders();
        } else if (orderFilters?.booking) { // si user !== waiter && dans une booking
          Vibration.vibrate(400);
          soundObject.replayAsync();
          fetchOrders();
        }
      });
      socket.on('specialOffer', () => {
        if (!['waiter', 'barman'].includes(user?.role.type) && authBooking) {
          Vibration.vibrate(400);
          soundObject.replayAsync();
          const fetchAndSetData = async () => {
            const applicables = await fetchSpecialOffersApplicable({
              booking: authBooking.id,
              site: authBooking.site,
            });

            setSpecialOffers(applicables);
          };

          fetchAndSetData();
        } else if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchSpecialOffers();
        }
      });
      socket.on('chatUpdate', () => {
        if (!['waiter', 'barman'].includes(user?.role.type) && authBooking && chatActive) {
          fetchUsers();
          fetchMessages();
        }
      });
      socket.on('productUpdate', () => {
        async function fetchAndSetSiteData() {
          const site = await fetchSiteData(authBooking.site?.id ?? authBooking.site);

          setSite(site);
        }
        if (['waiter', 'barman'].includes(user?.role.type)) {
          fetchCategories();
        } else if (authBooking) {
          fetchAndSetSiteData();
        }
      });
      socket.on('reconnect', async () => {
        if (['waiter', 'barman'].includes(user?.role.type)) {
          authenticate();
        }
        if (authBooking) {
          authenticateUser(authBooking.id, authBooking.site.id);
        }
      });
    }
  }, [setAlert, fetchCalls, currentCall, user, authBooking, booking,
    authenticateUser, authenticate, socket, t, fetchOrders,
    orderFilters, fetchSpecialOffersApplicable, setSpecialOffers,
    fetchUsers, fetchMessages, fetchSiteData, setSite,
    fetchCategories, fetchSpecialOffers, chatActive, fetchBookings,
    setBooking, visualizerFetchCalls, visualizerfetchBookingItems, visualizerfetchOrders,
    checkCode, refreshOrders, fetchBarmanList, fetchBooking,
  ]);

  // if user is logged in, authenticate socket
  useEffect(() => {
    if (['waiter', 'barman'].includes(user?.role?.type)) {
      authenticate();
    }
  }, [user, authenticate]);

  // if user is in his booking, bind bookingId with socket
  useEffect(() => {
    if (authBooking) {
      authenticateUser(authBooking.id, authBooking?.site?.id ?? authBooking?.site);
    }
  }, [authBooking, authenticateUser]);

  return children;
};

SocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SocketProvider;
