import React, { useCallback, useEffect, useState} from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { View, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import { Text } from 'react-native';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import useTranslation from 'common/contexts/translations';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';


const makeStyles = (theme, isMobile) => StyleSheet.create({
  optionsBox: { 
    width: '100%',
    maxHeight: theme.normalize(300),
    minHeight: isMobile ? 300 : null,
    borderRadius: theme.radius.medium,
    marginVertical: theme.sizings.medium
  },
  option: {
    flex: 1,
    padding: theme.sizings.small,
    borderColor: theme.colors.greyLighter,
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.tiny
  },
  selected: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: theme.sizings.small,
    borderRadius: theme.radius.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchInput: { 
    padding: 0
  },
  searchInputInner: {
    paddingHorizontal: theme.sizings.small, 
    fontSize: theme.sizings.medium,
    marginBottom: 0
  }
});

export const AutoComplete = ({
  value,
  onChange,
  initialOptions,
  enableSearch,
  enableCreate,
  customFiltering,
  customOnChange,
  onCreate,
  valueName,
}) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions())
  const styles = makeStyles(theme, isMobile);
  
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  
  const {handleCreate, handleSubmit} = onCreate;
  
  const handleUpdate = useCallback((value) => {
    // Refresh list with new created item
    setOptions(value.name);
  
    handleSubmit(value);
  }, [handleSubmit, setOptions]);

  const setOptions = useCallback((filterValue) => {
    if (filterValue && enableSearch) {
      setLoading(true);
      if (customFiltering) {
        const suggestions = customFiltering(filterValue);
        if (suggestions instanceof Promise) {
          suggestions.then((suggestions) => {
            setFilteredOptions(suggestions);
            setLoading(false);
          });
          return;
        } else {
          setFilteredOptions(suggestions);
        }
      } else {
        setFilteredOptions(initialOptions.filter((o) => (o.searchable || o.label).toLowerCase().includes(filterValue.toLowerCase()) ));
      }
    } else {
      setFilteredOptions(initialOptions);
    }
    setLoading(false);
  }, [customFiltering, setFilteredOptions, setLoading, initialOptions]);

  useEffect(() => {
    if (!loading) {
      setOptions();
    }
  }, [loading, setOptions])

  return (
    <>
      <TouchableOpacity
        onPress={() => setShowOptions(!showOptions)}
      >
        <View style={styles.selected}>
          <Text>{valueName || t('common.select')}</Text>

          <FontAwesome
            size={theme.fontSizes.large}
            name="angle-down"
            color={theme.colors.greyDarkest}
          />
        </View>
      </TouchableOpacity>

      {Boolean(showOptions) && (
      <View style={styles.optionsBox}>
        { Boolean(enableSearch) && (
          <DebouncedInput
            value={filter}
            onChange={(value) => {
              setFilter(value);
              setOptions(value);
            }}
            autoFocus
            autoCorrect={false}
            placeholder={t("common.enterSomethingToSuggest")}
            style={styles.searchInput}
            inputStyle={styles.searchInputInner}
          />
          )}

        <ScrollView
          style={{
            flex: 1,
            width: '100%',
            marginTop: theme.sizings.small
          }}
        >
          {Boolean(loading) && <Text>{t('common.loading')}</Text>}
          {Boolean(enableSearch) && Boolean(filter) && filteredOptions.every((o) => o.filterMatch !== filter) && Boolean(enableCreate) &&
            <TouchableOpacity
            style={styles.option}
            onPress={() => {
              handleCreate(filter, handleUpdate);
              setShowOptions(false);
            }}
            >
              <Text isBold>{t('common.newItem', {item: filter})}</Text>
            </TouchableOpacity>
          }

          {!Boolean(loading) &&
            filteredOptions?.map((_value) => (
              <TouchableOpacity 
                key={`${filter}_${_value.value}`} 
                style={styles.option}
                onPress={() => {
                  setFilteredOptions([]);
                  setShowOptions(false);
                  //setFilter('')
                  if(customOnChange){
                    customOnChange(_value)
                  }else{
                    onChange(_value.value, _value.label)
                  }
                }}
              >
                <Text>{_value.label}</Text>
              </TouchableOpacity>
            ))}
        </ScrollView>
      </View>
      )}
    </>
  );
};


AutoComplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  initialOptions: PropTypes.array,
  customOnChange: PropTypes.func,
  onCreate: PropTypes.any,
  handleFiltering: PropTypes.func,
  valueName: PropTypes.string,
  enableSearch: PropTypes.bool,
  enableCreate: PropTypes.bool

};

AutoComplete.defaultProps = {
  value: '',
  onChange: () => {},
  initialOptions: [],
  customOnChange: null,
  handleFiltering: null,
  valueName: '',
  enableSearch: false,
  enableCreate: false,
  onCreate: {
    handleCreate: null,
    handleSubmit: null,
  }
};

export default AutoComplete;
