import React, {
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import ContextProvider from 'common/contexts/crud';

const CustomerCategoriesContext = createContext();

export const CustomerCategoriesProvider = ({ children }) => (
  <ContextProvider url="customer-categories" context={CustomerCategoriesContext}>
    {children}
  </ContextProvider>
);

CustomerCategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useCustomerCategories = () => {
  const context = useContext(CustomerCategoriesContext);

  return { ...context };
};

export default useCustomerCategories;
