import React, { useCallback, useMemo } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import useTranslation from 'common/contexts/translations';
import truncateText from 'common/utils/truncateText';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const ProfileOrderListItem = ({
  payment,
  site,
  created_at: createdAt,
  updated_at: updatedAt,
  ...rest
}) => {
  const date = useMemo(() => updatedAt || createdAt, [createdAt, updatedAt]);
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const goRecap = useCallback(async () => {
    navigate('PaymentRecap', { paymentId: payment.id });
  }, [navigate, payment]);

  const generateButton = useMemo(() => {
    const isPassed = dayjs(date).startOf('day').isBefore(dayjs().startOf('day'));

    if (isPassed) {
      return (
        <Button
          noPadding
          icon="list"
          variant="primary"
          iconSize="default"
          style={styles.orderButton}
        />
      );
    }

    return (
      <Button
        noPadding
        icon={payment.status === 'PAID' ? 'list' : 'credit-card'}
        variant={payment.status === 'PAID' ? 'primary' : 'danger'}
        iconSize="default"
        style={styles.orderButton}
      />
    );
  }, [payment, date, styles]);

  return (
    <TouchableOpacity
      {...rest}
      key={payment.id}
      onPress={goRecap}
    >
      <View style={[styles.order, commonStyles.shadowBottom]}>
        <View style={styles.orderLeft}>
          <View style={styles.orderStatus}>
            <FontAwesome
              name={payment?.status === 'PAID' ? 'check' : 'clock-o'}
              color={payment?.status === 'PAID' ? theme.colors.link : theme.colors.warning}
              style={styles.orderStatusIcon}
            />
            <Text size="small" color={payment?.status === 'PAID' ? 'link' : 'warning'}>
              {payment?.status === 'PAID' ? t('orders.paid') : t('common.pending')}
            </Text>
          </View>
          <View style={styles.orderCode}>
            <Text noLineHeight size="large">n°</Text>
            <Text noLineHeight size="largest">{payment.id}</Text>
          </View>
        </View>
        <View style={styles.orderContent}>
          <View>
            <Text isBold>{truncateText(site.name, 20)}</Text>
          </View>
          <View style={styles.orderMeta}>
            <View style={styles.orderDate}>
              <FontAwesome
                style={{ paddingRight: theme.sizings.tiny }}
                name="calendar"
                color={theme.colors.primary}
                size={theme.fontSizes.default}
              />
              <Text noLineHeight>{dayjs(date).format('LL')}</Text>
            </View>
          </View>
        </View>
        <View style={styles.orderButtonContainer}>
          {generateButton}
        </View>
      </View>
    </TouchableOpacity>
  );
};

ProfileOrderListItem.propTypes = {
  payment: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
};

const makeStyles = (theme) => StyleSheet.create({
  order: {
    marginTop: theme.sizings.small,
    marginBottom: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    flexDirection: 'row',
    alignItems: 'center',
  },

  orderMeta: {
    flexDirection: 'row',
  },

  orderCode: {
    paddingRight: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  orderContent: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.greyLighter,
    paddingLeft: theme.sizings.medium,
  },

  orderDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  orderButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  orderButton: {
    paddingHorizontal: 10,
    paddingVertical: theme.sizings.tiny,
  },

  orderLeft: {
    flex: 1.25,
  },

  orderStatus: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  orderStatusIcon: {
    marginRight: theme.sizings.small,
  },
});

export default ProfileOrderListItem;
