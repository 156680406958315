/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback, useMemo,
} from 'react';
import {
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import StayDuration from 'waiter/components/booking-detail-sections/StayDuration';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import PaymentSwitcher from 'waiter/components/booking-detail-sections/PaymentSwitcher';
import { FontAwesome5 } from '@expo/vector-icons';
import formatPrice from 'common/utils/formatPrice';
import StatusSwitcher from 'waiter/components/booking-detail-sections/StatusSwitcher';
import useBookings from 'bookings/contexts/bookings';
import dayjs from 'dayjs';

const DatesStatusItem = ({
  bookingItem, biIndex, editCurrentBookingItem, currentBookingItem,
  editBookingItems, setEditBookingItems, fetchCompleteBooking,
  isPaymentLoading,
  biPaymentVisible,
  setBiPaymentVisible,
  handlePayBookingItem,
}) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const { t } = useTranslation();
  const {
    item: completeBooking,
    changeBookingItemStatus,
  } = useBookings();

  const styles = makeStyles(theme, isMobile);

  const cost = Number(completeBooking.booking_items.find((bi) => bi.date === bookingItem.date).price);
  const alreadyPaid = Number(bookingItem.payments?.reduce((acc, item) => acc + item.amount, 0) ?? 0);
  const biStatus = bookingItem.status ?? 'reserved';
  const isCurrentBi = bookingItem.id === currentBookingItem?.id;

  const isPaidByBi = useMemo(() => (
    completeBooking?.paymentType !== 'booking'
  ), [completeBooking]);

  const getBiPaidIcon = useCallback((itemPaid) => {
    if (!itemPaid) {
      return ({
        color: theme.colors.dangerBright,
        name: 'exclamation',
      });
    }
    return ({
      color: theme.colors.primary,
      name: 'check',
    });
  }, [theme]);

  const getBiStatusIcon = useCallback((itemStatus) => {
    if (itemStatus === 'noShow') {
      return ({
        color: theme.colors.dangerBright,
        name: 'ghost',
      });
    }

    if (itemStatus === 'reserved') {
      return ({
        color: theme.colors.purple,
        name: 'clock',
      });
    }

    return ({
      color: theme.colors.primary,
      name: 'check',
    });
  }, [theme]);

  return (
    <View
      style={styles.bookingItemRow}
      key={`detail-of-${completeBooking.id}-${bookingItem.id}`}
    >
      {/* 1 : date +  stayDuration  */}
      <View style={{ minWidth: isMobile ? '100%' : undefined }}>
        <Text key={biIndex} isBold={currentBookingItem?.id === bookingItem.id}>
          { `${dayjs(bookingItem.date).format('LL')} ` }
        </Text>

        <StayDuration stayDuration={bookingItem.stayDuration} />

      </View>

      {/* 2 : status */}

      {editCurrentBookingItem === true ? (
        <StatusSwitcher
          initialStatus={biStatus}
          handleChangeStatus={async (newValue) => {
            await changeBookingItemStatus(
              bookingItem.id,
              newValue,
            );
            await fetchCompleteBooking();
          }}
        />

      ) : (
        <View style={{ flex: 1, maxWidth: theme.normalize(500), paddingHorizontal: theme.sizings.medium }}>
          <View style={styles.iconText}>
            <FontAwesome5
              name={getBiStatusIcon(biStatus).name}
              color={getBiStatusIcon(biStatus).color}
              size={theme.sizings.medium}
            />
            <Text size="small" style={{ marginLeft: 8 }}>
              {t(`bookings.bookingItemStatus_${biStatus}`)}
            </Text>
          </View>
        </View>
      )}

      {/* 3 : payment booking */}

      <Text size="medium" style={{ alignSelf: isMobile ? null : 'center', paddingHorizontal: theme.sizings.tiny }}>
        {editCurrentBookingItem
          ? `${isPaidByBi ? formatPrice(alreadyPaid) : '-'} / ${formatPrice(cost)}`
          : formatPrice(cost)}
      </Text>
      {editCurrentBookingItem === true ? (
        <PaymentSwitcher
          elementType="booking-item"
          elementID={bookingItem.id}
          isPaid={bookingItem.isFullyPaid}
          isPaymentLoading={isPaymentLoading}
          visible={biPaymentVisible}
          setVisible={setBiPaymentVisible}
          payAction={handlePayBookingItem}
        />
      ) : (
        <View style={{ flex: 1, maxWidth: theme.normalize(200), paddingHorizontal: theme.sizings.medium }}>

          <View style={styles.iconText}>
            <FontAwesome5
              name={getBiPaidIcon(bookingItem.isFullyPaid).name}
              color={getBiPaidIcon(bookingItem.isFullyPaid).color}
              size={theme.sizings.medium}
            />
            <Text size="small" style={{ marginLeft: 8 }}>
              {bookingItem.isFullyPaid
                ? t('bookings.bookingItemPaid_true')
                : `${t('bookings.bookingItemPaid_due', { due: formatPrice(cost - alreadyPaid) })}`}
            </Text>
          </View>
        </View>
      )}

      {!isCurrentBi
        ? (
          <View style={{ alignItems: 'flex-end' }}>
            {/* 3 : payment booking */}
            <Button
              onPress={() => {
                setEditBookingItems(() => editBookingItems
                  .map((editable, editableIndex) => editableIndex === biIndex ? !editable : false));
              }}
              variant="light"
              icon="pencil"
              iconColor="primary"
              style={styles.buttonEditItem}
              noPadding
              small
            />
          </View>
        )
        : !editCurrentBookingItem
    && (
    <View style={{ alignItems: 'flex-end' }}>
      <Button
        icon="calendar"
        iconColor="secondary"
        style={styles.buttonEditItem}
        noPadding
        small
      />
    </View>
    )}

    </View>
  );
};

export default DatesStatusItem;

DatesStatusItem.propTypes = {
  bookingItem: PropTypes.object.isRequired,
  biIndex: PropTypes.number.isRequired,
  editCurrentBookingItem: PropTypes.bool,
  currentBookingItem: PropTypes.object.isRequired,
  editBookingItems: PropTypes.bool,
  setEditBookingItems: PropTypes.any.isRequired,
  fetchCompleteBooking: PropTypes.func.isRequired,
  isPaymentLoading: PropTypes.any.isRequired,
  biPaymentVisible: PropTypes.any.isRequired,
  setBiPaymentVisible: PropTypes.any.isRequired,
  handlePayBookingItem: PropTypes.any.isRequired,
};

DatesStatusItem.defaultProps = {
  editCurrentBookingItem: false,
  editBookingItems: false,
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingItemInfo: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: isMobile ? 'column' : 'row',
  },
  bookingItemSituation: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  bookingFullPeriod: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  bookingActions: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',

  },

  detailOrders: {
    padding: theme.sizings.medium,
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginVertical: theme.sizings.medium,
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: theme.sizings.small,
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrdersTitle: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: theme.sizings.medium,
    flexDirection: isMobile ? 'column' : 'row',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addOrderLabel: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
  switchLabelIcon: {
    marginRight: theme.sizings.small,
    opacity: 0.6,
  },
  switchBooleanBox: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    borderRadius: theme.radius.rounded,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonBar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? theme.sizings.medium : undefined,
  },
  buttonOrder: {
    marginLeft: theme.sizings.medium,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: theme.sizings.tiny,
  },
  buttonEditItem: {
    height: theme.normalize(38),
    width: theme.normalize(38),
  },
  bookingItemRow: {
    marginTop: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    borderTopColor: theme.colors.greyLighter,
    borderTopWidth: 1,
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'space-between',
  },
  dirRow: {
    flexDirection: 'row',
  },
  dirColumn: {
    flexDirection: 'column',
  },
});
