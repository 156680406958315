import React, { useEffect } from 'react';
import useTranslation from 'common/contexts/translations';
import { useKeepAwake } from 'expo-keep-awake';
import useAuth from 'auth/contexts/auth';
import OrderList from 'orders/pages/OrderList/OrderList';
import Catalog from 'waiter/pages/WaiterHome/Catalog';
import SpecialOffers from 'waiter/pages/WaiterHome/SpecialOffers';
import CallList from 'waiter/pages/WaiterHome/CallList';
import BeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/BeachVisualizer';
import useSite from 'sites/contexts/sites';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import CustomerList from 'waiter/pages/WaiterHome/CustomerList';
import useSiteProvider from 'providers/SiteProvider';
import Calendar from 'waiter/pages/WaiterHome/Events';
import useTheme from 'providers/ThemeProvider';
import { Ionicons } from '@expo/vector-icons';
import {
  StyleSheet, View, Dimensions, Platform, useWindowDimensions,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { isMobileMode } from 'common/utils/desktopMode';
import AddOrderButton from 'orders/components/AddOrderButton/AddOrderButton';
import BookButton from 'waiter/pages/WaiterHome/BeachVisualizer/components/BookButton/BookButton';
import ToggleMapButton from './maps/components/ToggleMapButton/ToggleMapButton';
import useBeachVisualizer from './waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

const WaiterRouter = () => {
  const { t } = useTranslation();

  const WaiterStack = createStackNavigator();
  const { user } = useAuth();
  const { setCurrentSite } = useSite();
  const { fetchSite } = useSiteProvider();
  const { selectedSeats, date } = useBeachVisualizer();

  const { theme } = useTheme();
  const styles = makeStyles(theme);

  useEffect(() => {
    setCurrentSite(user?.site);
    fetchSite(user?.site?.id);
  }, [user, setCurrentSite, fetchSite]);

  useKeepAwake();

  const headerStyles = {
    headerBackTitleVisible: false,
    headerTitleAlign: 'center',
    headerTitleStyle: {
      color: theme.colors.light,
      fontSize: theme.fontSizes.larger,
      fontFamily: 'MontserratBold',
      width: Dimensions.get('window').width - (theme.normalize(56) + theme.normalize(18)) * 2,
      textAlign: 'center',
    },
    headerBackImage: () => (
      <View style={{
        width: theme.normalize(50), height: theme.normalize(30), paddingTop: theme.normalize(5),
      }}
      >
        <Ionicons
          style={{ marginLeft: theme.sizings.medium }}
          name="ios-arrow-back"
          size={theme.fontSizes.larger}
          color="rgba(255, 255, 255, .75)"
        />
      </View>
    ),
  };

  const twoRightIcons = {
    headerTitleStyle: {
      color: theme.colors.light,
      fontSize: theme.fontSizes.larger,
      fontFamily: 'MontserratBold',
      // 80 taille de deux pictos à droite + marge * 2 côté
      width: Dimensions.get('window').width - theme.normalize(98) * 2,
      textAlign: 'center',
    },
  };

  const gradientHeader = {
    headerBackground: () => (
      <LinearGradient
        colors={[theme.colors.secondary, theme.colors.secondaryDark]}
        start={[0, 0.5]}
        end={[1, 0.5]}
        style={{
          flex: 1,
        }}
      />
    ),
  };

  const darkGradientHeader = {
    headerBackground: () => (
      <LinearGradient
        colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.2)']}
        start={[0, 0]}
        end={[0, 1]}
        style={{
          flex: 1,
        }}
      />
    ),
  };

  const cardStyle = Platform.OS === 'web' ? { height: '100%' } : null;

  const isMobile = isMobileMode(useWindowDimensions());

  // private functions

  return (
    <WaiterStack.Navigator
      screenOptions={{
        ...headerStyles, cardStyle, ...TransitionPresets.RevealFromBottomAndroid, animationEnabled: false,
      }}
    >
      <WaiterStack.Screen
        name="maps"
        component={BeachVisualizer}
        options={{
          title: t('waiter.dashboard'),
          headerShown: isMobile,
          ...gradientHeader,
          ...twoRightIcons,
          headerRight: () => (
            <View style={styles.headerRight}>
              <BookButton selectedSeats={selectedSeats} date={date} iconOnly />

              <ToggleMapButton />
            </View>
          ),
        }}
      />
      {/* <WaiterStack.Screen name="bookings" component={BookingList} options={{ title: t('waiter.bookings') }} /> */}
      {['waiter', 'barman', 'manager'].includes(user?.role.type)
      && (
      <>
        <WaiterStack.Screen
          name="waiterOrders"
          component={OrderList}
          options={{
            title: t('waiter.ordersList'),
            headerShown: isMobile,
            ...gradientHeader,
            headerRight: () => <View style={styles.headerRight}><AddOrderButton /></View>,
          }}

        />
        <WaiterStack.Screen
          name="stock"
          component={Catalog}
          options={{
            title: t('waiter.stock'),
            headerShown: isMobile,
            // headerTransparent: true,
            ...gradientHeader,
          }}
        />
        <WaiterStack.Screen
          name="calls"
          component={CallList}
          options={{
            title: t('waiter.callList'),
            headerShown: isMobile,
            ...gradientHeader,
            // headerTransparent: true,
          }}
        />
        <WaiterStack.Screen
          name="offers"
          component={SpecialOffers}
          options={{
            title: t('waiter.offers'),
            headerShown: isMobile,
            ...gradientHeader,
            // headerTransparent: true,
          }}
        />
        <WaiterStack.Screen
          name="events"
          component={Calendar}
          options={{ title: t('waiter.events'), headerShown: false }}
        />
        <WaiterStack.Screen
          name="customers"
          component={CustomerList}
          options={{
            title: t('waiter.customers'),
            headerShown: isMobile,
            ...gradientHeader,
            // ...twoRightIcons,

            // headerTransparent: true,
          }}
        />
      </>
      )}
    </WaiterStack.Navigator>

  );
};

export default WaiterRouter;

const makeStyles = (theme) => StyleSheet.create({
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLeft: {
    marginLeft: theme.sizings.small,
  },
  buttonToggle: {
    backgroundColor: 'black',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
    cursor: 'pointer',
  },
});
