import React, { useMemo, useCallback } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import { ImageBackground } from 'common/components/Images';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';

import makeCommonStyles from 'styles/commonStyles';

import useTheme from 'providers/ThemeProvider';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';
import styles from './BasketItem.styles';

const BasketItem = ({
  entity, entityType, number, editable, price, reduction = null, selectedOptions,
}) => {
  const { addItem, removeItem } = useBasket();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const {
    itemView: itemViewStyle,
    productImage: productImageStyle,
    productContent: productContentStyle,
    productLabel: productLabelStyle,
    variant: variantStyle,
    variants: variantsStyle,
    buttonsGroup: buttonsGroupStyle,
    productButton: productButtonStyle,
    quantity: quantityStyle,
  } = useMemo(() => styles(editable, theme), [editable, theme]);

  const singleOptions = useMemo(() => selectedOptions.filter((selected) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return false;
    }
    const optId = (typeof selected.option === 'object') ? selected.option.id : selected.option;
    const o = entity.options.find(({ option }) => option.id === optId);

    return !o.option.multiple;
  }), [selectedOptions, entity]);

  const getVariantNames = useCallback((selectedOption) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return null;
    }
    const optId = (typeof selectedOption.option === 'object') ? selectedOption.option.id : selectedOption.option;
    const o = entity.options.find(({ option }) => option.id === optId);
    const variants = o.variant_prices.filter((vp) => selectedOption.variants.includes(vp.option_variant.id));

    if (variants) {
      return variants.map((v) => <TranslatedText value={v.option_variant.name} />);
    }
  }, [entity]);

  const getVariantPrices = useCallback((selectedOption) => {
    // quick fix because on update entity.options is not populate but this screen is tmp
    if (!entity.options) {
      return null;
    }
    const optId = (typeof selectedOption.option === 'object') ? selectedOption.option.id : selectedOption.option;
    const o = entity.options.find(({ option }) => option.id === optId);
    const variants = o.variant_prices.filter((vp) => selectedOption.variants.includes(vp.option_variant.id));

    if (variants) {
      return variants.map((v) => v.price);
    }
  }, [entity]);

  function displayOption(option, variantStyle, site) {
    const vNames = getVariantNames(option);
    const vPrices = getVariantPrices(option);

    return vNames.map((name, index) => (
      <View key={option.option} style={variantStyle}>
        {name}
        { vPrices[index] > 0 && (
        <Text isBold size="large">
          {formatPrice(vPrices[index], site?.currency)}
        </Text>
        )}
      </View>
    ));
  }

  return (
    <View>
      <View style={[itemViewStyle, commonStyles.shadowBottom]}>
        <ImageBackground style={productImageStyle} image={entity?.images?.[0]} />
        <View style={productContentStyle}>
          <View style={productLabelStyle}>
            <TranslatedText isBold value={entity.name} />
            {singleOptions && singleOptions.length > 0 && singleOptions.map((o) => (
              <View key={o.option} style={{ marginLeft: theme.sizings.small }}>{getVariantNames(o)[0]}</View>))}
          </View>
          <View style={buttonsGroupStyle}>
            <Gradient height={theme.normalize(40)} />
            {editable && (
              <Button
                noPadding
                text="-"
                variant="transparent"
                iconSize="default"
                style={productButtonStyle}
                onPress={() => removeItem(entity, selectedOptions, entityType)}
              />
            )}
            <Text size="large" color="light" style={quantityStyle}>{number}</Text>
            {editable && (
              <Button
                noPadding
                text="+"
                variant="transparent"
                iconSize="default"
                style={productButtonStyle}
                onPress={() => addItem(entity, selectedOptions, price, entityType)}
              />
            )}
          </View>
        </View>
        <ReductionPrice price={price} reduction={reduction} />
      </View>
      <View style={variantsStyle}>
        {selectedOptions && selectedOptions.length > 0 && selectedOptions.map((option) => (
          displayOption(option, variantStyle, site)
        ))}
        {/* reduction && (
          <View style={variantStyle}>
            <Text>Discount</Text>
            <Text isBold size="large">
              { formatPrice(Math.round(price * (reduction.discount_rate)) / 100, site?.currency) }
            </Text>
          </View>
        ) */}
      </View>
    </View>
  );
};

BasketItem.path = 'products';

BasketItem.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  number: PropTypes.number,
  editable: PropTypes.bool,
  price: PropTypes.number,
  reduction: PropTypes.object,
  selectedOptions: PropTypes.array,
};

BasketItem.defaultProps = {
  number: 1,
  editable: false,
  price: 0,
  reduction: null,
  selectedOptions: [],
};

export default BasketItem;
