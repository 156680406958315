import './i18n';
import * as Sentry from 'sentry-expo';
import { Text, TouchableOpacity } from 'react-native';
import React, { useEffect, Suspense } from 'react';
import Entry from './src/App';

Sentry.init({
  dsn: 'https://2aaa79d01f353d94e3a89978bb2580dd@o432100.ingest.sentry.io/4506156666585088',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 0.5,
  debug: true,
});

/* StatusBar.setTranslucent(true); */

const App = () => {
  useEffect(() => {
    // Tweak to force feedback on TouchableOpacity without setting the props in every instance of TouchableOpacity
    // as suggested here -> https://github.com/facebook/react-native/issues/29360
    if (TouchableOpacity.defaultProps == null) TouchableOpacity.defaultProps = {};
    TouchableOpacity.defaultProps.delayPressIn = 0;
  }, []);

  return (
    <Suspense fallback={<Text />}>
      <Entry />
    </Suspense>
  );
};

export default App;
