import BeachBackground from 'assets/beach.jpg';
import { useAuth } from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import useChat from 'sites/contexts/chat';
import useTheme from 'providers/ThemeProvider';

const makeStyles = (theme) => StyleSheet.create({

  title: {
    fontSize: theme.fontSizes.larger,
    color: theme.colors.light,
    fontWeight: 'bold',
    marginBottom: theme.sizings.small,
  },

  statusView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.sizings.medium,
    width: '80%',
    marginLeft: '0%',
    paddingTop: 140,
  },

  statusLabel: {
    flexDirection: 'row',
    marginRight: theme.sizings.small,
  },

  messageInput: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
    borderRadius: 0,
    borderBottomColor: theme.colors.primary,
    borderBottomWidth: theme.normalize(2),
    fontSize: theme.sizings.medium,
  },

  inputs: {
    width: '80%',
    marginLeft: '10%',
  },

  inputLabel: {
    marginBottom: theme.sizings.small,
  },

  submitView: {
    paddingVertical: theme.sizings.large,
    width: '100%',
    alignItems: 'center',
  },

});

const ChatSettings = ({ navigation }) => {
  const auth = useAuth();
  const { user } = auth;
  const { chatUser, saveChatUser } = useChat();
  const { t } = useTranslation();
  const [chatUsername, setChatUsername] = useState(chatUser ? chatUser.name : (user !== null) ? user.username : '');
  const [chatDescription, setChatDescription] = useState(chatUser ? chatUser.description : '');
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const handleChat = useCallback(async (active) => {
    const userInfo = {
      name: chatUsername,
      description: chatDescription,
      active,
    };

    if (chatUser) {
      userInfo.id = chatUser.id;
    }

    await saveChatUser(userInfo);

    if (active) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'ChatList' }],
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'ChatSettings' }],
      });
    }
  }, [navigation, saveChatUser, chatUsername, chatDescription, chatUser]);

  return (
    <Page scroll padding backgroundSource={BeachBackground} style={{ marginBottom: 0 }}>
      <View style={styles.statusView}>
        <View style={styles.statusLabel}>
          <Text size="large">
            {t('chat.status')}
          </Text>
          <Text size="large" isBold>
            {chatUser?.active ? t('common.online') : t('common.offline')}
          </Text>
        </View>
      </View>
      <View style={styles.inputs}>
        <View style={styles.inputLabel}>
          <Text size="large" isBold>{t('chat.myUsername')}</Text>
        </View>
        <TextInput
          style={styles.messageInput}
          onChangeText={setChatUsername}
          value={chatUsername}
        />
        <View style={styles.inputLabel}>
          <Text size="large" isBold>{t('chat.description')}</Text>
        </View>
        <TextInput
          style={styles.messageInput}
          onChangeText={setChatDescription}
          value={chatDescription}
        />
        <Text>{t('chat.disclaimer')}</Text>
      </View>
      <View style={styles.submitView}>
        <Button
          icon="arrow-right"
          text={t('chat.chatButton')}
          variant="gradient"
          onPress={() => handleChat(true)}
          style={{ marginBottom: theme.sizings.medium }}
        />

        <Button
          icon="arrow-left"
          text={t('chat.leaveChat')}
          variant="greyDarker"
          onPress={() => handleChat(false)}
        />
      </View>
    </Page>
  );
};

ChatSettings.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default ChatSettings;
