import React from 'react';
import PropTypes from 'prop-types';

import OrderProductItem from './OrderProductItem';

// TODO enlever ce composant qui ne sert plus à rien
const OrderProduct = ({ product, reduction }) => (
  [<OrderProductItem editable price={product.price} type="product" entity={product} reduction={reduction} />]
);

OrderProduct.path = 'products';

OrderProduct.propTypes = {
  product: PropTypes.object.isRequired,
  reduction: PropTypes.object,
};

OrderProduct.defaultProps = {
  reduction: null,
};

export default OrderProduct;
