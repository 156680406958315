import getFurniturePrice from './getFurniturePrice';
import getActiveSeason from './getActiveSeason';

const getBookingPublicMapPrices = (bookingItems, areas, seasons, hotel) => {
  const prices = bookingItems.map((bi) => {
    let price = 0;

    let errorSeason = false;

    let errorFurniturePrice = false;

    bi.seats.forEach((seat) => {
      const seatPriceAreaId = seat.price_area?.id ?? seat.price_area;
      const area = areas.find((a) => a.id === seatPriceAreaId);

      const season = getActiveSeason(bi.date, seasons);

      if (!season) {
        errorSeason = true;
      }

      const furniturePrice = getFurniturePrice({
        area,
        furnitureId: seat.furniture?.id ?? seat.furniture,
        stayDuration: bi.stayDuration,
        hotel,
        season,
      });

      if (!furniturePrice || furniturePrice === 0) {
        console.log('erreur de prix');
        errorFurniturePrice = true;
      }

      price += furniturePrice;
    });

    // end foreach mobiliers
    return ({
      date: bi.date,
      price,
      errorSeason,
      errorFurniturePrice,
    });
  });

  // end foreach dates

  return prices;
};

export default getBookingPublicMapPrices;
