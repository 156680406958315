/* eslint-disable max-len */
import { useNavigation } from '@react-navigation/native';

import useAuth from 'auth/contexts/auth';
import useBookings from 'bookings/contexts/bookings';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  StyleSheet,
  View,
  Platform,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import BookingPayments from 'waiter/components/BookingPayments/BookingPayments';
import isToday from 'dayjs/plugin/isToday';
import Button from 'common/components/Button/Button';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import useAlert from 'common/contexts/alert';
import CustomerPanel from 'waiter/components/CustomerPanel/CustomerPanel';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import Introduction from './components/Introduction';
import DatesStatus from './components/DatesStatus';
import Orders from './components/Orders';

dayjs.extend(isToday);

const WaiterBookingDetail = ({ route: { params: { bookingId, seatId, print } } }) => {
  const {
    item: completeBooking,
    fetchItem: fetchBooking,
    isFetching: isBookingFetching,
    remove: deleteBooking,
  } = useBookings();
  const {
    bookingItems,
    date,
    refreshData,
    setSeatFocused,
    setBookingItemFocused,
    setSelectedSeats,
    showCustomerBloc,
    setShowMapBloc,
  } = useBeachVisualizer();
  const { navigate, reset } = useNavigation();
  const { t } = useTranslation();

  const { user } = useAuth();
  const { setAlert } = useAlert();
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const styles = makeStyles(theme, isMobile);

  const isDeletable = [
    'admin',
    'superadmin',
  ].includes(user.role?.type)
    ? true : !completeBooking?.booking_items.find((bi) => (bi.status ?? 'reserved') !== 'reserved');

  const currentBookingItem = useMemo(() => (
    bookingItems.find((bi) => (
      bi.booking.id === bookingId && dayjs(bi.date).isSame(date, 'date')
    ))
  ), [bookingItems, bookingId, date]);

  const fetchCompleteBooking = useCallback(async () => {
    try {
      await fetchBooking(bookingId);
    } catch (e) {
      console.log(e);
    }
  }, [bookingId, fetchBooking]);

  useEffect(() => {
    fetchCompleteBooking();
  }, [bookingId, fetchCompleteBooking]);

  const goEditBooking = useCallback(() => {
    navigate(
      'BookingEdit',
      { editedBooking: completeBooking },
    );
  }, [completeBooking, navigate]);

  const handleDeleteBooking = useCallback(async () => {
    await deleteBooking(completeBooking?.id);
    refreshData();
    setSeatFocused(null);
    setBookingItemFocused(null);
    setSelectedSeats([]);
    setAlert({ color: 'success', title: t('common.success'), message: t('bookings.deactivatedBooking') });
    !Platform.OS === 'web' && ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock[(user && ['waiter', 'barman'].includes(user.role?.type))
        ? 'DEFAULT'
        : 'PORTRAIT_UP'],
    );
    reset({
      index: 0,
      routes: [{ name: ['waiter', 'barman'].includes(user.role.type) ? 'WaiterHome' : 'HomePage' }],
    });
  }, [
    deleteBooking,
    completeBooking,
    refreshData,
    setSeatFocused,
    setBookingItemFocused,
    setSelectedSeats,
    setAlert,
    t,
    user,
    reset,
  ]);

  if (!completeBooking) {
    return (
      <Page>
        <View style={{
          justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: theme.normalize(400),
        }}
        >
          {isBookingFetching ? (
            <ActivityIndicator size="large" color={theme.colors.primary} />
          ) : (
            <Text>{t('bookings.noBooking')}</Text>
          )}
        </View>
      </Page>
    );
  }

  return (
    <WaiterLayout noPadding noScroll={!isMobile} hideMenu>
      <View style={styles.splitPage}>
        {((isMobile && !showCustomerBloc) || !isMobile) && (
        <ScrollViewLarge style={styles.mainPart}>
          <Introduction completeBooking={completeBooking} currentBookingItem={currentBookingItem} />

          {completeBooking
            ? (
              <>
                <Orders completeBooking={completeBooking} seatId={seatId} />
                <DatesStatus bookingId={bookingId} currentBookingItem={currentBookingItem} print={print} />
              </>
            )
            : <ActivityIndicator />}

          {/* foot */}
          {isDeletable && (
          <>
            <View style={styles.borderBottom} />

            <ConfirmButton
              onPress={() => handleDeleteBooking()}
              icon="trash-o"
              text={t('bookings.delete')}
              variant="light"
              iconColor="greyDarker"
              confirmText={t('bookings.confirmDeleteBooking')}
              style={{ marginBottom: theme.sizings.medium }}
            />
          </>
          )}

        </ScrollViewLarge>
        )}

        {((isMobile && showCustomerBloc) || !isMobile) && (
        <ScrollViewLarge style={styles.customerPart}>
          <CustomerPanel
            customerContent={completeBooking?.customer || {
              name: completeBooking?.clientName,
              firstName: completeBooking?.clientFirstName,
              email: completeBooking?.clientEmail,
              phone: completeBooking?.clientPhone,
              notes: completeBooking?.comments,
            }}
          />

          {!completeBooking.customer && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Button
              variant="light"
              icon="pencil"
              text={t('customer.addCustomer')}
              onPress={goEditBooking}
              iconColor="primary"
              style={styles.buttonItemEnd}
              small
            />
          </View>
          )}
        </ScrollViewLarge>
        )}

      </View>
    </WaiterLayout>
  );
};

const makeStyles = (theme, isMobile) => StyleSheet.create({
  splitPage: {
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    height: '100%',
  },
  mainPart: {
    flex: isMobile ? undefined : 1,
  },
  customerPart: {
    width: isMobile ? '100%' : '35%',
    maxWidth: isMobile ? undefined : '35%',
    backgroundColor: theme.colors.white,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: {
      width: -2,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 1,
  },
  clientInfo: {
    marginBottom: theme.sizings.medium,
  },

  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingItemInfo: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: isMobile ? 'column' : 'row',
  },
  bookingItemSituation: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  bookingFullPeriod: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  bookingActions: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',

  },

  detailOrders: {
    padding: theme.sizings.medium,
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginVertical: theme.sizings.medium,
  },

  call: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: theme.sizings.small,
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrdersTitle: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: theme.sizings.medium,
    flexDirection: isMobile ? 'column' : 'row',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addOrderLabel: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
  switchLabelIcon: {
    marginRight: theme.sizings.small,
    opacity: 0.6,
  },
  switchBooleanBox: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    borderRadius: theme.radius.rounded,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonBar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? theme.sizings.medium : undefined,
  },
  buttonOrder: {
    marginLeft: theme.sizings.medium,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: theme.sizings.tiny,
  },
  buttonEditItem: {
    height: theme.normalize(38),
    width: theme.normalize(38),
  },
  bookingItemRow: {
    marginTop: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    borderTopColor: theme.colors.greyLighter,
    borderTopWidth: 1,
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'space-between',
  },
  dirRow: {
    flexDirection: 'row',
  },
  dirColumn: {
    flexDirection: 'column',
  },
});

WaiterBookingDetail.propTypes = {
  route: PropTypes.any.isRequired,
};

WaiterBookingDetail.defaultProps = {

};

export default WaiterBookingDetail;
