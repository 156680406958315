import { useNavigation } from '@react-navigation/native';
import useBooking from 'bookings/contexts/bookings';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import {
  ActivityIndicator,
  ScrollView, StyleSheet, View,
  Dimensions,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import Page from 'common/components/Page/Page';
import Button from 'common/components/Button/Button';
import getBookingPrices from 'common/utils/getBookingPrices';
import Price from 'common/components/Price/Price';
import formatPrice from 'common/utils/formatPrice';
import BookingContentSelector from 'bookings/components/BookingForm/components/BookingContentSelector';
import useSiteProvider from 'providers/SiteProvider';

const ContentSelector = ({ route }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { siteId, edit } = route.params;
  const {
    item: booking,
    setItem: setBooking,
    update: updateBooking,
    checkCode,
  } = useBooking();
  const {
    fetchSite,
    site,
    countBookedSeats,
    siteBookingItems,
    totalPublicSeats,
  } = useSiteProvider();

  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const navigation = useNavigation();

  // eslint-disable-next-line no-unused-vars
  const handleSelectFurniture = (priceArea, furniture, nb, _mutators) => {
    let newBookingContent = [];

    // on prend le 1er booking_item pour exemple (tous similaires)
    const currentBookingContent = booking.booking_items[0].bookingContent;

    let found = false;

    if (currentBookingContent?.length > 0) {
      newBookingContent = currentBookingContent.map((bc) => {
        if (bc.furniture === furniture.id && bc.price_area === priceArea.id) {
          found = true;
          const newBc = { ...bc };

          newBc.amount = bc.amount + nb > 0 ? bc.amount + nb : 0;
          return newBc;
        }
        return bc;
      });
    }
    if (!found) {
      newBookingContent.push({ furniture: furniture.id, price_area: priceArea.id, amount: nb > 0 ? nb : 0 });
    }

    // on repercute sur toutes les dates
    setBooking((prevBooking) => {
      const newBookingItems = prevBooking.booking_items.map((bi) => ({
        ...bi,
        bookingContent: newBookingContent,
      }));

      return ({
        ...prevBooking,
        bookingContent: newBookingContent,
        booking_items: newBookingItems,
      });
    });
  };

  useEffect(() => {
    fetchSite(siteId);
  }, [siteId, fetchSite]);

  const bookedSeatsCount = useMemo(() => (
    countBookedSeats(
      booking?.dates,
      siteBookingItems,
      booking?.stayDuration,
      booking,
      site.maps?.find((map) => map.active)?.id,
    )
  ), [booking, countBookedSeats, site, siteBookingItems]);

  const bookingPrices = useMemo(() => (
    (booking && site && !edit)
      ? getBookingPrices(
        booking.booking_items,
        site.price_areas ?? [],
        site.seasons,
        booking.hotel,
      )
      : []
  ), [booking, site, edit]);

  const onContentSubmit = async () => {
    if (edit) {
      try {
        await updateBooking(booking.id, booking);
        const res = await checkCode(booking.code);

        navigation.reset({
          index: 0,
          routes: [
            { name: 'HomePage', params: {} },
            { name: 'SiteDetail', params: { siteId: res.site.id, bookingId: res.id } },
          ],
        });
        setAlert({ color: 'success', title: t('common.success'), message: t('bookings.successChangingSeat') });
      } catch {
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.errorChangingSeat') });
      }
    } else {
      console.log({ booking });
      // tout a déjà été mis à jour dans SelectFurniture()
      navigation.navigate('HourSelect', { siteId });
    }
  };

  return (
    <Page>
      <ScrollView>
        <View style={styles.container}>

          <Text style={styles.label} size="large" isBold centered>{t('common.selectContent')}</Text>

          {Object.keys(totalPublicSeats)?.length ? (
            <BookingContentSelector
              values={booking}
              currentDate={null}
              selectFurniture={handleSelectFurniture}
              bookedSeatsCount={bookedSeatsCount}
              bookingContent={booking.booking_items[0].bookingContent} // because all bi are same
              initialBookingContent={booking.booking_items[0].bookingContent} // because all bi are same
              bookingType="withoutSeats"
              bookingItemIndex="-1"
              seats={totalPublicSeats}
            />
          ) : (
            <View style={{
              width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <ActivityIndicator />
            </View>

          )}

        </View>

        <View style={commonStyles.scrollViewWithButton} />
      </ScrollView>
      <View style={[commonStyles.fullViewDetailsContainer, styles.recap]}>
        <View style={commonStyles.fullViewButtonContainer}>
          <Button
            disabled={booking.booking_items[0].bookingContent?.length === 0}
            text={t('common.validContent')}
            variant="gradient"
            onPress={onContentSubmit}
          />
        </View>
        <View style={[commonStyles.fullViewDetailsContainer2, styles.tmp]}>
          {!edit
            && (
            <View style={[commonStyles.fullViewDetailsPriceWrapper, styles.total]}>
              <Price
                price={bookingPrices.reduce((acc, item) => acc + item.price, 0)}
                color="secondaryLight"
                size="huge"
              />
            </View>
            )}
        </View>
      </View>
    </Page>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: theme.sizings.large,
    paddingHorizontal: theme.sizings.medium,
  },
  total: {
    top: theme.normalize(35),
    paddingRight: theme.sizings.medium,
  },
  recap: {
    width: Dimensions.get('window').width,
    minHeight: theme.normalize(120),
    justifyContent: 'center',
  },
  tmp: {
    height: theme.normalize(120),
    marginBottom: theme.normalize(-15),
  },

});

ContentSelector.propTypes = {
  route: PropTypes.object.isRequired,
};

export default ContentSelector;
