import { StyleSheet } from 'react-native';

export default (theme) => StyleSheet.create({
  card: {
    backgroundColor: theme.colors.dark,
    flex: 1,
    marginBottom: theme.sizings.large,
    flexDirection: 'row',
  },
  imageWrapper: {
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: theme.radius.medium,
    borderBottomLeftRadius: theme.radius.medium,
    overflow: 'hidden',
    flex: 1,
  },
  image: {
    width: '100%',
    minHeight: theme.normalize(230),
  },
  content: {
    flex: 1.25,
    padding: theme.sizings.smallMedium,
  },
});
