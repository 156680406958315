import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import useTranslation from 'common/contexts/translations';

import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import Button from 'common/components/Button/Button';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import ItemCard from 'common/components/ItemCard/ItemCard';
import ReductionPrice from 'common/components/ReductionPrice/ReductionPrice';

import useOffer from 'specialOffers/hooks/use-offer';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const makeStyles = (theme) => StyleSheet.create({
  productsLabel: {
    marginBottom: theme.sizings.small,
  },
  description: {
    padding: theme.sizings.small,
  },
});

const ProductItem = ({ product, navigation }) => {
  const { t } = useTranslation();
  const {
    id, name, images, description, options, price,
  } = product;
  const { findProductOffer } = useOffer();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const reduction = findProductOffer(product);

  const startingPrice = (options && options.length > 0)
    ? price + Math.min(...options.map((o) => Math.min(...o.variant_prices.map((vp) => vp.price))))
    : price;

  const handlePress = () => {
    navigation.navigate('ProductDetail', { product, reduction });
  };

  return (
    <View key={id}>
      <View style={styles.productsLabel}>
        <TranslatedText isBold size="large" value={name} />
      </View>
      <ItemCard
        onPress={handlePress}
        image={images[0]}
      >
        <View style={styles.description}>
          <TranslatedText tagsStyles={{ p: { color: theme.colors.light } }} value={description} truncateLength={50} />
        </View>
        <View style={commonStyles.cardButtonWrapper}>
          <View>
            <Button
              text={t('common.seeDetails')}
              icon="eye"
              variant="light"
              onPress={handlePress}
            />
          </View>
        </View>
        <View style={commonStyles.priceWrapper}>
          <Text
            style={commonStyles.priceLabel}
            centered
            isUppercase
            color="darkBlue"
            size="tiny"
          >
            {t('beach.priceFrom')}

          </Text>
          <ReductionPrice price={startingPrice} reduction={reduction} direction="row" />
        </View>
      </ItemCard>
    </View>
  );
};

ProductItem.propTypes = {
  navigation: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

const renderProducts = (products, navigation) => products.map((product, index) => (
  <ProductItem key={index} product={product} navigation={navigation} />
));

const CategoryDetail = ({ route, navigation }) => {
  const { category } = route.params;
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  return (
    <Page safeArea scroll padding>
      {renderProducts(category.products, navigation) }
      { category.children.map(({ id, name, products }) => (
        <View key={id}>
          <View style={styles.productsLabel}>
            <TranslatedText isBold color="primary" size="larger" value={name} />
          </View>
          {renderProducts(products, navigation) }
        </View>
      ))}
    </Page>
  );
};

CategoryDetail.propTypes = {
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default CategoryDetail;
