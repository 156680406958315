import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useCallback, useEffect,
} from 'react';
import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';
import useTranslation from 'common/contexts/translations';
import useSiteProvider from 'providers/SiteProvider';
import usePersistedState2 from 'common/utils/usePersistedState2';
import useAuth, { useLocalUser } from 'auth/contexts/auth';

const OrderContext = createContext();

export const OrdersProvider = ({ children }) => {
  const { site } = useSiteProvider();
  const { localUser } = useLocalUser();

  const [isOrderStateUpdating, setIsOrderStateUpdating] = useState(false);
  const [waiterCatalog, setWaiterCatalog] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const { t } = useTranslation();
  const [states, setStates] = useState({});
  // const [activeOrdersFilters, setActiveOrdersFilters] = useState({});
  const [barmanList, setBarmanList] = useState([]);
  const [customerHistoricalOrders, setCustomerHistoricalOrders] = useState([]);
  const [isCustomOrdersFetching, setIsCustomOrdersFetching] = useState(false);

  const formatQueryParams = (params) => {
    const esc = encodeURIComponent;
    const queryParams = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((p) => `${esc(key)}=${esc(p)}`).join('&');
        }
        return `${esc(key)}=${esc(params[key])}`;
      })
      .join('&');

    return queryParams;
  };

  const getStates = useCallback(() => {
    setStates({
      created: t('orders.stateCreated'),
      in_preparation: t('orders.stateInPreparation'),
      ready: t('orders.stateReady'),
      done: t('orders.stateServed'),
    });
  }, [t]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const updateOrderState = useCallback(async (id, payload) => {
    setIsOrderStateUpdating(true);

    try {
      const res = await fetchJSON({ url: `orders/${id}/update-state`, method: 'PUT', payload });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    } finally {
      setIsOrderStateUpdating(false);
    }
  }, [setIsOrderStateUpdating]);

  const fetchBarmanList = useCallback(() => {
    fetchJSON({
      url: 'users',
      method: 'GET',
    }).then((users) => {
      if (!users || !users.length) {
        return;
      }
      setBarmanList(users.filter(({ role }) => role?.name === 'barman')
        .map((user) => ({ value: user.id, label: user.username })));
    });
  }, []);

  const fetchActiveOrders = useCallback(async (filters = {}) => {
    try {
      const esc = encodeURIComponent;

      if (localUser?.userId) {
        // eslint-disable-next-line no-param-reassign
        filters = { userId: localUser?.userId };
      }

      const queryParams = Object.keys(filters)
        .map((key) => `${esc(key)}=${esc(filters[key])}`)
        .join('&');
      const res = await fetchJSON({
        url: `orders/active${queryParams ? '?' : ''}${queryParams}`,
        method: 'GET',
      });

      return res;
    } catch (e) {
      throw new Error('BAD_REQUEST');
    }
  }, [localUser]);

  const fetchCustomerHistoricalOrders = useCallback(
    async (customerId) => {
      if (!site?.id) {
        setCustomerHistoricalOrders([]);
        return [];
      }

      setIsCustomOrdersFetching(true);

      const params = {
        _limit: -1,
        'site.id': site?.id,
        'booking.customer': customerId,
        // _sort:
      };

      try {
        const res = await fetchJSON({
          url: `orders?${formatQueryParams(params)}`,
          method: 'GET',
        });

        setCustomerHistoricalOrders(res);
        setIsCustomOrdersFetching(false);
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    [site, setCustomerHistoricalOrders, setIsCustomOrdersFetching],
  );

  return (
    <ContextProvider
      url="orders"
      context={OrderContext}
      value={{
        updateOrderState,
        isOrderStateUpdating,
        states,
        fetchActiveOrders,
        waiterCatalog,
        setWaiterCatalog,
        categorySelected,
        setCategorySelected,
        // activeOrdersFilters,
        // setActiveOrdersFilters,
        fetchBarmanList,
        barmanList,
        customerHistoricalOrders,
        fetchCustomerHistoricalOrders,
        setCustomerHistoricalOrders,
        isCustomOrdersFetching,
      }}
    >
      {children}
    </ContextProvider>
  );
};

OrdersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useOrders = () => {
  const {
    setIsFetching, fetchActiveOrders, setItems,
    // activeOrdersFilters, setActiveOrdersFilters,
    ...rest
  } = useContext(OrderContext);

  const refreshOrders = useCallback(() => {
    const getActivesAndSetOrders = async () => {
      const activeOrders = await fetchActiveOrders(); // fetchActiveOrders(activeOrdersFilters)

      setItems(activeOrders);
      setIsFetching(false);
    };

    setIsFetching(true);
    getActivesAndSetOrders();
  }, [setIsFetching, fetchActiveOrders, setItems]);

  /* const handleActiveOrdersFilters = useCallback((key, value) => {
    setItems([]);
    setIsFetching(true);
    setActiveOrdersFilters((prevFilters) => {
      if (value) {
        return ({ ...prevFilters, [key]: value });
      }
      const newFilters = { ...prevFilters };

      delete newFilters[key];
      return (newFilters);
    });
  }, [setItems, setActiveOrdersFilters, setIsFetching]); */

  return {
    refreshOrders,
    setIsFetching,
    fetchActiveOrders,
    setItems,
    // activeOrdersFilters,
    // handleActiveOrdersFilters,
    ...rest,
  };
};

export const useLocalOrders = () => {
  const [localOrders, setLocalOrders] = usePersistedState2([], 'local-orders', null);

  return { localOrders, setLocalOrders };
};

export default useOrders;
