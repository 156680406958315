import { FontAwesome5 } from '@expo/vector-icons';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import useCustomers from 'customers/contexts/customers';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { ScrollView, StyleSheet, View, useWindowDimensions} from 'react-native';
import { Icon } from 'react-native-elements';
import { TouchableHighlight } from 'react-native-gesture-handler';
import useTheme from 'providers/ThemeProvider';
import { useNavigation } from '@react-navigation/native';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import useAuth from 'auth/contexts/auth';
import { isMobileMode } from 'common/utils/desktopMode';
import useSite from 'sites/contexts/sites';

const BookingFormSwitch = ({
  formTab, setFormTab,
  values, submitting, handleSubmit, handleDelete, bookingPrices,
  hasOverbooking
}) => {
  const { t } = useTranslation();

  const {
    items: customers,
  } = useCustomers();
  const {user} = useAuth();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isMobile = isMobileMode({ width, height });
  const styles = makeStyles(theme, isMobile);
  const navigation = useNavigation();
  const { currentSite } = useSite();

  const missingCustomer = (!values.customer && !values.clientName)
  || (!values.waiterAuthor && currentSite?.waiterAuthorRequired);

  const missingDate = values.dates.length < 1;

  const missingPrices = bookingPrices?.find((bp) => bp.errorSeason) 
  || bookingPrices?.find((bp) => bp.errorFurniturePrice)
  || !bookingPrices?.length;

  const missingSeats = (values.seats?.length < 1 && !values.bookingContent?.find((bc) => bc.amount > 0));

  const missingPosition = missingPrices || missingSeats || hasOverbooking;

  const missingValues = missingCustomer || missingDate || missingPosition;

  const printDates = () => {
    const dates = values?.dates?.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
    const firstDate = dayjs(dates[0]).format('L');
    const lastDate = dayjs(dates[dates.length - 1]).format('L');

    if (dates.length === 1) {
      return firstDate;
    }
    if (values.periodType === 'multiple') {
      return `${firstDate} ... ${lastDate}`;
    } if (values.periodType === 'range') {
      return `${firstDate} > ${lastDate}`;
    }
    return null;
  };

  const tabs = [ 
    {
      tab: 'customer',
      label: t('bookings.formTabCustomer'),
      resume: values?.customer ? [customers.find(({ id }) => id === values?.customer)?.name] : values?.clientName ? 
      [`${values?.clientName} ${values?.clientFirstName || ''}`] : [],
      icon: 'user',
    },
    {
      tab: 'dates',
      label: t('bookings.formTabDates'),
      resume: values?.dates?.length ? [printDates()] : [],
      disabled: missingCustomer,
      icon: 'calendar',
    },
    {
      tab: 'position',
      label: t('bookings.formTabPosition'),
      resume: values?.bookingType === 'withSeats' ? [t('bookings.withSeats')] : [t('bookings.withoutSeats')],
      disabled: missingCustomer || missingDate,
      icon: 'map',
    },
  ];

const isDeletable = [
    'admin',
    'superadmin',
  ].includes(user.role?.type) ? true : !values?.booking_items.find((bi) => bi.status !== 'reserved');




  return (
    <View style={styles.mainContainer}>

{!isMobile && (
      <TouchableHighlight
        onPress={() => navigation.navigate('maps')}
        style={styles.goBack}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FontAwesome5
            name="arrow-left"
            size={theme.fontSizes.medium}
            color={theme.colors.greyLightest}
          />

          {!isMobile && (
          <Text
            size="medium"
            color="greyLightest"
            style={{ marginLeft: theme.sizings.medium }}
          >
            { t(
              `bookings.${
                values?.id ? 'cancelEdit' : 'cancelCreate'
              }`
            )}
          </Text>
          )}
        </View>
      </TouchableHighlight>
)}

      <ScrollView style={styles.container} horizontal>
        <View style={styles.tabsBar}>
          <View style={styles.tabsBarInner}>
            {tabs.map((item, tabIndex) => (
              <View key={`tabinfo-${tabIndex}`} style={{flexDirection: 'row', alignItems: 'center'}}>
              
                <TouchableHighlight
                  onPress={() => setFormTab(item.tab)}
                  disabled={item.disabled}
                  style={[styles.tabSelectorButton, formTab === item.tab && {
                    backgroundColor: theme.colors.purple,
                  }, {
                    opacity: item.disabled ? 0.5 : 1,
                  }]}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesome5
                      name={item.icon}
                      type="fontawesome5"
                      size={theme.fontSizes.larger}
                      color={formTab === item.tab ? theme.colors.light : theme.colors.dark}
                    />

                    {!isMobile && (
                    <View style={{ marginLeft: theme.sizings.small, flex: 1 }}>
                      <Text size="medium" color={formTab === item.tab ? 'light' : 'dark'}>
                        {item.label}
                      </Text>
                      {item.resume?.map((data, textIndex) => (
                        <Text size="small" color={formTab === item.tab ? 'light' : 'dark'}
                        key={`tabinfo-${tabIndex}-${textIndex}`}>
                          {data}
                        </Text>
                      ))}
                    </View>
                    )}

                  </View>

                </TouchableHighlight>
                {tabIndex + 1 < tabs.length && (
                <Icon
                  name="arrow-right"
                  type="fontawesome5"
                  size={theme.fontSizes.largest}
                  color={theme.colors.primary}
                  style={{ marginHorizontal: theme.sizings.small }}
                />
                )}
              </View>
            ))}

          </View>
        </View>

      </ScrollView>

   
      <TouchableHighlight
        onPress={handleSubmit}
        disabled={missingValues || submitting}
        type="submit"
        style={missingValues || submitting ? styles.submitDisabled : styles.submit}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FontAwesome5
            name="save"
            size={theme.fontSizes.medium}
            color={missingValues || submitting ? theme.colors.greyLightest : theme.colors.dark}
          />
          {!isMobile && (
          <Text
            size="medium"
            color={missingValues || submitting ? 'greyLightest' : 'dark'}
          style={{ marginLeft: theme.sizings.medium }}

          >
            { t(
              `bookings.${
              values?.id ? 'edit' : 'add'
              }Booking`,
            )}
          </Text>
          )}
        </View>
      </TouchableHighlight>

    </View>
  );
};

BookingFormSwitch.propTypes = {
  formTab: PropTypes.string,
  setFormTab: PropTypes.any,
  values: PropTypes.any.isRequired,
  submitting: PropTypes.any.isRequired,
  handleSubmit: PropTypes.any.isRequired,
  handleDelete: PropTypes.any.isRequired,
  bookingPrices: PropTypes.array.isRequired,
  hasOverbooking: PropTypes.bool,
};

BookingFormSwitch.defaultProps = {
  formTab: null,
  setFormTab: null,
  hasOverbooking: false,
};

const makeStyles = (theme, mobileMode) => StyleSheet.create({
  mainContainer: { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: mobileMode ? theme.normalize(55) : theme.normalize(80),
    backgroundColor: theme.colors.dark
  },
  container: {
    backgroundColor: theme.colors.darkerBlue,
    zIndex: 10,
    width: '100%',
    height: mobileMode ? theme.normalize(55) : theme.normalize(80),
    maxHeight: mobileMode ? theme.normalize(55) : theme.normalize(80),
  },
  tabsBar: {
    minWidth: '100%',
    width: '100%',
    height: '100%',
    paddingRight: theme.sizings.medium,
    flex: 1,
  },
  tabsBarInner: {
    minWidth: '100%',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: theme.sizings.small,
  },
  tabSelectorButton: {
    backgroundColor: theme.colors.light,
    marginHorizontal: theme.sizings.tiny,
    marginVertical: theme.sizings.tiny,
    borderRadius: theme.radius.small,
    paddingHorizontal: theme.sizings.medium,
    justifyContent: 'center',
    flex: 1,
    width: mobileMode ? 'auto' : theme.normalize(200),
    minHeight: theme.normalize(70),
  },
  goBack: {
    backgroundColor: theme.colors.dark,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.sizings.medium,
  },
  submitDisabled: {
    backgroundColor: theme.colors.dark,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.sizings.medium,
  },
  submit: {
    backgroundColor: theme.colors.secondary,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.sizings.medium,
    cursor: 'pointer',
  },
});

export default BookingFormSwitch;
