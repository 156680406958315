import { useNavigation } from '@react-navigation/native';
import useBookings from 'bookings/contexts/bookings';
import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';
import useBasket from 'basket/contexts/basket';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import formatPrice from 'common/utils/formatPrice';
import useSiteProvider from 'providers/SiteProvider';
import BookingPaymentTypeModal from '../BookingPayments/BookingPaymentTypeModal';

const BookingQuickButtons = ({
  seats, hotel, roomID, bookingItem, isMapFooter, print,
}) => {
  const { t } = useTranslation();
  const { item: site } = useSiteProvider();
  const {
    changeBookingItemStatus,
    payBookingItem,
  } = useBookings();

  const {
    seatFocused,
    fetchBookingItems,
  } = useBeachVisualizer();

  const { setItems: setBasket } = useBasket();

  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeStatusValue = useCallback(async (newStatus) => {
    setIsLoading(true);
    await changeBookingItemStatus(bookingItem.id, newStatus);
    await fetchBookingItems();
    setIsLoading(false);
  }, [bookingItem, setIsLoading, changeBookingItemStatus, fetchBookingItems]);

  const expectedSeatCount = bookingItem.bookingContent?.reduce((acc, item) => acc + item.amount, 0);
  const isMissingSeatsAssignation = bookingItem.seats.length < expectedSeatCount;
  const isNeverPlaced = bookingItem.bookingStatus !== 'withSeats'
                        && bookingItem.seats.length === 0
                        && !bookingItem.override;

  const handleCheckOut = async () => {
    if (bookingItem.isFullyPaid || bookingItem.booking.isFullyPaid) {
      await handleChangeStatusValue('checked_out');
    } else {
      setModalPaymentVisible(true);
    }
  };

  const handlePay = useCallback(async (id, offlineMethod) => {
    setModalPaymentVisible(false);
    setIsPaymentLoading(true);
    await payBookingItem(id, offlineMethod);
    await handleChangeStatusValue('checked_out');
    await fetchBookingItems();
    setIsPaymentLoading(false);
  }, [setModalPaymentVisible, setIsPaymentLoading,
    payBookingItem, handleChangeStatusValue, fetchBookingItems]);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [modalPaymentVisible, setModalPaymentVisible] = useState(false);
  const total = bookingItem.price
    - (bookingItem.discount ?? 0)
    - (bookingItem.payments?.reduce((acc, item) => acc + item.amount) ?? 0);

  return (
    <View style={[styles.bookingButtonContainer]}>
      <Button
        icon="edit"
        iconFamily="MaterialIcons"
        iconColor="purple"
        iconSize="medium"
        size="medium"
        style={[styles.iconOnlyButton, {
          backgroundColor: theme.colors.white,
          borderWidth: 1,
          borderColor: '#dddddd',
          borderStyle: 'solid',
        }]}
        onPress={() => navigation.navigate(
          'BookingEdit',
          { editedBooking: bookingItem.booking },
        )}
        noPadding
      />
      {print && (
        <Button
          icon="print"
          iconFamily="MaterialIcons"
          iconColor="purple"
          iconSize="medium"
          size="medium"
          style={[styles.iconOnlyButton, {
            backgroundColor: theme.colors.white,
            borderWidth: 1,
            borderColor: '#dddddd',
            borderStyle: 'solid',
          }]}
          onPress={() => print(bookingItem)}
          noPadding
        />
      )}
      {/** ************************************** Toggle NoShow ***************************************** */}

      {bookingItem?.status === 'reserved' && (
        <ConfirmButton
          icon="ghost"
          iconFamily="fontawsome5"
          iconColor="white"
          iconSize="medium"
          style={[styles.iconOnlyButton, {
            backgroundColor: theme.colors.greyAlpha,
          }]}
          confirmText={<Text>{t('bookings.confirmNoShow')}</Text>}
          noPadding
          onPress={() => handleChangeStatusValue('noShow')}
          disabled={isLoading}
        />
      )}

      {bookingItem?.status === 'noShow' && (
        <ConfirmButton
          icon="ghost"
          iconFamily="fontawsome5"
          iconColor="white"
          iconSize="medium"
          style={[styles.iconOnlyButton, {
            backgroundColor: theme.colors.dangerBright,
          }]}
          confirmText={(
            <>
              <Text>{t('bookings.confirmValid')}</Text>
              {hotel && (
              <Text size="large" style={commonStyles.modalText}>{t('common.checkRoomID', { roomID })}</Text>
              )}
            </>
          )}
          noPadding
          onPress={() => handleChangeStatusValue('checked_in')}
          disabled={isLoading}
        />
      )}

      {/** ************************************** Localisation  ***************************************** */}
      {bookingItem?.status !== 'cancelled' && (
        isMissingSeatsAssignation
          ? (
            <Button
              icon="add-location"
              iconFamily="MaterialIcons"
              iconColor="white"
              iconSize="medium"
              size="medium"
              style={[styles.iconOnlyButton, {
                backgroundColor: isNeverPlaced ? theme.colors.dangerBright : theme.colors.darkOrange,
              }]}
              onPress={() => navigation.navigate(
                'BookingEdit',
                { editedBooking: bookingItem.booking, editPositionsAtDate: bookingItem?.date },
              )}
              noPadding
            />
          )
          : (
            <Button
              icon="edit-location"
              iconFamily="MaterialIcons"
              iconColor="white"
              iconSize="medium"
              size="medium"
              style={[styles.iconOnlyButton, {
                backgroundColor: theme.colors.primary,
              }]}
              onPress={() => {
                navigation.navigate(
                  'BookingEdit',
                  { editedBooking: bookingItem.booking, editPositionsAtDate: bookingItem?.date },
                );
              }}
              noPadding
            />
          )
      )}

      {/** ********************************* Enter/Exit (activate/deactivate) *********************************** */}

      {!isMissingSeatsAssignation && (bookingItem?.status === 'reserved' || bookingItem?.status === 'checked_out') && (
        <ConfirmButton
          icon="logout"
          iconFamily="MaterialIcons"
          iconColor="white"
          iconSize="medium"
          style={[styles.iconTextButton, {
            backgroundColor: bookingItem?.status === 'reserved' ? theme.colors.darkerGreen : theme.colors.greyAlpha,
          }]}
          text={t('bookings.bookingItemStatus_do_checked_in')}
          confirmText={(
            <>
              <Text>{t('bookings.confirmValid')}</Text>
              {hotel && (
              <Text size="large" style={commonStyles.modalText}>{t('common.checkRoomID', { roomID })}</Text>
              )}
            </>
          )}
          small
          onPress={() => handleChangeStatusValue('checked_in')}
          disabled={isLoading}
        />
      )}

      {!isMissingSeatsAssignation && bookingItem?.status === 'checked_in' && (
        isPaymentLoading ? (
          <ActivityIndicator />
        ) : (
          bookingItem.isFullyPaid ? (
            <ConfirmButton
              icon="login"
              iconFamily="MaterialIcons"
              iconColor="white"
              iconSize="medium"
              style={[styles.iconTextButton, {
                backgroundColor: theme.colors.greyAlpha,
              }]}
              confirmText={<Text>{t('bookings.confirmUnvalid')}</Text>}
              text={t('bookings.bookingItemStatus_do_checked_out')}
              small
              onPress={() => handleCheckOut()}
            />
          ) : (
            <Button
              icon="login"
              iconFamily="MaterialIcons"
              iconColor="white"
              iconSize="medium"
              style={[styles.iconTextButton, {
                backgroundColor: theme.colors.greyAlpha,
              }]}
              text={t('bookings.bookingItemStatus_do_checked_out')}
              small
              onPress={() => handleCheckOut()}
            />
          )

        )
      )}

      {/** ************************************** Orders ***************************************** */}
      {bookingItem?.status === 'checked_in' && (
        <Button
          icon="utensils"
          iconFamily="fontawsome5"
          iconColor="secondaryDark"
          iconSize="medium"
          size="medium"
          style={[styles.iconOnlyButton, {
            borderWidth: 1,
            borderColor: '#dddddd',
            borderStyle: 'solid',
            backgroundColor: theme.colors.white,
          }]}
          onPress={() => {
            setBasket([]);
            navigation.navigate('OrderSeatSelect',
              { bookingItem, seat: isMapFooter ? seatFocused : (seats?.length === 1 ? seats[0] : undefined) });
          }}
          noPadding
        />
      )}

      <BookingPaymentTypeModal
        setModalVisible={setModalPaymentVisible}
        modalVisible={modalPaymentVisible}
        handleValidate={(methodSelected) => handlePay(bookingItem.id, methodSelected)}
        modalText={<Text>{t('bookings.confirmUnvalidAndPay', { balance: formatPrice(total, site?.currency) })}</Text>}
      />
    </View>
  );
};

BookingQuickButtons.propTypes = {
  seats: PropTypes.array,
  hotel: PropTypes.bool,
  roomID: PropTypes.string,
  bookingItem: PropTypes.object,
  isMapFooter: PropTypes.bool,
  print: PropTypes.func,
};

BookingQuickButtons.defaultProps = {
  seats: [],
  hotel: false,
  roomID: '',
  bookingItem: null,
  isMapFooter: false,
  print: null,
};

const makeStyles = (theme) => StyleSheet.create({
  bookingButtonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },

  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  icon: {
    margin: theme.sizings.tiny,
  },

  bookingButton: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.small,
  },

  divider: {
    marginLeft: theme.normalize(20),
    marginRight: theme.normalize(20),
    height: theme.normalize(25),
    width: 1,
    backgroundColor: 'rgba(147,147,147,0.5)',
  },
  iconOnlyButton: {
    marginLeft: theme.sizings.small,
    marginVertical: theme.sizings.tiny,
  },
  iconTextButton: {
    marginLeft: theme.sizings.small,
    marginVertical: theme.sizings.tiny,
  },
});

export default BookingQuickButtons;
