import Card from 'common/components/Card/Card';
import { Image } from 'common/components/Images';
import PropTypes from 'prop-types';
import React from 'react';
import {
  View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeStyles from './ItemCard.styles';

const ItemCard = ({
  children, image, onPress,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  return (
    <Card isClickable onPress={onPress} noPadding noShadow style={styles.card}>
      <View style={styles.imageWrapper}>
        <Image
          style={styles.image}
          image={image}
        />
      </View>

      <View style={styles.content}>{children}</View>
    </Card>
  );
};

ItemCard.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object,
  onPress: PropTypes.func.isRequired,
};

ItemCard.defaultProps = {
  image: null,
};

export default ItemCard;
