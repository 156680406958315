import { StyleSheet } from 'react-native';

export default (noPadding, noShadow, theme) => StyleSheet.create({
  card: {
    backgroundColor: theme.colors.white,
    width: '100%',
    alignSelf: 'stretch',
    borderRadius: theme.radius.medium,
    padding: noPadding ? 0 : theme.sizings.medium,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: {
      width: 0,
      height: noShadow ? 0 : 2,
    },
    shadowOpacity: noShadow ? 0 : 0.5,
    shadowRadius: noShadow ? 0 : 3,
    elevation: noShadow ? 0 : 1,
  },
});
