import { FontAwesome } from '@expo/vector-icons';
import useBooking from 'bookings/contexts/bookings';
import Confirm from 'common/components/Confirm/Confirm';
import Price from 'common/components/Price/Price';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useOrders from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Dimensions, StyleSheet,
  TouchableOpacity, View,
} from 'react-native';
import makeOrderStyles from 'sites/pages/SiteDetail/styles/OrderSummary.styles';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import OrderItem from 'waiter/components/BookingPayments/OrderItem';

const Order = ({ order, booking }) => {
  const [isShow, setIsShow] = useState(false);

  const { fetchItems: fetchBookings } = useBooking();
  const { states, save: saveOrder } = useOrders();
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const orderStyles = makeOrderStyles(theme);
  const styles = makeStyles(theme);

  const toggleItems = () => setIsShow((prevShow) => !prevShow);

  const reOrder = useCallback(async () => {
    const payload = { ...order };

    delete payload.id;
    delete payload.payment.id;

    const newPayload = {
      ...payload,
      booking: booking.id,
      state: 'created',
      site: booking.site.id,
      payment: {
        status: 'PENDING',
        amount: payload.payment.amount,
        offline_method: 'CASH',
        site: booking.site.id,
        transactionFees: payload.payment.transactionFees,
        user: payload.payment.user,
      },
    };

    try {
      await saveOrder(newPayload);

      setAlert({ color: 'success', title: t('common.success'), message: t('common.success') });
      fetchBookings();
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [order, booking, saveOrder, fetchBookings, setAlert, t]);

  return (
    <TouchableOpacity onPress={toggleItems}>
      <View
        style={[
          styles.booking,
          commonStyles.shadowBottom,
          {
            backgroundColor: order.state === 'done' && order.payment.status === 'PAID'
              ? '#cce0ff' : theme.colors.secondaryLight,
          },
        ]}
      >

        <View style={styles.bookingLeft}>
          <View style={styles.bookingStatus}>
            <FontAwesome
              name={order.state === 'done' ? 'check' : 'clock-o'}
              style={styles.bookingStatusIcon}
            />
            <Text size="small">
              {states[order.state]}
            </Text>
          </View>
          <View style={styles.viewSeparator} />
          <View style={[styles.bookingStatus]}>
            <FontAwesome
              name={order.payment?.status === 'PAID' ? 'check' : 'clock-o'}
              style={styles.bookingStatusIcon}
            />
            <Text size="small">
              {order.payment?.status === 'PAID' ? t('bookings.paymentPaid') : t('bookings.paymentPending')}
            </Text>
          </View>
          <View style={[styles.bookingStatus, commonStyles.shadowBottom, styles.date]}>
            <Text size="small" style={{ fontStyle: 'italic', justifyContent: 'center' }}>
              <RelativeDate value={order.updated_at || order.created_at} />
            </Text>
          </View>
        </View>

        <View style={styles.bookingButtonContainer}>
          <Price
            noLineHeight
            price={order.payment.amount}
            size="larger"
          />
          {order.state === 'done' && <View style={styles.viewSeparator} />}
          {order.state === 'done' && (
            <Confirm confirmText={t('orders.reOrderConfirm')}>
              <TouchableOpacity style={styles.btnReOrder} onPress={reOrder}>
                <Text isBold style={styles.textReorderBtn}>{t('orders.reOrderBtn')}</Text>
              </TouchableOpacity>
            </Confirm>
          )}
        </View>
      </View>

      <View style={[orderStyles.orderItems, { marginBottom: 5 }]}>
        {isShow && order.order_items.map((item) => <OrderItem key={item.id} item={item} />)}
      </View>
    </TouchableOpacity>
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

const SCREEN_WIDTH = Dimensions.get('screen').width;

const makeStyles = (theme) => StyleSheet.create({
  btnReOrder: {
    alignSelf: 'center',
    borderRadius: theme.radius.rounded,
  },

  date: {
    paddingVertical: theme.sizings.tiny,
    borderRadius: theme.radius.small,
    paddingHorizontal: theme.sizings.small,
    marginVertical: theme.sizings.tiny,
    marginLeft: theme.sizings.tiny,
  },

  gradientBtnBackground: {
    borderRadius: theme.radius.rounded,
    width: SCREEN_WIDTH * 0.2,
    height: theme.normalize(30),
    justifyContent: 'center',
  },

  textReorderBtn: {
    alignSelf: 'center',
  },

  header: {
    marginBottom: theme.sizings.medium,
  },
  bookingPaymentView: {
    justifyContent: 'center',
  },

  amount: {
    justifyContent: 'center',
  },

  viewSeparator: {
    marginHorizontal: theme.sizings.small,
    height: theme.normalize(25),
    width: 1,
    backgroundColor: 'rgba(147,147,147,0.5)',
  },

  booking: {
    marginTop: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.small,
    flexDirection: 'row',
    alignItems: 'center',
  },

  orderItem: {
    marginBottom: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'center',
  },

  bookingMeta: {
    flexDirection: 'row',
  },
  bookingLeft: {
    flexDirection: 'row',
    alignContent: 'space-between',
    flex: 1.25,
    flexWrap: 'wrap',
  },
  bookingStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },

  bookingStatusIcon: {
    marginRight: theme.sizings.small,
  },

  bookingCode: {
    paddingRight: theme.sizings.medium,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  bookingContent: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.greyLighter,
    paddingLeft: theme.sizings.medium,
  },
  bookingDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  bookingButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  bookingButton: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
  },
});

export default Order;
