import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const Confirm = ({ confirmText, children }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const child = React.Children.only(children);

  const handleConfirm = () => {
    setModalVisible(false);
    child.props.onPress();
  };

  return (
    <>
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        <View style={commonStyles.modalInner}>

          <TouchableOpacity
            onPress={() => setModalVisible(false)}
            style={commonStyles.modalMask}
          />
          <View style={commonStyles.modalBox}>
            <Text style={commonStyles.modalText}>{confirmText}</Text>
            <View style={commonStyles.modalFooter}>
              <Button
                variant="greyLighter"
                text={t('common.cancel')}
                onPress={() => setModalVisible(false)}
                style={commonStyles.modalButton}
              />
              <Button
                variant="success"
                text={t('common.confirm')}
                onPress={handleConfirm}
                style={commonStyles.modalButton}
              />
            </View>
          </View>
        </View>
      </Modal>
      {React.cloneElement(child, { onPress: () => setModalVisible(true) })}
    </>
  );
};

Confirm.propTypes = {
  confirmText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.node.isRequired,
};

Confirm.defaultProps = {
  confirmText: 'Confirm ?',
};

export default Confirm;
