import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import * as Location from 'expo-location';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SiteListItem from 'sites/components/SiteListItem/SiteListItem';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';

const SitesList = ({ navigation }) => {
  const {
    fetchItems: fetchSites,
    items: sites,
    isFetching,
    setFilters,
  } = useSite();
  const { theme } = useTheme();

  useEffect(() => {
    const fetch = async () => {
      const { status } = await Location.requestPermissionsAsync();

      if (status === 'granted') {
        const position = await Location.getCurrentPositionAsync({});

        setFilters({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    };

    fetch();
  }, [setFilters]);

  // fetchSites change à chaque fois que setFilters est appelé
  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  return (
    <Page safeArea scroll padding style={{ marginBottom: 0, paddingBottom: 0 }}>
      {!sites && sites.length < 1 && (
        <LoadingSpinner
          visible={isFetching}
          theme={theme}
        />
      )}
      {sites.map((site) => (
        <SiteListItem
          navigation={navigation}
          key={site.id}
          siteCurrency={site.currency}
          targetLink="SitePresentation"
          {...site}
        />
      ))}
    </Page>
  );
};

SitesList.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default SitesList;
