import { useEffect } from 'react';
import { AppState, Platform } from 'react-native';
import * as Updates from 'expo-updates';
import dayjs from 'dayjs';

import useAuth from 'auth/contexts/auth';
import useCall from 'calls/contexts/calls';
import useOrders from 'orders/contexts/orders';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useChat from 'sites/contexts/chat';
import useCategories from 'categories/contexts/categories';
import useBooking from 'bookings/contexts/bookings';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

import { resetNavigation } from './RootNavigation';

const AppStateHandler = ({ children }) => {
  const {
    user, refreshUser, booking, setBooking,
  } = useAuth();
  const {
    fetchItems: fetchCalls,
  } = useCall();
  const {
    fetchItems: fetchOrders, filters: ordersFilters, refreshOrders, fetchBarmanList,
  } = useOrders();
  const {
    setItems: setSpecialOffers,
    fetchSpecialOffersApplicable,
    fetchItems: fetchSpecialOffers,
  } = useSpecialOffers();
  const {
    fetchUsers, fetchMessages, chatActive,
  } = useChat();
  const {
    fetchItems: fetchCategories,
  } = useCategories();
  const {
    fetchItem: fetchBooking,
  } = useBooking();
  const {
    refreshData,
  } = useBeachVisualizer();

  useEffect(() => {
    const handleAppStateChange = async (nextAppState) => {
      if (!__DEV__ && Platform.OS !== 'web' && nextAppState === 'active') {
        try {
          const update = await Updates.checkForUpdateAsync();

          if (update.isAvailable) {
            await Updates.fetchUpdateAsync();
            await Updates.reloadAsync();
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (nextAppState === 'active') {
        if (user?.role && ['waiter', 'barman'].includes(user.role.type)) {
          fetchBarmanList();
          fetchCalls();
          refreshOrders();
          refreshUser();
          fetchCategories();
          fetchSpecialOffers();
          refreshData();
        } else if (user) {
          // user
          if (ordersFilters.booking) {
            fetchOrders();
          }
          if (booking) {
            const fetchAndSetData = async () => {
              const applicables = await fetchSpecialOffersApplicable({ booking: booking.id, site: booking.site });

              setSpecialOffers(applicables);
            };

            fetchAndSetData();

            if (chatActive) {
              fetchUsers();
              fetchMessages();
            }
          }
        } else {
          const refreshBooking = async () => {
            const result = await fetchBooking(booking.id);

            if (!result.booking_items.find(({ date }) => dayjs().isSame(date, 'date'))) {
              resetNavigation({
                index: 0,
                routes: [{ name: 'HomePage' }],
              });
            } else {
              setBooking(result);
            }
          };

          if (booking) {
            refreshBooking();
          }
        }
      }
    };

    const listener = AppState.addEventListener('change', handleAppStateChange);

    return () => listener.remove();
  }, [fetchBooking, booking, chatActive, fetchBarmanList, fetchCalls, fetchCategories, fetchMessages,
    fetchOrders, fetchSpecialOffers, fetchSpecialOffersApplicable, fetchUsers, ordersFilters, refreshData,
    refreshOrders, refreshUser, setBooking, setSpecialOffers, user]);

  /* fetchBooking, booking, chatActive, fetchBarmanList, fetchCalls, fetchCategories, fetchMessages,
    fetchOrders, fetchSpecialOffers, fetchSpecialOffersApplicable, fetchUsers, ordersFilters, refreshData,
    refreshOrders, refreshUser, setBooking, setSpecialOffers, user */

  return children;
};

export default AppStateHandler;
