import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/el';
import 'intl/locale-data/jsonp/es';
import i18n from 'i18next';

const formatPrice = (price, siteCurrency) => {
  const roundedPrice = Math.round(price * 100) / 100;
  const checkCurrency = siteCurrency || 'EUR';

  if (roundedPrice % 1 === 0) {
    return (new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: checkCurrency,
      // currencyDisplay: 'narrowSymbol',
    }).format(roundedPrice.toFixed(0)));
  }
  let formatedPrice = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: checkCurrency,
    // currencyDisplay: 'narrowSymbol',
  }).format(roundedPrice);

  if ((roundedPrice * 10) % 1 === 0) {
    formatedPrice += '0';
  }

  return (formatedPrice);
};

export default formatPrice;
