import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Dimensions, StyleSheet, TouchableOpacity, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import BookingQuickButtons from '../BookingQuickButtons/BookingQuickButtons';
import StayDuration from '../booking-detail-sections/StayDuration';
import OrderInProgress from '../booking-detail-sections/OrderInProgress';
import SeatsResume from '../booking-detail-sections/SeatsResume';
import ExpectedSeatsResume from '../booking-detail-sections/ExpectedSeatsResume';
import Room from '../booking-detail-sections/Room';

const BookingListItem = ({
  bookingItem,
  isMapFooter,
  currentStatus,
  printBookingItem,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const {
    bookingItemFocused,
  } = useBeachVisualizer();

  const {
    booking: {
      id: bookingId, customer, clientName, clientFirstName, hotel, roomID, orders,
      created_by_customer: createdByCustomer, comments, bookingType,
    },
    bookingContent: expectedSeats,
    isFullyPlaced,
    isFullyPaid,
    seats,
  } = bookingItem;

  const customerCame = bookingItem?.status === 'checked_in';
  const ordersLength = orders?.find((order) => order.state === 'created' && order.active)?.length;

  const nbSeatsNotPlaced = bookingType === 'withSeats'
    ? expectedSeats.reduce((acc, item) => {
      const relatedSeats = seats.filter((s) => (
        s.furniture === item.furniture.id && s.price_area === item.price_area.id
      ));

      return acc + (item.amount - relatedSeats?.length);
    }, 0)
    : 0;

  const displayIntro = () => (
    <View style={styles.iconText}>
      <FontAwesome5
        name={customer?.customer_category?.name === 'VIP' ? 'crown' : (customer?.noShow > 0 ? 'ghost' : 'user')}
        color={customer?.customer_category?.name === 'VIP'
          ? theme.colors.secondaryDark : (customer?.noShow > 0 ? theme.colors.dangerLight : theme.colors.primary)}
        size={theme.sizings.medium}
        style={styles.icon}
      />
      <View style={[{
        flex: 1, paddingLeft: theme.sizings.tiny,
      }]}
      >
        <Text
          isBold
          size="medium"
          color={customerCame ? 'dark' : 'greyDarkest'}
          style={{ flex: 1 }}
        >
          {customer?.name
            ? `${customer.civility
              ? `${t(`customer.civility${customer.civility}`)} `
              : ''}${customer.name} ${customer.firstName || ''}`
            : `${clientName} ${clientFirstName || ''}`}
          {/* todo : if created by himself */}
          {Boolean(createdByCustomer) && ` ${t('bookings.customerIsAuthor')}`}
        </Text>

        <StayDuration stayDuration={bookingItem.stayDuration} />
      </View>
    </View>
  );

  return (
    <View style={{
      ...styles.bookingRow,
      backgroundColor: !isMapFooter && bookingItemFocused?.id === bookingItem.id
        ? theme.colors.lightPurple
        : theme.colors.greyLightest,
    }}
    >
      <View style={styles.bookingBox}>

        {currentStatus !== 'reserved' && !isFullyPaid && (
        <View style={{ width: theme.sizings.small, height: '100%', backgroundColor: theme.colors.danger }} />
        )}

        {currentStatus !== 'reserved' && isFullyPaid && (
        <View style={{ width: theme.sizings.small, height: '100%', backgroundColor: theme.colors.darkerGreen }} />
        )}

        <TouchableOpacity
          onPress={() => navigation.navigate('WaiterBookingDetail', { bookingId, print: printBookingItem })}
          style={styles.bookingLeft}
        >
          <>
            {displayIntro()}

            {Boolean(ordersLength) && (
            <OrderInProgress ordersLength={orders?.length} />
            )}
            {Boolean(bookingItem?.seats.length) && (
            <SeatsResume seats={seats} customerCame={customerCame} isMapFooter={isMapFooter} />
            )}
            {!isFullyPlaced && (
            <ExpectedSeatsResume expectedSeats={expectedSeats} customerCame={customerCame} />
            )}
            {hotel && (
            <Room roomID={roomID} customerCame={customerCame} />
            )}
            {comments && (
            <Text size="small">
              {comments}
            </Text>
            )}

          </>
        </TouchableOpacity>

        {/* seuls les champs "hotel et roomID et ID" sont dans booking */}
        <View style={styles.bookingRight}>
          {currentStatus && (
          <Text
            size="small"
            style={{ fontStyle: currentStatus === 'checked_in' ? 'normal' : 'italic', marginTop: theme.sizings.tiny }}
            isBold={currentStatus === 'checked_in'}
            color={currentStatus === 'checked_in' ? 'darkerGreen' : 'greyAlpha'}
          >
            {t(`bookings.bookingItemStatus_${currentStatus}`)}
          </Text>
          )}
          {!!nbSeatsNotPlaced && (
            <Text
              size="small"
              isBold
              color="danger"
            >
              {t('bookings.seatToPosition', { nb: nbSeatsNotPlaced })}
            </Text>
          )}
          <BookingQuickButtons
            bookingItem={bookingItem}
            seats={seats}
            customerCame={customerCame}
            hotel={hotel}
            roomID={roomID}
            isMapFooter={isMapFooter}
            print={printBookingItem}
          />
        </View>
      </View>
    </View>
  );
};

BookingListItem.propTypes = {
  bookingItem: PropTypes.object.isRequired,
  isMapFooter: PropTypes.bool,
  currentStatus: PropTypes.string,
  printBookingItem: PropTypes.func,
};

BookingListItem.defaultProps = {
  isMapFooter: false,
  currentStatus: null,
  printBookingItem: null,
};

const makeStyles = (theme) => StyleSheet.create({
  bookingRow: {
    marginVertical: theme.sizings.tiny,
    borderRadius: theme.sizings.small,
    overflow: 'hidden',
  },
  bookingBox: {
    flexDirection: 'row',
  },
  bookingLeft: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    minWidth: '55%',
    maxWidth: '55%',
  },
  bookingRight: {
    flex: 1,
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    borderLeftColor: 'rgba(0,0,0,0.15)',
    borderLeftWidth: theme.normalize(2),
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  bookingCode: {
    flex: 2,
    paddingRight: theme.sizings.medium,
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'baseline',
    paddingLeft: theme.sizings.medium,
  },
  bookingDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    margin: theme.sizings.tiny,
    width: theme.sizings.mediumLarge,
  },
});

export default BookingListItem;
