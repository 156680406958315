import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import OrderStateBadge from 'orders/components/OrderStateBadge/OrderStateBadge';
import useCall from 'calls/contexts/calls';
import useOrder from 'orders/contexts/orders';
import useTheme from 'providers/ThemeProvider';

const BadgeController = ({ name }) => {
  const { items: calls } = useCall();
  const { items: orders } = useOrder();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  switch (name) {
    case 'calls':
      if (calls.length) {
        return (<View style={styles.badge} />);
      }
      return null;
    case 'waiterOrders':
      return (<OrderStateBadge orders={orders} />);
    default:
      return null;
  }
};

BadgeController.propTypes = {
  name: PropTypes.string.isRequired,
};

const makeStyles = (theme) => StyleSheet.create({
  badge: {
    position: 'absolute',
    // right: -6,
    // top: -3,
    top: 10,
    right: 10,
    backgroundColor: theme.colors.info,
    borderRadius: theme.radius.rounded,
    width: theme.sizings.small,
    height: theme.sizings.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default BadgeController;
