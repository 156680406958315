import dayjs from 'dayjs';

const getActiveSeason = (date, seasons) => {
  const year = new Date().getFullYear();
  const bookingDate = dayjs(date);

  const res = seasons?.find((season) => {
    // Si saison sur 2 années
    const endYear = season.endMonth < season.startMonthyear ? year + 1 : year;

    const sDate = new Date(year, season.startMonth - 1, season.startDay); // dayjs rajoute 1 sur le mois

    let startDate = dayjs(sDate);

    const eDate = new Date(endYear, season.endMonth - 1, season.endDay); // dayjs rajoute 1 sur le mois

    let endDate = dayjs(eDate);

    // Si booking avant début saison (on regarde si est pas sur la saison précédente)
    // Exemple: période 20 Novembre au 20 Mars, on est en février 2000
    // StartDate faut 20 Novembre 2000 / EnDate 20 Mars 2021 => donc on retire 1 an
    // startDate 20 Novembre 19999 / EndDate 20 Mars 202 => et ca marche
    if (season.active) {
      if (bookingDate.isBefore(startDate)) {
        startDate = startDate.subtract(1, 'year');
        endDate = endDate.subtract(1, 'year');
      }

      if (bookingDate.isSameOrAfter(startDate, 'day') && bookingDate.isSameOrBefore(endDate, 'day')) {
        return true;
      }
      return false;
    }
    return false;
  });

  return res;
};

export default getActiveSeason;
