import React from 'react';
import {
  View, ScrollView, StyleSheet,
  useWindowDimensions,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import FormInput from 'common/components/FormInput/FormInput';
import useTheme from 'providers/ThemeProvider';
import useTranslation from 'common/contexts/translations';
import Button from 'common/components/Button/Button';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import isDesktopMode, { isMobileMode } from 'common/utils/desktopMode';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';

const BookingFormDates = ({
  values, form, setFormTab, onDateUpdate, onStayDurationUpdate,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = makeStyles(theme, isDesktopMode({ width, height }), isMobileMode({ width, height }));
  const {
    date: currentDate,
  } = useBeachVisualizer();

  return (
    <ScrollView style={styles.container}>
      {/* left part : dates settings */}
      <View style={styles.columns}>
        <View style={styles.column}>
          <FormInput
            type="buttongroup"
            name="stayDuration"
            label={t('bookings.stayDuration')}
            options={[{
              value: 'fullday',
              text: t('bookings.fullday'),
            }, {
              value: 'morning',
              text: t('bookings.morning'),
            }, {
              value: 'afternoon',
              text: t('bookings.afternoon'),
            }]}
            onChange={(value) => {
              onStayDurationUpdate(value, values, form);
            }}
            required
          />

          {(!values.id || !values.booking_items?.find((bi) => bi.status !== 'reserved')) && (
            <FormInput
              type="buttongroup"
              name="periodType"
              label={t('bookings.periodType')}
              options={[{
                value: 'single',
                text: t('bookings.singleDate'),
              }, {
                value: 'multiple',
                text: t('bookings.multipleDate'),
              }, {
                value: 'range',
                text: t('bookings.rangeDate'),
              }]}
              verticalMobile
              required
            />
          )}
        </View>

        <ScrollViewLarge style={styles.column}>
          <FormInput
            type="date"
            icon="calendar"
            name="dates"
            label={values.periodType === 'single' ? t('bookings.date') : t('bookings.dates')}
            showCalendar
            onChange={(dates) => {
              onDateUpdate(dates, values, form);
            }}
            required
            mode={values.periodType}
            multiple
            disabledPassedDays
            initialDate={values.booking_items?.length ? values.booking_items[0].date : currentDate}
            lockedDates={values.booking_items
              .filter((bi) => bi.status !== 'reserved' && bi.status !== undefined && bi.status !== null)
              .map((bi) => bi.date)}
          />
        </ScrollViewLarge>
      </View>

      <View style={{
        paddingVertical: theme.sizings.medium, alignItems: 'center', width: '100%', justifyContent: 'flex-end',
      }}
      >
        <Button
          variant={!values.dates?.length ? 'greyDarker' : 'gradient'}
          onPress={() => setFormTab('position')}
          disabled={!values.dates?.length}
          icon="arrow-right"
          iconColor="white"
          text={t('bookings.nextStep')}
          type="submit"
          style={{ marginLeft: theme.sizings.medium }}
        />
      </View>
    </ScrollView>
  );
};

BookingFormDates.propTypes = {
  values: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  setFormTab: PropTypes.any.isRequired,
  onDateUpdate: PropTypes.func.isRequired,
  onStayDurationUpdate: PropTypes.func.isRequired,
};

const makeStyles = (theme, isDesktop, isMobile) => StyleSheet.create({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
  },
  columns: {
    flex: 1,
    flexDirection: 'row',
    width: isMobile ? '100%' : theme.normalize(900),
    maxWidth: '100%',
    alignSelf: 'center',
    flexWrap: 'wrap',
    height: '100%',
  },
  column: {
    paddingHorizontal: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    paddingBottom: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    flex: 1,
    minWidth: isMobile ? '100%' : theme.normalize(400),
    maxHeight: isMobile ? undefined : Dimensions.get('window').height - theme.normalize(180),
  },

});

export default BookingFormDates;
