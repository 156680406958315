/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  View, ScrollView, StyleSheet, Dimensions, useWindowDimensions,
} from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';

import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useBooking from 'bookings/contexts/bookings';
import useSeatSelector from 'bookings/pages/SeatSelector/contexts/clientSeatSelector';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
// import getFF from 'common/utils/getFF';
import Page from 'common/components/Page/Page';
import DateInput from 'common/components/DateInput/DateInput';
import { isHorizontalMode, isTabletMode } from 'common/utils/desktopMode';

const makeStyles = (theme, isTablet, isHorizontal) => StyleSheet.create({
  dateSelectScreen: {
    flex: 1,
    paddingTop: theme.sizings.large,
    paddingHorizontal: theme.sizings.medium,
    position: 'relative',
    backgroundColor: theme.colors.white,
    alignItems: 'center',

  },
  dateSelectInner: {
    width: isTablet || isHorizontal
      ? (Dimensions.get('window').height * 0.5) - theme.sizings.medium
      : Dimensions.get('window').width - theme.sizings.medium,
  },
  button: {
    position: 'absolute',
    zIndex: 40,
    top: -30,
  },
  daysLabelWrapper: {
    paddingTop: theme.sizings.medium,
    paddingBottom: theme.sizings.medium,
    width: '100%',
    backgroundColor: theme.colors.secondaryLight,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },

  headerMask: {
    borderTopLeftRadius: theme.radius.medium,
    borderTopRightRadius: theme.radius.medium,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: theme.normalize(100),
    backgroundColor: theme.colors.secondaryDark,
  },

  calendar: {
    borderRadius: theme.radius.medium,
    position: 'relative',
    marginHorizontal: theme.sizings.small,
    marginTop: theme.sizings.small,
    paddingBottom: theme.sizings.mediumLarge,
    height: 'auto',
    backgroundColor: theme.colors.white,
  },

  selectedDay: {
    width: theme.normalize(40),
    height: theme.normalize(40),
    backgroundColor: theme.colors.secondary,
  },

  hideSelectedDay: {
    width: theme.normalize(40),
    height: theme.normalize(40),
    backgroundColor: theme.colors.white,
  },

  calendarText: {
    fontFamily: 'Avenir',
    fontSize: theme.fontSizes.large,
    color: theme.colors.dark,
  },

  nav: {
    fontSize: theme.fontSizes.default,
  },

  today: { fontFamily: 'AvenirBold' },

  label: {
    paddingBottom: theme.sizings.small,
    fontSize: theme.fontSizes.medium,
    // fontFamily: getFF(lang, true, theme),
  },
  containerStyle: {
    marginBottom: theme.sizings.medium,
    paddingHorizontal: theme.sizings.small,
    width: '100%',
  },
});

const DateSelect = ({ route }) => {
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const styles = makeStyles(theme, isTabletMode(useWindowDimensions()), isHorizontalMode(useWindowDimensions()));

  const { item: site } = useSite();
  const navigation = useNavigation();

  const defaultDate = dayjs().isBefore(dayjs(site?.dateBegin))
    ? dayjs(site?.dateBegin).utc().startOf('day')
    : dayjs().utc().startOf('day');

  const [selectedDates, setSelectedDates] = useState([]); // [defaultDate]
  const { t } = useTranslation();
  const { setItem: setBooking } = useBooking();
  const { setSelectedFullSeats } = useSeatSelector();

  const openDays = useMemo(() => {
    const openingDays = Object.values(site.schedule);
    const final = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [index, day] of openingDays.entries()) {
      if (day.isClosed) {
        final.push(index !== 6 ? index + 1 : 0);
      }
    }

    return final;
  }, [site]);

  const [stayDuration, setStayDuration] = useState('fullday');

  const redirectSeatSelector = () => {
    setBooking((prevBooking) => ({
      ...prevBooking,
      dates: selectedDates.map((d) => dayjs(d).format('YYYY-MM-DD')),

      stayDuration,
      booking_items: selectedDates.map((date) => ({
        date: dayjs(date).format('YYYY-MM-DD'),
        stayDuration,
        bookingType: site.bookingsWithSeats ? 'withSeats' : 'withoutSeats',
        status: 'reserved',
        isFullyPlaced: !!site.bookingsWithSeats,
        isFullyPaid: false,
        override: false,
        seats: [],
        bookingContent: [],
      }))
      ,
    }));
    setSelectedFullSeats([]);

    if (site.bookingsWithSeats) {
      if (site.maps.length === 1) {
        navigation.navigate('SeatSelector', { siteId: route.params.siteId, mapId: site.maps[0].id });
      } else {
        navigation.navigate('MapsList');
      }
    } else {
      navigation.navigate('ContentSelector', { siteId: route.params.siteId });
    }
  };

  const disableDate = (date) => {
    const _date = new Date(date);

    return openDays.find((day) => day === _date.getDay()) !== undefined;
  };

  return (
    <Page>
      <ScrollView>
        <View style={styles.dateSelectScreen}>

          <View style={styles.containerStyle}>
            <Text style={styles.label} size="large" isBold centered>{t('bookings.stayDuration')}</Text>
            <ButtonGroup
              onChange={(value) => { setStayDuration(value); }}
              value={stayDuration}
              options={[{
                value: 'fullday',
                text: t('bookings.fullday'),
              }, {
                value: 'morning',
                text: t('bookings.morning'),
              }, {
                value: 'afternoon',
                text: t('bookings.afternoon'),
              }]}
            />
          </View>

          <Text style={styles.label} size="large" isBold centered>{t('bookings.pickdates')}</Text>

          <DateInput
            value={selectedDates}
            onChange={(dates) => {
              setSelectedDates(dates);
            }}
            errorStyle={{ color: theme.colors.danger }}
            showCalendar
            mode="multiple"
            multiple
            disabledPassedDays
            disabledDates={(date) => disableDate(date)}
            defaultDates={[defaultDate]}
          />
        </View>

        <View style={commonStyles.scrollViewWithButton} />
      </ScrollView>

      <View style={commonStyles.buttonWrapper}>
        <View style={commonStyles.buttonContainer}>
          <Button
            disabled={!selectedDates?.length || stayDuration === ''}
            text={t('common.selectSeat')}
            variant="gradient"
            onPress={redirectSeatSelector}
          />
        </View>
      </View>

    </Page>
  );
};

DateSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default DateSelect;
