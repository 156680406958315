import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import Text from 'common/components/Text/Text';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import useTheme from 'providers/ThemeProvider';
import usePersistedState from 'common/utils/usePersistedState2';

const { useTranslation } = require('react-i18next');

const EventMessages = ({ events }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [allVisible, setAllVisible] = usePersistedState(false);

  const hasEvents = events?.length > 0;
  const hasMoreThanOneEvent = events?.length > 1;

  const styles = makeStyles(theme, allVisible, hasMoreThanOneEvent);

  const handleOpenAllEvents = useCallback(() => {
    if (hasMoreThanOneEvent) {
      setAllVisible(!allVisible);
    }
  }, [allVisible, hasMoreThanOneEvent, setAllVisible]);

  return hasEvents ? (
    <>
      <View style={styles.headerSeparator} />
      <View style={styles.headerEvents}>
        <View style={styles.picto}>
          <FontAwesome
            name="warning"
            color={theme.colors.danger}
            size={theme.fontSizes.largest}
          />
        </View>
        <View style={styles.events}>
          {hasEvents && (
            hasMoreThanOneEvent ? (
              <>
                <View style={styles.eventContainer}>
                  <Text
                    color="danger"
                    style={[styles.event, styles.eventTitle]}
                  >
                    {t('events.moreThanOneEvent', { count: events.length })}
                  </Text>
                  <TouchableOpacity
                    onPress={handleOpenAllEvents}
                  >
                    <FontAwesome5
                      name={allVisible ? 'chevron-down' : 'chevron-left'}
                      color={theme.colors.danger}
                      size={theme.fontSizes.large}
                    />
                  </TouchableOpacity>
                </View>
                {allVisible && (
                  events.map((event) => (
                    <View style={styles.eventContainer}>
                      <Text
                        key={event.id}
                        color="danger"
                        style={[styles.event, styles.roundedBorders]}
                      >
                        {event.name}
                      </Text>
                    </View>
                  ))
                )}
              </>
            ) : (
              <View style={styles.eventContainer}>
                <Text
                  key={events[0].id}
                  color="danger"
                  style={[styles.event, styles.roundedBorders, { marginVertical: theme.sizings.tiny }]}
                >
                  {events[0].name}
                </Text>
              </View>
            )
          )}
        </View>
      </View>
    </>
  ) : null;
};

const makeStyles = (theme, isOpen, hasMoreThanOneEvent) => StyleSheet.create({
  headerSeparator: {
    border: `1px solid ${theme.colors.greyMedium}`,
    borderRadius: theme.radius.rounded,
    height: 1,
    width: '30%',
    marginTop: theme.sizings.tiny,
  },
  headerEvents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'top',
    width: '100%',
    paddingHorizontal: theme.sizings.small,
    height: isOpen ? 'auto' : theme.normalize(44),
    maxHeight: isOpen ? 'auto' : theme.normalize(44),
  },
  picto: {
    marginTop: hasMoreThanOneEvent ? theme.sizings.tiny : theme.sizings.smallMedium,
  },
  events: {
    backgroundColor: theme.colors.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    minWidth: '100%',
    maxWidth: 0,
    paddingBottom: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  event: {
    flexGrow: 1,
    fontWeight: 'bold',
    alignSelf: 'center',
    paddingVertical: theme.sizings.tiny,
    paddingHorizontal: theme.sizings.small,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  eventTitle: {
    fontSize: theme.fontSizes.large,
    flexGrow: 1,
  },
  roundedBorders: {
    border: `1px solid ${theme.colors.danger}`,
    borderRadius: theme.radius.medium,
  },
});

EventMessages.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventMessages;
