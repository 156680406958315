import React, { useCallback } from 'react';
import {
  View, TouchableHighlight, StyleSheet, useWindowDimensions,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';

import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import makeCommonStyles from 'styles/commonStyles';
import useTheme from 'providers/ThemeProvider';
import { isMobileMode } from 'common/utils/desktopMode';

const makeStyles = (theme) => StyleSheet.create({

  buttons: {
    justifyContent: 'center',
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.large,
    marginVertical: theme.sizings.small,
  },

  button: {
    width: '100%',
  },
  buttonWrapper: {
    flex: 1,
    overflow: 'hidden',
  },
  buttonWrapperRow: {
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'row',
  },

  buttonLeft: {
    borderTopLeftRadius: theme.radius.large,
    borderBottomLeftRadius: theme.radius.large,
  },
  buttonRight: {
    borderTopRightRadius: theme.radius.large,
    borderBottomRightRadius: theme.radius.large,
  },
  buttonTop: {
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
  buttonBottom: {
    borderBottomLeftRadius: theme.radius.large,
    borderBottomRightRadius: theme.radius.large,
  },
  buttonInner: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

const ButtonGroup = ({
  options, value, onChange, onChangeCustom, small, backgroundColor, vertical,
  verticalMobile,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const isVertical = (isMobileMode(useWindowDimensions()) && verticalMobile) ? true : vertical;

  const computeStyles = useCallback((index) => {
    if (index === 0) {
      return [isVertical ? styles.buttonWrapperRow : styles.buttonWrapper,
        isVertical ? styles.buttonTop : styles.buttonLeft];
    } if (index === options.length - 1) {
      return [isVertical ? styles.buttonWrapperRow : styles.buttonWrapper,
        isVertical ? styles.buttonBottom : styles.buttonRight];
    }
    return [isVertical ? styles.buttonWrapperRow : styles.buttonWrapper];
  }, [options, styles, isVertical]);

  return (
    <View style={[styles.buttons, commonStyles.shadowBottom, { flexDirection: isVertical ? 'column' : 'row' }]}>
      {options.map((option, index) => (
        <TouchableHighlight
          key={index}
          style={computeStyles(index)}
          onPress={onChangeCustom || (() => onChange(option.value))}
        >
          <View style={[styles.button, backgroundColor && value === option.value && { backgroundColor }]}>
            {value === option.value && !backgroundColor && <Gradient height="100%" />}
            <View style={[styles.buttonInner, {
              paddingHorizontal: small ? theme.sizings.tiny : theme.sizings.medium,
              paddingVertical: small ? theme.sizings.tiny : theme.sizings.small,
              flexDirection: isVertical ? 'row' : 'column',
            }]}
            >
              {option.iconName && (
              <FontAwesome
                name={option.iconName}
                color={value === option.value ? theme.colors.light : theme.colors.primary}
                size={theme.sizings.large}
              />
              )}
              <Text
                centered
                color={value === option.value ? 'light' : 'dark'}
                style={{ marginLeft: option.iconName && isVertical ? theme.sizings.small : 0 }}
              >
                {option.text}

              </Text>
            </View>
          </View>
        </TouchableHighlight>
      ))}
    </View>
  );
};

ButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeCustom: PropTypes.func,
  small: PropTypes.bool,
  backgroundColor: PropTypes.string,
  vertical: PropTypes.bool,
  verticalMobile: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  onChangeCustom: null,
  small: false,
  backgroundColor: null,
  vertical: false,
  verticalMobile: false,
};

export default ButtonGroup;
