import { NativeSwitch as Switch } from 'common/components/Switch/Switch';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import useEvents from 'events/contexts/events';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import Text from 'common/components/Text/Text';
import dayjs from 'dayjs';
import useTranslation from 'common/contexts/translations';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import ConfirmButton from 'common/components/ConfirmButton/ConfirmButton';

const makeStyles = (theme, isPast) => StyleSheet.create({
  eventItem: {
    padding: theme.sizings.small,
    paddingTop: 12,
    marginTop: theme.sizings.small,
    borderRadius: theme.radius.medium,
    backgroundColor: isPast ? theme.colors.greyMedium : theme.colors.greyLightest,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clickableItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    justifyContent: 'flex-start',
  },
  textAlignStyle: {
    textAlign: 'left',
    width: '50%',
  },
  alignLeft: {
    flexShrink: 1,
    paddingRight: 16,
  },
  button: {
    marginHorizontal: theme.sizings.small,
  },
});

const EventItem = ({ event }) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const { update, fetchEvents, remove: deleteEvent } = useEvents();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme, dayjs().startOf('day').isAfter(event.endDate));
  const commonStyles = makeCommonStyles(theme);

  const handleToggleActive = useCallback(async () => {
    setLoading(true);
    try {
      await update(event.id, { ...event, active: !event.active });
      fetchEvents({ ...event, active: !event.active });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [update, event, fetchEvents]);

  const handleEditItem = useCallback(() => {
    navigation.navigate('EventEdit', { editedEvent: event });
  }, [event, navigation]);

  return (
    <View style={[styles.eventItem, commonStyles.shadowBottom]}>
      <TouchableOpacity onPress={handleEditItem} style={[styles.clickableItem]}>
        <TranslatedText
          style={{
            width: '50%',
          }}
          isBold
          size="medium"
          value={event.name}
        />
        <View style={styles.innerContent}>
          <FontAwesome5
            name={event.startDate === event.endDate ? 'calendar-day' : 'calendar-week'}
            color={theme.colors.primary}
            size={theme.sizings.medium}
            style={{ ...styles.icon, alignSelf: 'flex-start' }}
          />
          <Text>
            {event.startDate === event.endDate ? (
              t('events.oneDate', {
                date: dayjs(event.startDate).format('DD/MM/YYYY'),
              })
            ) : (
              t('events.dateToDate', {
                from: dayjs(event.startDate).format('DD/MM/YYYY'),
                to: dayjs(event.endDate).format('DD/MM/YYYY'),
              })
            )}
          </Text>
        </View>

      </TouchableOpacity>
      <Switch
        loading={loading}
        activeColor={theme.colors.secondary}
        onChange={handleToggleActive}
        value={event.active}
      />
      <ConfirmButton
        onPress={async () => {
          await deleteEvent(event.id);
          await fetchEvents();
        }}
        confirmText={t('customer.deleteEventConfirm', { eventName: event.name })}
        variant="white"
        iconColor="dangerBright"
        icon="trash"
        small
        style={styles.button}
      />
    </View>
  );
};

EventItem.propTypes = {
  event: PropTypes.object.isRequired,
};

EventItem.defaultProps = {
};

export default EventItem;
