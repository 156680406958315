import React, { useEffect } from 'react';

import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import OfferItem from 'waiter/components/OfferItem/OfferItem';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import { useTranslation } from 'react-i18next';
import useTheme from 'providers/ThemeProvider';
import {
  ActivityIndicator, View,
} from 'react-native';

const Catalog = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const {
    fetchItems, items: specialOffers = [], isFetching,
  } = useSpecialOffers();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <WaiterLayout boxed>
      {isFetching ? (
        <View style={{ height: theme.normalize(300), width: '100%', alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      ) : (
        specialOffers.length ? (
          <View style={{ paddingVertical: theme.sizings.mediumLarge }}>
            {specialOffers.map((specialOffer) => (
              <OfferItem key={specialOffer.id} specialOffer={specialOffer} />
            ))}
          </View>
        ) : (
          <EmptyPage text={t('offers.empty')} />
        )
      )}
    </WaiterLayout>
  );
};

export default Catalog;
