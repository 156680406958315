import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinnerOverlay from 'react-native-loading-spinner-overlay';
import useTranslation from 'common/contexts/translations';

const LoadingSpinner = ({ textContent, ...props }) => {
  const { t } = useTranslation();

  return (
    <LoadingSpinnerOverlay
      textContent={textContent || t('common.loading')}
      {...props}
    />
  );
};

LoadingSpinner.propTypes = {
  textContent: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  textContent: null,
};
export default LoadingSpinner;
