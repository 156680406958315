import useAuth from 'auth/contexts/auth';
import useCall from 'calls/contexts/calls';
import Page from 'common/components/Page/Page';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import useOrders from 'orders/contexts/orders';
import React, {
  useEffect,
} from 'react';
import {
  ScrollView,
} from 'react-native';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import OrderSummary from '../../../orders/components/OrderListItem/OrderSummary';

const LastOrderListing = () => {
  const { t } = useTranslation();
  const { booking } = useAuth();
  const {
    fetchItems: fetchCalls,
    setFilter: setCallFilters,
  } = useCall();
  const {
    fetchItems: fetchOrders,
    items: distOrders,
    setFilter,
    filters,
  } = useOrders();

  // const { localOrders } = useLocalOrders();
  const {
    fetchSpecialOffersApplicable,
    setItems: setSpecialOffers,
  } = useSpecialOffers();
  // const { theme } = useTheme();

  const orders = distOrders;

  useEffect(() => {
    if (booking) {
      setFilter('booking', booking.id);
      setFilter('created_at_gte', dayjs().utc().startOf('day').format());
      setFilter('created_at_lte', dayjs().utc().endOf('day').format());
      setFilter('_sort', 'created_at:DESC');
      setCallFilters('booking', booking.id);
    }
  }, [booking, setCallFilters, setFilter]);

  useEffect(() => {
    if (filters.booking) {
      fetchOrders();
      fetchCalls();
    }
  }, [filters, fetchOrders, fetchCalls]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const applicables = await fetchSpecialOffersApplicable({ booking: booking.id, site: booking.site });

      setSpecialOffers(applicables);
    };

    fetchAndSetData();
  }, [booking, setSpecialOffers, fetchSpecialOffersApplicable]);

  return (
    <Page
      padding
      style={{ paddingVertical: 0 }}
    >
      <ScrollView>
        {!orders?.length ? (
          <EmptyPage text={t('orders.empty')} />
        ) : (
          orders.map((
            order, key,
          ) => (
            <OrderSummary
              key={`${key}_orderSum_${order.id}`}
              order={order}
            />
          ))
        )}
      </ScrollView>
    </Page>
  );
};

export default LastOrderListing;
