import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import fetchJSON from 'common/utils/fetchJSON';
import useSiteProvider from 'providers/SiteProvider';

export const BeachVisualizerContext = createContext();

const formatQueryParams = (params) => {
  const esc = encodeURIComponent;
  const queryParams = Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((p) => `${esc(key)}=${esc(p)}`).join('&');
      }
      return `${esc(key)}=${esc(params[key])}`;
    })
    .join('&');

  return queryParams;
};

export const BeachVisualizerProvider = ({ children }) => {
  const [selectedSeats, setSelectedSeats] = useState([]);

  const [bookingModal, setBookingModal] = useState(null);
  const [date, setDate] = useState(dayjs().startOf('day').format());
  const [currentStayDuration, setCurrentStayDuration] = useState('fullday');
  const [searchBookingItem, setSearchBookingItem] = useState('');

  const [mapId, setMapId] = useState(-1);
  const [activeWaiterRoot, setActiveWaiterRoute] = useState('maps');

  const [seatOrders, setSeatOrders] = useState({});
  const [seatCalls, setSeatCalls] = useState({});
  const [bookingItems, setBookingItems] = useState([]);
  const [customerHistoricalBookingItems, setCustomerHistoricalBookingItems] = useState([]);
  const [isCustomBookingsFetching, setIsCustomBookingsFetching] = useState(false);

  const [isFetching, setIsFetching] = useState(0);

  const [bookingItemFocused, setBookingItemFocused] = useState(null);
  const [seatFocused, setSeatFocused] = useState(null);
  const [switchSeat, setSwitchSeat] = useState(false);

  const [showMapBloc, setShowMapBloc] = useState(false);
  const [showCustomerBloc, setShowCustomerBloc] = useState(false);

  const { site, getValidStayDurationQueryValues, getBookingItemsBySeat } = useSiteProvider();

  useEffect(() => {
    if (site?.maps?.[0]) {
      const res = site.maps?.find((map) => map.active);

      setMapId(res?.id || -1);
    }
  }, [site]);

  const getRefreshedBookingItem = useCallback((bookingItem) => (
    bookingItems.find((bi) => bi.id === bookingItem.id)
  ), [bookingItems]);

  useEffect(() => {
    if (bookingItemFocused) {
      const updatedBookingItemFocused = getRefreshedBookingItem(bookingItemFocused);

      if (!['reserved', 'checked_in'].includes(updatedBookingItemFocused?.status)) {
        // Remove focused seat + booking item :
        // booking item has a status that shouldn't allow it to be placed on the map.
        setSeatFocused(null);
        setBookingItemFocused(null);
      }
    }
  }, [bookingItemFocused, bookingItems, getRefreshedBookingItem]);

  const seatBookingItems = useMemo(() => (
    getBookingItemsBySeat(bookingItems)
  ), [bookingItems, getBookingItemsBySeat]);

  // BOOKINGS

  const updateBookingItem = useCallback(
    (item) => fetchJSON({ url: `booking-items/${item.id}`, method: 'PUT', payload: item }), [],
  );

  const fetchCustomerHistoricalBookingItems = useCallback(
    async (customerId) => {
      if (!site?.id) {
        setCustomerHistoricalBookingItems([]);
        return [];
      }

      setIsCustomBookingsFetching(true);

      const params = {
        _limit: -1,
        'booking.site.id': site?.id,
        'booking.customer.id': customerId,
        // _sort:
      };

      setIsFetching((isFetching) => isFetching + 1);
      try {
        const res = await fetchJSON({
          url: `booking-items?${formatQueryParams(params)}`,
          method: 'GET',
        });

        setCustomerHistoricalBookingItems(res);
        setIsCustomBookingsFetching(false);

        return res;
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching((isFetching) => isFetching - 1);
      }
    },
    [site, setCustomerHistoricalBookingItems, setIsFetching, setIsCustomBookingsFetching],
  );

  const fetchBookingItems = useCallback(
    async () => {
      const day = dayjs(date).format('YYYY-MM-DD');

      if (!site?.id) {
        setBookingItems([]);
        return [];
      }

      const params = {
        _start: 0,
        _limit: -1,
        // status_in: ['reserved', 'checked_in'],
        date: day,
        stayDuration: getValidStayDurationQueryValues(currentStayDuration),
        'booking.site.id': site?.id,
        // _sort:
      };

      setIsFetching((isFetching) => isFetching + 1);
      try {
        const res = await fetchJSON({
          url: `booking-items?${formatQueryParams(params)}`,
          method: 'GET',
        });

        setBookingItems(res);
        return res;
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching((isFetching) => isFetching - 1);
      }
    },
    [date, site, getValidStayDurationQueryValues, currentStayDuration],
  );

  const getSeatData = useCallback((seatId) => ({
    bookingItems: seatBookingItems[seatId] || [],
    calls: seatCalls[seatId] || [],
    orders: seatOrders[seatId] || [],
  }), [seatBookingItems, seatCalls, seatOrders]);

  // BOOKINGS
  const fetchOrders = useCallback(
    async () => {
      const endDate = dayjs(date).endOf('day').format();
      const startDate = dayjs(date).startOf('day').format();

      const params = {
        _start: 0,
        _limit: -1,
        created_at_lte: endDate,
        created_at_gte: startDate,
        // _sort:
      };

      setIsFetching((isFetching) => isFetching + 1);
      try {
        const res = await fetchJSON({
          url: `orders?${formatQueryParams(params)}`,
          method: 'GET',
        });

        const sOrders = {};

        res.forEach((o) => {
          if (!sOrders[o.seat?.id]) {
            sOrders[o.seat.id] = [];
          }
          sOrders[o.seat.id].push(o);
        });

        setSeatOrders(sOrders);
        return res;
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching((isFetching) => isFetching - 1);
      }
    },
    [date],
  );

  const fetchCalls = useCallback(
    async () => {
      const endDate = dayjs(date).endOf('day').format();
      const startDate = dayjs(date).startOf('day').format();

      const params = {
        _start: 0,
        _limit: -1,
        created_at_lte: endDate,
        created_at_gte: startDate,
        // _sort:
      };

      setIsFetching((isFetching) => isFetching + 1);
      try {
        const res = await fetchJSON({
          url: `calls?${formatQueryParams(params)}`,
          method: 'GET',
        });

        const sCalls = {};

        res.forEach((c) => {
          if (!sCalls[c.seat.id]) {
            sCalls[c.seat.id] = [];
          }
          sCalls[c.seat.id].push(c);
        });

        setSeatCalls(sCalls);
        return res;
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching((isFetching) => isFetching - 1);
      }
    },
    [date],
  );

  const refreshData = useCallback(() => {
    fetchBookingItems();
    fetchOrders();
    fetchCalls();
  }, [fetchBookingItems, fetchOrders, fetchCalls]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  // SEATS
  const handleClickonSquare = useCallback((seatId, bookingId) => {
    if (bookingId) {
      setBookingModal(bookingId);
    } else {
      setSelectedSeats((previousSelectedSeats) => {
        let newSeats = [...previousSelectedSeats];

        if (previousSelectedSeats.find((id) => id === seatId) === undefined) {
          newSeats.push(seatId);
        } else {
          newSeats = newSeats.filter((id) => id !== seatId);
        }
        return newSeats;
      });
    }
  }, []);

  const removeSelectedSeat = useCallback((seatId) => {
    setSelectedSeats((previousSelectedSeats) => previousSelectedSeats.filter((id) => id !== seatId));
  }, []);

  const bookingItemsByStayDuration = useMemo(() => bookingItems
    ? bookingItems.filter((bi) => (
      bi.date === dayjs(date).format('YYYY-MM-DD')
          && getValidStayDurationQueryValues(currentStayDuration).includes(bi.stayDuration)
    ))
    : [], [bookingItems, currentStayDuration, date, getValidStayDurationQueryValues]);

  const getBookingsByDates = useCallback((dates) => {
    const formatedDates = dates.map((d) => dayjs(d).format('YYY-MM-DD'));

    return bookingItems.filter((b) => (
      b.booking_items.find((bi) => (
        formatedDates.includes(dayjs(bi.date).format('YYY-MM-DD'))
      ))
    ));
  }, [bookingItems]);

  const getSeatAvailableStayDuration = useCallback((selectedSeats, date) => {
    let concurrentBookingItem = null;

    selectedSeats.forEach((seatId) => {
      concurrentBookingItem = getSeatData(seatId)?.bookingItems?.find((bi) => (
        dayjs(bi.date).isSame(date, 'date')
      ));
    });
    if (concurrentBookingItem) {
      if (concurrentBookingItem.stayDuration === 'morning') {
        return 'afternoon';
      }
      if (concurrentBookingItem.stayDuration === 'afternoon') {
        return 'morning';
      }
      return 'fullday';
    }
    return 'fullday';
  }, [getSeatData]);

  const searched = useMemo(() => searchBookingItem?.toLowerCase(), [searchBookingItem]);

  const included = useCallback((words) => (
    words?.find((word) => word?.toLowerCase().includes(searched))
  ), [searched]);

  const bookingItemList = useMemo(() => bookingItemsByStayDuration
    ?.sort((a, b) => a.place > b.place ? -1 : 1)
    ?.filter((bi) => (!bi.seats?.length || bi.seats?.find((s) => s.map === mapId)))
    ?.filter((bi) => {
      const { booking } = bi;
      const seatsName = bi.seats?.map((seat) => seat.name);

      return (
        included([
          ...seatsName,
        booking?.customer?.name,
        booking?.customer?.firstName,
        booking?.clientName,
        booking?.clientFirstName,
        booking?.code,
        booking?.customer?.email,
        booking?.roomID,
        ])
      );
    }),
  [bookingItemsByStayDuration, included, mapId]);

  const value = {
    date,
    setDate,
    currentStayDuration,
    setCurrentStayDuration,
    bookingModal,
    setBookingModal,
    selectedSeats,
    setSelectedSeats,
    removeSelectedSeat,
    handleClickonSquare,
    setMapId,
    mapId,
    activeWaiterRoot,
    setActiveWaiterRoute,
    getSeatData,
    bookingItems,
    fetchBookingItems,
    fetchCalls,
    fetchOrders,
    refreshData,
    isFetching,
    bookingItemsByStayDuration,
    getBookingsByDates,
    switchSeat,
    setSwitchSeat,
    bookingItemFocused,
    setBookingItemFocused,
    seatFocused,
    setSeatFocused,
    getSeatAvailableStayDuration,
    updateBookingItem,
    customerHistoricalBookingItems,
    setCustomerHistoricalBookingItems,
    fetchCustomerHistoricalBookingItems,
    isCustomBookingsFetching,
    getRefreshedBookingItem,
    searchBookingItem,
    setSearchBookingItem,
    bookingItemList,
    showMapBloc,
    setShowMapBloc,
    showCustomerBloc,
    setShowCustomerBloc,
  };

  return (
    <BeachVisualizerContext.Provider value={value}>
      {children}
    </BeachVisualizerContext.Provider>
  );
};

BeachVisualizerProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default () => useContext(BeachVisualizerContext);
