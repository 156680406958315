import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Confirm from 'common/components/Confirm/Confirm';
import Price from 'common/components/Price/Price';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import formatPrice from 'common/utils/formatPrice';
import { LinearGradient } from 'expo-linear-gradient';
import useOrder, { useLocalOrders } from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeStyles from 'sites/pages/SiteDetail/styles/OrderSummary.styles';
import useAuth from 'auth/contexts/auth';

const OrderSummary = ({
  order,
}) => {
  const { t } = useTranslation();
  const { item: site } = useSite();
  const [detailsOpen, setDetailsOpen] = useState(true);
  const { save: saveOrder } = useOrder();
  const { localOrders, setLocalOrders } = useLocalOrders();
  const navigation = useNavigation();
  const { setAlert } = useAlert();
  const {
    id,
    state,
    // eslint-disable-next-line camelcase
    order_items,
  } = order;
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const handleState = useCallback((state) => {
    switch (state) {
      case 'created':
        return t('orders.created');
      case 'in_preparation':
        return t('orders.inPreparation');
      case 'ready':
        return t('orders.ready');
      case 'done':
        return t('orders.done');
      default:
        return t('orders.created');
    }
  }, [t]);

  const reOrderHandle = useCallback(async () => {
    const payload = order;

    delete payload.id;
    delete payload.payment.id;

    payload.payment.status = 'PENDING';
    payload.site = payload.site.id;
    payload.state = 'created';
    payload.user = -1;

    try {
      await saveOrder(payload);
      await setLocalOrders((oldOrders) => Array.isArray(oldOrders) ? [...oldOrders, payload] : [payload]);

      setAlert({ color: 'success', title: t('common.success'), message: t('common.success') });

      const routes = [
        { name: 'HomePage' },
        { name: 'SiteDetail', params: { siteId: site.id } },
      ];

      navigation.reset({
        index: 0,
        routes,
      });
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [navigation, order, saveOrder, setAlert, site, t, setLocalOrders]);

  return (
    <View
      style={styles.viewMaster}
    >
      <TouchableOpacity
        style={[
          styles.viewOrderHeader,
          {
            backgroundColor: (state === 'done' || state === 'ready'
              ? theme.orderStateColor.cornflowerbluebackground
              : theme.orderStateColor.goldenrodbackground),
          }]}
        onPress={() => setDetailsOpen((old) => !old)}
      >
        <View style={[styles.viewOrderHeaderSection, { paddingLeft: 7 }]}>
          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontWeight: 'bold' }}
              size="large"
              color="black"
            >
              {`${t('orders.orderNbr', { orderId: id })}`}
            </Text>
          </View>
          <View style={styles.viewTimeCmdContainer}>
            <FontAwesome
              size={theme.fontSizes.large}
              name="clock-o"
              color={theme.colors.dark}
            />
            <Text style={{ marginLeft: 7 }}>
              <RelativeDate value={order.updated_at || order.created_at} />
            </Text>
          </View>
        </View>
        <View style={styles.viewSeparatorContainer}>
          <View style={styles.viewSeparator} />
        </View>
        <View style={[styles.viewOrderHeaderSection, { flexDirection: 'row', justifyContent: 'space-between' }]}>
          <View style={{ flex: 0.9 }}>
            <Text
              isBold
              size="large"
              color="black"
            >
              {`${formatPrice(order.price, site.currency)}`}
            </Text>
            <Text
              size="large"
              style={{ color: (state === 'done' || state === 'ready' ? 'cornflowerblue' : 'goldenrod') }}
            >
              {handleState(state)}
            </Text>
          </View>
          <View style={{ flex: 0.1, justifyContent: 'center' }}>
            <FontAwesome
              name={detailsOpen ? 'angle-up' : 'angle-down'}
              size={theme.fontSizes.large}
              color={(state === 'done' || state === 'ready' ? 'cornflowerblue' : 'goldenrod')}
            />
          </View>
        </View>
      </TouchableOpacity>
      {/* eslint-disable-next-line camelcase */}
      {order_items && detailsOpen && (
      <ScrollView>
        {order_items.map((orderItem) => (
          <View key={orderItem.id}>
            <View style={styles.viewDetailContainer}>
              <View
                style={styles.viewLeftDetailContainer}
              >
                <TranslatedText
                  size="large"
                  color="#e8ad4c"
                  value={orderItem.name}
                />
              </View>
              <View style={styles.viewRightDetailContainer}>
                <View style={[
                  styles.textQuantity,
                  {
                    backgroundColor: (state === 'done' || state === 'ready'
                      ? theme.orderStateColor.cornflowerbluebackground
                      : theme.orderStateColor.goldenrodbackground),
                  }]}
                >
                  <Text
                    size="large"
                    color="dark"
                  >
                    {`x ${orderItem.quantity}`}
                  </Text>
                </View>
                <Price
                  viewStyle={styles.price}
                  style={{ fontSize: 15, color: theme.colors.greyDarker }}
                  price={orderItem.price * orderItem.quantity}
                  color="dark"
                />
              </View>
            </View>
            <View style={{ marginLeft: theme.sizings.large }}>
              {orderItem.selected_options.map((option) => (
                <>
                  {option.variants.map((variant) => (
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <TranslatedText value={variant.name} />
                      <Text isBold color="greyDarker">
                        {variant.price ? formatPrice(variant.price, site?.currency) : formatPrice(0, site?.currency)}
                      </Text>
                    </View>
                  ))}
                </>
              ))}
            </View>
          </View>
        ))}
        <Confirm confirmText={t('orders.reOrderConfirm')}>
          <TouchableOpacity
            style={styles.btnReOrder}
            onPress={reOrderHandle}
          >
            <LinearGradient
              colors={[theme.colors.secondary, theme.colors.secondaryDark]}
              start={[0, 0.5]}
              end={[1, 0.5]}
              style={styles.gradientBtnBackground}
            >
              <Text style={styles.textReorderBtn}>
                {t('orders.reOrderBtn')}
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </Confirm>
      </ScrollView>
      )}
    </View>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderSummary;
