import getFF from 'common/utils/getFF';
import { StyleSheet } from 'react-native';

export default (lang, color, size, noLineHeight, isUppercase, isBold, centered, theme) => StyleSheet.create({
  text: {
    margin: 0,
    justifyContent: 'center',
    fontFamily: getFF(lang, isBold, theme),
    color: theme.colors[color],
    fontSize: theme.fontSizes[size],
    writingDirection: lang === 'ar' ? 'rtl' : 'ltr',
    textTransform: isUppercase ? 'uppercase' : 'none',
    // Font is not loaded if not existing in bold or normal,
    // fontWeight: isBold ? 'bold' : 'normal',
    alignSelf: centered ? 'center' : 'baseline',
    lineHeight: noLineHeight ? theme.fontSizes[size] + 8 : theme.fontSizes[size] * 1.6,
  },
});
