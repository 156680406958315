import React from 'react';
import PropTypes from 'prop-types';
import {
  TouchableOpacity, View, useWindowDimensions,
} from 'react-native';
import Modal from 'common/components/Modal/Modal';
import Button from 'common/components/Button/Button';
import useTranslation from 'common/contexts/translations';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import { ScrollView } from 'react-native-gesture-handler';
import ColumnDates from '../subforms/blocs/ColumnDates';

const CurrentDateModal = ({
  setModalVisible, modalVisible,
  form,
  values,
  currentDate,
  setCurrentDate,
  showOverrides,
  nbConflicts,
  nbOverBooking,
  checkConflict,
  siteBookingItems,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const { width, height } = useWindowDimensions();

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <View style={commonStyles.modalInner}>

        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={commonStyles.modalMask}
        />
        <View style={[commonStyles.modalBox, {
          maxHeight: height - (theme.sizings.large * 4),
          maxWidth: width - (theme.sizings.medium * 2),
        }]}
        >
          <Button
            variant="primary"
            text={t('common.cancel')}
            onPress={() => setModalVisible(false)}
          />
          <ScrollView>
            <ColumnDates
              form={form}
              values={values}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              showOverrides={showOverrides}
              nbConflicts={nbConflicts}
              nbOverBooking={nbOverBooking}
              checkConflict={checkConflict}
              siteBookingItems={siteBookingItems}
              setModalVisible={setModalVisible}
            />
          </ScrollView>

        </View>
      </View>
    </Modal>
  );
};

CurrentDateModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  currentDate: PropTypes.any.isRequired,
  setCurrentDate: PropTypes.any.isRequired,
  showOverrides: PropTypes.bool.isRequired,
  nbConflicts: PropTypes.object.isRequired,
  nbOverBooking: PropTypes.object.isRequired,
  checkConflict: PropTypes.func.isRequired,
  siteBookingItems: PropTypes.array.isRequired,
};

CurrentDateModal.defaultProps = {
  modalVisible: false,
};

export default CurrentDateModal;
