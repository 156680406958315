import { FontAwesome } from '@expo/vector-icons';
import BeachBackground from 'assets/beach.jpg';
import Page from 'common/components/Page/Page';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { GiftedAvatar } from 'react-native-gifted-chat';
import useChat from 'sites/contexts/chat';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const makeStyles = (theme) => StyleSheet.create({
  userItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    alignItems: 'center',
    marginBottom: theme.sizings.small,
  },
  userItemMain: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userItemMainInfo: {
    marginLeft: theme.sizings.small,
  },
  userItemMainMeta: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  count: {
    alignSelf: 'flex-start',
    paddingHorizontal: theme.sizings.small,
    paddingVertical: 0,
    backgroundColor: theme.colors.danger,
    borderRadius: theme.radius.small,
    marginRight: theme.sizings.small,
  },
  italic: {
    fontStyle: 'italic',
  },
});

const ChatList = ({ navigation }) => {
  const { users, fetchUsers, setChatActive } = useChat();
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchUsers();
      setChatActive(true);
    });

    return unsubscribe;
  }, [fetchUsers, navigation, setChatActive]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setChatActive(false);
    });

    return unsubscribe;
  }, [fetchUsers, navigation, setChatActive]);

  const generalChatInfo = useMemo(() => (users.find((({ id }) => id === 0))), [users]);

  return (
    <Page scroll padding transparentHeader backgroundSource={BeachBackground} style={{ marginBottom: 0 }}>
      <TouchableOpacity
        style={[styles.userItem, commonStyles.shadowBottom]}
        key={0}
        onPress={() => navigation.navigate('Chat', { user: { id: 0, name: t('chat.general') } })}
      >
        <View style={styles.userItemMain}>
          <GiftedAvatar user={{ name: t('chat.general') }} />
          <View style={styles.userItemMainInfo}>
            <Text size="large" color="primary">{t('chat.general')}</Text>
            {generalChatInfo && generalChatInfo.lastMessage && (
              <View style={styles.userItemMainMeta}>
                <Text
                  color="greyAlpha"
                  style={styles.italic}
                >
                  <RelativeDate value={generalChatInfo.lastMessageDate} />
                </Text>
              </View>
            )}
            {generalChatInfo && generalChatInfo.lastMessage ? (
              <Text isBold>{generalChatInfo.lastMessage}</Text>
            ) : (
              <Text color="greyAlpha" style={styles.italic}>{t('chat.noMessage')}</Text>
            )}
          </View>
        </View>
        <FontAwesome name="angle-right" size={theme.fontSizes.larger} color={theme.colors.darkBlue} />
      </TouchableOpacity>
      { users.filter(({ id, parent }) => !parent && id !== 0).map((user) => (
        <TouchableOpacity
          style={[styles.userItem, commonStyles.shadowBottom]}
          key={user.id}
          onPress={() => navigation.navigate('Chat', { user })}
        >
          <View style={styles.userItemMain}>
            <GiftedAvatar user={user} />
            <View style={styles.userItemMainInfo}>
              <Text size="large" color="primary">{user.name}</Text>
              {user.lastMessage && (
              <View style={styles.userItemMainMeta}>
                { user.countNewMessages > 0 && (
                <View style={styles.count}>
                  <Text noLineHeight size="small" color="light">
                    +
                    {user.countNewMessages}
                  </Text>
                </View>
                ) }
                <Text color="greyAlpha" style={styles.italic}><RelativeDate value={user.lastMessageDate} /></Text>
              </View>
              )}
              {user.lastMessage ? (
                <Text isBold>{user.lastMessage}</Text>
              ) : (
                <Text color="greyAlpha" style={styles.italic}>{t('chat.noMessage')}</Text>
              )}
              <Text
                color="greyAlpha"
                style={styles.italic}
              >
                {user.description ? `"${user.description}"` : t('chat.noDescription')}
              </Text>
            </View>
          </View>
          <FontAwesome name="angle-right" size={theme.fontSizes.larger} color={theme.colors.darkBlue} />
        </TouchableOpacity>
      ))}
    </Page>
  );
};

ChatList.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default ChatList;
