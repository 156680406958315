/* eslint-disable react/prop-types */
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import ProductsCounter from 'basket/components/ProductsCounter/ProductsCounter';
import HomeButton from 'common/components/HomeButton/HomeButton';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useTranslation from 'common/contexts/translations';
import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import {
  Dimensions, Platform, StyleSheet, TouchableOpacity, View, BackHandler,
} from 'react-native';
import SiteHeaderTitle from 'sites/components/SiteHeaderTitle/SiteHeaderTitle';
import useSite from 'sites/contexts/sites';
import Around from 'sites/pages/SiteDetail/AroundList';
import CategoriesList from 'sites/pages/SiteDetail/CategoriesList';
import ChatList from 'sites/pages/SiteDetail/ChatList';
import ChatSettings from 'sites/pages/SiteDetail/ChatSettings';
import Info from 'sites/pages/SiteDetail/Info';
import LastOrderListing from 'sites/pages/SiteDetail/LastOrderListing';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import useBasket from 'basket/contexts/basket';
import useChat from 'sites/contexts/chat';
import getFF from 'common/utils/getFF';
import dayjs from 'dayjs';
import useBooking, { useLocalBooking } from 'bookings/contexts/bookings';
import { useAuth } from 'auth/contexts/auth';

const styles = StyleSheet.create({
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const BackButton = () => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const goToInfo = () => {
    navigation.navigate('info');
  };

  return (
    <TouchableOpacity style={[commonStyles.headerIcon, { marginLeft: theme.sizings.small }]} onPress={goToInfo}>
      <FontAwesome
        color={theme.colors.light}
        size={theme.fontSizes.larger}
        name="arrow-left"
      />
    </TouchableOpacity>
  );
};

const CogChatButton = () => {
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const goToSettings = () => {
    navigation.navigate('ChatSettings');
  };

  return (
    <TouchableOpacity style={commonStyles.headerIcon} onPress={goToSettings}>
      <FontAwesome
        color={theme.colors.light}
        size={theme.fontSizes.larger}
        name="cog"
      />
    </TouchableOpacity>
  );
};

const SiteDetail = ({ route }) => {
  const { t, lang } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const { siteId, bookingId } = route.params;
  const {
    item: site,
    setItem,
    isDataFetching,
    fetchSiteData,
    setCurrentSite,
  } = useSite();
  const { setItems: setBasket } = useBasket();
  const { item: booking, fetchItem: fetchBooking } = useBooking();
  const { setBooking: setCurrentBooking } = useAuth();
  const navigation = useNavigation();
  const { chatUser } = useChat();
  const { localBookings, setLocalBookings } = useLocalBooking();

  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const SiteStack = createBottomTabNavigator();
  const InfoStack = createStackNavigator();
  const MenuStack = createStackNavigator();
  const AroundStack = createStackNavigator();
  const ChatStack = createStackNavigator();
  const OrderListStack = createStackNavigator();
  const routeLabels = {
    info: t('tabs.info'),
    shop: t('tabs.shop'),
    around: t('tabs.around'),
    chat: t('tabs.chat'),
    orders: t('tabs.orders'),
  };
  const routeIcons = {
    info: 'ios-water',
    shop: 'ios-cafe',
    around: 'ios-map',
    chat: 'ios-chatbubbles',
    orders: 'ios-basket',
  };

  useEffect(() => {
    if (bookingId) {
      fetchBooking(bookingId);
    }
  }, [bookingId, fetchBooking]);

  useEffect(() => {
    if (booking) {
      if (localBookings && !localBookings.find(({ id }) => id === booking.id)) {
        setLocalBookings(localBookings ? [...localBookings, booking] : [booking]);
      }
    }
  }, [booking, localBookings, setLocalBookings]);

  useEffect(() => {
    const backAction = () => {
      setModalVisible(true);
      return (true);
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    if (booking) {
      setCurrentBooking(booking);
    }
  }, [booking, setCurrentBooking]);

  const currentBookingItem = booking?.booking_items?.find(({ date }) => dayjs(date).isToday());

  const renderScreens = useMemo(() => {
    if (!site) {
      return;
    }

    const gradientHeader = {
      headerBackground: () => (
        <LinearGradient
          colors={[theme.colors.secondary, theme.colors.secondaryDark]}
          start={[0, 0.5]}
          end={[1, 0.5]}
          style={{
            flex: 1,
            height: '100%',
          }}
        />
      ),
    };

    const darkGradientHeader = {
      headerBackground: () => (
        <LinearGradient
          colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.2)']}
          start={[0, 0]}
          end={[0, 1]}
          style={{
            flex: 1,
          }}
        />
      ),
    };

    const headerStyles = {
      headerBackTitleVisible: false,
      headerRight: () => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <UserButton /> */}
          {site.shopProductsMode === 'buy' && currentBookingItem?.status === 'checked_in' && (
          <ProductsCounter />
          )}
        </View>
      ),
      headerTitleAlign: 'center',
      headerTitleStyle: {
        color: theme.colors.light,
        fontSize: theme.fontSizes.larger,
        fontFamily: 'MontserratBold',
        // 80 taille de deux pictos à droite + marge * 2 côté
        width: Dimensions.get('window').width - (theme.normalize(80 + 18)) * 2,
        textAlign: 'center',
      },
      headerBackImage: () => (
        <View style={{
          width: theme.normalize(50), height: theme.normalize(30), paddingTop: theme.normalize(5),
        }}
        >
          <Ionicons
            style={{ marginLeft: theme.sizings.medium }}
            name="ios-arrow-back"
            size={theme.fontSizes.larger}
            color="rgba(255, 255, 255, .5)"
          />
        </View>
      ),
    };

    const cardStyle = Platform.OS === 'web' ? { height: '100%' } : null;

    const InfoStackScreen = () => (
      <InfoStack.Navigator>
        <InfoStack.Screen
          name="informations"
          options={{
            headerTransparent: true,
            cardStyle,
            ...headerStyles,
            ...darkGradientHeader,
            headerLeft: () => <HomeButton />,
            title: <SiteHeaderTitle />,
          }}
        >
          {(props) => <Info {...props} />}
        </InfoStack.Screen>
      </InfoStack.Navigator>
    );
    const MenuStackScreen = () => (
      <MenuStack.Navigator>
        <MenuStack.Screen
          name="menu"
          options={{
            title: t('route.shop'),
            cardStyle,
            ...headerStyles,
            ...gradientHeader,
            headerLeft: () => <BackButton />,
          }}
        >
          {(props) => (
            <CategoriesList
              {...props}
              categories={site.categories}
              categoryServices={site.category_services}
            />
          )}
        </MenuStack.Screen>
      </MenuStack.Navigator>
    );

    const AroundStackScreen = () => (
      <AroundStack.Navigator>
        <AroundStack.Screen
          name="around"
          options={{
            title: t('route.around'),
            ...headerStyles,
            cardStyle,
            ...gradientHeader,
            headerLeft: () => <BackButton />,
          }}
        >
          {(props) => <Around {...props} categoryItems={site.category_items} />}
        </AroundStack.Screen>
      </AroundStack.Navigator>
    );

    const OrdersListStackScreen = () => (
      <OrderListStack.Navigator>
        <OrderListStack.Screen
          name="orders"
          options={{
            title: t('tabs.orders'),
            ...headerStyles,
            cardStyle,
            ...gradientHeader,
            headerLeft: () => <BackButton />,
          }}
        >
          {(props) => <LastOrderListing {...props} site={site} isFetching={isDataFetching} />}
        </OrderListStack.Screen>
      </OrderListStack.Navigator>
    );

    const ChatStackScreen = () => {
      let initialRouteName = 'ChatList';

      if (!chatUser?.active) {
        initialRouteName = 'ChatSettings';
      }
      return (
        <ChatStack.Navigator initialRouteName={initialRouteName}>
          <ChatStack.Screen
            name="ChatList"
            component={ChatList}
            options={{
              title: t('route.chat'),
              headerTransparent: true,
              ...headerStyles,
              cardStyle,
              ...darkGradientHeader,
              headerLeft: () => <BackButton />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <CogChatButton />
                </View>
              ),
            }}
          />
          <ChatStack.Screen
            name="ChatSettings"
            component={ChatSettings}
            options={{
              title: t('chat.settings'),
              headerTransparent: true,
              ...headerStyles,
              cardStyle,
              ...darkGradientHeader,
              headerRight: () => null,
            }}
          />
        </ChatStack.Navigator>
      );
    };

    const screens = [
      <SiteStack.Screen
        key="info"
        name="info"
        options={{
          title: t('tabs.info'),
        }}
        component={InfoStackScreen}
      />,
    ];

    if (currentBookingItem?.status === 'checked_in') {
      if (site.category_items.length) {
        screens.push(<SiteStack.Screen
          key="shop"
          name="shop"
          options={{ title: t('tabs.shop') }}
          component={MenuStackScreen}
        />);
      }
      screens.push(<SiteStack.Screen
        key="orders"
        name="orders"
        options={{ title: t('tabs.orders') }}
        component={OrdersListStackScreen}
      />);
      screens.push(<SiteStack.Screen
        key="around"
        name="around"
        options={{ title: t('tabs.around') }}
        component={AroundStackScreen}
      />);
      if (site?.chatIsActive) {
        screens.push(<SiteStack.Screen
          key="chat"
          name="chat"
          options={{ title: t('tabs.chat') }}
          component={ChatStackScreen}
        />);
      }
    }

    return screens;
  }, [site, t, isDataFetching, chatUser, theme, currentBookingItem]);

  useEffect(() => {
    if (site) {
      setCurrentSite(site);
    }
  }, [site, setCurrentSite]);

  const fetchAndSetData = useCallback(async () => {
    const site = await fetchSiteData(siteId);

    setItem(site);
  }, [fetchSiteData, setItem, siteId]);

  useEffect(() => {
    if (siteId) {
      fetchAndSetData();
    }
  }, [fetchAndSetData, siteId]);

  const handleConfirm = () => {
    setModalVisible(false);
    setBasket([]);
    navigation.reset({
      index: 0,
      routes: [{ name: 'HomePage' }],
    });
  };

  return site ? (
    <>
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
      >
        <View style={commonStyles.modalInner}>
          <TouchableOpacity
            onPress={() => setModalVisible(false)}
            style={commonStyles.modalMask}
          />
          <View style={commonStyles.modalBox}>
            <Text style={commonStyles.modalText}>{t('bookings.confirmExit')}</Text>
            <View style={commonStyles.modalFooter}>
              <Button
                variant="greyLighter"
                text={t('common.cancel')}
                onPress={() => setModalVisible(false)}
                style={commonStyles.modalButton}
              />
              <Button
                variant="success"
                text={t('common.confirm')}
                onPress={handleConfirm}
                style={commonStyles.modalButton}
              />
            </View>
          </View>
        </View>
      </Modal>
      <SiteStack.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ color, size }) => (
            <Ionicons name={routeIcons[route.name]} size={size} color={color} />
          ),
          tabBarLabel: routeLabels[route.name],
          headerShown: false,
        })}
        tabBarOptions={{
          tabStyle: {
            backgroundColor: theme.colors.dark,
            paddingVertical: theme.sizings.small,
            // paddingBottom: Platform.OS === 'android' ? theme.sizings.small : 30,
            // height: Platform.OS === 'android' ? 60 : 92,
            height: theme.normalize(60),
          },
          labelStyle: {
            fontSize: theme.fontSizes.default,
            fontFamily: getFF(lang, false, theme),
            paddingTop: Platform.OS === 'ios' ? theme.sizings.tiny : 0,
            textAlign: 'center',
          },
          activeTintColor: theme.colors.secondaryLight,
          inactiveTintColor: theme.colors.greyDarker,
        }}
      >
        {renderScreens}
      </SiteStack.Navigator>
    </>
  ) : (
    <LoadingSpinner
      visible={isDataFetching}
      theme={theme}
    />
  );
};

SiteDetail.propTypes = {
  route: PropTypes.object.isRequired,
};

export default SiteDetail;
