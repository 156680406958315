import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet,
} from 'react-native';

import useTranslation from 'common/contexts/translations';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import Text from 'common/components/Text/Text';
import useTheme from 'providers/ThemeProvider';
import Price from 'common/components/Price/Price';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';

const ChooseMethod = ({
  total,
  canPayOnline,
  setOnlineSelected,
  onlineSelected,
  setMethodSelected,
  methodSelected,
  onlineMinPrice,
  onlineMaxPrice,
  transactionFees,
  roomID,
}) => {
  const { t } = useTranslation();
  const { item: site } = useSite();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const paymentMethods = useMemo(() => {
    const methods = [{
      value: 'CASH',
      text: t('basket.cashPay'),
      iconName: 'money',
    }, {
      value: 'CB',
      text: t('basket.cbPay'),
      iconName: 'credit-card',
    }];

    if (roomID) {
      methods.push({ value: 'ROOM', text: t('common.room'), iconName: 'bed' });
    }

    return methods;
  }, [t, roomID]);

  const finalTotal = useMemo(() => {
    if (onlineSelected && total > 0) {
      return total + transactionFees;
    }
    return total;
  }, [total, onlineSelected, transactionFees]);

  return (
    <View style={styles.modalContent}>
      <View style={styles.header}>
        <Text centered isBold>{t('basket.totalAmount')}</Text>
        <Price
          price={finalTotal}
          color="primary"
          size="huge"
          viewStyle={styles.amount}
        />
        {onlineSelected && total > 0 && (
          <Text centered>
            { t('basket.transactionFees', { price: formatPrice(transactionFees, site.currency) })}

          </Text>
        )}
      </View>
      <Text centered isBold>{t('basket.howToPay')}</Text>
      {canPayOnline && total < onlineMinPrice && (
        <Text>{t('basket.onlineNotAllowed', { price: formatPrice(onlineMinPrice, site.currency) })}</Text>
      )}
      {canPayOnline && total > onlineMaxPrice && (
        <Text>{t('basket.maxAmountReached', { price: formatPrice(onlineMaxPrice, site.currency) })}</Text>
      )}
      {canPayOnline && total <= onlineMaxPrice && (
        <ButtonGroup
          onChange={setOnlineSelected}
          value={onlineSelected}
          options={[{
            value: false,
            text: t('basket.offline'),
            iconName: 'handshake-o',
          }, {
            value: true,
            text: t('basket.online'),
            iconName: 'wifi',
          }]}
        />
      )}

      {!onlineSelected && (
        <ButtonGroup
          onChange={setMethodSelected}
          value={methodSelected}
          options={paymentMethods}
          vertical
        />
      )}
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  modalContent: {
    paddingBottom: theme.sizings.medium,
  },
  header: {
    marginBottom: theme.sizings.medium,
  },
  amount: {
    justifyContent: 'center',
  },
});

ChooseMethod.propTypes = {
  total: PropTypes.number.isRequired,
  setOnlineSelected: PropTypes.func.isRequired,
  onlineSelected: PropTypes.bool.isRequired,
  setMethodSelected: PropTypes.func.isRequired,
  methodSelected: PropTypes.string.isRequired,
  canPayOnline: PropTypes.bool.isRequired,
  onlineMinPrice: PropTypes.number,
  onlineMaxPrice: PropTypes.number,
  transactionFees: PropTypes.number,
  roomID: PropTypes.string,
};

ChooseMethod.defaultProps = {
  onlineMinPrice: 0,
  onlineMaxPrice: 100000000,
  transactionFees: 0,
  roomID: '',
};

export default ChooseMethod;
