import React, {
  useMemo, useEffect, useState, useCallback,
} from 'react';
import {
  View, StyleSheet, useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import dayjs from 'dayjs';
import { useAuth } from 'auth/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';

import useSiteProvider from 'providers/SiteProvider';
import { ScrollView } from 'react-native-gesture-handler';
import isDesktopMode from 'common/utils/desktopMode';
import getBookingPrices from 'common/utils/getBookingPrices';
import useBooking from 'bookings/contexts/bookings';
import BookingFormCustomer from './subforms/BookingFormCustomer';
import BookingFormDates from './subforms/BookingFormDates';
import BookingFormPositions from './subforms/BookingFormPositions';
import BookingFormSwitch from './components/BookingFormSwitch';
import useBeachVisualizer from '../../../waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';

const BookingForm = ({
  onSubmit, booking, initialSeats, initialStayDuration, initialPeriodType,
  editPositionsAtDate, newCustomer, date: selectedDate, handleDelete,
}) => {
  const { user } = useAuth();
  const [formTab, setFormTab] = useState(editPositionsAtDate ? 'position' : 'customer');
  const [siteBookingItems, setSiteBookingItems] = useState([]);
  const [nbOverbookings, setNbOverbookings] = useState({});
  const isTablet = !isDesktopMode(useWindowDimensions());

  const {
    getSeatInfo, site,
  } = useSiteProvider();

  const { showMapBloc, setShowMapBloc } = useBeachVisualizer();

  const {
    setBookingConflicts, calculateConflicts,
  } = useBooking();

  // TODO code en double a factoriser
  const initialContent = useMemo(() => {
    const content = [];

    initialSeats?.forEach((s) => {
      const seat = getSeatInfo(s);
      const c = content.find((bc) => bc.price_area === seat.price_area && bc.furniture === seat.furniture);

      if (!c) {
        const bContent = {
          price_area: seat.price_area,
          furniture: seat.furniture,
          amount: 1,
        };

        content.push(bContent);
      } else {
        c.amount += 1;
      }
    });
    return content;
  }, [initialSeats, getSeatInfo]);

  const newReservableDate = useMemo(() => {
    const reservableDate = dayjs(selectedDate).isBefore(dayjs())
      ? dayjs()
      : dayjs(selectedDate);

    return reservableDate.startOf('day').toDate();
  }, [selectedDate]);

  const initialValues = useMemo(() => booking?.id
    ? {
      ...booking,
      booking_items: booking.booking_items.map((bi) => ({
        ...bi,
        seats: bi.seats.map(({ id }) => id),
        date: dayjs(bi.date).toDate(),
        bookingContent: bi.bookingContent.map((bc) => ({
          ...bc,
          furniture: bc.furniture.id,
          price_area: bc.price_area.id,
        })),
      })),
      bookingContent: booking.bookingContent.map((bc) => ({
        ...bc,
        furniture: bc.furniture.id,
        price_area: bc.price_area.id,
      })),
      customer: booking.customer?.id,
      clientName: booking.created_by_customer ? (booking.clientName || 'No name') : null,
      clientFirstName: booking.created_by_customer ? (booking.clientFirstName || null) : null,
      clientPhone: booking.created_by_customer ? (booking.clientPhone || '') : null,
      clientEmail: booking.created_by_customer ? (booking.clientEmail || '') : null,
      seats: booking.seats.map(({ id }) => id),
      dates: booking.booking_items?.map(({ date }) => dayjs(date).toDate()),
      periodType: booking.booking_items?.find((bi) => bi.status !== 'reserved') ? 'multiple' : booking.periodType,
      site: booking.site.id,
      created_by_customer: booking.created_by_customer,
    }
    : {
      waiterAuthor: user?.username || null,
      dates: [newReservableDate],
      booking_items: [{
        seats: [...initialSeats],
        bookingContent: [...initialContent],
        stayDuration: initialStayDuration,
        date: newReservableDate,
        override: null,
      }],
      bookingContent: [...initialContent],
      customer: newCustomer ? newCustomer.id : null,
      site: user.site?.id,
      seats: [...initialSeats],
      stayDuration: initialStayDuration,
      periodType: initialPeriodType,
      status: 'reserved',
      hotel: false,
      created_by_customer: false,
      bookingType: initialSeats.length ? 'withSeats' : 'withoutSeats',
    }, [
    booking,
    initialSeats,
    initialContent,
    initialStayDuration,
    user,
    initialPeriodType,
    newReservableDate,
    newCustomer,
  ]);

  const getCurrentBookingItems = useCallback(async (dates) => {
    const params = {
      _start: 0,
      _limit: -1,
      status_in: ['reserved', 'checked_in'],
      'booking.site.id': site?.id,
      // _sort:
    };

    dates.forEach((d) => {
      if (dayjs(d).isValid()) {
        if (!params.date) {
          params.date = [];
        }
        params.date.push(dayjs(d).format('YYYY-MM-DD'));
      }
    });

    const newSiteBookingItems = await fetchJSON({
      url: `booking-items?${formatQueryParams(params)}`,
      method: 'GET',
    });

    setSiteBookingItems(newSiteBookingItems);
    return newSiteBookingItems;
  }, [site]);

  const checkConflict = useCallback((values, seatBookingItems, isToggle = false) => {
    const newBookingConflicts = calculateConflicts(values, seatBookingItems, isToggle);

    setBookingConflicts(newBookingConflicts);
  }, [setBookingConflicts, calculateConflicts]);

  const handleUpdateContent = (content, values, form) => {
    const items = values.booking_items.map((bi) => {
      if (!bi.override) {
        return {
          ...bi,
          bookingContent: [...content],
        };
      }
      return bi;
    });

    form.mutators.setFieldValue({ field: 'booking_items', value: items });
  };

  const handleDateUpdate = useCallback(async (dates, values, form) => {
    const validDates = dates.map((d) => dayjs(d).toDate());
    const newSiteBookingItems = await getCurrentBookingItems(validDates);

    if (values.periodType === 'range' && dates.length < 2) {
      return;
    }
    const items = [];

    dates.forEach((date) => {
      const index = values.booking_items.findIndex(({ date: bdate }) => dayjs(date).isSame(bdate, 'day'));

      if (index !== -1) {
        items.push(values.booking_items[index]);
      } else {
        items.push({
          date: dayjs(date).toDate(),
          seats: [...values.seats],
          bookingContent: [...values.bookingContent.map(({ id, ...rest }) => rest)],
          stayDuration: values.stayDuration,
          override: false,
        });
      }
    });
    items.sort((a, b) => a.date.getTime() > b.date.getTime());
    form.mutators.setFieldValue({ field: 'booking_items', value: items });

    // Vérification des conflits sur les booking_items ajoutés
    checkConflict(
      { ...values, dates: validDates, booking_items: items },
      newSiteBookingItems,
    );
  }, [checkConflict, getCurrentBookingItems]);

  const handleStayDurationUpdate = useCallback(async (newStayDuration, values, form) => {
    const newSiteBookingItems = await getCurrentBookingItems(values.dates);
    const updatedBookingItems = values.booking_items.map((bi) => {
      if (bi.status === 'reserved' || !bi.status) {
        return { ...bi, stayDuration: newStayDuration };
      }
      return bi;
    });

    checkConflict(
      { ...values, stayDuration: newStayDuration, booking_items: updatedBookingItems },
      newSiteBookingItems,
    );

    form.mutators.setFieldValue({ field: 'booking_items', value: updatedBookingItems });
    form.mutators.setFieldValue({ field: 'stayDuration', value: newStayDuration });
  }, [checkConflict, getCurrentBookingItems]);

  const hasOverbooking = useMemo(() => (
    Object.values(nbOverbookings).reduce((acc, value) => acc + value, 0) > 0
  ), [nbOverbookings]);

  useEffect(() => {
    getCurrentBookingItems(initialValues.dates, initialValues.stayDuration);
  }, [initialValues, getCurrentBookingItems, selectedDate]);

  const formatQueryParams = (params) => {
    const esc = encodeURIComponent;
    const queryParams = Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((p) => `${esc(key)}=${esc(p)}`).join('&');
        }
        return `${esc(key)}=${esc(params[key])}`;
      })
      .join('&');

    return queryParams;
  };

  const formContent = useCallback((form, values) => (
    <View>
      {formTab === 'customer' && (
      <BookingFormCustomer
        form={form}
        values={values}
        initialValues={initialValues}
        setFormTab={setFormTab}
        newCustomer={newCustomer}
      />
      )}

      {formTab === 'dates' && (
      <BookingFormDates
        form={form}
        values={values}
        setFormTab={setFormTab}
        onDateUpdate={handleDateUpdate}
        onStayDurationUpdate={handleStayDurationUpdate}
      />
      )}

      {formTab === 'position' && (
      <BookingFormPositions
        form={form}
        initialValues={initialValues}
        values={values}
        onUpdateContent={handleUpdateContent}
        editPositionsAtDate={editPositionsAtDate}
        siteBookingItems={siteBookingItems}
        checkConflict={checkConflict}
        nbOverBooking={nbOverbookings}
        setNbOverBooking={setNbOverbookings}
      />
      )}
    </View>
  ), [checkConflict, editPositionsAtDate, formTab, handleDateUpdate,
    handleStayDurationUpdate, initialValues, nbOverbookings, newCustomer, siteBookingItems]);

  useEffect(() => {
    if (formTab !== 'position') {
      setShowMapBloc(false);
    }
  }, [formTab, setShowMapBloc]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        handleSubmit, submitting, values, form,
      }) => (
        <View style={styles.container}>
          <BookingFormSwitch
            formTab={formTab}
            setFormTab={setFormTab}
            values={values}
            submitting={submitting}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            bookingPrices={getBookingPrices(
              values.booking_items,
              site.price_areas ?? [],
              site.seasons,
              values.hotel,
            )}
            hasOverbooking={hasOverbooking}
          />

          {/* why papin */}
          {/* isTablet && !showMapBloc ? (
            <ScrollView style={styles.tabsContent}>
              {formContent(form, values)}
            </ScrollView>
          ) : (
            <View style={styles.tabsContent}>
              {formContent(form, values)}
            </View>
          ) */}
          <ScrollView style={styles.tabsContent}>
            {formContent(form, values)}
          </ScrollView>
        </View>
      )}
    />
  );
};

BookingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  date: PropTypes.string,
  booking: PropTypes.object,
  initialSeats: PropTypes.array,
  initialStayDuration: PropTypes.string,
  initialPeriodType: PropTypes.string,
  newCustomer: PropTypes.object,
  editPositionsAtDate: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
};

BookingForm.defaultProps = {
  booking: {},
  initialSeats: [],
  initialStayDuration: 'fullday',
  initialPeriodType: 'single',
  newCustomer: null,
  editPositionsAtDate: null,
  date: null,
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  tabsContent: {
    flex: 1,
    height: '100%',
  },
});

export default BookingForm;
