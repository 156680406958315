import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import useChat from 'sites/contexts/chat';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const BlockUserButton = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    blockUser, reportUser, isUserFetching, chatWith,
  } = useChat();
  const { setAlert } = useAlert();
  const [modalVisible, setModalVisible] = useState(false);
  const [method, setMethod] = useState();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const handlePress = useCallback(() => {
    setMethod(null);
    setModalVisible(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    setModalVisible(false);
    try {
      if (method === 'block') {
        await blockUser();
      } else {
        await reportUser();
      }
      setAlert({ color: 'success', title: t('common.success'), message: t(`chat.${method}Success`) });
      navigation.pop();
    } catch {
      setAlert({ color: 'error', title: t('common.error'), message: t(`chat.${method}Error`) });
    }
  }, [blockUser, reportUser, method, navigation, setAlert, t]);

  return chatWith && chatWith.id !== 0 ? (
    <>
      <LoadingSpinner
        visible={isUserFetching}
        theme={theme}
      />
      <TouchableOpacity style={commonStyles.headerIcon} onPress={handlePress}>
        <FontAwesome
          color={theme.colors.warning}
          size={theme.fontSizes.larger}
          name="ban"
        />
      </TouchableOpacity>
      <Modal
        transparent
        visible={modalVisible}
        ariaHideApp={false}
        style={commonStyles.modal}
      >
        <View style={commonStyles.modalInner}>
          <TouchableOpacity
            onPress={() => setModalVisible(false)}
            style={commonStyles.modalMask}
          />
          <View style={commonStyles.modalBox}>
            {method && (method === 'block' ? (
              <Text
                style={commonStyles.modalText}
              >
                {t('chat.confirmBlockUser')}
              </Text>
            ) : (<Text style={commonStyles.modalText}>{t('chat.confirmReportUser')}</Text>))}
            <View style={commonStyles.modalFooter}>
              {method ? (
                <>
                  <Button
                    variant="greyLighter"
                    text={t('common.cancel')}
                    onPress={() => setMethod(null)}
                    style={commonStyles.modalButton}
                  />
                  <Button
                    variant="success"
                    text={t('common.confirm')}
                    onPress={handleConfirm}
                    style={commonStyles.modalButton}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="success"
                    text={t('chat.block')}
                    onPress={() => setMethod('block')}
                    style={commonStyles.modalButton}
                  />
                  <Button
                    variant="warning"
                    text={t('chat.report')}
                    onPress={() => setMethod('report')}
                    style={commonStyles.modalButton}
                  />
                </>
              )}
            </View>
          </View>
        </View>
      </Modal>
    </>
  ) : null;
};

export default BlockUserButton;
