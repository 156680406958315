import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Text from 'common/components/Text/Text';
import useBasket from 'basket/contexts/basket';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';

const makeStyles = (theme) => StyleSheet.create({
  countBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.colors.danger,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.normalize(18),
    height: theme.normalize(18),
    borderRadius: theme.radius.rounded,
  },
});

const ProductsCounter = () => {
  const { items: basket } = useBasket();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const [count, setCount] = useState(0);

  useEffect(() => {
    let tmp = 0;

    if (basket) {
      basket.forEach((item) => {
        tmp += item.quantity;
      });
      setCount(tmp);
    }
  }, [basket]);

  const CountBadge = ({ value }) => (value !== 0) ? (
    <View style={styles.countBadge}>
      <View>
        <Text color="light" size="tiny">{value}</Text>
      </View>
    </View>
  ) : null;

  CountBadge.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  return (
    <TouchableOpacity style={commonStyles.headerIcon} onPress={() => navigation.navigate('Basket')}>
      <FontAwesome
        name="shopping-basket"
        color={theme.colors.light}
        size={theme.fontSizes.large}
      />
      <CountBadge
        value={count}
      />
    </TouchableOpacity>
  );
};

export default ProductsCounter;
