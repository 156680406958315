import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';

import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import Gradient from 'common/components/Gradient/Gradient';

const PromoMessage = ({ offer }) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  return (
    <View style={[styles.card, commonStyles.shadowBottom]}>
      <View style={styles.iconContainer}>
        <View style={styles.iconWrapper}>
          <Gradient width={theme.normalize(40)} height={theme.normalize(40)} />
          <FontAwesome
            name="clock-o"
            color={theme.colors.secondaryLight}
            size={theme.fontSizes.large}
          />
        </View>
      </View>
      <View style={styles.messageContent}>
        <TranslatedText size="large" value={offer.message} />
      </View>
    </View>
  );
};

PromoMessage.propTypes = {
  offer: PropTypes.object.isRequired,
};

const makeStyles = (theme) => StyleSheet.create({
  card: {
    marginTop: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
    backgroundColor: theme.colors.greyLightest,
    borderRadius: theme.radius.medium,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  messageContent: {
    alignItems: 'center',
    borderLeftWidth: 1,
    flex: 1,
    borderLeftColor: theme.colors.greyLighter,
    paddingLeft: theme.sizings.smallMedium,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.sizings.small,
  },
  iconWrapper: {
    overflow: 'hidden',
    width: theme.normalize(40),
    height: theme.normalize(40),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.black,
    padding: theme.sizings.small,
  },
});

export default PromoMessage;
