/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback, useMemo,
} from 'react';
import {
  Dimensions,
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import PropTypes from 'prop-types';
import isDesktopMode, { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import formatPrice from 'common/utils/formatPrice';
import dayjs from 'dayjs';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import { TouchableHighlight } from 'react-native-gesture-handler';
import FormInput from 'common/components/FormInput/FormInput';
import getBookingPrices from 'common/utils/getBookingPrices';
import useSiteProvider from 'providers/SiteProvider';

const ColumnDates = ({
  form,
  values,
  currentDate,
  setCurrentDate,
  showOverrides,
  nbConflicts,
  nbOverBooking,
  checkConflict,
  siteBookingItems,
  setModalVisible,
}) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const isDesktop = isDesktopMode(useWindowDimensions());

  const styles = makeStyles(theme, isMobile, isDesktop);

  const { t } = useTranslation();

  const { site } = useSiteProvider();

  const priceAreas = useMemo(() => (site?.price_areas ?? []), [site]);

  const bookingPrices = getBookingPrices(
    values.booking_items,
    priceAreas,
    site.seasons,
    values.hotel,
  );

  const nbConflictsItem = useCallback((date) => (
    nbConflicts?.[dayjs(date).format('YYYY-MM-DD')] || 0
  ), [nbConflicts]);

  const nbOverBookingItem = useCallback((date) => (
    nbOverBooking?.[dayjs(date).format('YYYY-MM-DD')] || 0
  ), [nbOverBooking]);

  const handleOverrideChange = (index, override) => {
    if (!override) {
      const newBookingItem = {
        ...values.booking_items[index],
        bookingContent: [...values.bookingContent.map(({ id, ...rest }) => rest)],
        seats: [...values.seats],
        stayDuration: values.stayDuration,
        override: false,
      };
      // re-Vérification des conflits

      checkConflict(
        {
          ...values,
          booking_items: [
            ...values.booking_items.filter((bi, iBi) => iBi !== index),
            newBookingItem,
          ],
        },
        siteBookingItems,
      );

      const field = `booking_items[${index}]`;

      form.mutators.setFieldValue({
        field,
        value: newBookingItem,
      });
    }
  };

  return (
    <>
      <Text
        isBold
        color="dark"
        style={{
          marginTop: theme.sizings.mediumLarge,
          paddingBottom: theme.sizings.smallMedium,
          fontSize: theme.fontSizes.medium,
        }}
      >
        {t('bookings.dates')}
      </Text>

      <TouchableHighlight
        onPress={() => { setCurrentDate(null); if (setModalVisible) { setModalVisible(false); } }}
        style={[
          styles.dateBox,
          currentDate === null && styles.currentDateBox,
        ]}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.dateBoxTop}>
            <View style={{
              flexDirection: 'row', width: '100%', marginVertical: theme.sizings.small, alignItems: 'center',
            }}
            >
              <View style={{ marginRight: theme.sizings.small }}>
                <FontAwesome
                  name="calendar"
                  color={theme.colors.primary}
                  size={theme.fontSizes.large}
                />
              </View>
              <Text
                size="small"
                isBold={currentDate === null}
              >
                {showOverrides || values.booking_items.length === 0 ? (
                  <>
                    {t('bookings.genericDates')}
                  </>
                ) : (
                  <>
                    {dayjs(values.booking_items[0].date).format('LL')}
                    {values.booking_items[0].status !== undefined
                              && values.booking_items[0].status !== null
                              && ` - ${t(`bookings.bookingItemStatus_${values.booking_items[0].status}`)}`}
                  </>
                )}

              </Text>
            </View>

            <Text size="medium" isBold color="purple" style={{ marginVertical: theme.sizings.small }}>
              {t('bookings.totalAmount')}
              {formatPrice(bookingPrices.reduce((acc, item) => acc + item.price, 0))}
            </Text>

          </View>

          {bookingPrices.find((bp) => bp.errorSeason) && (
          <View style={styles.dateBoxTop}>
            <Text color="danger">{t('bookings.errorSeason')}</Text>
          </View>
          )}

          {bookingPrices.find((bp) => bp.errorFurniturePrice) && (
          <View style={styles.dateBoxTop}>
            <Text color="danger">{t('bookings.errorFurniturePrice')}</Text>
          </View>
          )}

          {(!showOverrides && (nbConflictsItem(values.dates[0]) > 0
                   || nbOverBookingItem(values.dates[0]) > 0)) && (
                   <View style={styles.dateBoxTop}>
                     {!showOverrides && nbConflictsItem(values.dates[0]) > 0 && (
                     <Text color="danger" style={{ alignSelf: 'flex-end' }}>
                       {nbConflictsItem(values.dates[0]) > 1
                         ? t('bookings.conflicts', { nb: nbConflictsItem(values.dates[0]) })
                         : t('bookings.conflict')}
                     </Text>
                     )}

                     {!showOverrides && nbOverBookingItem(values.dates[0]) > 0 && (
                     <Text color="danger" style={{ alignSelf: 'flex-end' }}>
                       {nbOverBookingItem(values.dates[0]) > 1
                         ? t('bookings.overbookings', { nb: nbOverBookingItem(values.dates[0]) })
                         : t('bookings.overbooking')}
                     </Text>
                     )}
                   </View>
          )}

          <View style={styles.dateBoxBottomHor}>
            <View style={styles.inputContainerStyle}>
              <FontAwesome5
                name="clock"
                color={theme.colors.dark}
                size={theme.fontSizes.medium}
                style={{ marginRight: theme.sizings.small }}
              />
              <Text size="small">
                {t(`bookings.${values.stayDuration}`)}
              </Text>
            </View>

            <View style={styles.inputContainerStyle}>
              <FontAwesome5
                name="chair"
                color={theme.colors.dark}
                size={theme.fontSizes.medium}
                style={{ marginRight: theme.sizings.small }}
              />
              <Text size="small">
                {values.seats?.length}
              </Text>
            </View>
          </View>

        </View>
      </TouchableHighlight>

      { showOverrides && values.booking_items.sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
        .map((bi, index) => (
          <TouchableHighlight
            onPress={() => { setCurrentDate(bi.date); if (setModalVisible) { setModalVisible(false); } }}
            key={`date-${bi.date}`}
            style={[
              styles.dateBox,
              dayjs(bi.date).isSame(currentDate, 'date') && styles.currentDateBox,
            ]}
          >
            <View style={{ width: '100%' }}>
              <View style={styles.dateBoxTop}>
                <View style={{
                  flexDirection: 'row', width: '100%', marginVertical: theme.sizings.small, alignItems: 'center',
                }}
                >
                  <View style={{ marginRight: theme.sizings.small }}>
                    <FontAwesome
                      name="calendar"
                      color={theme.colors.primary}
                      size={theme.fontSizes.large}
                    />
                  </View>
                  <Text
                    size="small"
                    isBold={dayjs(bi.date).isSame(currentDate, 'date')}
                  >
                    {dayjs(bi.date).format('LL')}
                    {bi.status !== undefined && bi.status !== null
                         && ` - ${t(`bookings.bookingItemStatus_${bi.status}`)}`}
                  </Text>
                </View>

                <View style={styles.priceSwitchContainer}>
                  <Text size="small" isBold color="purple" style={{ marginVertical: theme.sizings.small }}>
                    {formatPrice(bookingPrices.find((bookingPrice) => bookingPrice.date === bi.date).price)}
                  </Text>
                  {!dayjs(bi.date).startOf('day').isBefore(dayjs().startOf('day')) && (
                  <FormInput
                    type="switch"
                    name={`booking_items[${index}].override`}
                    onChange={(val) => { handleOverrideChange(index, val); }}
                    style={{ marginLeft: theme.sizings.smallMedium }}
                  />
                  )}
                </View>
              </View>

              {/* conflicts and overbooking per days */}
              {(nbConflictsItem(bi.date) > 0 || nbOverBookingItem(bi.date) > 0) && (
              <View style={styles.dateBoxTop}>
                {nbConflictsItem(bi.date) > 0 && (
                <Text color="danger">
                  {nbConflictsItem(bi.date) > 1
                    ? t('bookings.conflicts', { nb: nbConflictsItem(bi.date) })
                    : t('bookings.conflict')}
                </Text>
                )}
                {nbOverBookingItem(bi.date) > 0 && (
                <Text color="danger">
                  {nbOverBookingItem(bi.date) > 1
                    ? t('bookings.overbookings', { nb: nbOverBookingItem(bi.date) })
                    : t('bookings.overbooking')}
                </Text>
                )}
              </View>
              ) }

              { bi.override && (
                bi.stayDuration !== values.stayDuration
                      || bi.seats?.length !== values.seats?.length
              ) && (
              <View style={styles.dateBoxBottomHor}>
                {
                         bi.stayDuration !== values.stayDuration && (
                         <View style={styles.inputContainerStyle}>
                           <FontAwesome5
                             name="clock"
                             color={theme.colors.dark}
                             size={theme.fontSizes.medium}
                             style={{ marginRight: theme.sizings.small }}
                           />
                           <Text size="small">
                             {t(`bookings.${bi.stayDuration}`)}
                           </Text>
                         </View>
                         )
                      }

                { bi.seats?.length !== values.seats?.length && (
                <View style={styles.inputContainerStyle}>
                  <FontAwesome5
                    name="chair"
                    color={theme.colors.dark}
                    size={theme.fontSizes.medium}
                    style={{ marginRight: theme.sizings.small }}
                  />
                  <Text size="small">
                    {bi.seats?.length}
                  </Text>
                </View>
                          )}
              </View>

              )}
            </View>
          </TouchableHighlight>
        ))}
    </>
  );
};

export default ColumnDates;

ColumnDates.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  currentDate: PropTypes.any.isRequired,
  setCurrentDate: PropTypes.any.isRequired,
  showOverrides: PropTypes.bool.isRequired,
  nbConflicts: PropTypes.object.isRequired,
  nbOverBooking: PropTypes.object.isRequired,
  checkConflict: PropTypes.func.isRequired,
  siteBookingItems: PropTypes.array.isRequired,
  setModalVisible: PropTypes.any,
};

ColumnDates.defaultProps = {
  setModalVisible: null,
};

const makeStyles = (theme, isMobile, isDesktop) => StyleSheet.create({
  dateBox: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    backgroundColor: theme.colors.greyLightest,
  },
  dateBoxTop: {
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  dateBoxBottomHor: {
    flexDirection: 'row',
    paddingVertical: theme.sizings.tiny,
    width: '100%',
    alignItems: 'center',
  },
  dateBoxBottomVer: {
    width: '100%',
    alignItems: 'flex-start',
  },
  currentDateBox: {
    backgroundColor: theme.colors.greyLighter,
  },
  inputContainerStyle: {
    marginRight: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.tiny,
    paddingVertical: theme.sizings.tiny,
    backgroundColor: theme.colors.white,
    borderRadius: theme.sizings.small,
  },
  priceSwitchContainer: {
    flexDirection: 'row',
    width: isMobile ? '100%' : undefined,
    justifyContent: isMobile ? 'space-between' : undefined,
  },
});
