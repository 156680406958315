import { FontAwesome } from '@expo/vector-icons';
import Button from 'common/components/Button/Button';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import truncateText from 'common/utils/truncateText';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TouchableOpacity, View } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import isToday from 'dayjs/plugin/isToday';
import StayDuration from 'waiter/components/booking-detail-sections/StayDuration';
import SeatsResume from 'waiter/components/booking-detail-sections/SeatsResume';
import ExpectedSeatsResume from 'waiter/components/booking-detail-sections/ExpectedSeatsResume';
import useSite from 'sites/contexts/sites';
import makeStyles from './BookingListItem.styles';

dayjs.extend(isToday);

const BookingListItem = ({
  // eslint-disable-next-line camelcase
  code, booking_items, payment, stayDuration, onPress, ...rest
}) => {
  const { t } = useTranslation();
  const isPassed = dayjs(booking_items?.[booking_items.length - 1]).startOf('day')
    .isBefore(dayjs().startOf('day')) || true;
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);
  const { item: site } = useSite();

  const generateButton = useMemo(() => {
    if (isPassed) {
      return (
        <Button
          noPadding
          icon="arrow-right"
          variant="primary"
          onPress={onPress}
          iconSize="default"
          style={styles.bookingButton}
        />
      );
    }

    return (
      <Button
        noPadding
        icon={payment?.status === 'PAID' ? 'arrow-right' : 'credit-card'}
        variant={payment?.status === 'PAID' ? 'primary' : 'danger'}
        onPress={onPress}
        iconSize="default"
        style={styles.bookingButton}
      />
    );
  }, [payment, isPassed, onPress, styles]);

  return (
    <TouchableOpacity
      {...rest}
      onPress={onPress}
      data-cy={`cypress_recap_booking_${code}`}
    >
      <View style={[styles.booking, commonStyles.shadowBottom]}>
        {/* todo : populate site?.name */}
        <View style={styles.bookingTitle}>
          <Text isBold size="large">
            {site?.name}
          </Text>
        </View>
        <View style={styles.bookingTop}>
          {Boolean(booking_items?.[0]?.seats?.length) && (
          <SeatsResume seats={booking_items?.[0]?.seats} />
          )}

          {Boolean(booking_items?.[0]?.bookingContent?.length) && (
            <ExpectedSeatsResume expectedSeats={booking_items?.[0]?.bookingContent} />
          )}

          { site?.canPayOnline ? (
            <View style={styles.bookingStatus}>
              <FontAwesome
                name={payment?.status === 'PAID' ? 'check' : 'clock-o'}
                color={payment?.status === 'PAID' ? theme.colors.link : theme.colors.warning}
                style={styles.bookingStatusIcon}
              />
              <Text size="small" color={payment?.status === 'PAID' ? 'link' : 'warning'}>
                {payment?.status === 'PAID' ? t('bookings.booked') : t('bookings.pending')}
              </Text>
            </View>
          ) : null }
          <View style={styles.bookingCode}>
            <Text noLineHeight size="medium">n°</Text>
            <Text
              isBold
              noLineHeight
              size="large"
              style={{ marginLeft: theme.sizings.tiny, position: 'relative', top: 1 }}
            >
              {code}

            </Text>
          </View>
          <StayDuration stayDuration={booking_items?.[0]?.stayDuration} />

        </View>
        <View style={styles.bookingContent}>
          <View style={styles.bookingMeta}>
            {booking_items.map(({ date, id }) => (
              <View style={styles.bookingDate} key={`booking-item-${id}`}>
                <FontAwesome
                  style={{ paddingRight: theme.sizings.small }}
                  name="calendar"
                  color={theme.colors.primary}
                  size={theme.fontSizes.default}
                />
                <Text noLineHeight isBold={dayjs(date).isToday()}>{dayjs(date).format('LL')}</Text>
              </View>
            ))}
          </View>
        </View>
        {payment?.status && (
          <View style={styles.bookingButtonContainer}>
            {generateButton}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

BookingListItem.propTypes = {
  code: PropTypes.string.isRequired,
  booking_items: PropTypes.array.isRequired,
  payment: PropTypes.object,
  stayDuration: PropTypes.string,
  onPress: PropTypes.func,
};

BookingListItem.defaultProps = {
  payment: {},
  onPress: () => {},
  stayDuration: '',
};

export default BookingListItem;
