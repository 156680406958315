import { FontAwesome } from '@expo/vector-icons';
import Button from 'common/components/Button/Button';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import Modal from 'common/components/Modal/Modal';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ScrollView, Text, TouchableOpacity, View, StyleSheet, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import { isMobileMode } from 'common/utils/desktopMode';

const makeStyles = (theme) => StyleSheet.create({
  optionsBox: {
    width: '100%',
    maxHeight: theme.normalize(300),
    borderRadius: theme.radius.medium,
    marginVertical: theme.sizings.medium,
  },
  option: {
    flex: 1,
    padding: theme.sizings.small,
    borderColor: theme.colors.greyLighter,
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.tiny,
  },
  selected: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: theme.sizings.small,
    borderRadius: theme.radius.medium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchInput: {
    padding: 0,
  },
  searchInputInner: {
    paddingHorizontal: theme.sizings.small,
    fontSize: theme.sizings.medium,
    marginBottom: 0,
    backgroundColor: theme.colors.greyLighter,
  },
});

const Option = ({
  multiple, current, value, label, onPress,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const selected = useMemo(
    () => current && (multiple ? current.includes(value) : current === value),
    [current, multiple, value],
  );

  return (
    <TouchableOpacity onPress={() => onPress(value)}>
      <View style={[styles.option, {
        backgroundColor: selected ? 'none' : 'rgba(0,0,0,0.1)',
        backgroundImage: selected ? 'linear-gradient(90deg, rgb(255, 216, 83), rgb(255, 151, 21))' : 'none',
      }]}
      >

        <Text>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

Option.propTypes = {
  multiple: PropTypes.bool.isRequired,
  current: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
};

Option.defaultProps = {
  current: null,
  value: null,
};

const Select = ({
  options, onChange, value, multiple, enableSearch, handleNavigate,
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = isMobileMode(useWindowDimensions());
  const [filter, setFilter] = useState('');
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const styles = makeStyles(theme);

  const handleChange = useCallback((selected) => {
    if (multiple) {
      if (value.includes(selected)) {
        onChange(value.filter((val) => val !== selected));
      } else {
        onChange([...value, selected]);
      }
    } else {
      onChange(selected, options.find(({ value }) => value === selected));
      setModalVisible(false);
    }
  }, [onChange, options, value, multiple]);

  const current = multiple ? value || [] : value;

  let selectedText = '';

  if (multiple) {
    const labels = current.map((cval) => options.find(({ value: val }) => cval === val)?.label);

    selectedText = labels.join(', ');
  } else {
    selectedText = options.find(({ value: val }) => val === current)?.label;
  }

  const matches = enableSearch
    ? options?.filter(({ infos }) => infos?.toLowerCase()?.includes(filter?.toLowerCase())) : options;

  return (
    <>
      <TouchableOpacity onPress={() => { setModalVisible(true); }}>
        <View style={{
          backgroundColor: 'rgba(0,0,0,0.1)',
          padding: 10,
          borderRadius: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Text>
            {selectedText || t('common.select')}
          </Text>

          <FontAwesome
            size={theme.fontSizes.large}
            name="angle-down"
            color={theme.colors.greyDarkest}
          />
        </View>
      </TouchableOpacity>
      <Modal
        style={commonStyles.modal}
        transparent
        visible={modalVisible}
        ariaHideApp={false}
        supportedOrientations={['portrait', 'landscape']}
      >
        <View style={commonStyles.modalInner}>
          <TouchableOpacity
            style={commonStyles.modalMask}
            onPress={() => { setModalVisible(false); }}
          />
          <View style={[commonStyles.modalBox, isMobile ? { height: '50%' } : null]}>
            { enableSearch && (
            <DebouncedInput
              value={filter}
              onChange={setFilter}
              style={styles.searchInput}
              inputStyle={styles.searchInputInner}
            />
            ) }

            <ScrollView>

              { matches.map(({ value: val, label }, index) => (
                <Option
                  key={index}
                  value={val}
                  label={label}
                  current={current}
                  multiple={multiple}
                  onPress={handleChange}
                />
              )) }

              { !matches.length && filter
                && (
                <TouchableOpacity onPress={() => {
                  setModalVisible(false);
                  if (handleNavigate) {
                    handleNavigate(filter);
                  }
                }}
                >
                  <View style={styles.option}>
                    <Text isBold>{t('common.newItem', { item: filter })}</Text>
                  </View>
                </TouchableOpacity>
                ) }
            </ScrollView>

            { multiple && (
            <View style={commonStyles.modalFooter}>
              <Button
                variant="primary"
                text={t('common.save')}
                style={{ ...commonStyles.modalButton, flex: 1 }}
                onPress={() => setModalVisible(false)}
              />
            </View>
            )}
          </View>
        </View>
      </Modal>
    </>
  );
};

Select.propTypes = {
  value: PropTypes.any,
  multiple: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  enableSearch: PropTypes.bool,
  handleNavigate: PropTypes.func,
};

Select.defaultProps = {
  value: '',
  multiple: false,
  onChange: () => {},
  enableSearch: false,
  handleNavigate: null,
};

export default Select;
