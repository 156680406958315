import { useState, useEffect, useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useLoading from 'common/contexts/loading';

const usePersistedState = (initialState, key) => {
  const [state, setState] = useState(initialState);
  const [dataFetched, setDataFetched] = useState(false);
  const { addDataLoading, dataLoading, removeDataLoading } = useLoading();

  const getAndSetItem = useCallback(async () => {
    try {
      const item = await AsyncStorage.getItem(key);

      // if storage value is the same as initialState then no need to update
      if (JSON.stringify(initialState) !== item && item !== 'null') {
        setState(JSON.parse(item));
      }
      setDataFetched(true);
      // removeDataLoading(key);
    } catch (e) { // if not found in storage
      console.log('An error occured while getting stored data : ', key, ' error : ', e);
    }
  }, [key, setDataFetched, initialState]);

  useEffect(() => {
    if (dataLoading.indexOf(key) < 0 && !dataFetched) {
      // addDataLoading(key);
      getAndSetItem();
    }
  }, [addDataLoading, dataFetched, dataLoading, getAndSetItem, key]);

  // Only persist to storage if state changes.
  useEffect(() => {
    const asyncSet = async () => {
      // persist to localStorage
      try {
        await AsyncStorage.setItem(key, JSON.stringify(state));
      } catch (e) { // if not found in storage
        console.log('An error occured while getting stored data : ', key, ' error : ', e);
      }
    };

    asyncSet();
  }, [state, key]);

  return [state, setState];
};

export default usePersistedState;
