import React, {
  useMemo, useEffect, useState,
} from 'react';
import {
  View, StyleSheet, Dimensions,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useTheme from 'providers/ThemeProvider';
import useSiteProvider from 'providers/SiteProvider';
import isDesktopMode, { isMobileMode } from 'common/utils/desktopMode';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import useBooking from 'bookings/contexts/bookings';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import { useTranslation } from 'react-i18next';
import { FontAwesome5 } from '@expo/vector-icons';
import Text from 'common/components/Text/Text';
import ColumnMap from './blocs/ColumnMap';
import ColumnDates from './blocs/ColumnDates';
import ColumnContents from './blocs/ColumnContents';
import CurrentDateModal from '../components/CurrentDateModal';

const BookingFormPositions = ({
  values, form, initialValues,
  onUpdateContent,
  siteBookingItems,
  editPositionsAtDate,
  checkConflict,
  nbOverBooking,
  setNbOverBooking,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const isMobile = isMobileMode({ width, height });
  const {
    mapId: beachVisualizerMapId,
    showMapBloc,
  } = useBeachVisualizer();

  const styles = makeStyles(showMapBloc, theme, isDesktopMode({ width, height }), isMobileMode({ width, height }));
  const { t } = useTranslation();
  const {
    site,
    getBookingItemsBySeat,
    getBookedSeatsPerDay,
  } = useSiteProvider();

  const {
    bookingConflicts,
  } = useBooking();

  const [mapId, setMapId] = useState(beachVisualizerMapId ?? -1);
  const [dateModalVisible, setDateModalVisible] = useState(false);

  // Keep the bookingItems (by seat) for the whole booking (it changes when selecting the different booking_items)
  const [globalSeatBookingItems, setGlobalSeatBookingItems] = useState(null);

  /*
  clara : considérons qu'à la création ou édition d'un mono-booking-item (1 seule date),
  on cherchera jamais à overrider par soi-même (la règle générale suffit), mais qu'il peut se
  retrouver passivement overridé par les switchSeats rapides de la page d'accueil
  */
  const showOverrides = values.booking_items.length > 1
  || values.booking_items.find((bi) => bi.override);

  const targetDate = showOverrides && editPositionsAtDate
    ? dayjs(editPositionsAtDate) : null;

  const [currentDate, setCurrentDate] = useState(targetDate);
  const [nbConflicts, setNbConflicts] = useState();

  const bookingContent = useMemo(() => (
    currentDate ? values.booking_items.find((bi) => (
      dayjs(bi.date).isSame(currentDate, 'date')
    ))?.bookingContent : values.bookingContent
  ), [currentDate, values]);

  const bookingItemIndex = useMemo(() => (
    currentDate ? values.booking_items.findIndex((bi) => (
      dayjs(bi.date).isSame(currentDate, 'date')
    )) : -1
  ), [currentDate, values]);

  const bookingType = useMemo(() => (
    currentDate
      ? values.booking_items.find((bi) => (
        dayjs(bi.date).isSame(currentDate, 'date') && bi.override === true
      ))?.bookingType ?? values.bookingType
      : values.bookingType
  ), [currentDate, values]);

  const seatBookingItems = useMemo(() => {
    let currentBookingItems = siteBookingItems.filter((bi) => !values.id || bi.booking.id !== values.id);

    if (!currentDate) {
      const overrideDates = values.booking_items?.filter((bi) => bi.override)?.map((bi) => bi.date);

      currentBookingItems = currentBookingItems.filter((bi) => {
        const biDate = dayjs(bi.date);

        return !overrideDates.some((od) => biDate.isSame(od));
      });
    }
    return getBookingItemsBySeat(
      currentBookingItems,
      currentDate,
    );
  }, [getBookingItemsBySeat, siteBookingItems, currentDate, values]);

  const bookingWithSeats = bookingType === 'withSeats';

  const editingBookingItem = bookingItemIndex !== -1 ? values.booking_items[bookingItemIndex] : null;

  // todo : si c'est l'admin il fait ce qu'il veut
  const allowCurrentBookingItemEdition = bookingItemIndex !== -1
    ? !dayjs(editingBookingItem.date).startOf('day').isBefore(dayjs().startOf('day'))
    : true;

  useEffect(() => {
    if (site.maps?.find((map) => map.active) && mapId === -1) {
      setMapId(site.maps?.find((map) => map.active)?.id);
    }
  }, [site, mapId]);

  const updateAllBookingItems = (newSeats, newBookingContent, values) => (
    values.booking_items.map((bi) => {
      if (!bi.override) {
        return {
          ...bi,
          seats: [...newSeats],
          bookingContent: [...newBookingContent],
        };
      }
      return bi;
    })
  );

  useEffect(() => {
    if (!globalSeatBookingItems && seatBookingItems) {
      setGlobalSeatBookingItems(seatBookingItems);
    }
  }, [globalSeatBookingItems, seatBookingItems]);

  useEffect(() => {
    if (values && globalSeatBookingItems) {
      checkConflict(values, globalSeatBookingItems);
    }
  }, [checkConflict, values, seatBookingItems, globalSeatBookingItems]);

  useEffect(() => {
    const nbOverBookingPerDay = {};

    // get the bi with same dates excluding the ones being edited (if any)
    const otherBI = siteBookingItems.filter((bi) => bi.booking.id !== values.id);

    const currentBI = (values.bookingContent.length > 0 ? values.booking_items : []);

    const otherSeatsPerDay = getBookedSeatsPerDay(otherBI, values?.dates, values?.stayDuration);
    const currentSeatsPerDay = getBookedSeatsPerDay(currentBI, values?.dates, values?.stayDuration);

    // Vérifier pour chaque mobilier de chaque zone de prix si la quantité a excédé le max de la carte
    Object.entries(currentSeatsPerDay).forEach(([date, priceAreas]) => {
      let isPriceAreasOverBooked = 0;

      Object.entries(priceAreas).forEach(([priceAreaId, furnitures]) => {
        let isfurnituresOverBooked = 0;

        Object.entries(furnitures).forEach(([furnitureId, seat]) => {
          const othersSeat = otherSeatsPerDay[date]?.[priceAreaId]?.[furnitureId];

          if (othersSeat) { // if no 'others' seats : no conflicts, no overbooking
            // Récupérer le compte total (current + others)
            // en fonction du stayDuration
            const nbSeatsMorning = seat.morningCount
              + (otherSeatsPerDay[date][priceAreaId][furnitureId].morningCount);
            const nbSeatsAfternoon = seat.afternoonCount
              + (otherSeatsPerDay[date][priceAreaId][furnitureId].afternoonCount);

            let nbSeats = 0;

            if (values.stayDuration === 'morning') {
              nbSeats = nbSeatsMorning;
            } else if (values.stayDuration === 'afternoon') {
              nbSeats = nbSeatsAfternoon;
            } else {
              // get the max from both
              nbSeats = nbSeatsMorning > nbSeatsAfternoon ? nbSeatsMorning : nbSeatsAfternoon;
            }

            if (nbSeats > seat.max) { // s'il y a overbooking
              // vérifier si y a pas déjà un conflit sur les seats de cette furniture
              const nbSeatsInConflict = seat.seats.filter((seatId) => (
                othersSeat.seats.includes(seatId)
              )).length;

              isfurnituresOverBooked += nbSeats - nbSeatsInConflict - seat.max;
            }
          }
        });
        isPriceAreasOverBooked += isfurnituresOverBooked;
      });
      nbOverBookingPerDay[date] = isPriceAreasOverBooked;
    });
    setNbOverBooking(nbOverBookingPerDay);
  }, [getBookedSeatsPerDay, mapId, setNbOverBooking, siteBookingItems, values]);

  // Calculate le number of conflicts by date
  useEffect(() => {
    const nbConflictPerDate = [];

    Object.entries(bookingConflicts).forEach(([date, biList]) => {
      const nbConflicts = Object.values(biList).reduce((biAcc, biItem) => biAcc + biItem.seats?.length || 0, 0);

      if (nbConflicts) {
        nbConflictPerDate[date] = nbConflicts;
      }
    });

    setNbConflicts(nbConflictPerDate);
  }, [bookingConflicts, setNbConflicts]);

  return (
    <View style={styles.container}>
      {isMobile && (
        <>
          <View style={styles.mobileDateHeader}>
            <Text
              isBold
              color={bookingItemIndex !== -1 ? 'purple' : 'dark'}
              centered
              style={{
                fontSize: theme.fontSizes.medium,
              }}
            >
              {bookingItemIndex !== -1 ? dayjs(values.booking_items[bookingItemIndex].date).format('LL')
                : t('bookings.genericDates')}
            </Text>

            <TouchableOpacity
              style={styles.buttonToggle}
              onPress={() => setDateModalVisible(true)}
            >
              <FontAwesome5
                name="calendar"
                color={theme.colors.white}
                size={theme.fontSizes.large * 1.2}
              />
            </TouchableOpacity>
          </View>

          <CurrentDateModal
            setModalVisible={setDateModalVisible}
            modalVisible={dateModalVisible}
            form={form}
            values={values}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            showOverrides={showOverrides}
            nbConflicts={nbConflicts}
            nbOverBooking={nbOverBooking}
            checkConflict={checkConflict}
            siteBookingItems={siteBookingItems}
          />
        </>
      )}

      <View style={styles.columns}>
        { !showMapBloc && (
          <>
            {!isMobile && (
              <ScrollViewLarge style={styles.column}>
                <ColumnDates
                  form={form}
                  values={values}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  showOverrides={showOverrides}
                  nbConflicts={nbConflicts}
                  nbOverBooking={nbOverBooking}
                  checkConflict={checkConflict}
                  siteBookingItems={siteBookingItems}
                />
              </ScrollViewLarge>
            )}

            <View style={[styles.column, { flex: 1 }]}>
              <ColumnContents
                form={form}
                values={values}
                initialValues={initialValues}
                currentDate={currentDate}
                checkConflict={checkConflict}
                siteBookingItems={siteBookingItems}
                onUpdateContent={onUpdateContent}
                bookingItemIndex={bookingItemIndex}
                bookingWithSeats={bookingWithSeats}
                allowCurrentBookingItemEdition={allowCurrentBookingItemEdition}
                bookingContent={bookingContent}
                updateAllBookingItems={updateAllBookingItems}
                bookingType={bookingType}
                mapId={mapId}
              />
            </View>
          </>
        )}

        {(!isMobile || (isMobile && showMapBloc)) && (
          <View style={[styles.column, { flex: 2, padding: 0 }]}>
            <ColumnMap
              form={form}
              values={values}
              currentDate={currentDate}
              checkConflict={checkConflict}
              bookingWithSeats={bookingWithSeats}
              allowCurrentBookingItemEdition={allowCurrentBookingItemEdition}
              bookingContent={bookingContent}
              seatBookingItems={seatBookingItems}
              siteBookingItems={siteBookingItems}
              bookingItemIndex={bookingItemIndex}
              updateAllBookingItems={updateAllBookingItems}
              mapId={mapId}
              setMapId={setMapId}
            />
          </View>
        )}
      </View>
    </View>
  );
};

BookingFormPositions.propTypes = {
  values: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  initialValues: PropTypes.any.isRequired,
  onUpdateContent: PropTypes.func.isRequired,
  editPositionsAtDate: PropTypes.any,
  siteBookingItems: PropTypes.any.isRequired,
  checkConflict: PropTypes.func.isRequired,
  nbOverBooking: PropTypes.object.isRequired,
  setNbOverBooking: PropTypes.func.isRequired,
};

BookingFormPositions.defaultProps = {
  editPositionsAtDate: null,
};

const makeStyles = (showMapBloc, theme, isDesktop, isMobile) => StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  columns: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    flexWrap: 'wrap',
  },
  column: {
    paddingHorizontal: showMapBloc ? 0 : isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    paddingBottom: showMapBloc ? 0 : isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    flex: 1,
    minWidth: isMobile ? '100%' : theme.normalize(400),
    maxHeight: isMobile ? undefined : Dimensions.get('window').height - theme.normalize(90),
  },
  buttonToggle: {
    backgroundColor: 'black',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
    cursor: 'pointer',
  },
  mobileDateHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.sizings.smallMedium,
    backgroundColor: theme.colors.white,
    padding: theme.sizings.smallMedium,
    justifyContent: 'space-between',
  },
});

export default BookingFormPositions;
