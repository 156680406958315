import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  useState,
  useCallback,
  useEffect,
} from 'react';

const getProvider = () => {
  if (typeof global !== 'undefined' && global.localStorage) {
    return global.localStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined' && globalThis.localStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.localStorage;
  }
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage;
  }
  if (typeof localStorage !== 'undefined') {
    return localStorage;
  }
  return AsyncStorage;
};

const createStorage = (provider) => ({
  async get(key, defaultValue) {
    const json = await provider.getItem(key);
    // eslint-disable-next-line no-nested-ternary

    if (json === null || json === 'null' || typeof json === 'undefined') {
      return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }

    const parsed = JSON.parse(json);

    if (parsed.date === null || parsed.date >= Date.now()) {
      return parsed.value;
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  },
  async set(key, value, cacheDuration) {
    try {
      await provider.setItem(
        key,
        JSON.stringify({
          date: cacheDuration ? Date.now() + cacheDuration * 1000 : null,
          value,
        }),
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
});

const storage = createStorage(getProvider());

const usePersistedState = (initialState, key, cacheDuration) => {
  const [state, setState] = useState(undefined);

  useEffect(() => {
    if (state === undefined) {
      storage.get(key, initialState)
        .then((s) => setState(s));
    }
  }, [initialState, key, setState, state]);

  const persistentSetState = useCallback(
    (newState) => {
      setState((oldState) => {
        const newStateValue = typeof newState === 'function' ? newState(oldState) : newState;

        // persist to localStorage
        storage.set(key, newStateValue, cacheDuration);

        return newStateValue;
      });
    },
    [key, cacheDuration],
  );

  return [state, persistentSetState];
};

export default usePersistedState;
