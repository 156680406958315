import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';

import PropTypes from 'prop-types';
import { useTranslation as useI18nTranslation } from 'react-i18next';

import ContextProvider from 'common/contexts/crud';

const TranslationContext = createContext();

dayjs.extend(localizedFormat);
dayjs.extend(weekday);

export const TranslationProvider = ({ children }) => {
  const { t, i18n } = useI18nTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      dayjs.locale(lang.split('-')[0]);
    }
  }, [lang, i18n]);

  const value = useMemo(() => ({
    setLang,
    lang,
    t,
    i18n,
  }), [lang, t, i18n]);

  return (
    <ContextProvider
      url="translations"
      context={TranslationContext}
      value={value}
    >
      {children}
    </ContextProvider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useTranslation = () => useContext(TranslationContext);

export default useTranslation;
