import Button from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import useTranslation from 'common/contexts/translations';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  TouchableOpacity, View,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useCall from 'calls/contexts/calls';
import useCallType from 'callTypes/contexts/callTypes';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import useTrans from 'common/hooks/use-trans';
import useSite from 'sites/contexts/sites';

const CallWaiterModal = ({
  setModalVisible, modalVisible,
}) => {
  const { t } = useTranslation();
  const { translateText } = useTrans();

  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const {
    callWaiter,
  } = useCall();
  const { item: site } = useSite();

  const defaultOptions = [{
    value: null,
    text: t('calls.generic'),
  }];

  const {
    fetchCallTypes,
  } = useCallType();

  useEffect(() => {
    const getOptions = async () => {
      const res = await fetchCallTypes({ site: site.id });

      setOptions(res);
    };

    getOptions();
  }, [fetchCallTypes, setOptions, site]);

  const [callTypeSelected, setCallTypeSelected] = useState(null);
  const [callTypeOptions, setCallTypesOptions] = useState(defaultOptions);

  const setOptions = useCallback((items) => {
    if (items?.length) {
      const newOpts = items?.map((ct) => ({
        value: ct.id,
        text: translateText(ct.name),
      }));

      setCallTypesOptions([...defaultOptions, ...newOpts]);
    }
  },
  [defaultOptions, translateText]);

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <View style={commonStyles.modalInner}>
        <TouchableOpacity
          onPress={() => {
            setModalVisible(false);
          }}
          style={commonStyles.modalMask}
        />
        <View style={[commonStyles.modalBox, { backgroundColor: 'rgba(0, 0, 0, 0.7)' }]}>
          <ScrollView>
            <ButtonGroup
              onChange={setCallTypeSelected}
              value={callTypeSelected}
              options={callTypeOptions}
              vertical
            />

            <Button
              onPress={() => {
                callWaiter(callTypeSelected);
                setModalVisible(false);
              }}
              text={t('orders.saveSelection')}
              style={{ marginVertical: theme.sizings.smallMedium }}
              icon="check"
              variant="gradient"
            />

            <Button
              onPress={() => {
                setModalVisible(false);
              }}
              text={t('common.cancel')}
              variant="primary"
              icon="times"
              iconColor="white"
            />
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

CallWaiterModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
};

CallWaiterModal.defaultProps = {
  modalVisible: false,
};

export default CallWaiterModal;
