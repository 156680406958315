import React from 'react';

import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import Page from 'common/components/Page/Page';

const CGU = () => {
  const { t } = useTranslation();

  return (
    <Page padding scroll>
      <Text size="larger" color="primary">{t('cgu.cgu')}</Text>
      <Text>
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Quibusdam ut accusamus aliquam maxime rerum magnam veritatis aliquid nemo quasi,
        architecto illum ipsa eos eum deserunt dolorem inventore harum consequuntur ducimus.
      </Text>
    </Page>
  );
};

export default CGU;
