import React, {
  useMemo, useState, useCallback,
  useEffect,
} from 'react';
import {
  View, StyleSheet, useWindowDimensions,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import dayjs from 'dayjs';
import Text from 'common/components/Text/Text';
import { ScrollView } from 'react-native-gesture-handler';
import { isDesktopMode, isMobileMode } from 'common/utils/desktopMode';
import Button from 'common/components/Button/Button';
import FormInput from 'common/components/FormInput/FormInput';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import useTheme from 'providers/ThemeProvider';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';

const EventForm = ({
  onSubmit, event, selectedDate,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme, isDesktopMode({ width, height }), isMobileMode({ width, height }));
  const isTablet = !isDesktopMode(useWindowDimensions());
  const { width, height } = useWindowDimensions();
  const [periodType, setPeriodType] = useState('single');

  const getFullDates = (from, to) => {
    let currentDate = dayjs(from);
    const dateList = [currentDate.format('YYYY-MM-DD')];

    while (currentDate.isBefore(to)) {
      currentDate = currentDate.add(1, 'day');
      dateList.push(currentDate.format('YYYY-MM-DD'));
    }
    return dateList;
  };
  const initialValues = useMemo(() => event?.id
    ? {
      ...event,
      dates: getFullDates(event.startDate, event.endDate),
    }
    : {
      name: '',
      active: true,
      dates: [],
    }, [event]);

  useEffect(() => {
    if (event?.id && !dayjs(event?.startDate).isSame(event?.endDate)) {
      setPeriodType('range');
    }
  }, [event]);

  const handleDateUpdate = useCallback(async (dates, form) => {
    const validDates = (Array.isArray(dates) ? dates : [dates]).map((d) => dayjs(d));
    const sortedDates = validDates.sort((a, b) => (a.isAfter(dayjs(b)) ? 1 : -1));

    // start date
    const earliestDate = dayjs(sortedDates[0]);
    // end date
    const lastestDate = dayjs(sortedDates[sortedDates.length - 1]);

    form.mutators.setFieldValue({ field: 'startDate', value: earliestDate });
    form.mutators.setFieldValue({ field: 'endDate', value: lastestDate });
  }, []);

  const ViewForDevice = useMemo(() => isTablet ? ScrollView : View, [isTablet]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({
        handleSubmit, values, form, pristine,
      }) => (
        <View style={styles.container}>
          <ViewForDevice style={styles.tabsContent}>
            <View>
              <ScrollView style={styles.container}>
                {/* left part : dates settings */}
                <View style={styles.columns}>

                  <ScrollViewLarge style={styles.column}>
                    <View style={styles.containerRowStyle}>
                      <Text isBold style={styles.label} color="primary">{t('events.periodType')}</Text>
                      <ButtonGroup
                        options={[{
                          value: 'single',
                          text: t('events.singleDate'),
                        }, {
                          value: 'range',
                          text: t('events.rangeDate'),
                        }]}
                        value={periodType}
                        verticalMobile
                        required
                        onChange={(val) => setPeriodType(val)}
                      />
                    </View>
                    <FormInput
                      type="date"
                      icon="calendar"
                      name="dates"
                      label={periodType === 'single' ? t('bookings.date') : t('bookings.dates')}
                      showCalendar
                      onChange={(dates) => handleDateUpdate(dates, form)}
                      required
                      mode={periodType}
                      multiple
                      disabledPassedDays
                      showDateList={false}
                      initialDate={selectedDate}
                      selectedStartDate={dayjs(values.startDate).format('YYYY-MM-DD')}
                      selectedEndDate={pristine && values?.endDate && dayjs(values.endDate).format('YYYY-MM-DD')}
                    />
                  </ScrollViewLarge>

                  <View style={styles.column}>
                    <FormInput
                      type="text"
                      name="name"
                      label={t('events.name')}
                      required
                      containerStyle={{ paddingTop: theme.sizings.mediumLarge }}
                    />
                    <FormInput
                      type="switch"
                      name="active"
                      label={t('events.active')}
                    />
                  </View>

                </View>

                <View style={{
                  paddingVertical: theme.sizings.medium,
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
                >
                  <Button
                    variant={!values.dates?.length ? 'greyDarker' : 'gradient'}
                    onPress={handleSubmit}
                    disabled={!(values.name && values.startDate && values.endDate)}
                    icon="arrow-right"
                    iconColor="white"
                    text={t('common.submit')}
                    type="submit"
                    style={{ marginLeft: theme.sizings.medium }}
                  />
                </View>
              </ScrollView>
            </View>
          </ViewForDevice>

        </View>
      )}
    />
  );
};

EventForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

EventForm.defaultProps = {
  event: {},
};

const makeStyles = (theme, isDesktop, isMobile) => StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  tabsContent: {
    flex: 1,
    height: '100%',
  },
  goBack: {
    backgroundColor: theme.colors.dark,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.sizings.medium,
  },
  columns: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    flexWrap: 'wrap',
  },
  column: {
    paddingHorizontal: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    paddingBottom: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    flex: 1,
    minWidth: theme.normalize(400),
    maxHeight: isMobile ? undefined : Dimensions.get('window').height - theme.normalize(90),
  },

  withBottomBorderStyle: {
    borderBottomColor: theme.colors.primary,
    borderBottomWidth: 1,
  },
  containerRowStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  label: {
    alignSelf: 'center',
    fontSize: theme.fontSizes.medium,
    // fontFamily: getFF(lang, true, theme),
  },
  labelStyle: {
    textTransform: 'capitalize',
    color: theme.colors.primary,
    fontSize: theme.fontSizes.medium,
    border: 'none',
    fontWeight: 'bold',
  },
});

export default EventForm;
