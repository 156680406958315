/* eslint-disable no-return-assign */
import { FontAwesome } from '@expo/vector-icons';
import Waiter from 'assets/waiter.png';
import useCall from 'calls/contexts/calls';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import { ImageBackground } from 'common/components/Images';
import Page from 'common/components/Page/Page';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import useOrders from 'orders/contexts/orders';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Image, ScrollView,

  TouchableOpacity, View,
} from 'react-native';
import PromoMessage from 'sites/components/PromoMessage/PromoMessage';
import useSpecialOffers from 'specialOffers/contexts/specialOffers';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import isToday from 'dayjs/plugin/isToday';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner.native';
import useBooking from 'bookings/contexts/bookings';
import useSite from 'sites/contexts/sites';
import makeStyles from './styles/Info.styles';
import CallWaiterModal from './CallWaiterModal';
import useTrans from 'common/hooks/use-trans';

dayjs.extend(isToday);

const Info = () => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  // const { isFetching: isSiteFetching, item: site } = useSite();
  // const { setSelectedSeats } = useSeatSelector();
  const { t } = useTranslation();
  const {translateText} = useTrans()
  // const navigation = useNavigation();
  const { item: booking } = useBooking();
  // const { setItem: setBooking } = useBooking();
  const {
    fetchItems: fetchCalls,
    setFilter: setCallFilter,
    currentCall,
    callWaiter
  } = useCall();
  const {
    fetchItems: fetchOrders,
    items: orders,
    setFilter: setOrderFilter,
    filters: orderFilters,
  } = useOrders();
  const {
    fetchSpecialOffersApplicable,
    items: specialOffers,
    setItems: setSpecialOffers,
  } = useSpecialOffers();
  const { currentSite } = useSite();

  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const [callWaiterModalVisible, setCallWaiterModalVisible] = useState(false)

  useEffect(() => {
    if (booking) {
      setOrderFilter('booking', booking.id);
      setOrderFilter('created_at_gte', dayjs().utc().startOf('day').format());
      setOrderFilter('created_at_lte', dayjs().utc().endOf('day').format());
      setOrderFilter('_sort', 'created_at:DESC');
      setCallFilter('booking', booking.id);
    }
  }, [booking, setCallFilter, setOrderFilter]);

  useEffect(() => {
    if (orderFilters.booking) {
      fetchOrders();
      fetchCalls();
    }
  }, [orderFilters, fetchOrders, fetchCalls]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const applicables = await fetchSpecialOffersApplicable({ booking: booking?.id, site: booking?.site });

      setSpecialOffers(applicables);
    };

    fetchAndSetData();
  }, [booking, setSpecialOffers, fetchSpecialOffersApplicable]);

  const handleState = useCallback((state) => {
    switch (state) {
      case 'created':
        return t('orders.created');
      case 'in_preparation':
        return t('orders.inPreparation');
      case 'ready':
        return t('orders.ready');
      case 'done':
        return t('orders.done');
      default:
        return t('orders.created');
    }
  }, [t]);

  /* const changeSeat = useCallback(() => {
     setBooking({
      ...booking,
      date: dayjs(booking.date).format(),
    });
    setSelectedSeats([]);

    if (site.maps.length === 1) {
      navigation.navigate('SeatSelector', { edit: true, siteId: site.id, mapId: site.maps[0].id });
    } else {
      navigation.navigate('MapsList', { edit: true });
    }
  }, [navigation, site.id, site.maps]); */

  const currentBookingItem = booking?.booking_items.find(({ date }) => dayjs(date).isToday());
  const siteImage = currentSite?.images?.[0] || null;

  return !booking
    ? <LoadingSpinner />
    : (
      <Page
        transparentHeader
        padding
        backgroundImage={siteImage}
        style={{ marginBottom: 0 }}
      >
        <View style={styles.bookingContainer}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Text size="large" color="light">{t('beach.bookingNumber')}</Text>
            <Text isBold size="larger" color="light" style={styles.code}>{booking.code}</Text>
          </View>

          {booking?.stayDuration && (
            <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text noLineHeight color="light">{t(`bookings.${booking.stayDuration}`)}</Text>
            </View>
          )}

          {booking?.booking_items?.length > 0 && (
            <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <View
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <FontAwesome
                  style={{ paddingRight: theme.sizings.small }}
                  name="calendar"
                  color={theme.colors.secondary}
                  size={theme.fontSizes.default}
                />
                <Text noLineHeight color="light">
                  {dayjs(currentBookingItem?.date).format('LL')}
                </Text>
              </View>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                  
                  <Text noLineHeight color="light">
                  {currentBookingItem?.seats?.length > 0
                    ? currentBookingItem?.seats?.length > 1
                      ? t('bookings.seatsNbr', {nbr: currentBookingItem?.seats?.map((s) => `#${s.name}`).join(', ')})
                      : t('bookings.seatNbr', {nbr: currentBookingItem?.seats[0].name})
                    : t('bookings.noSeatsAttributed')
                  }
                  </Text>
                </View>
            </View>
          )}

        </View>
        {specialOffers?.map((offer, index) => <PromoMessage key={`promo-${index}`} offer={offer} />)}
        {currentBookingItem?.seats?.length > 0 ? (
        <View style={styles.buttonContainer}>

          {/* call */}
      
            <>
              <View style={styles.waiterIconView}>
                <Image style={styles.waiterIcon} source={Waiter} />
              </View>

            
              <Button
                text={currentCall ? `${t('beach.cancelCall')} ${currentCall.call_type ? translateText(currentCall.call_type?.name) : ''}` : t('beach.callWaiter')}
                onPress={currentCall ? () => callWaiter() : () => setCallWaiterModalVisible(true)}
                variant={currentCall ? 'greyDarker' : 'gradient'} 
                style={styles.buttons}
              />
            </>
       

          {/* change seat
          {site.allowChangeSeat && site.bookingsWithSeats && (
            <Button
              text={t('beach.changeSeat')}
              onPress={changeSeat}
              disabled={isSiteFetching}
              style={styles.buttons}
            />
          )} */}
        </View>) : null}
        <View style={styles.absoluteContent}>
          {currentBookingItem?.status !== 'checked_in' && (
            <View style={styles.absoluteContainer}>
              <View style={[commonStyles.textWithIcon]}>
                <View style={commonStyles.fullViewDetailsIcon}>
                  <FontAwesome
                    size={theme.fontSizes.large}
                    name="exclamation-circle"
                    color={theme.colors.secondaryLight}
                  />
                </View>
                <View style={[styles.optionsTitleText, { flex: 1 }]}>
                  <Text
                    size="large"
                    color="light"
                  >
                    {(currentBookingItem?.status ?? 'reserved') === 'reserved'
                    || currentBookingItem?.status === 'noSHow'
                      ? t('bookings.notValidYet')
                      : t('bookings.notValid')}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {currentBookingItem?.status === 'checked_in' && orders && orders[0] && (
            <View style={styles.absoluteContainer}>
              <>
                <TouchableOpacity style={styles.currentOrderHeader} onPress={() => setDetailsOpen(!detailsOpen)}>
                  <>
                    <View style={[commonStyles.textWithIcon]}>
                      <View style={commonStyles.fullViewDetailsIcon}>
                        <FontAwesome size={theme.fontSizes.large} name="clock-o" color={theme.colors.secondaryLight} />
                      </View>
                      <View style={styles.optionsTitleText}>
                        <Text
                          size="large"
                          color="light"
                        >
                          {`${t('orders.orderNbr', { orderId: orders[0].id })} ${handleState(orders[0].state)}`}
                        </Text>
                      </View>
                    </View>
                    <FontAwesome
                      name={detailsOpen ? 'angle-up' : 'angle-down'}
                      size={theme.fontSizes.large}
                      color={theme.colors.light}
                    />
                  </>
                </TouchableOpacity>
                {detailsOpen && (
                  <ScrollView style={styles.orderItems}>
                    {orders[0].order_items.map((orderItem) => (
                      <View style={styles.currentOrderItem} key={`order-item-${orderItem.id}`}>
                        <View style={styles.itemImage}>
                          {orderItem.product ? (
                            <ImageBackground style={styles.itemImageInner} image={orderItem.product.images[0]} />
                          ) : (
                            <ImageBackground style={styles.itemImageInner} image={orderItem.service.images[0]} />
                          )}
                        </View>
                        {orderItem.quantity > 1 && (
                          <View style={styles.itemQuantity}>
                            <Gradient height={theme.normalize(50)} />
                            <View>
                              <Text noLineHeight size="large" color="light">{`x ${orderItem.quantity}`}</Text>
                            </View>
                          </View>
                        )}
                        <View style={{ flex: 1 }}>
                          <TranslatedText isBold size="large" color="light" value={orderItem.name} />
                        </View>
                        <Price
                          price={orderItem.price * orderItem.quantity}
                          color="secondaryLight"
                        />
                      </View>
                    ))}
                  </ScrollView>
                )}
              </>
            </View>
          )}
        </View>

        <CallWaiterModal modalVisible={callWaiterModalVisible} setModalVisible={setCallWaiterModalVisible}/>
        
      </Page>
    );
};

export default Info;
