import PropTypes from 'prop-types';
import React from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  useWindowDimensions,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import useTheme from 'providers/ThemeProvider';
import { isMobileMode } from 'common/utils/desktopMode';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useHeaderHeight } from '@react-navigation/elements';
import WaiterLeftMenu from '../WaiterLeftMenu/WaiterLeftMenu';
import BookingStats from '../BookingStats/BookingStats';

export const WaiterSubheader = ({ children, centered }) => {
  const { theme } = useTheme();
  const styles = makeStylesHeader(theme, isMobileMode(useWindowDimensions()), centered);

  return (
    <View style={styles.header}>
      <View style={styles.headerInner}>
        {children}
      </View>
    </View>

  );
};

const WaiterLayout = ({
  children, noPadding, noPaddingHorizontal, noScroll, hideMenu, boxed,
}) => {
  const { width, height } = Dimensions.get('window');
  const { theme } = useTheme();
  const wideWidth = hideMenu ? width : width - theme.sizings.waiterMenuSidebar - 1;
  const wideHeight = hideMenu ? height : height - theme.sizings.waiterMenuSidebar;
  const isMobile = isMobileMode(useWindowDimensions());
  const headerHeight = useHeaderHeight();
  const styles = makeStyles(
    theme,
    isMobileMode(useWindowDimensions()),
    wideWidth,
    wideHeight,
    noPadding,
    noPaddingHorizontal,
    headerHeight,
  );

  return isMobile ? (
    <SafeAreaView style={{ flex: 1 }} backgroundColor={theme.colors.white}>
      <View style={styles.waiterLayout}>
        {!hideMenu && (
        <View style={styles.firstColumn}>
          <WaiterLeftMenu />
        </View>
        )}

        {noScroll ? (
          <View style={styles.waiterMainMobile}>
            {children}
          </View>
        ) : (
          <ScrollView style={styles.waiterMainMobileScroll}>
            {children}
          </ScrollView>
        )}

      </View>
    </SafeAreaView>
  ) : (
    <View style={styles.waiterLayout}>
      {!hideMenu && (
        <View style={styles.firstColumn}>
          <WaiterLeftMenu />
          <BookingStats />
        </View>
      )}
      {noScroll ? (
        <View style={styles.waiterMain}>
          <View style={boxed ? styles.boxed : styles.notBoxed}>
            {children}
          </View>
        </View>
      ) : (

        <ScrollView style={styles.waiterMain}>
          <View style={boxed ? styles.boxed : styles.notBoxed}>
            {children}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

WaiterLayout.propTypes = {
  children: PropTypes.element.isRequired,
  noPadding: PropTypes.bool,
  noPaddingHorizontal: PropTypes.bool,
  noScroll: PropTypes.bool,
  hideMenu: PropTypes.bool,
  boxed: PropTypes.bool,
};

WaiterLayout.defaultProps = {
  noPadding: false,
  noPaddingHorizontal: false,
  noScroll: false,
  hideMenu: false,
  boxed: false,
};

WaiterSubheader.propTypes = {
  children: PropTypes.element.isRequired,
  centered: PropTypes.bool,
};

WaiterSubheader.defaultProps = {
  centered: false,
};

const makeStyles = (theme, isMobile, wideWidth, wideHeight, noPadding,
  noPaddingHorizontal, headerHeight) => StyleSheet.create({
  waiterLayout: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: isMobile ? 'column-reverse' : 'row',
  },
  waiterMain: {
    flex: 1,
    width: isMobile ? '100%' : wideWidth,
    height: isMobile ? wideHeight - 63 : '100%',
    paddingHorizontal: !noPadding && !noPaddingHorizontal ? theme.sizings.mediumLarge : 0,
    paddingVertical: !noPadding ? theme.sizings.mediumLarge : 0,
  },
  waiterMainMobile: {
    flex: 1,
    width: '100%',
    paddingHorizontal: !noPadding && !noPaddingHorizontal ? theme.sizings.mediumLarge : 0,
    paddingVertical: !noPadding ? theme.sizings.mediumLarge : 0,
    // paddingTop: Platform.OS === 'ios' ? 63 : 0,
  },
  waiterMainMobileScroll: {
    flex: 1,
    width: '100%',
    paddingHorizontal: !noPadding && !noPaddingHorizontal ? theme.sizings.mediumLarge : 0,
    paddingVertical: !noPadding ? theme.sizings.mediumLarge : 0,
    // paddingTop: Platform.OS === 'ios' ? 63 : 0,
  },
  boxed: {
    maxWidth: 700,
    width: '100%',
    marginHorizontal: 'auto',
  },
  notBoxed: {
    width: '100%',
    marginHorizontal: 'auto',
    minHeight: '100%',
  },
  header: {
    width: '100%',
    height: theme.normalize(55),
    maxHeight: theme.normalize(55),
    backgroundColor: theme.colors.purple,
  },
  firstColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgb(29, 28, 38)',
    zIndex: 1,
  },
});

const makeStylesHeader = (theme, isMobile, centered) => StyleSheet.create({
  header: {
    width: '100%',
    height: 55,
    paddingVertical: theme.sizings.small,
    backgroundColor: theme.colors.purple,
  },
  headerInner: {
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: centered ? 'center' : 'flex-end',
    alignItems: isMobile ? (centered ? 'center' : 'flex-end') : undefined,
    height: '100%',
  },
});

export default WaiterLayout;
