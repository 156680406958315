import React, { useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';
import useTranslation from 'common/contexts/translations';
import Text from 'common/components/Text/Text';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import useBookings, { useLocalBooking } from 'bookings/contexts/bookings';
import BookingListItem from 'home/components/BookingListItem/BookingListItem';
import Page from 'common/components/Page/Page';
import useAlert from 'common/contexts/alert';
import useTheme from 'providers/ThemeProvider';
import useSite from 'sites/contexts/sites';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';

const BookingsList = () => {
  const { setAlert } = useAlert();
  const {
    isFetching,
    checkCode,
    setSort,
  } = useBookings();
  const { item: site } = useSite();

  const { localBookings } = useLocalBooking();

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { theme } = useTheme();

  useEffect(() => {
    setSort('created_at:DESC');
  }, [setSort]);

  const bookings = Array.isArray(localBookings) ? localBookings : [];

  const passedBookings = bookings?.filter((b) => (
      b?.id
      && b?.site === site?.id
      && b?.booking_items.find((bi) => dayjs(bi.date).startOf('day').isBefore(dayjs().startOf('day')))
  ));

  const nextBookings = bookings?.filter((b) => (
    b?.id
    && b?.site === site?.id
    && b?.booking_items.find((bi) => dayjs(bi.date).startOf('day').isAfter(dayjs().startOf('day')))
  ));

  const displayBookings = bookings?.filter((b) => (
    b?.id
    && b?.site === site?.id
    && b?.booking_items.find((bi) => dayjs(bi.date).startOf('day').isSame(dayjs().startOf('day')))
  ));

  const handleBookingPress = useCallback(async (booking) => {
    const currentBookingItem = booking?.booking_items.find(
      (bi) => dayjs(bi.date).startOf('day').isSame(dayjs().startOf('day')),
    );

    if (['reserved', 'checked_in'].includes(currentBookingItem?.status)) {
      try {
        await checkCode(booking.code);
      } catch {
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.codeError') });
        return;
      }
      navigation.reset({
        index: 0,
        routes: [
          { name: 'HomePage', params: {} },
          { name: 'SiteDetail', params: { siteId: booking.site.id, bookingId: booking.id } },
        ],
      });
    } else if (booking.payment?.status === 'PENDING') {
      navigation.navigate('PaymentRecap', { paymentId: booking.payment.id });
    }
  }, [navigation, checkCode, setAlert, t]);

  return (
    <Page scroll safeArea padding>

      {!displayBookings?.length && !nextBookings?.length && !passedBookings?.length && (
        <EmptyPage text={t('bookings.empty')} />
      )}
      {displayBookings?.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('bookings.currentBooking')}
          </Text>
          {displayBookings?.length && displayBookings.map((booking, index) => (
            <BookingListItem
              key={`booking-display-${index}`}
              {...booking}
              onPress={() => handleBookingPress(booking)}
            />
          ))}
        </>
      )}

      {nextBookings.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('bookings.nextBookings')}
          </Text>
          {nextBookings.length && nextBookings.map((booking, index) => (
            <BookingListItem
              key={`booking-next-${index}`}
              {...booking}
              onPress={() => handleBookingPress(booking)}
            />
          ))}
        </>
      )}
      {passedBookings.length > 0 && (
        <>
          <Text
            isBold
            centered
            color="primary"
            size="larger"
          >
            {t('bookings.passedBookings')}
          </Text>
          {passedBookings.length && passedBookings.map((booking, index) => (
            <BookingListItem
              key={`booking-passed-${index}`}
              {...booking}
            />
          ))}
        </>
      )}

      <LoadingSpinner
        visible={isFetching}
        theme={theme}
      />
    </Page>
  );
};

export default BookingsList;
