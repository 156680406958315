import { useNavigation } from '@react-navigation/native';
import useBooking from 'bookings/contexts/bookings';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import useSeats from 'seats/contexts/seats';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import Board from 'waiter/pages/WaiterHome/BeachVisualizer/components/Board/Board';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import BookButton from './components/BookButton/BookButton';
import useSeatSelector from './contexts/clientSeatSelector';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import getBookingPublicMapPrices from 'common/utils/getBookingPublicMapPrices';

const SeatSelector = ({ route }) => {
  const { siteId, mapId, edit } = route.params;

  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setAlert } = useAlert();

  const { currentSite, item: site, isFetching } = useSite();

  const {
    item: booking,
    isFetching: isCreatingBooking,
    setItem: setBooking,
    update: updateBooking,
    checkCode,
  } = useBooking();

  const {
    setItems: setList, items: list,
    selectedFullSeats,
    handleClientClickSeat,
  } = useSeatSelector();
  const { fetchSeatsAvailable, isAvailableFetching } = useSeats();

  // rustine clara 26/01/2022, car il faut un getSeatData, mais à modifier pour anonymiser
  const {
    getSeatData, 
  } = useBeachVisualizer();

  // We could request the API but since the map is contained in the site object we can avoid an API call.
  const map = useMemo(() => {
    const newMap = site.maps.find((map) => map.id === mapId);

    // rustine clara 26/01/2022, pour afficher des transats, à modifier
    newMap.seats = list;
    return newMap;
  }, [mapId, site.maps, list]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      if (site && booking.dates) {
        let availables = await fetchSeatsAvailable({
          dates: booking.dates.map((d) => dayjs(d).format('YYYY-MM-DD')).join(','),
          site: siteId,
          map: mapId,
          stayDuration: booking.stayDuration,
        });

        if (booking.seats) {
          availables = availables.concat(booking.seats);
        }

        const seats = site.seats.filter(({ active, map }) => active && map === mapId);

        const list = seats.map((item) => ({
          ...item,
          selected: !!selectedFullSeats?.find((seat) => seat.id === item.id),
          available: !!availables.find(({ id }) => item.id === id),
        }));

        setList(list);
      }
    };

    fetchAndSetData();
  }, [site, booking, setList, siteId, fetchSeatsAvailable, mapId, selectedFullSeats]);


  const bookingPrices = useMemo(() => {
    const tempBookingItems = booking?.booking_items?.map((bi) => ({...bi, seats: selectedFullSeats}));
    console.log({tempBookingItems})
    return(
    (booking && site && !edit)
      ? getBookingPublicMapPrices(
        tempBookingItems,
        site.price_areas ?? [],
        site.seasons,
        booking.hotel,
      )
      : []
  )}, [booking, site, edit, selectedFullSeats]);

  const total = bookingPrices.reduce((acc, item) => acc + item.price, 0);

  const onSubmit = async () => {
    if (edit) {
      const newBookingItems = booking.booking_items.map((bi) => ({
        ...bi,
        seats: selectedFullSeats.map((seat) => (seat.id)),
      }));

      try {
        await updateBooking(booking.id, {
          seats: selectedFullSeats.map((seat) => (seat.id)),
          booking_items: newBookingItems,
        });
        const res = await checkCode(booking.code);

        navigation.reset({
          index: 0,
          routes: [
            { name: 'HomePage', params: {} },
            { name: 'SiteDetail', params: { siteId: res.site.id, bookingId: res.id } },
          ],
        });
        setAlert({ color: 'success', title: t('common.success'), message: t('bookings.successChangingSeat') });
      } catch {
        setAlert({ color: 'error', title: t('common.error'), message: t('bookings.errorChangingSeat') });
      }
    } else {
      setBooking((prevBooking) => {
        const newBookingItems = prevBooking.booking_items.map((bi) => ({
          ...bi,
          seats: selectedFullSeats.map((seat) => (seat.id)),
        }));

        return ({
          ...prevBooking,
          seats: selectedFullSeats.map((seat) => (seat.id)),
          booking_items: newBookingItems,
        });
      });

      navigation.navigate('HourSelect', { siteId });
    }
  };

  // clara 26/01/22 : inspiré par la fonction dans OldBoardSquare
  const handleClickSeat = (seat) => {
    if (seat.available) {
      const priceAreaId = seat.price_area.id;
      const price = seat.furniture.prices.find((p) => Number.isInteger(p.price_area)
        ? p.price_area === priceAreaId : p.price_area.id === priceAreaId);

      handleClientClickSeat({ price: booking.hotel ? price.hotelPrice : price.price, ...seat });
    }else{
      console.log('pas libre 2')
    }
  };

  return (
    <View style={styles.safeArea}>
      <View style={styles.beachContainer}>
        <LoadingSpinner
          visible={isAvailableFetching || isCreatingBooking}
          theme={theme}
        />

        {(!isFetching && site && map) ? (
          <Board
            map={map}
            handleClickSeat={handleClickSeat}
            date={booking?.dates?.[0]} /* clara 26/01/2022, comme on est obligé de mettre une date..
            (mais les transats devront être
            pré-filtrés pour la plage de date de toute façon, cf ligne 66 ) */
            selectedSeats={selectedFullSeats}
            getSeatData={getSeatData}
            customerView
          />
        ) : (
          <View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
            <EmptyPage text={t('beach.noMap')} />
          </View>
        )}

        <View style={[commonStyles.fullViewDetailsContainer, styles.recap]}>
          <View style={commonStyles.fullViewButtonContainer}>
            <BookButton
              onSubmit={onSubmit}
              disabled={isAvailableFetching || isCreatingBooking || selectedFullSeats.length === 0}
            />
          </View>
          <View style={commonStyles.fullViewDetailsContainer2}>
            {!edit
              && (
                <View style={[commonStyles.fullViewDetailsPriceWrapper, styles.total]}>
                  <Price
                    price={total}
                    color="secondaryLight"
                    size="huge"
                  />
                </View>
              )}
            <View style={[commonStyles.fullViewDetailsContent, styles.recapInfo]}>
              { booking.dates?.map((date) => (
                <View style={styles.date} key={`booking-at-${dayjs(date).format('YYYY-MM-DD')}`}>
                  <Text size="larger" color="primary">
                    {dayjs(date).format('LL')}
                  </Text>
                </View>
              ))}
              {selectedFullSeats.length > 0 && selectedFullSeats.filter((sel) => sel).map((seatInfos) => (
                <View key={seatInfos.id} style={styles.recapSeat}>
                  <Text isBold color="light" style={[styles.seatName, styles.recapText]}>{seatInfos.name}</Text>
                  <TranslatedText value={seatInfos.furniture.name} style={styles.recapText} color="light" />
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beach,
  },

  beachContainer: {
    height: '100%',
    justifyContent: 'center',
    paddingTop: theme.normalize(64),
  },
  recap: {
    width: Dimensions.get('window').width - theme.sizings.medium,
    minHeight: theme.normalize(120),
    justifyContent: 'center',
    left: theme.normalize(8),
    bottom: theme.sizings.small,
  },

  recapInfo: {
    marginTop: -theme.sizings.small,
    width: '70%',
  },

  recapSeat: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  seatName: {
    paddingRight: theme.sizings.small,
  },

  recapText: {
    fontFamily: 'MontserratBold',
  },

  grid: {
    height: Dimensions.get('window').height,
    width: Dimensions.get('window').width,
    position: 'absolute',
    overflow: 'hidden',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  total: {
    top: theme.normalize(35),
  },
});

SeatSelector.propTypes = {
  route: PropTypes.object.isRequired,
};

export default SeatSelector;
