import { useNavigation } from '@react-navigation/native';
import useBasket from 'basket/contexts/basket';
import Button from 'common/components/Button/Button';
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import Page from 'common/components/Page/Page';
import Text from 'common/components/Text/Text';
import useTranslation from 'common/contexts/translations';
import useOrder from 'orders/contexts/orders';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  ScrollView, StyleSheet, View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import AutoComplete from 'common/components/AutoComplete/AutoComplete';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import { isMobileMode } from 'common/utils/desktopMode';

const OrderSeatSelect = ({ route }) => {
  const [booking, setBooking] = useState(null);
  const [seat, setSeat] = useState(null);

  const { setItems: setBasket } = useBasket();
  const { orderId, bookingItem: currentBookingItem, seat: currentSeat } = route.params;
  const navigation = useNavigation();
  const isMobile = isMobileMode(useWindowDimensions());

  const {
    fetchItem: fetchOrder,
    item: order,
    setItem: setOrder,
    isFetching: isOrdersFetching,
  } = useOrder();

  const {
    bookingItemsByStayDuration,
  } = useBeachVisualizer();

  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const commonStyles = makeCommonStyles(theme);

  useEffect(() => {
    if (orderId && order?.id === orderId) {
      setBasket(order.order_items);
      setBooking(order.booking.id);
      setSeat(order?.seat?.id);
    }
  }, [order, orderId, setBasket]);

  useEffect(() => {
    if (orderId === null) {
      setOrder(null);
    }
  }, [orderId, setOrder]);

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    } else {
      setBasket([]);
    }
  }, [fetchOrder, orderId, setBasket]);

  useEffect(() => {
    if (currentBookingItem) {
      setBooking(currentBookingItem.booking.id);

      if (currentSeat) {
        setSeat(currentSeat.id);
      } else if (currentBookingItem.seats.length === 1) {
        setSeat(currentBookingItem.seats[0].id);
      }
    }
  }, [currentBookingItem, currentSeat]);

  const pickerOptions = useMemo(() => {
    const res = [];

    if (currentBookingItem) {
      currentBookingItem.seats.map((seat) => {
        const hasCustomer = currentBookingItem.booking.customer?.name;

        res.push({
          label: hasCustomer
            ? `${currentBookingItem.booking.civility
              ? t(`customer.civility${currentBookingItem.booking.civility}`)
              : ''}${currentBookingItem.booking.customer.name} 
            ${currentBookingItem.booking.customer.firstName || ''} #${seat.name}`
            : `${currentBookingItem.booking.clientName || ''}
             ${currentBookingItem.booking.clientFirstName || ''} #${seat.name}`,
          value: seat.id,
          seat: seat.id,
          booking: currentBookingItem.booking.id,
        });
        return null;
      });
    } else {
      bookingItemsByStayDuration
        .filter((bi) => bi.status === 'checked_in')
        .map((bi) => {
          if (!bi.seats.length) { return null; }
          bi.seats.map((seat) => {
            res.push({
              label: `${bi.booking.customer?.name || bi.booking.clientName || ''} #${seat.name}`,
              value: seat.id,
              seat: seat.id,
              booking: bi.booking.id,
            });
            return null;
          });
          return null;
        });
    }

    return res;
  }, [bookingItemsByStayDuration, currentBookingItem, t]);

  const getAutoCompleteSuggestions = (newWord) => {
    const suggestions = pickerOptions?.filter((option) => option.label.toLowerCase().includes(newWord.toLowerCase()));

    return suggestions;
  };

  const handleValidate = useCallback(() => {
    navigation.navigate('OrderDetail', { seat, booking });
  }, [booking, navigation, seat]);

  const handleCreateBooking = useCallback(() => {
    navigation.navigate('BookingEdit', {
      orderCreation: true,
    });
  }, [navigation]);

  return (
    <WaiterLayout noPadding hideMenu>
      {isOrdersFetching && (
      <LoadingSpinner
        visible={isOrdersFetching}
        theme={theme}
      />
      )}
      <View style={styles.innerView}>

        {!currentBookingItem && (
          <>
            <Button
              icon="arrow-right"
              text={t('orders.createBooking')}
              onPress={handleCreateBooking}
            />

            <Text
              isBold
              centered
              isUppercase
              style={styles.orCreate}
            >
              {t('common.or')}
            </Text>
          </>
        )}

        <View style={{ padding: theme.sizings.small }}>

          <Text style={styles.text} size="medium" color="primary" isBold>
            {t('bookings.seatName')}
            {currentBookingItem && currentSeat
              ? `#${currentSeat.name || ''} - ${currentBookingItem?.customer?.name
                ? `${currentBookingItem.booking.customer.name} ${currentBookingItem.booking.customer.firstName}`
                : `${currentBookingItem.booking.clientName || ''} ${currentBookingItem.booking.clientFirstName || ''}`}`
              : ''}
          </Text>
          {!currentBookingItem || !currentSeat ? (
            <AutoComplete
              initialOptions={pickerOptions}
              customFiltering={getAutoCompleteSuggestions}
              enableSearch
              valueName={pickerOptions?.find((o) => o.value === seat)?.label}
              customOnChange={(option) => {
                setBooking(option.booking);
                setSeat(option.seat);
              }}
            />

          ) : null}

        </View>

      </View>

      <Button
        variant="gradient"
        icon="arrow-right"
        text={t('orders.goNextStep')}
        disabled={!booking}
        onPress={handleValidate}
      />

    </WaiterLayout>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  type: {
    marginTop: theme.sizings.medium,
  },

  innerView: {
    paddingHorizontal: theme.sizings.small,
    paddingTop: theme.sizings.medium,
  },

  orCreate: {
    marginTop: theme.sizings.large,
    marginBottom: theme.sizings.medium,
  },

  text: {
    marginBottom: theme.sizings.tiny,
  },
});

OrderSeatSelect.propTypes = {
  route: PropTypes.object.isRequired,
};

export default OrderSeatSelect;
