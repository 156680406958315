/* eslint-disable max-len */
import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Dimensions,
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import PropTypes from 'prop-types';
import isDesktopMode, { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import MapsSwitch from 'waiter/pages/WaiterHome/BeachVisualizer/components/MapsSwitch/MapsSwitch';
import Board from 'waiter/pages/WaiterHome/BeachVisualizer/components/Board/Board';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import useAuth from 'auth/contexts/auth';
import useSiteProvider from 'providers/SiteProvider';
import useAlert from 'common/contexts/alert';

const ColumnMap = ({
  form,
  values,
  currentDate,
  checkConflict,
  bookingWithSeats,
  allowCurrentBookingItemEdition,
  bookingContent,
  seatBookingItems,
  siteBookingItems,
  bookingItemIndex,
  updateAllBookingItems,
  mapId,
  setMapId,
}) => {
  const isMobile = isMobileMode(useWindowDimensions());
  const isDesktop = isDesktopMode(useWindowDimensions());

  const { theme } = useTheme();
  const styles = makeStyles(theme, isMobile, isDesktop);

  const { user: currentUser } = useAuth();
  const { setAlert } = useAlert();

  const { t } = useTranslation();

  const {
    site,
    getSeatInfo,
  } = useSiteProvider();

  const [clicked, setClicked] = useState(true); // I'm sure there is another way to do that

  const map = useMemo(() => (
    site?.maps?.find(({ id }) => id === mapId)
  ), [mapId, site]);

  const getSeatData = (seatId) => ({
    bookingItems: seatBookingItems[seatId] || [],
    calls: [],
    orders: [],
  });

  const setSeatsMode = useCallback(() => {
    const newBookingContent = bookingContent.map((bc) => ({
      ...bc,
      amount: 0,
    }));

    if (bookingItemIndex !== -1) {
      const field = `booking_items[${bookingItemIndex}]`;

      form.mutators.setFieldValue({
        field,
        value: {
          ...values.booking_items[bookingItemIndex],
          bookingContent: newBookingContent,
          bookingType: 'withSeats',
          override: true,
        },
      });
    } else {
      let field = 'bookingContent';

      form.mutators.setFieldValue({
        field,
        value: newBookingContent,
      });

      field = 'bookingType';
      form.mutators.setFieldValue({
        field,
        value: 'withSeats',
      });

      const newBookingItems = updateAllBookingItems(values.seats, newBookingContent, values, form);

      form.mutators.setFieldValue({ field: 'booking_items', value: newBookingItems });
    }
  }, [bookingContent, bookingItemIndex, updateAllBookingItems, form, values]);

  const updateBookingContent = useCallback((seats) => {
    const newBookingContent = [];

    seats.forEach((s) => {
      const seat = getSeatInfo(s);
      const c = newBookingContent.find((bc) => bc.price_area === seat.price_area && bc.furniture === seat.furniture);

      if (!c) {
        const bContent = {
          price_area: seat.price_area,
          furniture: seat.furniture,
          amount: 1,
        };

        newBookingContent.push(bContent);
      } else {
        c.amount += 1;
      }
    });
    return newBookingContent;
  }, [getSeatInfo]);

  const selectedSeats = useMemo(() => {
    const seats = (currentDate
      ? values.booking_items.find((bi) => dayjs(bi.date).isSame(currentDate, 'date'))
      : values
      )?.seats ?? [];

    const newSelectedSeats = seats?.filter((seat) => {
      if (seat in seatBookingItems) {
        const currentSeatBookingItem = seatBookingItems[seat];

        const conflictingBI = currentSeatBookingItem.find((bi) => (
          bi.stayDuration === values.stayDuration || bi.stayDuration === 'fullday'
        ));

        if (conflictingBI) {
          /* checkConflict(seat, []); */
          return false;
        }
      }
      return true;
    });

    return newSelectedSeats;
  }, [currentDate, seatBookingItems, values]);

  const getSelectedSeatsByMap = () => {
    const selectedSeats = {};
    // initialise

    currentUser?.site?.maps.forEach((map) => {
      selectedSeats[map.id] = 0;
    });
    // get list of seats by map
    const seatsByMaps = {};

    site.maps.forEach((map) => {
      seatsByMaps[map.id] = map.seats.map((seat) => seat.id);
      return null;
    });
    if (currentDate) {
      values.booking_items
        .filter((bi) => dayjs(bi.date).isSame(currentDate, 'date'))
        .forEach((bi) => { // Normalement y en a qu'un !
          bi.seats.forEach((s) => {
            site?.maps.forEach((map) => {
              if (seatsByMaps[map.id].includes(s)) {
                selectedSeats[map.id] += 1;
              }
            });
          });
        });
    } else {
      values.seats.forEach((s) => {
          site?.maps.forEach((map) => {
            if (seatsByMaps[map.id].includes(s)) {
              selectedSeats[map.id] += 1;
            }
          });
      });
    }

    return selectedSeats;
  };

  const handleClickSeat = useCallback((s) => {
    if (s.outOfService && !currentUser?.showOutOfService) {
      setAlert({ color: 'info', title: t('common.info'), message: t('auth.isOutOfService') });
    } else {
      let index = 0;

      let newBookingContent = values.bookingContent;

      let newSeats = values.seats;

      const seat = s.id;

      if (currentDate) {
      // Single bookingItem update (override)
        index = values.booking_items.findIndex((bi) => dayjs(bi.date).isSame(currentDate, 'date'));

        const currentBiSeats = values.booking_items[index].seats;

        let newCurrentBiSeats = [];

        newBookingContent = values.booking_items[index].bookingContent;
        // update booking_item seats
        if (currentBiSeats.find((s) => s === seat)) {
          newCurrentBiSeats = currentBiSeats.filter((s) => s !== seat);
        } else {
          newCurrentBiSeats = [...currentBiSeats, seat];
        }
        // update booking seats (if last one among all the booking_items)
        if (values.booking_items?.find((bi, iBi) => iBi !== index && !bi.seats.includes(seat))?.length === 0) {
          newSeats = values.seats.filter((s) => s !== seat);
        }

        newBookingContent = updateBookingContent(newCurrentBiSeats);

        checkConflict({
          ...values,
          seats: newSeats,
          booking_items: [
            ...values.booking_items.filter((item, i) => i !== index),
            {
              ...values.booking_items[index],
              seats: newCurrentBiSeats,
              bookingContent: newBookingContent,
              override: true,
            },
          ],
        }, siteBookingItems);

        form.mutators.setFieldValue({
          field: `booking_items[${index}]`,
          value: {
            ...values.booking_items[index],
            seats: newCurrentBiSeats,
            bookingContent: newBookingContent,
            override: true,
          },
        });
      } else {
      // Gereral booking modification (all non overridden booking items)

        // update booking seats
        if (values.seats.find((s) => s === seat)) {
          newSeats = newSeats.filter((s) => s !== seat);
        } else {
          newSeats.push(seat);
        }

        // update bookingContent
        newBookingContent = updateBookingContent(newSeats);

        // update booking_items
        const newBookingItems = updateAllBookingItems(newSeats, newBookingContent, values);

        if (seat in seatBookingItems) {
          checkConflict({
            ...values,
            seats: newSeats,
            bookingContent: newBookingContent,
            booking_items: newBookingItems,
          }, siteBookingItems);
        }

        form.mutators.setFieldValue({ field: 'seats', value: newSeats });
        form.mutators.setFieldValue({ field: 'bookingContent', value: newBookingContent });
        form.mutators.setFieldValue({ field: 'booking_items', value: newBookingItems });
      }
      setClicked(!clicked);
    }
  }, [checkConflict, clicked, currentDate, form.mutators,
    seatBookingItems, siteBookingItems, updateBookingContent,
    values, currentUser, t, setAlert, updateAllBookingItems]);

  return (
    <>
      {map && (
      <MapsSwitch
        maps={currentUser?.site?.maps}
        mapId={mapId}
        setMapId={setMapId}
        mapSeatCounts={getSelectedSeatsByMap()}
        allowChangeBookingContent={!values.seats?.length}
      />
      )}
      <View style={styles.beachContainer}>
        {map ? (
          <Board
            map={map}
            handleClickSeat={handleClickSeat}
            currentDate={currentDate}
            currentStayDuration={values.stayDuration}
            getSeatData={getSeatData}
            selectedSeats={selectedSeats}
            disabled={!bookingWithSeats || !allowCurrentBookingItemEdition}
          />
        ) : (
          <View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
            <EmptyPage text={t('beach.noMap')} />
          </View>
        )}

        { !bookingWithSeats && (
        <View style={styles.setPositions}>
          <Button
            variant="gradient"
            icon="chair"
            iconFamily="fontawsome5"
            text={t('bookings.setPositionedSeats')}
            onPress={setSeatsMode}
            disabled={!allowCurrentBookingItemEdition}
          />
        </View>
        )}

      </View>
    </>
  );
};

export default ColumnMap;

ColumnMap.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  currentDate: PropTypes.any.isRequired,
  checkConflict: PropTypes.func.isRequired,
  bookingWithSeats: PropTypes.bool.isRequired,
  allowCurrentBookingItemEdition: PropTypes.bool.isRequired,
  bookingContent: PropTypes.array.isRequired,
  seatBookingItems: PropTypes.array.isRequired,
  siteBookingItems: PropTypes.array.isRequired,
  bookingItemIndex: PropTypes.string.isRequired,
  updateAllBookingItems: PropTypes.func.isRequired,
  mapId: PropTypes.number.isRequired,
  setMapId: PropTypes.any.isRequired,
};

ColumnMap.defaultProps = {
};

const makeStyles = (theme, isMobile, isDesktop) => StyleSheet.create({
  column: {
    paddingHorizontal: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    paddingBottom: isDesktop ? theme.sizings.large : theme.sizings.smallMedium,
    flex: 1,
    minWidth: theme.normalize(400),
    maxHeight: isMobile ? undefined : Dimensions.get('window').height - theme.normalize(90),
  },
  setPositions: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  beachContainer: {
    height: '100%',
    width: '100%',
    flex: 1,
    minHeight: Dimensions.get('window').height * 0.75,
    maxHeight: Dimensions.get('window').height - theme.normalize(80 - 55 - 1), // header + map switcher
  },
});
