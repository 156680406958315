import React, { useEffect, useCallback } from 'react';
import {
  View, ScrollView, StyleSheet, TouchableHighlight, ActivityIndicator,
  Dimensions, useWindowDimensions,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import RelativeDate from 'common/components/RelativeDate/RelativeDate';
import useCall from 'calls/contexts/calls';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import { useTranslation } from 'react-i18next';
import useTrans from 'common/hooks/use-trans';

const CallList = () => {
  const {
    fetchItems: fetchCalls, items: calls, remove, isFetching,
  } = useCall();
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();
  const { translateText } = useTrans();

  const deleteCall = useCallback(async (callId) => {
    await remove(callId);
    fetchCalls();
  }, [fetchCalls, remove]);

  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  return (
    <WaiterLayout boxed>
      {isFetching ? (
        <View style={{ height: theme.normalize(300), width: '100%', alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      ) : (
        calls.length ? (
          <View style={{ paddingVertical: theme.sizings.mediumLarge }}>
            {calls.map(({
              id, booking, seat, created_at: createadAt, call_type: callType,
            }) => (
              <View key={id} style={styles.call}>
                <View style={styles.info}>
                  <View style={styles.info}>
                    <Text color="light" size="larger" style={styles.infoText}>
                      {`${seat.name} - ${booking.customer
                        ? `${booking.customer.civility ? `${t(`customer.civility${booking.customer.civility}`)} `
                          : ''}${booking.customer.name} ${booking.customer.firstName || ''}`
                        : `${booking.clientName} ${booking.clientFirstName || ''}`
                      }`}
                    </Text>
                  </View>
                  {callType && (
                  <View>
                    <Text color="light" size="larger" isBold>
                      {translateText(callType?.name)}
                    </Text>
                  </View>
                  )}
                  <View style={styles.date}>
                    <FontAwesome
                      style={styles.dateIcon}
                      name="clock-o"
                      size={theme.sizings.medium}
                      color={theme.colors.light}
                    />
                    <Text noLineHeight color="light">
                      <RelativeDate value={createadAt} />
                    </Text>
                  </View>
                </View>
                <TouchableHighlight style={styles.button} onPress={() => deleteCall(id)}>
                  <FontAwesome name="check" color={theme.colors.light} size={theme.sizings.mediumLarge} />
                </TouchableHighlight>
              </View>
            ))}
          </View>
        ) : (
          <EmptyPage text={t('calls.empty')} />
        )
      )}
    </WaiterLayout>
  );
};

const makeStyles = (theme) => StyleSheet.create({
  call: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CallList;
