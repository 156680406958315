import React from 'react';
import PropTypes from 'prop-types';
import OrderProductItem from '../OrderProduct/OrderProductItem';

const OrderService = ({ service, reduction }) => (
  [<OrderProductItem editable price={service.price} type="service" entity={service} reduction={reduction} />]
);

OrderService.path = 'service';

OrderService.propTypes = {
  service: PropTypes.object.isRequired,
  reduction: PropTypes.object,
};

OrderService.defaultProps = {
  reduction: null,
};

export default OrderService;
