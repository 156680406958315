import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Text from 'common/components/Text/Text';

import useTheme from 'providers/ThemeProvider';
import { FontAwesome5 } from '@expo/vector-icons';

const EmptyPage = ({
  text, image,
}) => {
  const { theme } = useTheme();

  return (
    <View style={{ marginVertical: theme.sizings.medium, alignItems: 'center', width: '100%' }}>
      {image || (

      <FontAwesome5
        name="info-circle"
        size={theme.sizings.larger}
        color={theme.colors.lightPurple}
      />

      )}
      <Text
        size="large"
        style={{
          textAlign: 'center',
          marginTop: theme.sizings.medium,
          paddingHorizontal: theme.sizings.large,
          width: '100%',
        }}
        color="purple"
      >
        {text}
      </Text>
    </View>
  );
};

EmptyPage.propTypes = {
  text: PropTypes.string,
  image: PropTypes.node,

};

EmptyPage.defaultProps = {
  text: '',
  image: null,
};

export default EmptyPage;
