import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  TouchableOpacity, View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Modal from 'common/components/Modal/Modal';
import { useAuth } from 'auth/contexts/auth';
import Button from 'common/components/Button/Button';
import useSite from 'sites/contexts/sites';
import useBasket from 'basket/contexts/basket';
import useOrder from 'orders/contexts/orders';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useOffer from 'specialOffers/hooks/use-offer';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import ChooseMethod from './ChooseMethod';

const PaymentModal = ({
  setModalVisible, modalVisible, total,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [onlineSelected, setOnlineSelected] = useState(true);
  const [methodSelected, setMethodSelected] = useState('CB');
  const { user, seat, booking } = useAuth();
  const { item: site } = useSite();
  const { items: basket, setItems } = useBasket();
  const { save: saveOrder } = useOrder();
  const { setAlert } = useAlert();
  const {
    findProductOffer,
    findServiceOffer,
  } = useOffer();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const saveAndRedirect = useCallback(async () => {
    const amount = Math.round(total * 100) / 100;

    try {
      const res = await saveOrder({
        payment: {
          online: onlineSelected,
          offline_method: methodSelected,
          amount,
          transactionFees: onlineSelected && total > 0 ? site?.onlineTransactionFees : 0,
          status: 'PENDING',
          site: site?.id,
        },
        order_items: basket.map((orderItem) => {
          const offer = orderItem.product ? findProductOffer(orderItem.product) : findServiceOffer(orderItem.service);

          return ({
            ...orderItem,
            price: offer ? ((100 - offer.discount_rate) / 100) * orderItem.price : orderItem.price,
            reduction: offer ? offer.discount_rate : null,
          });
        }),
        booking: booking?.id,
        seat: seat?.id,
        price: amount,
        site: site?.id,
        // user: newUser,
        user: -1,
        active: true,
        comments: '',
      });

      if (res?.statusCode === 401) {
        throw new Error('UNAUTHORIZED');
      }

      const routes = [
        { name: 'HomePage' },
        { name: 'SiteDetail', params: { siteId: site?.id } },
      ];

      setModalVisible(false);

      if (onlineSelected) {
        if (user.cardId || site?.isDemo) {
          navigation.navigate('Payment', {
            payment: res.payment,
            callback: () => {
              setItems([]);
              navigation.reset({
                index: 0,
                routes,
              });
            },
          });
        } else {
          navigation.navigate('CreditCardForm', {
            callback: () => {
              navigation.navigate('Payment', {
                payment: res.payment,
                callback: () => {
                  setItems([]);
                  navigation.reset({
                    index: 0,
                    routes,
                  });
                },
              });
            },
          });
        }
      } else {
        setItems([]);
        setAlert({ color: 'success', title: t('common.success'), message: t('orders.successCreate') });

        navigation.reset({
          index: 0,
          routes,
        });
      }
    } catch (e) {
      setAlert({ color: 'error', title: t('common.error'), message: t('basket.errorPlacing') });
    }
  }, [setModalVisible, basket, user, booking, methodSelected, navigation,
    onlineSelected, saveOrder, seat, setItems, setAlert, site, t, total,
    findProductOffer, findServiceOffer]);

  const onlineAllowed = useMemo(
    () => site.canPayOnline && onlineSelected && (total > site.onlineMinPrice),
    [onlineSelected, site, total],
  );

  const checkPayment = () => {
    setModalVisible(false);
    saveAndRedirect(user);

    /*
    if (!user) {
      navigation.navigate('SignUpPage', { callback: saveAndRedirect });
    } else {
      saveAndRedirect(user);
    } */
  };

  useEffect(() => {
    if (site) {
      if (!site.canPayOnline || total < site.onlineMinPrice || total > site.onlineMaxPrice) {
        setOnlineSelected(false);
      }
    }
  }, [site, total]);

  return (
    <Modal
      transparent
      visible={modalVisible}
      ariaHideApp={false}
      style={commonStyles.modal}
    >
      <View style={commonStyles.modalInner}>
        <TouchableOpacity
          onPress={() => setModalVisible(false)}
          style={commonStyles.modalMask}
        />
        <View style={commonStyles.modalBox}>
          <ChooseMethod
            roomID={booking.roomID}
            setOnlineSelected={setOnlineSelected}
            onlineSelected={onlineSelected}
            setMethodSelected={setMethodSelected}
            methodSelected={methodSelected}
            canPayOnline={site.canPayOnline}
            onlineMinPrice={site.onlineMinPrice}
            onlineMaxPrice={site.onlineMaxPrice}
            transactionFees={site.onlineTransactionFees}
            total={total}
          />
          <Button
            disabled={onlineSelected && !onlineAllowed}
            variant="primary"
            text={t('common.confirm')}
            onPress={() => onlineSelected ? checkPayment() : saveAndRedirect()}
          />
        </View>
      </View>
    </Modal>
  );
};

PaymentModal.propTypes = {
  setModalVisible: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool,
  total: PropTypes.number,
};

PaymentModal.defaultProps = {
  modalVisible: false,
  total: 0,
};

export default PaymentModal;
