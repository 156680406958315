import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import fetchJSON from 'common/utils/fetchJSON';
import dayjs from 'dayjs';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const [site, setSite] = useState({});
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const fetchSite = useCallback(async (id) => {
    if (!id) {
      setSite({});
      return;
    }

    setIsFetching(true);
    try {
      const res = await fetchJSON({ url: `sites/${id}/config`, method: 'GET' });

      setSite(res);
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getSeatInfo = useCallback((seatId) => site.seats.find((s) => s.id === seatId), [site]);

  // eslint-disable-next-line no-plusplus
  /* for (let i = 0; i < site?.maps.length; i++) {
      const seat = site.maps[i].seats.find((s) => s.id === seatId);

      if (seat) {
        return seat;
      }
    }
    return null; */

  const getBookingItemsBySeat = (bookingItems, specificDate = null) => {
    const sBookingItems = {};

    bookingItems
      .filter((bi) => (
        (!specificDate || dayjs(bi.date).isSame(specificDate))
        && ['reserved', 'checked_in'].includes(bi.status)
      ))
      .forEach((bi) => {
        bi.seats?.forEach((s) => {
          if (!sBookingItems[s.id]) {
            sBookingItems[s.id] = [];
          }
          sBookingItems[s.id].push(bi);
        });
      });

    return sBookingItems;
  };

  const usedFurnitures = useMemo(() => {
    if (!site || !site.maps) {
      return [];
    }

    const newUsedFurnitures = [];
    const usedFurnituresIdList = [];

    site.maps.forEach(({ seats }) => (
      seats.forEach(
        ({ furniture }) => {
          if (!(usedFurnituresIdList.includes(furniture.id))) {
            newUsedFurnitures.push(furniture);
            usedFurnituresIdList.push(furniture.id);
          }
        },
      )
    ));
    return newUsedFurnitures;
  }, [site]);

  const usedPriceAreas = useMemo(() => {
    if (!site || !site.maps) {
      return [];
    }

    const usedPriceAreasIdList = [];

    site.maps.forEach(({ seats }) => (
      seats.forEach(
        // eslint-disable-next-line camelcase
        ({ price_area }) => {
          if (!(usedPriceAreasIdList.includes(price_area.id))) {
            usedPriceAreasIdList.push(price_area.id);
          }
        },
      )
    ));
    const newUsedPriceAreas = site.price_areas.filter(
      ({ id }) => (
        usedPriceAreasIdList.includes(id)
      ),
    );

    return newUsedPriceAreas;
  }, [site]);

  const totalSeats = useMemo(() => {
    if (!site || !site.maps) {
      return [];
    }

    const newTotalSeats = {};

    let totalCount = 0;

    site.maps
      .filter((m) => m.active)
      .forEach((map) => {
        map.seats.forEach((s) => {
          const priceAreaId = s.price_area.id;
          const furnitureId = s.furniture.id;

          if (!s.outOfOrder) {
            if (!newTotalSeats[priceAreaId]) {
              newTotalSeats[priceAreaId] = {};
            }
            if (!newTotalSeats[priceAreaId][furnitureId]) {
              newTotalSeats[priceAreaId][furnitureId] = 0;
            }
            newTotalSeats[priceAreaId][furnitureId] += 1;
            totalCount += 1;
          }
        });
      });
    newTotalSeats.totalSeats = totalCount;
    return newTotalSeats;
  }, [site]);

  const totalPublicSeats = useMemo(() => {
    if (!site || !site.maps) {
      return [];
    }

    const newTotalSeats = {};

    let totalCount = 0;

    site.maps.forEach((map) => {
      map.seats.forEach((s) => {
        const priceAreaId = s.price_area.id;
        const furnitureId = s.furniture.id;

        if (!s.outOfOrder && !s.outOfService) {
          if (!newTotalSeats[priceAreaId]) {
            newTotalSeats[priceAreaId] = {};
          }
          if (!newTotalSeats[priceAreaId][furnitureId]) {
            newTotalSeats[priceAreaId][furnitureId] = 0;
          }
          newTotalSeats[priceAreaId][furnitureId] += 1;
          totalCount += 1;
        }
      });
    });
    newTotalSeats.totalSeats = totalCount;
    return newTotalSeats;
  }, [site]);

  const getBookedSeatsPerDay = useCallback((biArray, dates, stayDuration) => {
    const bookedSeatsPerDay = {};

    dates?.forEach((date) => {
      const validDate = dayjs(date).format('YYYY-MM-DD');
      const currentDayBI = biArray.filter((bi) => (
        dayjs(bi.date).isSame(validDate, 'date')
        && (
          bi.stayDuration === 'fullday'
          || stayDuration === 'fullday'
          || bi.stayDuration === stayDuration
        )
      ));

      // Récupérer le compte de chaque mobilier de chaque zone de prix de la carte et son nb max de dispo
      currentDayBI.forEach((bi) => {
        bi.bookingContent
          ?.forEach((seat) => {
            const priceAreaId = seat.price_area?.id || seat.price_area;
            const furnitureId = seat.furniture?.id || seat.furniture;
            const nbBookedSeats = seat.amount;
            const seatId = seat.id;

            const totalSeatNb = totalSeats[priceAreaId][furnitureId];

            if (!bookedSeatsPerDay[validDate]) {
              bookedSeatsPerDay[validDate] = {};
            }
            if (!bookedSeatsPerDay[validDate][priceAreaId]) {
              bookedSeatsPerDay[validDate][priceAreaId] = {};
            }
            if (!bookedSeatsPerDay[validDate][priceAreaId][furnitureId]) {
              bookedSeatsPerDay[validDate][priceAreaId][furnitureId] = {
                morningCount: 0,
                afternoonCount: 0,
                max: totalSeatNb,
                seats: [],
              };
            }
            if (bi.stayDuration !== 'afternoon') {
              bookedSeatsPerDay[validDate][priceAreaId][furnitureId].morningCount += nbBookedSeats;
            }
            if (bi.stayDuration !== 'morning') {
              bookedSeatsPerDay[validDate][priceAreaId][furnitureId].afternoonCount += nbBookedSeats;
            }
            bookedSeatsPerDay[validDate][priceAreaId][furnitureId].seats.push(seatId);
          });
      });
    });
    return bookedSeatsPerDay;
  }, [totalSeats]);

  const countBookedSeats = useCallback((
    dates = [],
    bookingItems = [],
    stayDuration = 'fullday',
    currentBooking = null,
    mapId,
  ) => {
    const dateList = dates.map((d) => dayjs(d).format('YYYY-MM-DD'));
    const newBookedSeats = {};

    // replace the old current booking item with currently selected values
    const newBiList = currentBooking
      ? [...bookingItems.filter((b) => b.booking.id !== currentBooking.id),
        ...currentBooking.booking_items]
      : bookingItems;

    const bookedSeatsByDay = getBookedSeatsPerDay(newBiList, dateList, stayDuration);

    // La structure est maintenant :
    // bookedSeatsByDay[date][zone_de_prix][mobilier] = { morningCount, afternoonCount, max, sets }

    // Ramenons la structure sur 2 niveaux :
    // newBookedSeats[zone_de_prix][mobilier] = {...}
    // en prenant le min de chaque date.

    if (bookedSeatsByDay.length === 0) {
      return [];
    }

    Object.entries(bookedSeatsByDay).forEach(([date, priceAreas]) => {
      Object.entries(priceAreas).forEach(([priceAreaId, furnitures]) => {
        if (!newBookedSeats[priceAreaId]) {
          newBookedSeats[priceAreaId] = {};
        }

        Object.entries(furnitures).forEach(([furnitureId, seat]) => {
          const nbSeatsMorning = seat.morningCount;
          const nbSeatsAfternoon = seat.afternoonCount;

          let nbSeats = 0;

          if (stayDuration === 'morning') {
            nbSeats = nbSeatsMorning;
          } else if (stayDuration === 'afternoon') {
            nbSeats = nbSeatsAfternoon;
          } else {
            // get the max from both
            nbSeats = nbSeatsMorning > nbSeatsAfternoon ? nbSeatsMorning : nbSeatsAfternoon;
          }

          // calculate the min from the whole dates
          if (newBookedSeats[priceAreaId][furnitureId]) {
            newBookedSeats[priceAreaId][furnitureId] = Math.min(
              newBookedSeats[priceAreaId][furnitureId],
              nbSeats,
            );
          } else {
            newBookedSeats[priceAreaId][furnitureId] = nbSeats;
          }
        });
      });
    });

    return newBookedSeats;
  }, [getBookedSeatsPerDay]);

  const getPriceArea = useCallback((id) => site.price_areas.find((area) => `${area.id}` === `${id}`), [site]);

  const getFurniture = useCallback((id) => site.furnitures.find((f) => `${f.id}` === `${id}`), [site]);

  const getValidStayDurationQueryValues = (currentStayDuration) => {
    const validStayDurations = ['fullday'];

    if (currentStayDuration !== 'fullday') {
      validStayDurations.push(currentStayDuration);
    } else {
      validStayDurations.push('morning');
      validStayDurations.push('afternoon');
    }
    return validStayDurations;
  };

  const val = {
    site,
    fetchSite,
    countBookedSeats,
    totalSeats,
    totalPublicSeats,
    getSeatInfo,
    getBookingItemsBySeat,
    isFetching,
    error,
    usedFurnitures,
    usedPriceAreas,
    getPriceArea,
    getFurniture,
    getValidStayDurationQueryValues,
    getBookedSeatsPerDay,
  };

  return (
    <SiteContext.Provider value={val}>
      {children}
    </SiteContext.Provider>
  );
};

SiteProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSiteProvider = () => useContext(SiteContext);

export default useSiteProvider;
