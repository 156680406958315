import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import useTheme from 'providers/ThemeProvider';
import ForgotPassword from 'auth/pages/ForgotPassword/ForgotPassword';
import ResetPassword from 'auth/pages/ResetPassword/ResetPassword';
import SignInPage from 'auth/pages/SignIn/SignIn';
import ProductsCounter from 'basket/components/ProductsCounter/ProductsCounter';
import Basket from 'basket/pages/Basket/Basket';
import BookingEdit from 'bookings/pages/BookingEdit/BookingEdit';
import BookingsList from 'bookings/pages/BookingsList/BookingsList';
import ComplementaryProducts from 'bookings/pages/ComplementaryProducts/ComplementaryProducts';
import DateSelect from 'bookings/pages/DateSelect/DateSelect';
import HourSelect from 'bookings/pages/HourSelect/HourSelect';
import MapsList from 'bookings/pages/MapsList/MapsList';
import SeatSelector from 'bookings/pages/SeatSelector/SeatSelector';
import UserTypePage from 'bookings/pages/UserTypePage/UserTypePage';
import CGU from 'cgu/pages/CGU';
import navigationRef from 'common/components/AppStateHandler/RootNavigation';
import BlockUserButton from 'common/components/BlockUserButton/BlockUserButton';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import useTrans from 'common/hooks/use-trans';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import HomePage from 'home/pages/HomePage';
import OrderDetail from 'orders/pages/OrderDetail/OrderDetail';
import ProfileOrdersList from 'orders/pages/OrderList/ProfileOrdersList';
import OrderSeatSelect from 'orders/pages/OrderSeatSelect/OrderSeatSelect';
import OrderValidate from 'orders/pages/OrderValidate/OrderValidate';
import CreditCardForm from 'payments/pages/CreditCardForm/CreditCardForm';
import Payment from 'payments/pages/Payment/Payment';
import PaymentRecap from 'payments/pages/PaymentRecap/PaymentRecap';
import React from 'react';
import {
  Dimensions, StyleSheet, View, Platform, useWindowDimensions,
} from 'react-native';
import DropdownAlert from 'react-native-dropdownalert';
import SiteHeaderTitle from 'sites/components/SiteHeaderTitle/SiteHeaderTitle';
import AroundDetail from 'sites/pages/SiteDetail/AroundDetail';
import CategoryDetail from 'sites/pages/SiteDetail/CategoryDetail';
import CategoryServiceDetail from 'sites/pages/SiteDetail/CategoryServiceDetail';
import Chat from 'sites/pages/SiteDetail/Chat';
import ProductDetail from 'sites/pages/SiteDetail/Product';
import ServiceDetail from 'sites/pages/SiteDetail/Service';
import SiteDetail from 'sites/pages/SiteDetail/SiteDetail';
import SitePresentation from 'sites/pages/SitePresentation/SitePresentation';
import SitesList from 'sites/pages/SitesList/SitesList';
import QuickSitesList from 'sites/pages/QuickSitesList/QuickSitesList';
import AccountDeleted from 'user/pages/AccountDeleted/AccountDeleted';
import DeleteAccount from 'user/pages/DeleteAccount/DeleteAccount';
import Profile from 'user/pages/Profile/Profile';
// import BookingActivations from 'waiter/pages/WaiterHome/BookingActivations';
import WaiterBookingDetail from 'waiter/pages/WaiterBookingDetail/WaiterBookingDetail';
import MapHeaderTitle from 'maps/components/MapHeaderTitle/MapHeaderTitle';
import SitesListFiltered from 'sites/pages/SitesList/SitesListFiltered';
import CustomerEdit from 'waiter/pages/WaiterHome/CustomerEdit';
import ContentSelector from 'bookings/pages/ContentSelector/ContentSelector';
import useSite from 'sites/contexts/sites';
import EventEdit from 'events/pages/EventEdit';
import { isMobileMode } from 'common/utils/desktopMode';
import ToggleMapButton from 'maps/components/ToggleMapButton/ToggleMapButton';
import ToggleCustomerButton from 'waiter/pages/WaiterBookingDetail/components/ToggleCustomerButton';
import HomeRedirect from './HomeRedirect';
import WaiterRouter from './WaiterRouting';

const styles = StyleSheet.create(() => {
  const { theme } = useTheme();

  return {
    headerRight: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerLeft: {
      marginLeft: theme.sizings.small,
    },
  };
});

const cardStyle = Platform.OS === 'web' ? { height: '100%' } : null;

const prefix = Linking.makeUrl('/');

const MainStack = createStackNavigator();

export default () => {
  const { t } = useTranslation();
  const { translateText } = useTrans();
  const { setDropDownAlertRef } = useAlert();
  const {
    item: site,
  } = useSite();
  const { theme } = useTheme();
  const linking = {
    enabled: false,
    prefixes: [prefix],
    config: {
      /* ResetPasswordPage: 'reset-password/:code', */
    },
  };

  const headerStyles = {
    headerBackTitleVisible: false,
    headerTitleAlign: 'center',
    headerTitleStyle: {
      color: theme.colors.light,
      fontSize: theme.fontSizes.larger,
      fontFamily: 'MontserratBold',
      width: Dimensions.get('window').width - (theme.normalize(56) + theme.normalize(18)) * 2,
      textAlign: 'center',
    },
    headerBackImage: () => (
      <View style={{
        width: theme.normalize(50), height: theme.normalize(30), paddingTop: theme.normalize(5),
      }}
      >
        <Ionicons
          style={{ marginLeft: theme.sizings.medium }}
          name="ios-arrow-back"
          size={theme.fontSizes.larger}
          color="rgba(255, 255, 255, .75)"
        />
      </View>
    ),
  };

  const twoRightIcons = {
    headerTitleStyle: {
      color: theme.colors.light,
      fontSize: theme.fontSizes.larger,
      fontFamily: 'MontserratBold',
      // 80 taille de deux pictos à droite + marge * 2 côté
      width: Dimensions.get('window').width - theme.normalize(98) * 2,
      textAlign: 'center',
    },
  };

  const gradientHeader = {
    headerBackground: () => (
      <LinearGradient
        colors={[theme.colors.secondary, theme.colors.secondaryDark]}
        start={[0, 0.5]}
        end={[1, 0.5]}
        style={{
          flex: 1,
        }}
      />
    ),
  };

  const darkGradientHeader = {
    headerBackground: () => (
      <LinearGradient
        colors={['rgba(0,0,0,0.6)', 'rgba(0,0,0,0.2)']}
        start={[0, 0]}
        end={[0, 1]}
        style={{
          flex: 1,
        }}
      />
    ),
  };

  const isMobile = isMobileMode(useWindowDimensions());

  return (
    <>
      <NavigationContainer ref={navigationRef} linking={linking}>
        <MainStack.Navigator screenOptions={{ ...headerStyles, cardStyle, ...TransitionPresets.SlideFromRightIOS }}>
          <MainStack.Screen
            name="home"
            options={{
              headerShown: false,
            }}
            component={HomeRedirect}
          />
          <MainStack.Screen
            name="SitesList"
            component={SitesList}
            options={{
              ...gradientHeader,
              title: t('route.sitesList'),
            }}
          />
          <MainStack.Screen
            name="SitesListFiltered"
            component={SitesListFiltered}
            initialParams={{ listId: null }}
            options={{
              ...gradientHeader,
              title: t('route.sitesList'),
            }}
          />
          <MainStack.Screen
            name="QuickSitesList"
            component={QuickSitesList}
            options={{
              ...gradientHeader,
              title: t('route.sitesList'),
            }}
          />
          <MainStack.Screen
            name="BookingsList"
            component={BookingsList}
            options={{
              ...gradientHeader,
              title: t('route.bookingsList'),
            }}
          />
          <MainStack.Screen
            name="HomePage"
            component={HomePage}
            options={{
              ...gradientHeader,
              title: t('route.homePage'),
              headerShown: false,
              statusBarStyle: 'light',
              // headerRight: () => <UserButton />,
              ...TransitionPresets.FadeFromBottomAndroid,
            }}
          />
          <MainStack.Screen
            name="profileOrders"
            component={ProfileOrdersList}
            options={{ ...gradientHeader, title: t('waiter.ordersList') }}
          />
          <MainStack.Screen
            name="ProfilePage"
            component={Profile}
            options={{
              ...gradientHeader,
              title: t('route.profilePage'),
            }}
          />
          <MainStack.Screen
            name="DeleteAccount"
            component={DeleteAccount}
            options={{
              ...gradientHeader,
              title: t('route.deleteAccount'),
            }}
          />
          <MainStack.Screen
            name="AccountDeleted"
            component={AccountDeleted}
            options={{
              ...gradientHeader,
              title: t('route.accountDeleted'),
            }}
          />
          <MainStack.Screen
            name="WaiterHome"
            options={{
              headerShown: false,
            }}
          >
            {(props) => <WaiterRouter {...props} />}
          </MainStack.Screen>
          <MainStack.Screen
            name="SitePresentation"
            initialParams={{ siteId: null, edit: false, mapId: null }}
            component={SitePresentation}
            options={{
              title: <SiteHeaderTitle />,
              headerTransparent: true,
              ...darkGradientHeader,
            }}
          />
          <MainStack.Screen
            name="MapsList"
            initialParams={{ edit: false }}
            component={MapsList}
            options={{
              title: <SiteHeaderTitle />,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="Payment"
            initialParams={{ siteId: null }}
            component={Payment}
            options={{
              title: t('basket.payment'),
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="PaymentRecap"
            initialParams={{ siteId: null }}
            component={PaymentRecap}
            options={{
              title: t('basket.orderRecap'),
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="WaiterBookingDetail"
            initialParams={{ siteId: null }}
            component={WaiterBookingDetail}
            options={() => ({
              title: t('route.bookingDetail'),
              // headerShown: isMobile,
              ...gradientHeader,
              headerRight: isMobile ? () => (
                <View style={styles.headerRight}>
                  <ToggleCustomerButton />
                </View>
              ) : null,
            })}

          />
          <MainStack.Screen
            name="CustomerEdit"
            initialParams={{ siteId: null }}
            component={CustomerEdit}
            options={({ route }) => ({
              title: route.params?.id ? t('customer.editCustomer') : t('customer.addCustomer'),
              // headerShown: isMobile,
              ...gradientHeader,
            })}
          />
          <MainStack.Screen
            name="CreditCardForm"
            initialParams={{ siteId: null }}
            component={CreditCardForm}
            options={{
              title: t('creditCard.creditCard'),
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="OrderDetail"
            initialParams={{ orderId: null }}
            component={OrderDetail}
            options={{
              title: t('route.orderDetail'),
              // headerShown: isMobile,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="OrderSeatSelect"
            initialParams={{ orderId: null }}
            component={OrderSeatSelect}
            options={{
              title: t('route.orderDetail'),
              // headerShown: isMobile,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="EventEdit"
            initialParams={{ orderId: null }}
            component={EventEdit}
            options={{
              title: t('route.eventEdit'),
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="BookingEdit"
            component={BookingEdit}
            initialParams={{
              orderCreation: false,
            }}
            options={{
              title: t('route.bookingDetail'),
              headerShown: isMobile,
              ...gradientHeader,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <ToggleMapButton />
                </View>
              ),
            }}
          />
          <MainStack.Screen
            name="OrderValidate"
            component={OrderValidate}
            initialParams={{ order: null, bookingId: null }}
            options={{
              title: t('route.orderValidate'),
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="SeatSelector"
            component={SeatSelector}
            initialParams={{ siteId: null }}
            options={({ route }) => ({
              title: <MapHeaderTitle mapId={route.params.mapId} />,
              headerTransparent: true,
              ...gradientHeader,
            })}
          />
          <MainStack.Screen
            name="ContentSelector"
            component={ContentSelector}
            initialParams={{ siteId: null }}
            options={{
              title: t('route.contentSelector'),
              headerTransparent: true,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="DateSelect"
            component={DateSelect}
            initialParams={{ siteId: null }}
            options={{
              title: <SiteHeaderTitle />,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="HourSelect"
            component={HourSelect}
            initialParams={{ siteId: null }}
            options={{
              title: <SiteHeaderTitle />,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="UserTypePage"
            component={UserTypePage}
            initialParams={{ siteId: null }}
            options={{
              title: <SiteHeaderTitle />,
              ...gradientHeader,
            }}
          />
          <MainStack.Screen
            name="AroundDetail"
            component={AroundDetail}
            options={({ route }) => ({
              ...twoRightIcons,
              headerTransparent: true,
              ...darkGradientHeader,
              title: translateText(route.params.location.name),
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="SiteDetail"
            initialParams={{ siteId: null, bookingId: null }}
            options={{
              headerShown: false,
              ...twoRightIcons,
              ...darkGradientHeader,
              title: <SiteHeaderTitle />,
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            }}
          >
            {(props) => <SiteDetail {...props} />}
          </MainStack.Screen>
          <MainStack.Screen
            name="ProductDetail"
            initialParams={{ product: null }}
            component={ProductDetail}
            options={({ route }) => ({
              headerTransparent: true,
              ...darkGradientHeader,
              ...twoRightIcons,
              title: translateText(route.params.product.name),
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="CategoryDetail"
            initialParams={{ products: null }}
            component={CategoryDetail}
            options={({ route }) => ({
              title: translateText(route.params.category.name),
              ...gradientHeader,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="ServiceDetail"
            initialParams={{ product: null }}
            component={ServiceDetail}
            options={({ route }) => ({
              headerTransparent: true,
              ...twoRightIcons,
              title: translateText(route.params.service.name),
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="CategoryServiceDetail"
            initialParams={{ services: null }}
            component={CategoryServiceDetail}
            options={({ route }) => ({
              title: translateText(route.params.category.name),
              ...gradientHeader,
              ...twoRightIcons,
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="ComplementaryProducts"
            component={ComplementaryProducts}
            options={{
              ...gradientHeader,
              title: t('route.complementaryProducts'),
            }}
          />
          <MainStack.Screen
            name="SignInPage"
            component={SignInPage}
            initialParams={{ callback: null }}
            options={{
              ...gradientHeader,
              title: t('route.signInPage'),
            }}
          />
          <MainStack.Screen
            name="ForgotPasswordPage"
            component={ForgotPassword}
            initialParams={{ callback: null }}
            options={{
              ...gradientHeader,
              title: t('auth.forgotPassword'),
            }}
          />
          <MainStack.Screen
            name="ResetPasswordPage"
            component={ResetPassword}
            initialParams={{ callback: null }}
            options={{
              ...gradientHeader,
              title: t('auth.resetPassword'),
            }}
          />
          <MainStack.Screen
            name="Basket"
            component={Basket}
            options={{
              ...gradientHeader,
              ...twoRightIcons,
              title: t('route.basket'),
              headerRight: () => (
                <View style={styles.headerRight}>
                  {/* <UserButton /> */}
                  {site.shopProductsMode === 'buy' && (
                  <ProductsCounter />
                  )}
                </View>
              ),
            }}
          />
          <MainStack.Screen
            name="Chat"
            component={Chat}
            options={({ route }) => ({
              headerTransparent: true,
              ...darkGradientHeader,
              title: route.params.user.name,
              headerRight: () => (
                <View style={styles.headerRight}>
                  <BlockUserButton />
                </View>
              ),
            })}
          />
          <MainStack.Screen
            name="Cgu"
            component={CGU}
            options={{
              ...gradientHeader,
              title: t('route.cgu'),
            }}
          />
        </MainStack.Navigator>

      </NavigationContainer>
      { /* translucent={true} bug on android ca fait un decalage vers le bas */}
      <DropdownAlert
        ref={(ref) => setDropDownAlertRef(ref)}
      />
    </>
  );
};
