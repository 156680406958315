import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  View, StyleSheet, TouchableOpacity, Dimensions, ActivityIndicator, ScrollView, useWindowDimensions,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';

// import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import BookingListItem from 'waiter/components/BookingListItem/BookingListItem';
import Text from 'common/components/Text/Text';
import useTheme from 'providers/ThemeProvider';
import DebouncedInput from 'common/components/DebouncedInput/DebouncedInput';
import useTranslation from 'common/contexts/translations';
import Modal from 'common/components/Modal/Modal';
import makeCommonStyles from 'styles/commonStyles';
import CalendarPicker from 'react-native-calendar-picker';
import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import EmptyPage from 'common/components/EmptyPage/EmptyPage';
import ScrollViewLarge from 'common/components/ScrollViewLarge/ScrollViewLarge';
import { checkPrinter, printTicket } from 'common/utils/thermalPrinter/tmPrinter';
import useSiteProvider from 'providers/SiteProvider';
import baseUrl from 'common/utils/getBaseUrl';
import useTrans from 'common/hooks/use-trans';
import Button from 'common/components/Button/Button';
import { isHorizontalMode, isMobileMode } from 'common/utils/desktopMode';
import useEvents from 'events/contexts/events';
import EventMessages from './EventMessages';

const BookingList = () => {
  const {
    date,
    setDate,
    currentStayDuration: stayDuration,
    setCurrentStayDuration: setStayDuration,
    isFetching,
    fetchBookingItems,
    searchBookingItem: search,
    setSearchBookingItem: setSearch,
    bookingItemList,
  } = useBeachVisualizer();

  const { items: refreshedEvents, fetchEventsByDay } = useEvents();

  const { site } = useSiteProvider();

  const { t } = useTranslation();
  const { translateText } = useTrans();
  const [events, setEvents] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [hasPrinter, setHasPrinter] = useState(false);
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const navigation = useNavigation();

  const printerUrl = useMemo(() => site.thermalPrinterHost, [site]);

  const isMobile = isMobileMode(useWindowDimensions());
  const isHorizontal = isHorizontalMode(useWindowDimensions());

  const styles = makeStyles(theme, isMobile);


  const modifiersStyles = {
    selected: {
      borderRadius: 0,
      backgroundColor: '#009ad4',
    },
    today: {
      color: '#ffab41',
    },
  };

  useEffect(() => {
    const getEvents = async () => {
      setEvents(await fetchEventsByDay(date));
    };

    getEvents();
  }, [ fetchEventsByDay, date, setEvents, refreshedEvents ]);

  const handleChangeDate = (value) => {
    const newDate = dayjs(value).startOf('day').format();

    setModalVisible(false);
    if (!dayjs(date).isSame(newDate, 'date')) {
      setDate(newDate);
    }
  };

  useEffect(() => {
    if (printerUrl) {
      checkPrinter(printerUrl).then((isPrinterFound) => {
        setHasPrinter(isPrinterFound)
      })
    }
  }, [printerUrl])

  const printBookingItem = hasPrinter
    ? (bi) => {
      const { customer, clientFirstName, clientName } = bi.booking;

      printTicket(
        printerUrl,
        {
          title: site.name,
          qrCodeValue: `${baseUrl}?code=${bi.booking.code}`,
          code: `${bi.booking.code}`,
          infos: {
            Client: customer?.name
              ? `${customer.civility
                ? `${t(`customer.civility${customer.civility}`)} `
                : ''}${customer.name} ${customer.firstName || ''}`
              : `${clientName} ${clientFirstName || ''}`,
            Date: dayjs(date).format('DD/MM/YYYY'),
            Durée: t(`bookings.${bi.stayDuration}`),
            Places: bi.seats?.map((s) => s.name)?.join(', ') ?? '',
            Sièges: bi.bookingContent?.map((s) => `${s.amount} ${translateText(s.furniture.name)} (${s.price_area.name})`)?.join(", ") ?? '',
          },
        }
      );
    }
    : null;

  const viewBookingListItem = (bi) => (
    <BookingListItem
      key={bi.booking.id}
      bookingItem={bi}
      currentStatus={bi.status}
      printBookingItem={printBookingItem}
    />
  );

  const displayPrintButton = (biList = []) => {
    return (
        hasPrinter && (
          <Button
            icon="print"
            iconFamily="MaterialIcons"
            iconColor="purple"
            iconSize="medium"
            size="medium"
            style={[styles.iconOnlyButton, {
              backgroundColor: theme.colors.white,
              borderWidth: 1,
              borderColor: '#dddddd',
          borderStyle: 'solid',
              borderRadius: theme.radius.small,
              height: theme.sizings.mediumLarge
            }]}
            onPress={() => {
              biList.map((bi) => printBookingItem(bi));
            }}
            noPadding
          />
        )
    );
  };

  const listNotFullyPlaced = () => {
    const listSeatNotAttributed = bookingItemList.filter(
      (bi) => (!bi.isFullyPlaced),
    );
    const listAttributedNotArrived = bookingItemList.filter(
      (bi) => (bi.status === 'reserved'
      && (bi.isFullyPlaced)),
    );

    return listSeatNotAttributed.length || listAttributedNotArrived.length ? (
      <View style={{ marginBottom: theme.sizings.small }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text isBold>{t('bookings.bookingItemStatus_notFullyPlaced')}</Text>
          {hasPrinter && displayPrintButton([...listSeatNotAttributed, ...listAttributedNotArrived])}
        </View>
        {listSeatNotAttributed.map((bi) => viewBookingListItem(bi))}
        {listAttributedNotArrived.map((bi) => viewBookingListItem(bi))}
      </View>
    ) : null;
  };

  const listPlaced = () => {
    const list = bookingItemList
      .filter((bi) => bi.status === 'checked_in' && bi.isFullyPlaced);

    return list.length ? (
      <View style={{ marginBottom: theme.sizings.small }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text isBold>{t('bookings.bookingItemStatus_checked_in')}</Text>
          {hasPrinter && displayPrintButton(list)}
        </View>
        {list.map((bi) => viewBookingListItem(bi))}
      </View>
    ) : null;
  };

  const listNoShow = () => {
    const list = bookingItemList
      .filter((bi) => bi.status === 'noShow');

    return list.length ? (
      <View style={{ marginBottom: theme.sizings.small }}>
        <Text isBold>{t('bookings.bookingItemStatus_noShow')}</Text>
        {list.map((bi) => viewBookingListItem(bi))}
      </View>
    ) : null;
  };

  const listOthers = () => {
    const list = bookingItemList
      .filter((bi) => bi.status !== 'reserved' && bi.status !== 'checked_in' && bi.status !== 'noShow');

    return list.length ? (
      <View style={{ marginBottom: theme.sizings.small }}>
        <Text isBold>{t('bookings.bookingItemStatus_others')}</Text>
        {list
          .map((bi) => <BookingListItem key={bi.booking.id} bookingItem={bi} currentStatus={bi.status} />)}
      </View>
    ) : null;
  };

  useEffect(() => {
    const refreshBookingItems = navigation.addListener('focus', async () => {
      setTimeout(async () => {
        await fetchBookingItems();
      }, 500);
    });

    return refreshBookingItems;
  }, [navigation, fetchBookingItems]);

  return (
    <View style={styles.container}>
      {/* <LoadingSpinner visible={isFetching} /> */}
      <View style={styles.header}>
        <View style={styles.headerTop}>
          <View style={styles.headerDate}>
            <TouchableOpacity
              style={styles.buttonNav}
              onPress={() => setDate(dayjs(date).subtract(1, 'month').format())}
            >
              <FontAwesome
                name="angle-double-left"
                color={theme.colors.dark}
                size={theme.fontSizes.large * 1.2}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonNav}
              onPress={() => setDate(dayjs(date).subtract(1, 'day').format())}
            >
              <FontAwesome
                name="angle-left"
                color={theme.colors.dark}
                size={theme.fontSizes.large * 1.2}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.bookingDate}
              onPress={() => setModalVisible(true)}
            >
              <Text size="medium">{dayjs(date).format('L')}</Text>
              <FontAwesome
                style={{ paddingLeft: theme.sizings.small }}
                name="calendar"
                color={theme.colors.primary}
                size={theme.fontSizes.medium}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonNav}
              onPress={() => setDate(dayjs(date).add(1, 'day').format())}
            >
              <FontAwesome
                name="angle-right"
                color={theme.colors.dark}
                size={theme.fontSizes.large * 1.2}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonNav}
              onPress={() => setDate(dayjs(date).add(1, 'month').format())}
            >
              <FontAwesome
                name="angle-double-right"
                color={theme.colors.dark}
                size={theme.fontSizes.large * 1.2}
              />
            </TouchableOpacity>
          </View>

          {/* Event messages */}
          
            <EventMessages events={events} />
        </View>

        <View style={styles.headerBottom}>
          <View>
            <ButtonGroup
              options={[{
                value: 'fullday',
                text: t('bookings.fullday'),
              }, {
                value: 'morning',
                text: t('bookings.morning'),
              }, {
                value: 'afternoon',
                text: t('bookings.afternoon'),
              }]}
              onChange={setStayDuration}
              value={stayDuration}
            />
          </View>

          <Modal
            style={commonStyles.modal}
            transparent
            visible={modalVisible}
            ariaHideApp={false}
          >
            <View style={commonStyles.modalInner}>
              <TouchableOpacity
                style={commonStyles.modalMask}
                onPress={() => setModalVisible(false)}
              />
              <View style={commonStyles.modalBox}>

                <CalendarPicker
                  allowRangeSelection={false}
                  onDateChange={handleChangeDate}
                  weekdays={t('common.weekdays').split(',')}
                  months={t('common.months').split(',')}
                  startFromMonday
                  previousTitle={t('common.previous')}
                  nextTitle={t('common.next')}
                  selectedDayColor={theme.colors.secondary}
                  selectedDayStyle={styles.hideSelectedDay}
                  todayBackgroundColor={theme.colors.lightPurple}
                  customDatesStyles={[date]}
                  modifiersStyles={modifiersStyles}
                  width={!isMobile || isHorizontal
                    ? theme.normalize(400)
                    : Dimensions.get('window').width - theme.sizings.medium * 3}
                  mode="single"
                  selectedStartDate={date}
                  initialDate={date || []}
                />

              </View>
            </View>
          </Modal>

          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ marginRight: theme.sizings.small, flex: 1 }}>
              <DebouncedInput
                value={search}
                onChange={setSearch}
                placeholder={t('common.search')}
              />
            </View>
            <TouchableOpacity>
              <FontAwesome
                color={theme.colors.primary}
                size={theme.fontSizes.larger}
                name="search"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <ScrollView style={{ flex: 1, padding: theme.sizings.small }}>
        {!bookingItemList?.length && isFetching > 0 && (
          <View style={{ width: '100%', height: theme.normalize(300), alignItems: 'center' }}>
            <ActivityIndicator />
          </View>
        )}
        {!bookingItemList?.length && isFetching === 0 && (
          <EmptyPage text={t('bookings.waiterEmpty')} image={<></>} />
        )}

        {Boolean(bookingItemList?.length) && (
          <>
            {listNotFullyPlaced()}

            {listPlaced()}

            {listNoShow()}

            {listOthers()}
          </>
        )}

      </ScrollView>
    </View>
  );
};
const makeStyles = (theme, isMobile) => StyleSheet.create({
  container: {
    height: '100%',
  },
  bookingDate: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: theme.sizings.tiny,
    borderRadius: theme.radius.rounded,
    marginVertical: theme.sizings.small,
    paddingVertical: theme.sizings.small,
    backgroundColor: theme.colors.white,
    width: theme.sizings.large * 5,
  },
  header: {
    marginBottom: theme.sizings.medium,
  },
  headerTop: {
    backgroundColor: theme.colors.secondary,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.small,
  },
  headerDate: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.normalize(55),
    maxHeight: theme.normalize(55),
  },
  button: {
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.rounded,
    padding: theme.sizings.small,
    width: theme.sizings.large * 2,
    alignItems: 'center',
  },
  buttonNav: {
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: theme.radius.rounded,
    width: theme.sizings.larger * 0.8,
    height: theme.sizings.larger * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: theme.sizings.tiny,
  },
  headerBottom: {
    backgroundColor: theme.colors.greyLighter,
    paddingHorizontal: theme.sizings.medium,
  },
});

export default BookingList;
