import PropTypes from 'prop-types';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Text from 'common/components/Text/Text';
import { useTranslation } from 'react-i18next';

const StayDuration = ({
  stayDuration,
}) => {
  const { theme } = useTheme();
  const styles = makeStyles(theme);
  const { t } = useTranslation();

  return (
    <View style={styles.iconText}>
      <View style={styles.stayDurationMask}>
        <View style={[styles.stayDurationFill, {
          width: stayDuration === 'fullday' ? '100%' : '50%',
          marginLeft: stayDuration === 'afternoon' ? '50%' : 0,
        }]}
        />
      </View>
      <Text size="small" style={{ marginLeft: 8 }}>{t(`bookings.${stayDuration}`)}</Text>
    </View>
  );
};

StayDuration.propTypes = {
  stayDuration: PropTypes.string,
};

StayDuration.defaultProps = {
  stayDuration: 'fullday',
};

const makeStyles = (theme) => StyleSheet.create({
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  stayDurationMask: {
    borderRadius: theme.radius.rounded,
    backgroundColor: theme.colors.greyLighter,
    height: theme.sizings.medium,
    width: theme.sizings.medium,
    overflow: 'hidden',
  },
  stayDurationFill: {
    backgroundColor: theme.colors.purple,
    height: '100%',
  },
});

export default StayDuration;
