import Price from 'common/components/Price/Price';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import formatPrice from 'common/utils/formatPrice';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import useSite from 'sites/contexts/sites';
import useTheme from 'providers/ThemeProvider';

const OrderItem = ({ item, variant }) => {
  const { item: site } = useSite();
  const { theme } = useTheme();
  const styles = makeStyles(theme);

  const colorStatus = useMemo(() => {
    if (item.barman?.color) {
      return item.barman.color;
    }
    if (item.state === 'created') {
      return theme.colors.greyLighter;
    }

    if (item.state === 'done' || item.state === 'ready') {
      return theme.orderStateColor.cornflowerbluebackground;
    }

    return theme.orderStateColor.goldenrodbackground;
  }, [item, theme]);

  return (
    <View>
      <View style={styles.viewDetailContainer}>
        <View style={styles.viewLeftDetailContainer}>
          <TranslatedText size="medium" color="black" value={item.name} />
          {variant?.option_variant && (
          <View style={styles.variant}>
            <TranslatedText size="small" value={variant.option_variant.name} />
          </View>
        )}
          <View
            style={[
              styles.textQuantity,
              {
                backgroundColor: colorStatus,
              },
            ]}
          >
            <Text size="medium" color="dark">{`x${item.quantity}`}</Text>
          </View>
        </View>
        <View style={styles.viewRightDetailContainer}>
          <Price
            viewStyle={{ justifyContent: 'flex-end', alignItems: 'center' }}
            style={{ fontSize: 15, color: '#b9b4be' }}
            price={item.price * item.quantity}
            color="dark"
          />
        </View>
      </View>
      <View style={{ paddingHorizontal: theme.sizings.small }}>
        {item.selected_options.map((option) => (
          <>
            {option.variants.map((variant, index) => (
              <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <TranslatedText value={variant.name} size="medium" />
                <Text isBold color="greyDarker" size="medium">
                  {variant.price ? formatPrice(variant.price, site?.currency) : formatPrice(0, site?.currency)}
                </Text>
              </View>
            ))}
          </>
        ))}
      </View>
    </View>
  );
};

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
  variant: PropTypes.object,
};

OrderItem.defaultProps = {
  variant: {},
};

const makeStyles = (theme) => StyleSheet.create({
  variant: {
    marginLeft: theme.sizings.small,
    backgroundColor: theme.colors.secondaryLight,
    paddingHorizontal: theme.sizings.small,
    borderRadius: theme.radius.rounded,
  },

  viewLeftDetailContainer: {
    flex: 0.6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  viewDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
  },

  textQuantity: {
    borderRadius: theme.sizings.medium,
    marginLeft: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },

  viewRightDetailContainer: {
    flex: 0.4,
    justifyContent: 'flex-end',
  },
});

export default OrderItem;
