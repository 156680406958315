/* eslint-disable max-len */
import { FontAwesome } from '@expo/vector-icons';
import Button from 'common/components/Button/Button';
import Gradient from 'common/components/Gradient/Gradient';
import Text from 'common/components/Text/Text';
import TranslatedText from 'common/components/TranslatedText/TranslatedText';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  StyleSheet, TouchableHighlight, TouchableOpacity, View,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import formatPrice from 'common/utils/formatPrice';
import useSite from 'sites/contexts/sites';

const makeStyles = (theme) => StyleSheet.create({
  description: {
    width: '80%',
  },

  optionsTitle: {
    paddingTop: theme.sizings.medium,
  },
  optionsTitleText: {
    justifyContent: 'center',
  },
  options: {
    marginVertical: theme.sizings.smallMedium,
    padding: theme.sizings.smallMedium,
    backgroundColor: theme.colors.darkGrey,
    borderRadius: theme.radius.large,
  },
  optionVariants: {
    paddingTop: theme.sizings.smallMedium,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  option: {
    paddingHorizontal: theme.sizings.small,
    paddingVertical: theme.sizings.tiny,
    marginRight: theme.sizings.small,
    marginBottom: theme.sizings.small,
    minWidth: theme.normalize(80),
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.32)',
  },
  buttonsGroupRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.sizings.small,
    borderTopColor: 'rgba(255,255,255,0.2)',
    borderTopWidth: 1,
  },
  buttonsGroup: {
    width: theme.normalize(100),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.rounded,
    overflow: 'hidden',
    paddingHorizontal: theme.sizings.small,
  },

  quantity: {
    marginVertical: 0,
    marginHorizontal: theme.sizings.small,
  },

  productButton: {
    paddingHorizontal: theme.sizings.small,
  },
});

const ProductWithOptionForm = ({
  entity, setSelectedOptions, formValues, setQuantity, removeItem,
}) => {
  const { options, price: basePrice } = entity;
  const { selectedOptions, offsetPrice, quantity } = formValues;
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);
  const styles = makeStyles(theme);
  const { item: site } = useSite();

  const handlePressOption = useCallback((optionIndex, variantId, variantPrice, multiple) => {
    const selectedCopy = [...selectedOptions];

    let offset = offsetPrice;

    if (multiple) {
      const variantIndex = selectedCopy[optionIndex].variants.findIndex((v) => v === variantId);

      if (variantIndex >= 0) {
        selectedCopy[optionIndex]?.variants.splice(variantIndex, 1);
        offset -= variantPrice;
      } else {
        selectedCopy[optionIndex]?.variants.push(variantId);
        offset += variantPrice;
      }
    } else if (selectedCopy[optionIndex].variants?.[0] !== variantId) {
      selectedCopy[optionIndex].variants = [variantId];
      offset = basePrice + Number((selectedCopy[optionIndex]?.variants?.[0] === variantId) ? variantPrice : (variantPrice * -1));
    }
    setSelectedOptions({
      selectedOptions: selectedCopy,
      offsetPrice: offset,
      quantity,
    });
  }, [basePrice, offsetPrice, quantity, selectedOptions, setSelectedOptions]);

  return (
    <View style={styles.options}>
      { /* eslint-disable-next-line camelcase  */}
      {options.map(({ option, variant_prices }, optionIndex) => (
        <View key={`option-${optionIndex}`}>
          <View style={styles.optionsTitleText}>
            <TranslatedText color="white" value={option.name} suffix={option.multiple ? null : '*'} />
          </View>
          <View style={styles.optionVariants}>
            {variant_prices.map((variant) => (
              <TouchableHighlight
                key={`option-${optionIndex}-${variant.id}`}
                style={[styles.option, {
                  backgroundColor: selectedOptions[optionIndex]?.variants.find((v) => v === variant.option_variant.id) ? (
                    'rgba(255, 255 ,255, 0.32)') : 'transparent',
                }]}
                onPress={() => handlePressOption(optionIndex, variant.option_variant.id, variant.price, option.multiple)}
              >
                <View>
                  { /* eslint-disable-next-line camelcase  */}
                  <TranslatedText color="white" value={variant.option_variant.name} />
                </View>
              </TouchableHighlight>
            ))}
          </View>
        </View>
      ))}

      <View style={styles.buttonsGroupRow}>
        <TouchableOpacity style={commonStyles.headerIcon} onPress={() => removeItem()}>
          <FontAwesome
            color={theme.colors.danger}
            size={theme.fontSizes.large}
            name="trash"
          />
        </TouchableOpacity>

        <Text color="white">{formatPrice(offsetPrice * quantity, site?.currency)}</Text>

        <View style={styles.buttonsGroup}>
          <Gradient height={theme.normalize(40)} />
          <Button
            noPadding
            variant="transparent"
            iconSize="default"
            style={styles.productButton}
            text="-"
            onPress={() => setQuantity(-1)}
          />
          <Text size="large" color="light" style={styles.quantity}>{quantity}</Text>
          <Button
            noPadding
            iconSize="default"
            variant="transparent"
            style={styles.productButton}
            text="+"
            onPress={() => setQuantity(1)}
          />
        </View>
      </View>
    </View>
  );
};

ProductWithOptionForm.propTypes = {
  entity: PropTypes.object.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  setQuantity: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default ProductWithOptionForm;
