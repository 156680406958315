import { useNavigation } from '@react-navigation/native';
import BookingForm from 'bookings/components/BookingForm/BookingForm';
import useBooking from 'bookings/contexts/bookings';
import CustomButton from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import Text from 'common/components/Text/Text';
import useAlert from 'common/contexts/alert';
import useTranslation from 'common/contexts/translations';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import QRCode from 'react-native-qrcode-svg';
import {
  ActivityIndicator, TouchableOpacity, View, Platform,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import makeCommonStyles from 'styles/commonStyles';
import WaiterLayout from 'waiter/components/WaiterLayout/WaiterLayout';
import useBeachVisualizer from 'waiter/pages/WaiterHome/BeachVisualizer/contexts/beachVisualizer';
import useAuth from 'auth/contexts/auth';
import { fetchJSON } from 'common/utils/fetchJSON';
import baseUrl from 'common/utils/getBaseUrl';
// import { isMobileMode } from 'common/utils/desktopMode';

const BookingEdit = ({ route }) => {
  const {
    editedBooking, orderCreation, initialSeats, initialStayDuration,
    editPositionsAtDate, newCustomer, date: initialDate,
  } = route.params;

  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const { setAlert } = useAlert();
  const {
    save, update, remove, isFetching, error, fetchItem, item: fetchedBooking, setBookingConflicts, conflictingBookings,
  } = useBooking();
  const {
    refreshData,
    setBookingItemFocused,
    setSeatFocused,
    setSelectedSeats,
    date: selectedDate,
    currentStayDuration,
  } = useBeachVisualizer();
  const { user } = useAuth();
  const navigation = useNavigation();
  const { theme } = useTheme();
  const commonStyles = makeCommonStyles(theme);

  const date = useMemo(() => initialDate || selectedDate,
    [initialDate, selectedDate]);
  const stayDuration = useMemo(() => initialStayDuration || currentStayDuration,
    [initialStayDuration, currentStayDuration]);

  // clear homepage datas
  useEffect(() => {
    setBookingItemFocused(null);
    setSeatFocused(null);
  }, [setBookingItemFocused, setSeatFocused]);

  useEffect(() => {
    if (editedBooking) {
      fetchItem(editedBooking.id);
    }
  }, [fetchItem, editedBooking]);

  const booking = useMemo(() => {
    if (fetchedBooking || !isFetching) {
      return fetchedBooking;
    }
    return editedBooking;
  }, [editedBooking, fetchedBooking, isFetching]);

  const handleNavigate = useCallback(() => {
    setModalVisible(false);
    if (orderCreation) {
      navigation.navigate('OrderDetail', { seat: booking?.seats[0], booking });
    } else {
      navigation.pop();
    }
  }, [navigation, orderCreation, booking]);

  const handleDelete = useCallback(async (bookingId) => {
    await remove(bookingId);
    setSeatFocused(null);
    setBookingItemFocused(null);
    setSelectedSeats([]);
    refreshData();

    setAlert({ color: 'success', title: t('common.success'), message: t('bookings.deactivatedBooking') });
    !Platform.OS === 'web' && ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock[(user && ['waiter', 'barman'].includes(user.role?.type))
        ? 'DEFAULT'
        : 'PORTRAIT_UP'],
    );
    navigation.reset({
      index: 0,
      routes: [{ name: ['waiter', 'barman'].includes(user.role.type) ? 'WaiterHome' : 'HomePage' }],
    });
  }, [
    remove,
    setSeatFocused,
    setBookingItemFocused,
    setSelectedSeats,
    refreshData,
    setAlert,
    t,
    user,
    navigation,
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      const bookingToSave = { ...data };

      await fetchJSON({
        url: 'booking-item-conflicts',
        method: 'POST',
        payload: conflictingBookings,
      });

      setBookingConflicts({});

      bookingToSave.booking_items = bookingToSave.booking_items.map((bi) => ({
        ...bi,
        date: dayjs(bi.date).format('YYYY-MM-DD'),
      }));

      if (bookingToSave.id) {
        await update(bookingToSave.id, bookingToSave);

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('bookings.editSuccess') });
          refreshData();
          fetchItem(editedBooking.id);
          handleNavigate();
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('bookings.editError') });
        }
      } else {
        await save({
          ...bookingToSave,
        });

        if (!error) {
          setAlert({ color: 'success', title: t('common.success'), message: t('bookings.createSuccess') });
          setSeatFocused(null);
          setBookingItemFocused(null);
          setSelectedSeats([]);
          refreshData();
          if (editedBooking) {
            fetchItem(editedBooking.id);
          }
          setModalVisible(true);
        } else {
          setAlert({ color: 'danger', title: t('common.error'), message: t('bookings.createError') });
        }
      }
    },
    [
      conflictingBookings,
      update,
      error,
      setAlert,
      t,
      refreshData,
      fetchItem,
      editedBooking,
      handleNavigate,
      save,
      setSeatFocused,
      setBookingItemFocused,
      setBookingConflicts,
      setSelectedSeats,
    ],
  );

  // const isMobile = isMobileMode(useWindowDimensions());

  return (
    <WaiterLayout noPadding noScroll hideMenu>
      <>
        {isFetching && (
          <View style={{
            justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: theme.normalize(400),
          }}
          >
            <ActivityIndicator size="large" color={theme.colors.primary} />
          </View>
        )}
        { !isFetching && (
        <BookingForm
          onSubmit={handleSubmit}
          booking={editedBooking ? booking : undefined} // clear old booking if create new
          initialSeats={initialSeats}
          initialStayDuration={stayDuration}
          newCustomer={newCustomer}
          editPositionsAtDate={editPositionsAtDate}
          handleDelete={handleDelete}
          date={date}
        />
        )}
        <Modal
          style={commonStyles.modal}
          transparent
          visible={modalVisible}
          ariaHideApp={false}
        >
          <View style={commonStyles.modalInner}>
            <TouchableOpacity
              onPress={() => setModalVisible(false)}
              style={commonStyles.modalMask}
            />
            <View
              style={{
                ...commonStyles.modalBox,
                flexDirection: 'column',
              }}
            >
              <Text style={commonStyles.modalText}>
                {booking?.code
                  ? t('orders.giveCodeToClient')
                  : t('bookings.errorCreating')}
              </Text>
              <Text size="largest" style={{ alignSelf: 'center' }}>
                {booking?.code ?? ''}
              </Text>

              {booking?.code && (
                <View style={{ alignSelf: 'center' }}>
                  <QRCode
                    size={200}
                    value={`${baseUrl}?code=${booking.code}`}
                  />
                </View>
              )}
              <View style={commonStyles.modalFooter}>
                <CustomButton
                  variant="success"
                  text={t('common.confirm')}
                  style={{ ...commonStyles.modalButton, flex: 1 }}
                  onPress={handleNavigate}
                />
              </View>
            </View>
          </View>
        </Modal>
      </>
    </WaiterLayout>
  );
};

BookingEdit.propTypes = {
  route: PropTypes.object.isRequired,
};

export default BookingEdit;
