/* eslint-disable max-len */
import Text from 'common/components/Text/Text';
import React, {
  useCallback,
} from 'react';
import {
  StyleSheet,
  View, useWindowDimensions,
} from 'react-native';
import useTheme from 'providers/ThemeProvider';
import Button from 'common/components/Button/Button';
import PropTypes from 'prop-types';
import { isMobileMode } from 'common/utils/desktopMode';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import BookingPayments from 'waiter/components/BookingPayments/BookingPayments';
import dayjs from 'dayjs';
import useAuth from 'auth/contexts/auth';

const Orders = ({ completeBooking, seatId }) => {
  const { theme } = useTheme();
  const isMobile = isMobileMode(useWindowDimensions());
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { user } = useAuth();

  const styles = makeStyles(theme, isMobile);

  const createSpecialOffer = useCallback((bookingId) => {
    // router.push(`/special-offers/add?destBooking=${bookingId}`);
  }, []);

  const goToOrder = useCallback(
    () => navigate('OrderDetail', {
      seat: seatId || completeBooking?.booking_items
        .find((bi) => (
          dayjs().isSame(bi.date, 'date')
          && bi.status === 'checked_in'
        ))?.seats[0].id,
      booking: completeBooking?.id,
    }), [navigate, completeBooking, seatId],
  );

  return (
    <View style={{ ...styles.detailOrders, ...styles.topSeparatorUnder }}>
      <View style={[styles.detailOrdersTitle]}>
        <View style={styles.addOrderLabel}>
          <Text isBold size="large">{t('waiter.orders')}</Text>
        </View>

        { ['waiter', 'barman', 'manager'].includes(user?.role.type) && (
        <Button
          variant="light"
          icon="utensils"
          iconFamily="fontawsome5"
          text={t('bookings.addNewOrder')}
          onPress={goToOrder}
          style={styles.buttonOrder}
          iconColor="orange"
          small
          disabled={!completeBooking.booking_items.find((bi) => dayjs().isSame(bi.date, 'date') && bi.status === 'checked_in')}
        />
        )}
      </View>

      <BookingPayments booking={completeBooking} />

      <Button
        variant="light"
        icon="gift"
        text={t('bookings.createOffer')}
        onPress={() => createSpecialOffer(completeBooking?.id)}
        iconColor="primary"
        disabled
        style={styles.buttonOrder}
        small
      />

    </View>
  );
};

export default Orders;

const makeStyles = (theme, isMobile) => StyleSheet.create({
  splitPage: {
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    height: '100%',
  },
  mainPart: {
    flex: isMobile ? undefined : 1,
  },
  customerPart: {
    width: isMobile ? '100%' : '35%',
    maxWidth: isMobile ? undefined : '35%',
    backgroundColor: theme.colors.white,
    shadowColor: theme.colors.greyLighter,
    shadowOffset: {
      width: -2,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    elevation: 1,
  },
  clientInfo: {
    marginBottom: theme.sizings.medium,
  },

  editBookingLabel: {
    justifyContent: 'center',
  },

  bookingItemInfo: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: isMobile ? 'column' : 'row',
  },
  bookingItemSituation: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },

  bookingFullPeriod: {
    padding: theme.sizings.medium,
    marginBottom: theme.sizings.medium,
  },
  bookingActions: {
    padding: theme.sizings.medium,
    backgroundColor: '#f6f6f6',

  },

  detailOrders: {
    padding: theme.sizings.medium,
    width: '100%',
  },

  editBooking: {
    display: 'flex',
    flexDirection: 'row',
  },

  borderBottom: {
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
    marginVertical: theme.sizings.medium,
  },

  call: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: theme.sizings.medium,
    paddingVertical: theme.sizings.small,
    borderRadius: theme.radius.medium,
    marginBottom: theme.sizings.small,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  info: {
    flex: 1,
  },

  infoText: {
    fontFamily: 'Bebas',
  },

  date: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  dateIcon: {
    marginRight: theme.sizings.small,
  },

  seats: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: theme.sizings.small,
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.sizings.small,
  },

  button: {
    width: theme.normalize(50),
    height: theme.normalize(50),
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: theme.radius.rounded,
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailOrdersTitle: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginVertical: theme.sizings.medium,
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
  },

  addOrder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addOrderLabel: {
    justifyContent: 'center',
    marginBottom: theme.sizings.medium,
  },

  addOrderButton: {
    borderRadius: theme.radius.rounded,
  },

  containerStyle: {
    marginVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.small,
  },
  horizontal: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchLabel: {
    marginRight: theme.sizings.small,
  },
  switchLabelIcon: {
    marginRight: theme.sizings.small,
    opacity: 0.6,
  },
  switchBooleanBox: {
    paddingVertical: theme.sizings.small,
    paddingHorizontal: theme.sizings.medium,
    borderRadius: theme.radius.rounded,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonBar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? theme.sizings.medium : undefined,
  },
  buttonOrder: {
    marginLeft: theme.sizings.medium,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: theme.sizings.tiny,
  },
  buttonEditItem: {
    height: theme.normalize(38),
    width: theme.normalize(38),
  },
  bookingItemRow: {
    marginTop: theme.sizings.medium,
    paddingTop: theme.sizings.medium,
    borderTopColor: theme.colors.greyLighter,
    borderTopWidth: 1,
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'flex-end' : 'center',
    justifyContent: 'space-between',
  },
  dirRow: {
    flexDirection: 'row',
  },
  dirColumn: {
    flexDirection: 'column',
  },
});

Orders.propTypes = {
  completeBooking: PropTypes.object.isRequired,
  seatId: PropTypes.any.isRequired,
};
