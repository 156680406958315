/* eslint-disable global-require */
import React from 'react';
import { useFonts } from 'expo-font';
import { Platform } from 'react-native';

import Routing from './Routing';
import AppProvider from './AppProvider';
import AppLoader from './AppLoader';

if (Platform.OS === 'web') {
  // Code for IOS to prevent pinch zoom manage by safari
  document.addEventListener('gesturestart', (e) => {
    e.preventDefault();
  });
  require('./cssloader');
}

export default function App() {
  const [fontsLoaded] = useFonts({
    Lato: require('assets/fonts/Lato/Lato-Regular.ttf'),
    LatoBold: require('assets/fonts/Lato/Lato-Bold.ttf'),
    Avenir: require('assets/fonts/Avenir/Avenir-Medium.ttf'),
    AvenirBold: require('assets/fonts/Avenir/Avenir-Black.ttf'),
    Roboto: require('assets/fonts/Roboto/Roboto-Regular.ttf'),
    RobotoBold: require('assets/fonts/Roboto/Roboto-Bold.ttf'),
    Montserrat: require('assets/fonts/Montserrat/Montserrat-Regular.ttf'),
    MontserratBold: require('assets/fonts/Montserrat/Montserrat-Bold.ttf'),
    Bebas: require('assets/fonts/Bebas/BebasNeue.otf'),
  });

  return (
    <AppProvider>
      <AppLoader>
        {fontsLoaded && <Routing />}
      </AppLoader>
    </AppProvider>
  );
}
